/* eslint-disable react/no-array-index-key */

import React, { memo, useCallback, useMemo, useRef } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../../../hooks';
import { DotsIndicator } from '../../../DotsIndicator';

// ─────────────────────────────────────────────────────────────────────────────

export const CarouselDots = memo((props: CarouselDotsProps) => {
  const {
    slidesCount,
    activeSlideIndex,
    accessibilityLabel,
    accessibilityHint,
    colors = DOT_BG_COLORS,
    layout,
    onPress,
  } = props;

  // ─────────────────────────────────────────────────────────────────────

  const dots = useMemo(
    () => Array.from({ length: slidesCount }),
    [slidesCount],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    layout === 'compact' ? styles.containerCompact : styles.containerStandard,
  ];

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRenderDynamicDots = slidesCount > 5;

  // ─────────────────────────────────────────────────────────────────────

  if (shouldRenderDynamicDots) {
    return (
      <View style={containerStyles}>
        <DotsIndicator
          active={activeSlideIndex}
          length={slidesCount}
          activeColor={colors.active.idle}
          inactiveColor={colors.inactive.idle}
        />
      </View>
    );
  }

  return (
    <View style={containerStyles}>
      {dots.map((_dot, index) => (
        <CarouselDot
          key={index}
          isActive={activeSlideIndex === index}
          index={index}
          accessibilityLabel={accessibilityLabel}
          accessibilityHint={accessibilityHint}
          colors={colors}
          onPress={onPress}
        />
      ))}
    </View>
  );
});

// ─── Components ──────────────────────────────────────────────────────────────

const CarouselDot = memo((props: CarouselDotProps) => {
  const {
    index,
    accessibilityLabel,
    accessibilityHint,
    isActive,
    colors,
    onPress,
  } = props;

  const pressableRef = useRef(null);
  const { isHovered } = usePressableState(pressableRef);

  // ─── a11y ────────────────────────────────────────────────────────────

  const readableIndex = index + 1;
  const accessibilityLabelWithIndex = `${accessibilityLabel} ${readableIndex}`;
  const accessibilityHintWithIndex = accessibilityHint
    ? `${accessibilityHint} ${readableIndex}`
    : undefined;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    if (isActive) return;

    onPress(index);
  }, [index, isActive, onPress]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const dotHover = useDerivedValue(() =>
    withTiming(isHovered ? 1 : 0, { duration: theme.transitions.base }),
  );

  const dotAnimatedStyle = useAnimatedStyle(() => {
    const { inactive, active } = colors;

    if (isActive) {
      return {
        backgroundColor: interpolateColor(
          dotHover.value,
          [0, 1],
          [active.idle, active.hover],
        ),
      };
    }

    return {
      backgroundColor: interpolateColor(
        dotHover.value,
        [0, 1],
        [inactive.idle, inactive.hover],
      ),
    };
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={pressableRef}
      style={styles.dotContainer}
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabelWithIndex}
      accessibilityHint={accessibilityHintWithIndex}
      accessibilityState={{ selected: isActive }}
      onPress={handleOnPress}
    >
      <Animated.View style={[styles.dot, dotAnimatedStyle]} />
    </Pressable>
  );
});

// ─── Constants ───────────────────────────────────────────────────────────────

const DOT_SIZE = theme.spacing['2'];

const DOT_BG_COLORS: CarouselDotColors = {
  inactive: {
    idle: theme.colors.GRAY,
    hover: theme.colors.LIGHT,
  },
  active: {
    idle: theme.colors.KALE,
    hover: theme.colors.KALE_HOVER,
  },
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  containerStandard: {
    marginVertical: theme.spacing['6'],
  },
  containerCompact: {
    marginBottom: theme.spacing['5'],
  },
  dotContainer: {
    padding: theme.spacing['1'],
  },
  dot: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CarouselDotsProps = Readonly<{
  layout: 'compact' | 'standard';
  slidesCount: number;
  activeSlideIndex: number;
  accessibilityLabel: string;
  accessibilityHint?: string;
  colors?: CarouselDotColors;
  onPress: (slideIndex: number) => void;
}>;

type CarouselDotProps = Readonly<{
  index: number;
  isActive: boolean;
  accessibilityLabel: string;
  accessibilityHint?: string;
  colors: CarouselDotColors;
  onPress: (slideIndex: number) => void;
}>;

type CarouselDotColors = {
  inactive: { idle: string; hover: string };
  active: { idle: string; hover: string };
};
