/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LastOrderStatusQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type LastOrderStatusQuery = {
  readonly __typename?: 'Query';
  readonly orderStatus:
    | { readonly __typename?: 'InvalidOrder' }
    | {
        readonly __typename: 'OrderStatus';
        readonly flattenedOrderStatus: Types.FlattenedOrderStatuses;
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly wantedTime: string;
          readonly isPendingFeedback: boolean;
          readonly canTrackOrderStatus: boolean;
          readonly hasEarnedLoyaltyPoints: boolean;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly estimatedDeliveryTime: string | null;
          } | null;
        };
      }
    | null;
};

export const LastOrderStatusDocument = gql`
  query LastOrderStatus {
    orderStatus {
      ... on OrderStatus {
        __typename
        flattenedOrderStatus
        order {
          id
          orderType
          wantedTime
          isPendingFeedback
          canTrackOrderStatus
          hasEarnedLoyaltyPoints
          deliveryOrderDetail {
            id
            estimatedDeliveryTime
          }
        }
      }
    }
  }
`;

export function useLastOrderStatusQuery(
  options?: Omit<Urql.UseQueryArgs<LastOrderStatusQueryVariables>, 'query'>,
) {
  return Urql.useQuery<LastOrderStatusQuery, LastOrderStatusQueryVariables>({
    query: LastOrderStatusDocument,
    ...options,
  });
}
