import { useCallback } from 'react';

import { type BrazeInAppMessagePayload, useBraze } from '@order/Braze';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A hook that sets up a listener for when a Braze in-app message modal is shown.
 *
 * Can be used as an effect (`useEffect`, `useFocusEffect`) or in response to an action.
 */
export const useOnBrazeInAppMessageShow = (
  params: UseOnBrazeInAppMessageShowParams,
) => {
  const { onInAppMessageShow } = params;

  const { addInAppMessageReceivedListener } = useBraze();

  return useCallback(() => {
    const removeListener = addInAppMessageReceivedListener(onInAppMessageShow);

    return () => {
      removeListener?.();
    };
  }, [addInAppMessageReceivedListener, onInAppMessageShow]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseOnBrazeInAppMessageShowParams = {
  onInAppMessageShow: (messagePayload?: BrazeInAppMessagePayload) => void;
};
