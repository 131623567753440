import { StyleSheet, type TextStyle, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

export const containerPaletteStyles = StyleSheet.create<
  Record<ScanScreenPalette, ViewStyle>
>({
  primary: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  premium: {
    backgroundColor: theme.colors.OLIVE,
  },
});

export const textPaletteStyles = StyleSheet.create<
  Record<ScanScreenPalette, TextStyle>
>({
  primary: {
    color: theme.colors.GREEN_1,
  },
  premium: {
    color: theme.colors.LIGHT,
  },
});

export const tintColorStyles = StyleSheet.create<
  Record<ScanScreenPalette, { tintColor: string }>
>({
  // apparently image tints cannot be in RGBA.
  /* eslint-disable react-native/no-color-literals */
  primary: { tintColor: '#0E150E' }, // dark kale
  premium: { tintColor: '#F8F7F0' }, // cream
});

type ScanScreenPalette = 'primary' | 'premium';
