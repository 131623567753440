import React from 'react';
import { StatusBar as ExpoStatusBar } from 'expo-status-bar';

import { useLoyaltyPremiumStatus } from '@order/hooks';

/**
 * When the customer is in the premium status for loyalty, their status bar should be light instead of dark.
 */
export const StatusBar = () => {
  const { isPremium } = useLoyaltyPremiumStatus();

  return <ExpoStatusBar animated style={isPremium ? 'light' : 'dark'} />;
};
