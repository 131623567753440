import React, { type ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from '@sg/garnish';

export const LoyaltyPostPurchaseCTA = (props: LoyaltyPostPurchaseCTAProps) => {
  const { onPress, palette = 'sweetCorn' } = props;

  const { formatMessage } = useIntl();

  return (
    <Button
      size="large-wide"
      palette={palette}
      onPress={onPress}
      accessibilityLabel={formatMessage(messages.accessibilityLabel)}
    >
      {formatMessage(messages.buttonText)}
    </Button>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Go to order',
    description: 'Loyalty | Post Purchase | Button Accessibility Label',
  },
  buttonText: {
    defaultMessage: 'View order',
    description: 'Loyalty | Post Purchase | CTA',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyPostPurchaseCTAProps = {
  onPress: () => void;
  palette?: ComponentProps<typeof Button>['palette'];
};
