import React, { type ComponentProps, useMemo } from 'react';
import { MainTabBar as MainTabBarBase } from '@sg/garnish';

import { useChallengesAndRewards } from '@order/ChallengesAndRewards';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { type MainTabName } from '../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Wrapper around garnish's MainTabBar.
 */
export const MainTabBar = (props: MainTabBarProps) => {
  const { hasUnseenChallenges } = useChallengesAndRewards();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  // ─────────────────────────────────────────────────────────────────────

  // NOTE: Adds challenges feature flag check + badge.
  const tabsDisplayingBadge: MainTabNames = hasUnseenChallenges
    ? ['LoyaltyTab']
    : [];

  const hiddenTabs = useMemo<MainTabNames>(() => {
    const inAppGiftCardTabs: MainTabNames = isInAppGiftCardEnabled
      ? ['AccountTab']
      : ['GiftCardsTab'];

    const loyaltyTabs: MainTabNames = isLoyaltyV2Enabled
      ? ['ScanTab']
      : ['ReorderTab'];

    return [...inAppGiftCardTabs, ...loyaltyTabs];
  }, [isLoyaltyV2Enabled, isInAppGiftCardEnabled]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <MainTabBarBase
      tabsDisplayingBadge={tabsDisplayingBadge}
      maxFontSizeMultiplier={1.1}
      hiddenTabs={hiddenTabs}
      {...props}
    />
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type MainTabNames = readonly MainTabName[];

type MainTabBarProps = Omit<
  ComponentProps<typeof MainTabBarBase>,
  'hiddenTabs' | 'tabsDisplayingBadge'
>;
