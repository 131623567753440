import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { Asset, Entry } from 'contentful';

import { SG_REWARDS_TERMS_AND_CONDITIONS } from '@order/constants';
import { useContentfulContentTypeEntry } from '@order/Contentful';

import { fallbacks } from './fallbacks';

/**
 * Returns the contentful data for the bag.
 */
export const useLoyaltyContent = (params?: UseLoyaltyContentParams) => {
  const { availablePoints = 0 } = params ?? {};

  const content = useContentfulContentTypeEntry<LoyaltyContainer>({
    contentType: 'loyalty',
  });

  const { formatMessage } = useIntl();

  return useMemo(() => {
    const contentfulData = content.data?.fields;

    const fallbackData = {
      bagHeaderLoggedOut: formatMessage(fallbacks.bagHeaderLoggedOut),
      bagHeaderNewUser: formatMessage(fallbacks.bagHeaderNewUser),
      bagHeaderRecurringUser: formatMessage(fallbacks.bagHeaderRecurringUser, {
        points: availablePoints,
      }),
      bagHeaderSubtitle: formatMessage(fallbacks.bagHeaderSubtitle),
      bagHeaderLoggedOutImageMessage: formatMessage(
        fallbacks.bagHeaderLoggedOutImageMessage,
      ),
      earnPointsTitle: formatMessage(fallbacks.earnPointsTitle),
      earnPointsDescription: formatMessage(fallbacks.earnPointsDescription),
      redeemRewardsTitle: formatMessage(fallbacks.redeemRewardsTitle),
      redeemRewardsDescription: formatMessage(
        fallbacks.redeemRewardsDescription,
      ),
      lookOutForOffersTitle: formatMessage(fallbacks.lookOutForOffersTitle),
      lookOutForOffersDescription: formatMessage(
        fallbacks.lookOutForOffersDescription,
      ),
      homeChallengesEmptyStateTitle: formatMessage(
        fallbacks.homeChallengesEmptyStateTitle,
      ),
      homeChallengesEmptyStateBody: formatMessage(
        fallbacks.homeChallengesEmptyStateBody,
      ),
      homeChallengesEmptyStateCtaXS: formatMessage(
        fallbacks.homeChallengesEmptyStateCtaXS,
      ),
      homeChallengesEmptyStateCtaSM: formatMessage(
        fallbacks.homeChallengesEmptyStateCtaSM,
      ),
    };

    // ─── FAQ ────────────────────────────────────────────────────────────────

    const faqLoyaltyHome = (contentfulData?.faqLoyaltyHome ?? EMPTY_FAQ)
      ?.map(extractLoyaltyFAQ)
      ?.filter(filterOutInvalidLoyaltyFAQ);

    const faqPointHistory = (contentfulData?.faqPointHistory ?? EMPTY_FAQ)
      ?.map(extractLoyaltyFAQ)
      ?.filter(filterOutInvalidLoyaltyFAQ);

    // ─── Bag ────────────────────────────────────────────────────────────────

    const bagHeaderLoggedOut =
      contentfulData?.bagHeaderLoggedOut ?? fallbackData?.bagHeaderLoggedOut;
    const bagHeaderNewUser =
      contentfulData?.bagHeaderNewUser ?? fallbackData?.bagHeaderNewUser;
    const bagHeaderRecurringUser =
      contentfulData?.bagHeaderRecurringUser ??
      fallbackData?.bagHeaderRecurringUser;
    const bagHeaderSubtitle =
      contentfulData?.bagHeaderSubtitle ?? fallbackData?.bagHeaderSubtitle;
    const bagHeaderLoggedOutImageMessage =
      contentfulData?.bagLoggedOutImageMessage ??
      fallbackData?.bagHeaderLoggedOutImageMessage;
    const bagHeaderLoggedOutImage =
      contentfulData?.bagHeaderLoggedOutImage?.fields?.file?.url;

    // ─── Home Empty State ───────────────────────────────────────────────────

    const homeChallengesEmptyStateTitle =
      contentfulData?.homeChallengesEmptyStateTitle ??
      fallbackData?.homeChallengesEmptyStateTitle;

    const homeChallengesEmptyStateBody =
      contentfulData?.homeChallengesEmptyStateBody ??
      fallbackData?.homeChallengesEmptyStateBody;

    const homeChallengesEmptyStateCtaXS =
      contentfulData?.homeChallengesEmptyStateCtaXS ??
      fallbackData?.homeChallengesEmptyStateCtaXS;

    const homeChallengesEmptyStateCtaSM =
      contentfulData?.homeChallengesEmptyStateCtaSM ??
      fallbackData?.homeChallengesEmptyStateCtaSM;

    // ─── Progress Benefits ──────────────────────────────────────────────────

    const homeProgressBenefits = (
      contentfulData?.homeProgressBenefits ?? EMPTY_HOME_PROGRESS_BENEFITS
    )
      .map(extractHomeProgressBenefit)
      .filter(filterOutInvalidHomeProgressBenefit);

    // ─── How It Works ───────────────────────────────────────────────────────

    const homeHowItWorksSMData = (
      contentfulData?.homeHowItWorksSM ?? EMPTY_HOW_IT_WORKS
    ).map(extractLoyaltyHowItWorks);

    const homeHowItWorksSM = (
      homeHowItWorksSMData.length > 0
        ? homeHowItWorksSMData
        : [
            {
              title: fallbackData.earnPointsTitle,
              description: fallbackData.earnPointsDescription,
            },
            {
              title: fallbackData.redeemRewardsTitle,
              description: fallbackData.redeemRewardsDescription,
            },
            {
              title: fallbackData.lookOutForOffersTitle,
              description: fallbackData.lookOutForOffersDescription,
            },
          ]
    ).filter(filterOutInvalidLoyaltyHowItWorks);

    // ─── Terms And Conditions ───────────────────────────────────────────────

    const termsAndConditionsUrl =
      contentfulData?.termsAndConditionsUrl ?? SG_REWARDS_TERMS_AND_CONDITIONS;

    return {
      faqLoyaltyHome,
      faqPointHistory,
      bagHeaderLoggedOut,
      bagHeaderNewUser,
      bagHeaderRecurringUser,
      bagHeaderSubtitle,
      bagHeaderLoggedOutImageMessage,
      bagHeaderLoggedOutImage,
      homeProgressBenefits,
      homeHowItWorksSM,
      homeChallengesEmptyStateTitle,
      homeChallengesEmptyStateBody,
      homeChallengesEmptyStateCtaXS,
      homeChallengesEmptyStateCtaSM,
      termsAndConditionsUrl,
    };
  }, [availablePoints, content.data?.fields, formatMessage]);
};

// ─── Container ──────────────────────────────────────────────────────────────

type LoyaltyContainer = {
  faqLoyaltyHome: Entry<LoyaltyFAQ>[];
  faqPointHistory: Entry<LoyaltyFAQ>[];
  homeProgressBenefits: Entry<ProgressBenefit>[];
  homeHowItWorksSM: Entry<LoyaltyHowItWorks>[];

  bagHeaderLoggedOut?: string;
  bagHeaderNewUser?: string;
  bagHeaderRecurringUser?: string;
  bagHeaderSubtitle?: string;
  bagLoggedOutImageMessage?: string;
  bagHeaderLoggedOutImage?: Asset;

  homeChallengesEmptyStateTitle: string;
  homeChallengesEmptyStateBody: string;
  homeChallengesEmptyStateCtaXS: string;
  homeChallengesEmptyStateCtaSM: string;

  termsAndConditionsUrl?: string;
};

// ─── Loyalty FAQ ────────────────────────────────────────────────────────────

type LoyaltyFAQ = {
  title?: string;
  description?: string;
};

const extractLoyaltyFAQ = (loyaltyFAQ?: Entry<LoyaltyFAQ>) => {
  return {
    title: loyaltyFAQ?.fields?.title,
    description: loyaltyFAQ?.fields?.description,
  };
};

const filterOutInvalidLoyaltyFAQ = (
  entry: Partial<LoyaltyFAQ>,
): entry is Required<LoyaltyFAQ> => {
  return Boolean(entry.title && entry.description);
};

const EMPTY_FAQ: Entry<LoyaltyFAQ>[] = [];

// ─── Loyalty How It Works ───────────────────────────────────────────────────

type LoyaltyHowItWorks = {
  title?: string;
  description?: string;
};

type LoyaltyHowItWorksPostExtraction = {
  title?: string;
  description?: string;
};

const extractLoyaltyHowItWorks = (
  loyaltyHowItWorks?: Entry<LoyaltyHowItWorks>,
): LoyaltyHowItWorksPostExtraction => {
  return {
    title: loyaltyHowItWorks?.fields?.title,
    description: loyaltyHowItWorks?.fields?.description,
  };
};

const filterOutInvalidLoyaltyHowItWorks = (
  entry: Partial<LoyaltyHowItWorksPostExtraction>,
): entry is Required<LoyaltyHowItWorksPostExtraction> => {
  return Boolean(entry.title && entry.description);
};

const EMPTY_HOW_IT_WORKS: Entry<LoyaltyHowItWorks>[] = [];

// ─── Progress Benefits ──────────────────────────────────────────────────────

type ProgressBenefit = {
  title?: string;
  description?: string;
};

export type MappedProgressBenefit = {
  title: string;
  maxPoints: number;
  isPendingBenefit: boolean;
};

const extractHomeProgressBenefit = (
  benefit?: Entry<ProgressBenefit>,
): Partial<MappedProgressBenefit> => {
  return {
    title: benefit?.fields?.title,
    maxPoints: Number(benefit?.fields?.description),
    isPendingBenefit: benefit?.fields?.description === 'Pending',
  };
};

const filterOutInvalidHomeProgressBenefit = (
  entry: Partial<MappedProgressBenefit>,
): entry is Required<MappedProgressBenefit> => {
  return (
    entry.title !== undefined &&
    (Number.isInteger(entry.maxPoints) || entry.isPendingBenefit === true) &&
    entry.isPendingBenefit !== undefined
  );
};

const EMPTY_HOME_PROGRESS_BENEFITS: Entry<ProgressBenefit>[] = [];

// ─── Params ─────────────────────────────────────────────────────────────────

type UseLoyaltyContentParams = {
  availablePoints: number | undefined;
};
