import {
  LoyaltyOfferCardProgressBarContainer,
  LoyaltyOfferCardProgressNodeBase,
} from './components';
import {
  LoyaltyOfferCardProgressBarHurdleSpend,
  LoyaltyOfferCardProgressBarStep,
} from './variations';

export const LoyaltyOfferCardProgressBar = {
  Container: LoyaltyOfferCardProgressBarContainer,
  Step: LoyaltyOfferCardProgressBarStep,
  Spend: LoyaltyOfferCardProgressNodeBase,
  HurdleSpend: LoyaltyOfferCardProgressBarHurdleSpend,
};
