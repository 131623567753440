import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardContainer = (
  props: LoyaltyOfferCardContainerProps,
) => {
  const { rounded = true, withBorder = false, children } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyle = [
    styles.container,
    rounded ? styles.containerRounded : undefined,
    withBorder ? styles.containerWithBorder : undefined,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return <View style={containerStyle}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: theme.colors.KALE,
  },
  containerRounded: {
    borderRadius: theme.radius.large,
  },
  containerWithBorder: {
    borderWidth: 1,
    borderColor: theme.colors.CREAM,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardContainerProps = PropsWithChildren<{
  rounded?: boolean;
  withBorder?: boolean;
}>;
