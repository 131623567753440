/* eslint-disable @typescript-eslint/no-shadow */

import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import type { ViewStyle } from 'react-native';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { PanGestureHandler } from 'react-native-gesture-handler';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import useMergedRef from '@react-hook/merged-ref';
import { COLORS, RADIUS } from '@garnish/constants';

import { useBrowserEventListener, useResponsive } from '../../../../hooks';
import { List } from '../../../List';
import {
  PICKER_OPTION_HEIGHT,
  PICKER_OPTIONS_WRAPPER_GAP_SM,
  PICKER_OPTIONS_WRAPPER_HEIGHT,
  PICKER_OPTIONS_WRAPPER_TOP_OFFSET,
  PICKER_OPTIONS_WRAPPER_TOP_OFFSET_SM,
} from '../Picker.constants';
import { usePickerGesturesEventsHandler } from '../Picker.hooks';
import type {
  PickerControlProps,
  PickerSelectionBorderRadiusVariation,
} from '../Picker.types';
import {
  PickerGradientShadow,
  SHADOW_GRADIENT_HEIGHT,
} from './PickerGradientShadow';
import { PickerOption } from './PickerOption';

// ─────────────────────────────────────────────────────────────────────────────

export const PickerControl = forwardRef<ScrollView, PickerControlProps>(
  (props, ref) => {
    const {
      value,
      options = [],
      selectionBorderRadius = 'rounded',
      accessibilityLabel,
      accessibilityDescribedBy,
      onChange,
    } = props;

    const { match, minWidth } = useResponsive();

    const optionsWrapperRef = useRef<ScrollView>(null);
    const mergedRefs = useMergedRef(optionsWrapperRef, ref);

    // ─── Flags ───────────────────────────────────────────────────────────

    const shouldUseScrollVariation = minWidth.isSM;

    // ─── Active Option ───────────────────────────────────────────────────

    const activeOptionIndex = options.findIndex(
      (option) => option.value === value,
    );

    const activeOptionIndexRef = useRef(activeOptionIndex);

    // eslint-disable-next-line functional/immutable-data
    activeOptionIndexRef.current = activeOptionIndex;

    // ─── HELPERS ────────────────────────────────────────────────────────

    const { swipedDistance, animatedGestureHandler, selectOptionByIndex } =
      usePickerGesturesEventsHandler({
        options,
        optionsWrapperRef,
        activeOptionIndex,
        onChange,
        shouldUseScrollVariation,
      });

    const selectNextOption = useCallback(() => {
      selectOptionByIndex(activeOptionIndex + 1);
    }, [activeOptionIndex, selectOptionByIndex]);

    const selectPrevOption = useCallback(() => {
      selectOptionByIndex(activeOptionIndex - 1);
    }, [activeOptionIndex, selectOptionByIndex]);

    const checkIfElementInsideWrapper = useCallback(
      (element: EventTarget | HTMLElement | Node | null) => {
        const optionsWrapperAsNode =
          optionsWrapperRef.current as unknown as Node;

        if (!element || !optionsWrapperAsNode) return false;

        const elementAsNode = element as Node;

        return optionsWrapperAsNode.contains(elementAsNode);
      },
      [],
    );

    const checkIfFocusInsideWrapper = useCallback(() => {
      if (Platform.OS !== 'web') return false;

      const focusedElement = document.activeElement;

      return checkIfElementInsideWrapper(focusedElement);
    }, [checkIfElementInsideWrapper]);

    const selectOptionOnKeyDown = useCallback(
      (event: Event) => {
        if (!checkIfElementInsideWrapper(event.target)) return;

        const { key } = event as KeyboardEvent;

        if (key === 'ArrowUp') selectPrevOption();

        if (key === 'ArrowDown') selectNextOption();
      },
      [checkIfElementInsideWrapper, selectNextOption, selectPrevOption],
    );

    const scrollToSelectedOption = useCallback(() => {
      if (!shouldUseScrollVariation) return;

      const scrollContainer = optionsWrapperRef.current;

      if (!scrollContainer) return;

      const activeOptionIndex = activeOptionIndexRef.current;
      const activeItemPosition = getActiveOptionScrollPositionSM(activeOptionIndex); // prettier-ignore

      scrollContainer.scrollTo({ y: activeItemPosition, animated: false });
    }, [shouldUseScrollVariation]);

    // ─── STYLES ─────────────────────────────────────────────────────────

    const selectionStyle = [
      styles.optionsSelection,
      borderRadiusVariations[selectionBorderRadius],
    ];

    const animatedStyle = useAnimatedStyle(() => {
      if (shouldUseScrollVariation) return {};

      return { transform: [{ translateY: swipedDistance.value }] };
    });

    const optionsWrapperStyles = [
      styles.optionsWrapper,
      match([styles.optionsWrapperXS, styles.optionsWrapperSM]),
    ];

    const optionsListStyles = [
      styles.optionsList,
      match([styles.optionsListXS, styles.optionsListSM]),
    ];

    // ─── Effects ─────────────────────────────────────────────────────────

    // NOTE: Scroll to the selected option on mount
    useEffect(scrollToSelectedOption, [scrollToSelectedOption]);

    // ────────────────────────────────────────────────────────────────────

    useBrowserEventListener(window, 'keydown', selectOptionOnKeyDown);
    const isFocusInsideWrapper = checkIfFocusInsideWrapper();

    // ────────────────────────────────────────────────────────────────────

    const OptionsWrapper = shouldUseScrollVariation ? ScrollView : View;

    return (
      <View>
        {shouldUseScrollVariation ? <PickerGradientShadow.Top /> : null}

        <OptionsWrapper ref={mergedRefs} style={optionsWrapperStyles}>
          {shouldUseScrollVariation ? null : <View style={selectionStyle} />}

          <PanGestureHandler onGestureEvent={animatedGestureHandler}>
            <Animated.View style={animatedStyle}>
              <List style={optionsListStyles}>
                {options.map(({ value, label }, index) => (
                  <PickerOption
                    key={value}
                    index={index}
                    autofocus={isFocusInsideWrapper}
                    activeOptionIndex={activeOptionIndex}
                    label={label}
                    accessibilityLabel={accessibilityLabel}
                    accessibilityDescribedBy={accessibilityDescribedBy}
                    swipedDistance={swipedDistance}
                    selectOptionByIndex={selectOptionByIndex}
                    transition={shouldUseScrollVariation ? 'none' : 'scale-and-fade'} // prettier-ignore
                  />
                ))}
              </List>
            </Animated.View>
          </PanGestureHandler>
        </OptionsWrapper>

        {shouldUseScrollVariation ? <PickerGradientShadow.Bottom /> : null}
      </View>
    );
  },
);

// ─── Helpers ─────────────────────────────────────────────────────────────────

function getActiveOptionScrollPositionSM(activeOptionIndex: number) {
  const isFirstOption = activeOptionIndex === 0;

  if (isFirstOption) return 0;

  const optionsOffset = (activeOptionIndex + 1) * PICKER_OPTION_HEIGHT;
  const gapSpace = activeOptionIndex * PICKER_OPTIONS_WRAPPER_GAP_SM;
  const spacingOffset = PICKER_OPTIONS_WRAPPER_TOP_OFFSET_SM - SHADOW_GRADIENT_HEIGHT; // prettier-ignore

  return optionsOffset + gapSpace + spacingOffset;
}

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  optionsWrapper: {
    height: PICKER_OPTIONS_WRAPPER_HEIGHT,
  },
  optionsWrapperXS: {
    overflow: 'hidden',
  },
  optionsWrapperSM: {
    paddingVertical: PICKER_OPTIONS_WRAPPER_TOP_OFFSET_SM,
  },
  optionsSelection: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    height: PICKER_OPTION_HEIGHT,
    transform: [{ translateY: -PICKER_OPTION_HEIGHT / 2 }],
    backgroundColor: COLORS.OPACITY.KALE.LIGHTEST,
  },
  optionsSelectionRightBorderRadius: {
    borderTopRightRadius: RADIUS.large,
    borderBottomRightRadius: RADIUS.large,
  },
  optionsSelectionLeftBorderRadius: {
    borderTopLeftRadius: RADIUS.large,
    borderBottomLeftRadius: RADIUS.large,
  },
  optionsSelectionRoundedBorderRadius: {
    borderRadius: RADIUS.large,
  },
  optionsList: {
    position: 'relative',
    zIndex: 1,
  },
  optionsListXS: {
    paddingTop: PICKER_OPTIONS_WRAPPER_TOP_OFFSET,
  },
  optionsListSM: {
    paddingTop: PICKER_OPTIONS_WRAPPER_TOP_OFFSET_SM,
    rowGap: PICKER_OPTIONS_WRAPPER_GAP_SM,
  },
});

const borderRadiusVariations: Record<
  PickerSelectionBorderRadiusVariation,
  ViewStyle
> = {
  right: styles.optionsSelectionRightBorderRadius,
  left: styles.optionsSelectionLeftBorderRadius,
  rounded: styles.optionsSelectionRoundedBorderRadius,
};
