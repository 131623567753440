import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  StyleSheet,
  type TextProps,
  View,
  type ViewProps,
  type ViewStyle,
} from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';
import { BodyText, Collapsible, Icon, TitleText } from '@sg/garnish';

const LoyaltyHomeProgressBenefitXS = (
  props: LoyaltyHomeProgressBenefitPropsXS & ViewProps,
) => {
  const {
    progressBenefit,
    benefitsCount,
    palette,
    children,
    onBenefitsOpened,
  } = props;

  const minHeight = useStyle(
    () => ({
      minHeight: theme.spacing['15'] * benefitsCount,
    }),
    [benefitsCount],
  );

  const handleOnChange = useCallback(
    (expanded: boolean) => {
      if (expanded) onBenefitsOpened();
    },
    [onBenefitsOpened],
  );

  return (
    <Collapsible onChange={handleOnChange}>
      <Collapsible.Summary.Container
        style={[styles.summaryContainer, summaryPalettes[palette]]}
        expandedStyle={styles.summaryContainerExpanded}
      >
        <View style={styles.summaryContent}>
          <Icon name="IconSgRewardWhiteOutline" width={24} height={24} />

          <View>
            <BodyText style={styles.benefitLabel} sizeMatch={['14']} bold>
              <FormattedMessage {...messages.progressBenefitTitle} />
            </BodyText>

            <BodyText style={styles.benefitLabel} sizeMatch={['14']}>
              {progressBenefit}
            </BodyText>
          </View>
        </View>

        <Collapsible.Icon
          iconName="IconCaretDown"
          iconColor={theme.colors.CREAM}
          iconStrokeWidth={1.5}
        />
      </Collapsible.Summary.Container>

      <Collapsible.Details style={minHeight}>{children}</Collapsible.Details>
    </Collapsible>
  );
};

const LoyaltyHomeProgressBenefitSM = (
  props: LoyaltyHomeProgressBenefitPropsSM,
) => {
  const { progressBenefit, style } = props;

  return (
    <TitleText sizeMatch={['24']} style={[styles.progressBenefitSM, style]}>
      {progressBenefit}
    </TitleText>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  progressBenefitTitle: {
    defaultMessage: 'Order to earn',
    description: 'Loyalty | Home | Progress Benefit | Title',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  summaryContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: theme.radius.medium,
    padding: theme.spacing['4'],
    marginTop: theme.spacing['4'],
    marginHorizontal: theme.spacing['6'],
  },
  summaryDark: {
    backgroundColor: theme.colors.DARK_KALE,
    borderColor: theme.colors.CREAM,
  },
  summaryLight: {
    backgroundColor: theme.colors.BASIL,
    borderColor: theme.colors.KALE,
  },
  summaryContainerExpanded: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  summaryContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['2'],
  },
  benefitLabel: {
    color: theme.colors.CREAM,
  },
  progressBenefitSM: {
    color: theme.colors.KALE,
    textAlign: 'center',
    marginBottom: theme.spacing['16'],
  },
});

// ─── Palettes ───────────────────────────────────────────────────────────────

const summaryPalettes: Record<
  LoyaltyHomeProgressBenefitPropsXS['palette'],
  ViewStyle
> = {
  dark: styles.summaryDark,
  light: styles.summaryLight,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeProgressBenefitPropsSM = {
  progressBenefit: string;
  style?: TextProps['style'];
};

type LoyaltyHomeProgressBenefitPropsXS = ViewProps & {
  progressBenefit: string;
  benefitsCount: number;
  palette: 'dark' | 'light';
  onBenefitsOpened: () => void;
};

// ─── Compound Components ─────────────────────────────────────────────────────

export const LoyaltyHomeProgressBenefit = {
  XS: LoyaltyHomeProgressBenefitXS,
  SM: LoyaltyHomeProgressBenefitSM,
};
