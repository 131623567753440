import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { theme, useResponsive } from '@sg/garnish';

import { Loyalty, LOYALTY_PALETTES } from '@order/features/loyalty';
import { useLoyaltyPremiumStatus } from '@order/hooks';
import { useTelemetry } from '@order/Telemetry';

import { usePostPurchaseContent } from './hooks';
import { usePostPurchaseModalState } from './usePostPurchaseModalState';

export const LoyaltyPostPurchaseModal = () => {
  const navigation = useNavigation();
  const { match } = useResponsive();
  const { track } = useTelemetry();

  const { isPremium } = useLoyaltyPremiumStatus();

  const [content] = usePostPurchaseContent();
  const { isVisible, orderId, hideModal } = usePostPurchaseModalState();

  const onPressCta = useCallback(() => {
    if (orderId) {
      track('points.confirmation.orderstatus.cta_tapped');
      hideModal();
      navigation.navigate('Modal', {
        screen: 'OrderStatus',
        params: { orderId },
      });
    }
  }, [hideModal, orderId, navigation, track]);

  const onPressTextLink = useCallback(() => {
    track('points.confirmation.sgrewards.cta_tapped');
    hideModal();
    navigation.navigate('MainTabs', {
      screen: 'LoyaltyTab',
    });
  }, [hideModal, navigation, track]);

  const onCloseButtonPress = useCallback(() => {
    track('points.confirmation.dismiss.cta_tapped');
    hideModal();
  }, [hideModal, track]);

  return (
    <Loyalty.PostPurchase.Container
      visible={isVisible}
      isPremium={isPremium}
      style={match([styles.innerContainerXS, styles.innerContainerSM])}
      onRequestClose={onCloseButtonPress}
      onCloseButtonPress={onCloseButtonPress}
    >
      <Loyalty.PostPurchase.Hero
        image={content.image}
        style={match([styles.imageXS, styles.imageDefault])}
      />

      <Loyalty.PostPurchase.Body
        style={[
          match([styles.bodyXS, styles.bodySM]),
          isPremium ? styles.premiumBorder : undefined,
        ]}
      >
        <Loyalty.PostPurchase.Title
          style={[
            isPremium ? LOYALTY_PALETTES.premiumFgColor : {},
            match([styles.titleXS, styles.titleSM]),
          ]}
        >
          {content.title}
        </Loyalty.PostPurchase.Title>

        <Loyalty.PostPurchase.Subtitle>
          {content.description}
        </Loyalty.PostPurchase.Subtitle>

        <Loyalty.PostPurchase.CTA
          palette={isPremium ? 'premium' : 'sweetCorn'}
          onPress={onPressCta}
        />

        <Loyalty.PostPurchase.TextLink onPress={onPressTextLink}>
          <FormattedMessage {...messages.link} />
        </Loyalty.PostPurchase.TextLink>
      </Loyalty.PostPurchase.Body>
    </Loyalty.PostPurchase.Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  innerContainerXS: {
    flex: 1,
    flexDirection: 'column',
  },
  innerContainerSM: {
    flexDirection: 'row',
  },
  bodyXS: {
    paddingHorizontal: theme.spacing['6'],
  },
  bodySM: {
    flex: 1,
    paddingTop: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
  },
  imageXS: {
    width: '100%',
    height: 350,
  },
  imageDefault: {
    width: 228,
  },
  titleXS: {
    marginTop: theme.spacing['10'],
  },
  titleSM: {
    marginTop: theme.spacing['20'],
  },
  premiumBorder: {
    borderLeftWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.CREAM,
  },
});

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  link: {
    defaultMessage: 'Go to SgRewards',
    description: 'Loyalty Post Purchase | Modal | SgRewards Text Link',
  },
});
