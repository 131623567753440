import { useCallback } from 'react';
import { useActor } from '@xstate/react';

import { useGlobalAppState } from '@order/GlobalAppState';
import { useCloseBag } from '@order/hooks';

import type { ReorderEvent } from '../../machines/Reorder.machine';
import { useReorderNavigation } from '../useReorderNavigation';
import { useReorderTelemetry } from '../useReorderTelemetry';
import { getValidEventKeys, justStartedReorder, logger } from '../utils';

export const useConfirmLocation = () => {
  const { reorderMachineRef } = useGlobalAppState();
  const [state, sendReorderEvent] = useActor(reorderMachineRef);
  const { startReorderTelemetry } = useReorderTelemetry();
  const { navigateToConfirmLocation, replaceWithConfirmLocation } =
    useReorderNavigation();
  const closeBag = useCloseBag();

  return useCallback(
    (event?: ReorderEvent) => {
      logger.info('Confirm Location', event);

      // ─── State Machine ──────────────────────────────────────────
      sendReorderEvent({
        ...getValidEventKeys(event),
        type: 'CONFIRM_LOCATION',
      });

      // ─── Just Started Reorder ───────────────────────────────────
      if (justStartedReorder(state.value)) {
        // ─── Start Reorder Telemetry ──────────────────────────────
        startReorderTelemetry(
          event ?? state.context,
          'confirm_location',
          event?.userInitiated,
        );

        // ─── Navigation ───────────────────────────────────────────
        closeBag();
        setTimeout(navigateToConfirmLocation);

        return;
      }

      // ─── Continuing Reorder ─────────────────────────────────────
      replaceWithConfirmLocation();
    },
    [
      state.value,
      state.context,
      sendReorderEvent,
      closeBag,
      navigateToConfirmLocation,
      replaceWithConfirmLocation,
      startReorderTelemetry,
    ],
  );
};
