import React, { Children, cloneElement, type ReactElement } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import { Pressable, StyleSheet, View } from 'react-native';

import type {
  CollapsibleChildrenProps,
  CollapsibleSummaryProps,
} from '../Collapsible.types';
import { CollapseIndicatorIcon } from './CollapseIndicatorIcon';

export const Summary = (props: CollapsibleSummaryProps) => {
  const { children, collapsed, options = {} } = props as PropsType;
  const { iconName, hideIcon } = options;

  return (
    <SummaryContainer {...props}>
      <View style={styles.content}>{children}</View>

      {hideIcon ? null : (
        <CollapseIndicatorIcon collapsed={collapsed} iconName={iconName} />
      )}
    </SummaryContainer>
  );
};

const SummaryContainer = (props: CollapsibleSummaryProps) => {
  const {
    testID = 'sg-collapsible-summary',
    children,
    style,
    expandedStyle,
    collapsed,
    onCollapse,
  } = props as PropsType;

  const flatChildren = flattenChildren(children);

  const label = `${testID}-${collapsed ? 'collapsed' : 'expanded'}`;
  const containerStyle = [
    styles.container,
    style,
    collapsed ? null : expandedStyle,
  ];

  return (
    <Pressable
      testID={testID}
      accessibilityRole="button"
      onPress={onCollapse}
      accessibilityLabel={`${label}-label`}
      accessibilityHint={`${label}-hint`}
      style={containerStyle}
    >
      {Children.map(flatChildren, (child) =>
        cloneElement(child as ReactElement<CollapsibleChildrenProps>, {
          collapsed,
        }),
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  content: {
    flex: 1,
  },
});

// ─── Compound Components ────────────────────────────────────────────────────
/* eslint-disable functional/immutable-data */
Summary.Container = SummaryContainer;

// ─── Types ──────────────────────────────────────────────────────────────────

type PropsType = CollapsibleSummaryProps & CollapsibleChildrenProps;
