import React from 'react';
import { type ViewStyle } from 'react-native';

import { Loyalty } from '@order/features/loyalty';
import { useLoyaltyInfoBenefits } from '@order/hooks';

/**
 * Hooks up the loyalty profile points and rewards to the <Loyalty.InfoBenefits /> component.
 */
export const LoyaltyInfoBenefits = (props: LoyaltyInfoBenefitsProps) => {
  const { availablePoints, benefits, isLoading, hasError, refetch } =
    useLoyaltyInfoBenefits();

  return (
    <Loyalty.InfoBenefits
      style={props.style}
      availablePoints={availablePoints}
      loyaltyInfoBenefits={benefits}
      hasError={hasError}
      isLoading={isLoading}
      refetch={refetch}
    />
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyInfoBenefitsProps = {
  style?: ViewStyle;
};
