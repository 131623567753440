import { defineMessages } from 'react-intl';

export const loyaltyHomeScreenMessages = defineMessages({
  legacyScreenTitle: {
    defaultMessage: 'Loyalty',
    description: 'Loyalty Home Screen | Legacy title',
  },
  screenTitle: {
    defaultMessage: 'Rewards',
    description: 'Loyalty Home Screen | Title',
  },
  screenTitleWithPoints: {
    defaultMessage: 'Rewards • {points} points',
    description: 'Loyalty Home Screen | Title with points',
  },
  screenCustomDocumentTitle: {
    defaultMessage: 'Loyalty Home | sweetgreen',
    description: 'Loyalty Home Screen | Custom Document Title',
  },
});
