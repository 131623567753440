import {
  useChallengeOptInMutation,
  useChallengeOptInV2Mutation,
} from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';

// ─────────────────────────────────────────────────────────────────────────────

export function useChallengeOptIn() {
  const [, applyChallengeOptInMutationV2] = useChallengeOptInV2Mutation();
  const [, applyChallengeOptInMutationV1] = useChallengeOptInMutation();

  // ─────────────────────────────────────────────────────────────

  const shouldUseV2Mutation = useFeatureFlag(
    'CELS-1179-challenge-opt-in-v2-enabled',
  );

  const applyChallengeOptInMutation = shouldUseV2Mutation
    ? applyChallengeOptInMutationV2
    : applyChallengeOptInMutationV1;

  return applyChallengeOptInMutation;
}
