import { useCallback } from 'react';

import { useTelemetry } from '@order/Telemetry';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A custom hook to track telemetry event for the loyalty onboarding modal when
 * the rewards navigation occurs.
 */
export const useTrackLoyaltyOnboardingModalNavigation = (
  params: UseTrackLoyaltyOnboardingModalNavigationParams,
) => {
  const { hasNavigated, onTrack } = params;

  const { track } = useTelemetry();

  return useCallback(() => {
    if (!hasNavigated) return;

    track('sgrewards.onboarding.navigate-to-rewards');
    onTrack();
  }, [hasNavigated, onTrack, track]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseTrackLoyaltyOnboardingModalNavigationParams = {
  onTrack: () => void;
  hasNavigated: boolean;
};
