import React, { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import { useOnOnboardingModalShow } from '@order/hooks';

import { LoyaltyOfferCard, LoyaltyOffersModal } from './components';
import { useLoyaltyOffers } from './useLoyaltyOffers';

// ─────────────────────────────────────────────────────────────────────────────

const LoyaltyOffersModalContainer = () => {
  const {
    filteredOffers,
    isShowingOffersModal,
    isOffersModalEnabled,
    hideOffersModal,
    showOffersModal,
  } = useLoyaltyOffers();

  // NOTE: Offers modal should be hidden when the loyalty onboarding in-app message is shown.
  const hideOffersOnOnboardingModalShow = useOnOnboardingModalShow({
    onModalShow: hideOffersModal,
  });

  // ─── Effects ─────────────────────────────────────────────────────────

  useFocusEffect(showOffersModal);
  useFocusEffect(useCallback(() => hideOffersModal, [hideOffersModal]));
  useFocusEffect(hideOffersOnOnboardingModalShow);

  // ─────────────────────────────────────────────────────────────────────

  if (!isOffersModalEnabled) return null;

  return (
    <LoyaltyOffersModal
      offers={filteredOffers}
      visible={isShowingOffersModal}
      hideOffersModal={hideOffersModal}
    />
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOffers = {
  Modal: LoyaltyOffersModalContainer,
  Card: LoyaltyOfferCard,
};
