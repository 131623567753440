import { useCallback, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  type RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useNoticeBannersStackContext } from '@sg/garnish';
import { ChallengeStatus } from '@sg/graphql-schema';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useFeatureFlag } from '@order/LaunchDarkly';

import {
  type LoyaltyHomeScreenProps,
  type LoyaltyTabStackParamList,
} from '../../../../navigation/AppNavigation.props';
import { useLoyaltyOfferOptInOnFocusMutation } from './graphql/LoyaltyOfferOptInOnFocus.generated';

// ─────────────────────────────────────────────────────────────────────────────

export function useOptInChallengeOnFocus() {
  // ─── Feature Flags ───────────────────────────────────────────────────

  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');

  // ─────────────────────────────────────────────────────────────────────

  const isLoggedIn = useIsLoggedIn();
  const { formatMessage } = useIntl();

  // ─── Router Params ───────────────────────────────────────────────────

  const { params = {} } =
    useRoute<RouteProp<LoyaltyTabStackParamList, 'LoyaltyHome'>>();

  const { setParams } = useNavigation<LoyaltyHomeScreenProps['navigation']>();

  const [, optInChallenge] = useLoyaltyOfferOptInOnFocusMutation();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const { challengeId, optIn } = params;

  // ─── Opt In Callback ─────────────────────────────────────────────────

  const handleOptInChallenge = useCallback(async () => {
    if (!isLoggedIn) return;

    if (!isLoyaltyV2Enabled) return;

    if (!challengeId || optIn !== 'true') return;

    setParams({ challengeId: undefined, optIn: undefined });

    const { data } = await optInChallenge({ challengeId });

    const hasOptedIn =
      data?.challengeOptInV2?.__typename === 'Challenge' &&
      data?.challengeOptInV2?.status === ChallengeStatus.Started;

    // ─── Notice Banners ──────────────────────────────────────────

    if (hasOptedIn) {
      addNoticeBanner({
        palette: 'success',
        text: formatMessage(messages.optInSuccess),
      });

      return;
    }

    addNoticeBanner({
      palette: 'caution',
      text: formatMessage(messages.optInFailure),
    });
  }, [
    isLoggedIn,
    isLoyaltyV2Enabled,
    challengeId,
    optIn,
    setParams,
    optInChallenge,
    addNoticeBanner,
    formatMessage,
  ]);

  // ─── Entrypoint ──────────────────────────────────────────────────────

  useEffect(() => {
    void handleOptInChallenge();
  }, [handleOptInChallenge]);
}

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  optInSuccess: {
    defaultMessage: 'You have successfully opted in to this challenge',
    description: 'Loyalty | Opt-in challenge on focus | Success message',
  },
  optInFailure: {
    defaultMessage: 'An error occurred while opting in to this challenge',
    description: 'Loyalty | Opt-in challenge on focus | Failure message',
  },
});
