export const LOYALTY_HOME_SCREEN = {
  REFERRERS: {
    ONBOARDING: 'onboarding',
  },
};

export const LOYALTY_ONBOARDING_MODAL = {
  MODAL_ID: 'loyalty.onboarding-modal',
  MODAL_ID_KEY: 'ID',
  CUSTOM_EVENTS: {
    SHOW_MODAL: 'loyalty.onboarding-modal.show',
    SHOW_GOAT_MODAL: 'loyalty.onboarding-modal.show-goat',
  },
};
export const SG_REWARDS_TERMS_AND_CONDITIONS =
  'http://www.sweetgreen.com/sg-rewards-program-agreement';
