import type {
  CartDataFragment,
  Ingredient,
  IngredientModificationInput,
  IngredientSubstitutionModificationInput,
  Ledger,
  MixedDressingDetailsInput,
} from '@order/graphql';

// ────────────────────────────────────────────────────────────────────────────────

export function getCartLineItemsCount(
  lineItems: CartDataFragment['lineItems'] = [],
) {
  return lineItems.reduce((total, { quantity }) => total + quantity, 0);
}

export function getCartLedgerTotal(ledger: Partial<Ledger> | undefined) {
  const {
    subtotal = 0,
    tax = 0,
    tip = 0,
    feesTotal = 0,
    creditsTotal = 0,
    discountsTotal = 0,
  } = ledger ?? {};

  return [subtotal, tax, tip, feesTotal, -creditsTotal, -discountsTotal].reduce(
    (total, currentValue) => total + currentValue,
    0,
  );
}

export function extractIngredientId(ingredient: PossibleIngredientWithId) {
  return {
    ingredientId: ingredient?.ingredientId ?? ingredient.id ?? '',
  };
}

export function extractSubstitution(
  ingredientSubstitutionModification: IngredientSubstitutionModificationInput,
) {
  const { addedIngredientId, removedIngredientId } =
    ingredientSubstitutionModification;

  return { addedIngredientId, removedIngredientId };
}

export function extractMixedDressingDetails(
  mixedDressingDetail: MixedDressingDetailsInput & Record<string, unknown>,
): MixedDressingDetailsInput {
  return {
    ingredientId: mixedDressingDetail.ingredientId,
    weight: mixedDressingDetail.weight,
  };
}

export function getLineItemCustomName(props: GetLineItemCustomNameProps) {
  const { customName = '', productName = '' } = props;

  return customName === productName ? null : customName;
}

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type PossibleIngredientWithId = Partial<
  IngredientModificationInput & Ingredient
>;

type GetLineItemCustomNameProps = Readonly<{
  productName?: string | null;
  customName?: string | null;
}>;
