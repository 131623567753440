import React from 'react';
import type { PressableProps } from 'react-native';
import { Pressable } from 'react-native';
import { StyleSheet } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { FadeView } from '../../../FadeView';

// ─────────────────────────────────────────────────────────────────────────────

export const ModalBackdrop = (props: ModalBackdropProps) => {
  const { currentBreakpoint } = useResponsive();

  // This is a workaround to prevent the backdrop sliding up in XS.
  if (currentBreakpoint.isXS) {
    return (
      <FadeView show duration={500} style={StyleSheet.absoluteFill}>
        <Backdrop {...props} />
      </FadeView>
    );
  }

  return <Backdrop {...props} />;
};

const Backdrop = (props: ModalBackdropProps) => {
  const {
    opacity = BACKDROP_DEFAULT_OPACITY,
    onPress,
    backdropAccessibilityLabel,
  } = props;

  const dynamicStyles = useStyle(() => ({ opacity }), [opacity]);

  return (
    <Pressable
      accessibilityRole="button"
      accessibilityLabel={backdropAccessibilityLabel ?? 'dismiss dialog'}
      onPress={onPress}
      style={[StyleSheet.absoluteFill, styles.backdrop, dynamicStyles]}
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const BACKDROP_DEFAULT_OPACITY = 0.64;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  backdrop: {
    width: '100%',
    backgroundColor: theme.colors.DARK_KALE,
    zIndex: theme.zIndex.modalBackdrop,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ModalBackdropProps = Readonly<{
  backdropAccessibilityLabel?: string;
  onPress: PressableProps['onPress'];
  opacity?: number;
}>;
