/* eslint-disable react-native/no-unused-styles */

import React, { type ComponentProps } from 'react';
import { StyleSheet, type TextStyle, View, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardTag = (props: LoyaltyOfferCardTagProps) => {
  const { style, palette = 'sweet-corn', ...restProps } = props;

  const containerPaletteStyle = containerPaletteStyles[palette];
  const textPaletteStyle = textPaletteStyles[palette];

  return (
    <View style={[styles.container, containerPaletteStyle]}>
      <BodyText
        sizeMatch={['18']}
        style={[textPaletteStyle, style]}
        {...restProps}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    borderRadius: 50,
    paddingHorizontal: theme.spacing['2'],
  },
});

const containerPaletteStyles = StyleSheet.create<Record<Palette, ViewStyle>>({
  'sweet-corn': {
    backgroundColor: theme.colors.SWEET_CORN,
  },
  forest: {
    backgroundColor: theme.colors.FOREST,
  },
});

const textPaletteStyles = StyleSheet.create<Record<Palette, TextStyle>>({
  'sweet-corn': {
    color: theme.colors.KALE,
  },
  forest: {
    color: theme.colors.CREAM,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardTagProps = {
  palette?: Palette;
} & ComponentProps<typeof BodyText>;

type Palette = 'sweet-corn' | 'forest';
