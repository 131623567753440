import React from 'react';
import { LogBox } from 'react-native';

import { ErrorBoundary, StatusBar } from '@order/components';
import { initDatadog, notifee } from '@order/integrations';
import { UrqlProvider } from '@order/Urql';
import {
  fixAndroidKeyboardLayoutShifts,
  fixIosFullHeightIssue,
} from '@order/utils';

import { App } from './App';
import { loadOneTrust } from './integrations';
import { intlPolyfill } from './polyfills';
import { Providers } from './Providers';

// ─────────────────────────────────────────────────────────────────────────────

// FIXME we need to fix the underlying issue for Android; this is here so that Android e2e tests can pass.
// The warning it is hiding is covering UI elements the test runner is trying to interact with.
LogBox.ignoreAllLogs();

intlPolyfill();
void initDatadog();
fixAndroidKeyboardLayoutShifts();
fixIosFullHeightIssue();
loadOneTrust();

// ─── Notifications ───────────────────────────────────────────────────────────

void notifee?.requestPermission();

// ─────────────────────────────────────────────────────────────────────────────

export const Root = () => (
  <ErrorBoundary>
    <UrqlProvider>
      <Providers>
        <App />
        <StatusBar />
      </Providers>
    </UrqlProvider>
  </ErrorBoundary>
);
