import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { useResponsive } from '@sg/garnish';

export const JoinSgRewardsItemContainer = (props: PropsWithChildren) => {
  const { match } = useResponsive();

  return (
    <View style={match([styles.containerXS, styles.containerSM])}>
      {props.children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerSM: {
    flexDirection: 'row',
  },
});
