import type { TextStyle } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

export const TITLE = {
  '120': {
    fontSize: 120,
    fontFamily: 'Grenette Pro',
    fontWeight: '400',
    letterSpacing: -5,
    lineHeight: 100,
  },
  '80': {
    fontSize: 80,
    fontFamily: 'Grenette Pro',
    fontWeight: '300',
    letterSpacing: -4.8,
    lineHeight: 80,
  },
  '64': {
    fontSize: 64,
    fontFamily: 'Grenette Pro',
    fontWeight: '300',
    letterSpacing: -3,
    lineHeight: 64,
  },
  '56': {
    fontSize: 56,
    fontFamily: 'Grenette Pro',
    fontWeight: '300',
    letterSpacing: -2.7,
    lineHeight: 56,
  },
  '48': {
    fontSize: 48,
    fontFamily: 'Grenette Pro',
    fontWeight: '300',
    letterSpacing: -2.4,
    lineHeight: 48,
  },
  '40': {
    fontSize: 40,
    fontFamily: 'Grenette Pro',
    fontWeight: '300',
    letterSpacing: -2,
    lineHeight: 40,
  },
  '36': {
    fontSize: 36,
    fontFamily: 'Grenette Pro',
    fontWeight: '300',
    letterSpacing: -1.08,
    lineHeight: 36,
  },
  '32': {
    fontSize: 32,
    fontFamily: 'Grenette Pro',
    fontWeight: '300',
    letterSpacing: -1.6,
    lineHeight: 36,
  },
  '24': {
    fontSize: 24,
    fontFamily: 'Grenette Pro',
    fontWeight: '300',
    letterSpacing: -0.8,
    lineHeight: 28,
  },
  '18': {
    fontSize: 18,
    fontFamily: 'Grenette Pro',
    fontWeight: '300',
    letterSpacing: -0.8,
    lineHeight: 20,
  },
} satisfies NamedTextStyles;

export const DISPLAY = {
  '80': {
    fontSize: 80,
    fontFamily: 'SweetSans',
    fontWeight: '400',
    letterSpacing: 0.8,
    lineHeight: 80,
  },
  '56': {
    fontSize: 56,
    fontFamily: 'SweetSans',
    fontWeight: '400',
    letterSpacing: 0.6,
    lineHeight: 56,
  },
  '48': {
    fontSize: 48,
    fontFamily: 'SweetSans',
    fontWeight: '400',
    letterSpacing: 0.8,
    lineHeight: 48,
  },
  '40': {
    fontSize: 40,
    fontFamily: 'SweetSans',
    fontWeight: '400',
    letterSpacing: 0.8,
    lineHeight: 40,
  },
  '32': {
    fontSize: 32,
    fontFamily: 'SweetSans',
    fontWeight: '400',
    letterSpacing: 1,
    lineHeight: 36,
  },
  '24': {
    fontSize: 24,
    fontFamily: 'SweetSans',
    fontWeight: '400',
    letterSpacing: 1.2,
    lineHeight: 28,
  },
} satisfies NamedTextStyles;

export const DISPLAY_WIDE = {
  '80': {
    fontSize: 80,
    fontFamily: 'SweetSans Wide',
    fontWeight: '400',
    letterSpacing: 0.8,
    lineHeight: 80,
  },
  '40': {
    fontSize: 40,
    fontFamily: 'SweetSans Wide',
    fontWeight: '400',
    letterSpacing: 0.8,
    lineHeight: 40,
  },
  '32': {
    fontSize: 32,
    fontFamily: 'SweetSans Wide',
    fontWeight: '400',
    letterSpacing: 1,
    lineHeight: 36,
  },
} satisfies NamedTextStyles;

export const BODY = {
  '40': {
    fontSize: 40,
    fontFamily: 'SweetSans',
    fontWeight: '400',
    letterSpacing: -0.4,
    lineHeight: 40,
  },
  '24': {
    fontSize: 24,
    fontFamily: 'SweetSans Text',
    fontWeight: '400',
    letterSpacing: -0.4,
    lineHeight: 28,
  },
  '22': {
    fontSize: 22,
    fontFamily: 'SweetSans Text',
    fontWeight: '400',
    letterSpacing: -0.4,
    lineHeight: 28,
  },
  '18': {
    fontSize: 18,
    fontFamily: 'SweetSans Text',
    fontWeight: '400',
    letterSpacing: -0.2,
    lineHeight: 24,
  },
  '16': {
    fontSize: 16,
    fontFamily: 'SweetSans Text',
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: 22,
  },
  '14': {
    fontSize: 14,
    fontFamily: 'SweetSans Text',
    fontWeight: '400',
    letterSpacing: 0.1,
    lineHeight: 18,
  },
  '12': {
    fontSize: 12,
    fontFamily: 'SweetSans Text',
    fontWeight: '400',
    letterSpacing: 0.2,
    lineHeight: 16,
  },
} satisfies NamedTextStyles;

export const LABEL = {
  '14': {
    fontSize: 14,
    fontFamily: 'SweetSans Text',
    fontWeight: '400',
    letterSpacing: 1.2,
    lineHeight: 18,
    textTransform: 'uppercase',
  },
  '12': {
    fontSize: 12,
    fontFamily: 'SweetSans Text',
    fontWeight: '400',
    letterSpacing: 1,
    lineHeight: 16,
    textTransform: 'uppercase',
  },
  '10': {
    fontSize: 10,
    fontFamily: 'SweetSans Text',
    fontWeight: '400',
    letterSpacing: 0.8,
    lineHeight: 16,
    textTransform: 'uppercase',
  },
} satisfies NamedTextStyles;

// ─────────────────────────────────────────────────────────────────────────────

export const TYPE_CONFIG = {
  TITLE,
  DISPLAY,
  DISPLAY_WIDE,
  BODY,
  LABEL,
};

// ─── Types ───────────────────────────────────────────────────────────────────

type NamedTextStyles = Record<string, TextStyle>;
