import React, { type PropsWithChildren } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

export const JoinSgRewardsContainer = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <ScrollView
      contentContainerStyle={styles.contentContainer}
      style={styles.container}
    >
      {children}
    </ScrollView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.FOREST,
  },
  contentContainer: {
    flexGrow: 1,
    minWidth: '100%',
  },
});
