import React, { type ComponentProps } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Platform, type ScrollViewProps, StyleSheet } from 'react-native';
import Animated, {
  useAnimatedRef,
  useScrollViewOffset,
} from 'react-native-reanimated';
import {
  type AddressType,
  BodyText,
  CustomNavigationHeader,
  theme,
} from '@sg/garnish';

import { BagViewMenuCta } from '../../Ctas';
import { BagChannelAndLocationInfo } from '../../Header';

// Without the empty fragment around the header, the styling breaks.
/* eslint-disable react/jsx-no-useless-fragment */

/**
 * ScrollView for the bag.
 * Contains a header with the menu and close buttons.
 * When scrolled displays order channel and location.
 */
export const BagScrollView = (props: BagScrollViewProps) => {
  const {
    children,
    orderChannel,
    locationName,
    viewMenuCta,
    viewMenuA11y,
    isDisabled,
    automaticallyAdjustKeyboardInsets,
    onViewMenu,
    requestChannelOrLocationChange,
    requestDeliveryPreferencesChange,
    onHeaderCloseBtnPress,
  } = props;

  const { formatMessage } = useIntl();
  const animatedRef = useAnimatedRef<Animated.ScrollView>();
  const scrollOffsetSV = useScrollViewOffset(animatedRef);

  return (
    <Animated.ScrollView
      automaticallyAdjustKeyboardInsets={automaticallyAdjustKeyboardInsets}
      ref={animatedRef}
      style={styles.bagScrollView}
      contentContainerStyle={styles.bagScrollView}
      stickyHeaderIndices={[0]}
    >
      <>
        <CustomNavigationHeader.Container
          palette="cream"
          safeAreaEdges={safeAreaEdges}
          buttonSize={theme.spacing['16']}
          scrollOffsetSV={scrollOffsetSV}
        >
          <CustomNavigationHeader.Item.Left>
            <BagViewMenuCta
              cta={viewMenuCta}
              a11yLabel={viewMenuA11y}
              isDisabled={isDisabled}
              onPress={onViewMenu}
            />
          </CustomNavigationHeader.Item.Left>

          <CustomNavigationHeader.Content scrollOffsetSV={scrollOffsetSV}>
            <BodyText style={styles.headerText} sizeMatch={['16']}>
              <FormattedMessage {...messages.title} />
            </BodyText>

            <BagChannelAndLocationInfo
              orderChannel={orderChannel}
              locationName={locationName}
              requestChannelOrLocationChange={requestChannelOrLocationChange}
              requestDeliveryPreferencesChange={
                requestDeliveryPreferencesChange
              }
            />
          </CustomNavigationHeader.Content>

          <CustomNavigationHeader.Button.Right
            name="IconClose"
            accessibilityLabel={formatMessage(messages.closeBag)}
            onPress={onHeaderCloseBtnPress}
          />
        </CustomNavigationHeader.Container>
      </>

      {children}
    </Animated.ScrollView>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const safeAreaEdges = Platform.select<SafeAreaEdges>({
  android: ['top'],
  default: [],
});

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Your bag',
    description: 'Bag | Header | Title',
  },
  closeBag: {
    defaultMessage: 'Close Bag',
    description: 'Bag | Header | Close bag button',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerText: {
    textAlign: 'center',
  },
  bagScrollView: {
    flexGrow: 1,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type SafeAreaEdges = ComponentProps<
  typeof CustomNavigationHeader.Container
>['safeAreaEdges'];

type BagScrollViewProps = ScrollViewProps & {
  orderChannel: AddressType;
  locationName: string;
  viewMenuCta: string;
  viewMenuA11y: string;
  isDisabled: boolean;
  automaticallyAdjustKeyboardInsets: boolean;
  onViewMenu: () => void;
  requestChannelOrLocationChange: () => void;
  requestDeliveryPreferencesChange: () => void;
  onHeaderCloseBtnPress: () => void;
};
