import { useCallback } from 'react';
import { useClient } from 'urql';

import { type BagReward } from '@order/features/ordering';

import {
  BagLoyaltyRewardsDocument,
  type BagLoyaltyRewardsQuery,
} from '../../GraphQL/BagLoyaltyRewards.generated';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching available points rewards for this bag.
 *
 * WARNING: `apps/order/src/context/Urql/client/graphcache-exchange/keys.ts`
 * The file above contains an extra dependency for the `cart.loyaltyId` field.
 * It's necessary to avoid the cart query canceling this one due to the cache keys being the same.
 */
export const useBagLoyaltyRewards = () => {
  const client = useClient();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const fetchBagLoyaltyRewards = useCallback(async () => {
    const queryRewards = client.query<BagLoyaltyRewardsQuery>;

    const response = await queryRewards(
      BagLoyaltyRewardsDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();

    const hasNoData = response.data?.cart === undefined;
    const hasFailedToLoad = Boolean(response.error) || hasNoData;

    if (hasFailedToLoad) {
      throw new Error('Failed to fetch rewards'); // caught in xstate
    }

    const nonPointsRewards = response.data?.cart?.availableRewards ?? [];
    const pointsRewards = response.data?.cart?.eligiblePointRewards ?? [];
    const rewards = [...nonPointsRewards, ...pointsRewards] as BagReward[];

    return { rewards };
  }, [client.query]);

  // ──────────────────────────────────────────────────────────────────────────

  return { fetchBagLoyaltyRewards };
};
