import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { HStack } from '../../../../../Stack';
import { LoyaltyOfferCardProgressNodeBase } from '../../components';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardProgressBarHurdleSpend = (
  props: LoyaltyOfferCardProgressBarHurdleSpendProps,
) => {
  const { hurdles, percentageCompleted, accessibilityLabel } = props;

  const percentage = Math.max(Math.min(100, percentageCompleted), 0);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <HStack
        itemsPerRow={hurdles.length}
        gap={theme.spacing['1']}
        accessible
        accessibilityRole="progressbar"
        accessibilityLabel={accessibilityLabel}
        accessibilityValue={{ min: 0, max: 100, now: percentage }}
      >
        {hurdles.map((hurdle, index) => {
          const { progress, goal, outcome } = hurdle;

          const step = index + 1;
          const previousStep = hurdles[index - 1];
          const previousStepGoal = previousStep?.goal ?? 0;

          const label = Number.isNaN(outcome) ? undefined : `${outcome}`;
          const labelAlignment = step === hurdles.length ? 'right' : 'right-center'; // prettier-ignore

          // NOTE: We need to consider the previous step's final goal to correctly
          //       calculate progress for the current step
          const progressForCurrentStep = previousStepGoal >= progress ? 0 : progress; // prettier-ignore

          return (
            <LoyaltyOfferCardProgressNodeBase
              key={step}
              percentageCompleted={(progressForCurrentStep / goal) * 100}
              label={label}
              labelAlignment={labelAlignment}
            />
          );
        })}
      </HStack>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProgressBarHurdleSpendProps = {
  hurdles: Hurdle[] | readonly Hurdle[];
  percentageCompleted: number;
  accessibilityLabel?: string;
};

type Hurdle = { goal: number; progress: number; outcome: number };
