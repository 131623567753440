import React, { type ComponentProps } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { CustomNavigationHeader, theme, useResponsive } from '@sg/garnish';

// ─── Components ──────────────────────────────────────────────────────────────

const CustomizationNavigationHeaderContainer = (
  props: CustomizationSelectedIngredientsContainerProps,
) => {
  const { children, spacing = 'standard', ...restProps } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = match([
    undefined,
    spacing === 'narrow' ? styles.outerContainerNarrowSM : undefined,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <CustomNavigationHeader.Container
        palette="cream"
        transition="gradient"
        safeAreaEdges={HEADER_SAFE_AREA_EDGES}
        gradientColors={HEADER_GRADIENT_COLORS}
        {...restProps}
      >
        {children}
      </CustomNavigationHeader.Container>
    </View>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const CustomizationNavigationHeaderContainerText = (
  props: CustomizationNavigationHeaderTextProps,
) => {
  const { children, ...restProps } = props;

  const { match } = useResponsive();

  return (
    <CustomNavigationHeader.Text
      textAlignment={match(['center', 'left'])}
      {...restProps}
    >
      {children}
    </CustomNavigationHeader.Text>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationNavigationHeader = {
  ...CustomNavigationHeader,
  Container: CustomizationNavigationHeaderContainer,
  Text: CustomizationNavigationHeaderContainerText,
};

// ─── Constants ───────────────────────────────────────────────────────────────

const HEADER_GRADIENT_COLORS = [theme.colors.CREAM, 'rgba(248, 247, 240, 0)'];
const HEADER_SAFE_AREA_EDGES: CustomizationSelectedIngredientsContainerProps['safeAreaEdges'] =
  Platform.select({ android: ['top'], default: [] });

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainerNarrowSM: {
    paddingHorizontal: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationSelectedIngredientsContainerProps = {
  spacing?: 'standard' | 'narrow';
} & ComponentProps<typeof CustomNavigationHeader.Container>;

type CustomizationNavigationHeaderTextProps = ComponentProps<
  typeof CustomNavigationHeader.Text
>;
