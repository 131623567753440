import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  StyleSheet,
  useWindowDimensions,
  View,
  type ViewProps,
} from 'react-native';
import { format } from 'date-fns';
import {
  BodyText,
  type CloudinaryTransformConfig,
  FallbackImage,
  theme,
  TitleText,
} from '@sg/garnish';

import { DefaultImage } from './assets';
import {
  LoyaltyRewardApplyCta,
  LoyaltyRewardInStoreOnlyTag,
  LoyaltyRewardStartOrderCta,
  LoyaltyRewardTermsCta,
} from './subcomponents';

/**
 * Reward for loyalty.
 */
export const LoyaltyReward = (props: LoyaltyRewardProps) => {
  const {
    id,
    title,
    points,
    expirationDate,
    redeemableAt,
    imageUrl,
    style,
    isApplied = false,
    isLoading = false,
    isDisabled = false,
    isInStoreOnly = false,
    onShowTerms,
    onStartOrder,
    onPress,
  } = props;

  const { formatMessage } = useIntl();
  const { fontScale } = useWindowDimensions();

  const expiration = expirationDate
    ? format(new Date(expirationDate), 'MMM d')
    : null;

  // NOTE: Points rewards images are centered in the middle while non-points
  //       rewards images cover the entire frame.
  const shouldUseContainContentFit = Boolean(imageUrl && points);
  const showDarkerBackground = isLoading || isDisabled;

  const darkerBackgroundStyle = showDarkerBackground
    ? styles.darkerBackground
    : null;

  const containerStyle = [styles.container, darkerBackgroundStyle, style];

  const handleOnPress = useCallback(() => {
    onPress?.(id);
  }, [id, onPress]);

  return (
    <View style={containerStyle}>
      <FallbackImage
        contentFit={shouldUseContainContentFit ? 'contain' : 'cover'}
        style={styles.image}
        defaultImage={DefaultImage}
        baseUrl={imageUrl}
        cloudinaryConfig={cloudinaryConfig}
        aria-label={formatMessage(messages.accessibilityLabel)}
      />

      <View style={styles.infoColumn}>
        <View style={styles.infoContent}>
          <View style={styles.titleWrapper}>
            <TitleText
              sizeMatch={['18']}
              style={styles.title}
              numberOfLines={fontScale > 1.1 ? 2 : 3}
            >
              {title}
            </TitleText>

            {onShowTerms ? (
              <LoyaltyRewardTermsCta
                isDisabled={isDisabled}
                onPress={onShowTerms}
              />
            ) : null}
          </View>

          {points ? (
            <BodyText style={styles.body} sizeMatch={['14']}>
              {formatMessage(messages.pointsLabel, { points: String(points) })}
            </BodyText>
          ) : null}

          {expiration ? (
            <BodyText style={styles.body} sizeMatch={['14']}>
              {formatMessage(messages.expirationLabel, { expiration })}
            </BodyText>
          ) : null}
        </View>

        {isInStoreOnly ? <LoyaltyRewardInStoreOnlyTag /> : null}

        {onStartOrder ? (
          <LoyaltyRewardStartOrderCta
            isDisabled={isDisabled}
            onPress={onStartOrder}
          />
        ) : null}

        {onPress ? (
          <LoyaltyRewardApplyCta
            redeemableAt={redeemableAt}
            isApplied={isApplied}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onPress={handleOnPress}
          />
        ) : null}
      </View>
    </View>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const WIDTH = 310;
const HEIGHT = 140;

const cloudinaryConfig: CloudinaryTransformConfig = [
  { crop: 'crop', height: 0.6, width: 0.6 },
  { height: HEIGHT },
];

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  pointsLabel: {
    defaultMessage: '{points} points',
    description: 'Loyalty | Reward | Points label',
  },
  expirationLabel: {
    defaultMessage: 'Use by {expiration}',
    description: 'Loyalty | Reward | Expiration label',
  },
  accessibilityLabel: {
    defaultMessage: 'Reward',
    description: 'Loyalty | Reward | Reward A11y',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: WIDTH,
    height: HEIGHT,
    borderRadius: theme.radius.large,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.OATMEAL,
    borderColor: theme.colors.DARK_KALE,
    borderWidth: 1,
  },
  darkerBackground: {
    backgroundColor: theme.colors.LIGHT,
  },
  image: {
    width: 100,
    borderTopLeftRadius: theme.radius.large,
    borderBottomLeftRadius: theme.radius.large,
    borderRightColor: theme.colors.DARK_KALE,
    borderRightWidth: 1,
  },
  infoColumn: {
    width: '100%',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing['4'],
  },
  infoContent: {
    width: '100%',
    gap: theme.spacing['1'],
  },
  titleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing['2'],
  },
  title: {
    flex: 1,
    color: theme.colors.KALE,
    fontFamily: 'GrenettePro-Regular',
  },
  body: {
    color: theme.colors.CHARCOAL,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyRewardProps = {
  id: string;
  title?: string;
  points?: number | null;
  expirationDate?: string | null;
  redeemableAt?: string | null;
  imageUrl?: string | null;
  style?: ViewProps['style'];
  isApplied?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isInStoreOnly?: boolean;
  onShowTerms?: () => void;
  onStartOrder?: () => void;
  onPress?: (rewardId: string) => void;
};
