import React from 'react';
import { StyleSheet, type TextStyle, View } from 'react-native';
import { BodyText, Image, theme } from '@sg/garnish';

export const ScanAtCheckoutHowItWorksItem = (
  props: ScanAtCheckoutHowItWorksItemProps,
) => {
  const { description, image, textStyle, tintColor } = props;

  return (
    <View style={styles.row}>
      <Image tintColor={tintColor} style={styles.image} source={image.url} />
      <BodyText sizeMatch={['16']} style={[styles.description, textStyle]}>
        {description}
      </BodyText>
    </View>
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type ScanAtCheckoutHowItWorksItemProps = {
  description: string;
  image: {
    url: string;
    description: string;
  };
  textStyle: TextStyle;
  tintColor?: string;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: theme.spacing[6],
    gap: theme.spacing[4],
    alignItems: 'center',
  },
  image: {
    width: 32,
    height: 32,
    resizeMode: 'contain',
  },
  description: {
    flex: 1,
    color: theme.colors.CHARCOAL,
  },
});
