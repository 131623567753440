import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type ImageSourcePropType } from 'react-native';
import { type Entry } from 'contentful';

import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useLoyaltyPremiumStatus } from '@order/hooks';

import heroImageFallback from '../assets/fallback-post-purchase-hero.png';

export const usePostPurchaseContent = () => {
  const { formatMessage } = useIntl();
  const { isPremium } = useLoyaltyPremiumStatus();

  // ─── Remote Data ─────────────────────────────────────────────────────

  const loyaltyContent = useContentfulContentTypeEntry<LoyaltyContainer>({
    contentType: 'loyalty',
  });
  const contentfulData = loyaltyContent.data?.fields?.postPurchaseCelebration;

  // ─────────────────────────────────────────────────────────────────────

  const fallbackData = useMemo<PostPurchaseItem[]>(
    () => [
      {
        id: `Loyalty | Post-purchase | Modal | ${REWARDS_CONTENT_ID}`,
        title: formatMessage(fallbacks.title),
        image: {
          url: '',
          description: '',
          fallbackSource: heroImageFallback as ImageSourcePropType,
        },
        description: formatMessage(fallbacks.subtitle),
      },
      {
        id: `Loyalty | Post-purchase | Modal | ${PREMIUM_REWARDS_CONTENT_ID}`,
        title: formatMessage(fallbacks.title),
        image: {
          url: '',
          description: '',
          fallbackSource: heroImageFallback as ImageSourcePropType,
        },
        description: formatMessage(fallbacks.subtitle),
      },
    ],
    [formatMessage],
  );

  const postPurchaseContent = useMemo<PostPurchaseItem[]>(() => {
    if (!contentfulData) {
      return fallbackData;
    }

    if (isPremium) {
      return contentfulData
        .filter(filterPremiumRewardContent)
        .map(extractPostPurchaseItem);
    }

    return contentfulData
      .filter(filterNonPremiumRewardContent)
      .map(extractPostPurchaseItem);
  }, [contentfulData, fallbackData, isPremium]);

  // ─────────────────────────────────────────────────────────────────────

  return postPurchaseContent;
};

// ─── Fallbacks ───────────────────────────────────────────────────────────────

const fallbacks = defineMessages({
  title: {
    defaultMessage: 'Thanks for ordering! Your points are on the way.',
    description: 'Loyalty | Post purchase modal | Title',
  },
  subtitle: {
    defaultMessage: 'Points are issued within 24 hours and expire in 180 days.',
    description: 'Loyalty | Post purchase modal | Subtitle',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const REWARDS_CONTENT_ID = 'SgRewards';
const PREMIUM_REWARDS_CONTENT_ID = 'SgRewards Premium';

// ─── Helpers ─────────────────────────────────────────────────────────────────

function filterPremiumRewardContent(entry: Entry<PostPurchaseContentfulItem>) {
  return entry.fields.id.includes(PREMIUM_REWARDS_CONTENT_ID);
}

// prettier-ignore
function filterNonPremiumRewardContent(entry: Entry<PostPurchaseContentfulItem>) {
  return !entry.fields.id.includes(PREMIUM_REWARDS_CONTENT_ID);
}

// prettier-ignore
function extractPostPurchaseItem(entry: Entry<PostPurchaseContentfulItem>): PostPurchaseItem {
  return {
    ...entry.fields,
    image: {
      url: entry.fields.image.fields.file.url,
      description: entry.fields.image.fields.description,
      fallbackSource: undefined,
    },
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyContainer = {
  postPurchaseCelebration: Entry<PostPurchaseContentfulItem>[];
};

type PostPurchaseContentfulItem = {
  id: string;
  title: string;
  description: string;
  image: {
    fields: {
      description: string;
      file: {
        url: string;
      };
    };
  };
};

type PostPurchaseItem = {
  id: string;
  title: string;
  image: {
    url: string;
    description: string;
    fallbackSource?: ImageSourcePropType;
  };
  description: string;
};
