import React from 'react';
import { type ImageSourcePropType, StyleSheet, View } from 'react-native';
import {
  BodyText,
  FallbackImage,
  theme,
  TitleText,
  useResponsive,
} from '@sg/garnish';

export const JoinSgRewardsRowItem = (props: JoinSgRewardsRowItemProps) => {
  const { title, image, description } = props;
  const { match } = useResponsive();

  return (
    <View
      style={[styles.container, match([styles.contentXS, styles.contentSM])]}
    >
      <FallbackImage
        style={match([styles.imageXS, styles.imageSM])}
        baseUrl={image.url}
        defaultImage={image.fallbackSource}
        contentfulOptions={match([contentfulOptionsXS, contentfulOptionsSM])}
      />

      <View style={match([styles.textContainerXS, styles.textContainerSM])}>
        <TitleText
          sizeMatch={['24', '48']}
          style={match([styles.titleXS, styles.titleSM])}
        >
          {title}
        </TitleText>

        <BodyText
          sizeMatch={['22']}
          style={match([styles.descriptionXS, styles.descriptionSM])}
        >
          {description}
        </BodyText>
      </View>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const contentfulOptionsXS = { w: 40, h: 40 };
const contentfulOptionsSM = { w: 100, h: 100 };

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingVertical: theme.spacing['6'],
  },
  contentXS: {
    flexDirection: 'row',
    gap: theme.spacing['6'],
  },
  contentSM: {
    flex: 1,
    gap: theme.spacing['6'],
    paddingHorizontal: theme.spacing['16'],
  },
  textContainerXS: {
    flex: 1,
    gap: theme.spacing['6'],
  },
  textContainerSM: {
    flex: 1,
    gap: theme.spacing['10'],
  },
  titleXS: {
    color: theme.colors.SWEET_CORN,
  },
  descriptionXS: {
    color: theme.colors.WHITE,
  },
  titleSM: {
    color: theme.colors.SWEET_CORN,
    textAlign: 'center',
    height: 96,
  },
  descriptionSM: {
    color: theme.colors.WHITE,
    textAlign: 'center',
  },
  imageXS: {
    width: 40,
    height: 40,
  },
  imageSM: {
    alignSelf: 'center',
    width: 100,
    height: 100,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinSgRewardsRowItemProps = {
  title: string;
  description: string;
  image: {
    url?: string;
    fallbackSource?: ImageSourcePropType;
    description: string;
  };
};
