import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { Icon } from '../../../Icon';
import { QRCode } from '../../../QRCode';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanCardQrCode = (props: ScanCardBaseProps) => {
  const { value } = props;

  return (
    <View style={styles.container}>
      <QRCode
        value={value}
        color={theme.colors.BLACK}
        backgroundColor={theme.colors.OPACITY.TRANSPARENT}
      />

      <View style={styles.background}>
        <Icon
          name="IconQrCodeHexagon"
          width="100%"
          height="100%"
          color={theme.colors.WHITE}
        />
      </View>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const QR_CODE_CONTAINER_SIZE = 200;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: QR_CODE_CONTAINER_SIZE,
    height: QR_CODE_CONTAINER_SIZE,
    borderRadius: QR_CODE_CONTAINER_SIZE,
    marginTop: 'auto',
    padding: theme.spacing['11'],
  },
  background: {
    zIndex: -1,
    ...StyleSheet.absoluteFillObject,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanCardBaseProps = Readonly<{
  value: string;
}>;
