import { TierName } from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useSweetpassTier } from '../../../../../hooks/useSweetpassTier';

export const useSweetPassOptions = () => {
  const { sweetpassTierName } = useSweetpassTier();
  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');

  const isSweetpassJoinItemVisible =
    !isLoyaltyV2Enabled &&
    Boolean(sweetpassTierName) &&
    sweetpassTierName === TierName.OptedOut;

  const isSweetpassMembershipItemVisible =
    !isLoyaltyV2Enabled &&
    Boolean(sweetpassTierName) &&
    sweetpassTierName !== TierName.OptedOut;

  return { isSweetpassJoinItemVisible, isSweetpassMembershipItemVisible };
};
