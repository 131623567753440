/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoyaltyOfferOptInOnFocusMutationVariables = Types.Exact<{
  challengeId: Types.Scalars['String']['input'];
}>;

export type LoyaltyOfferOptInOnFocusMutation = {
  readonly __typename?: 'Mutation';
  readonly challengeOptInV2:
    | {
        readonly __typename: 'Challenge';
        readonly assetUrl: string | null;
        readonly achieved: number;
        readonly celebrationCopy: string;
        readonly descriptiveTitle: string;
        readonly expirationDate: string;
        readonly goal: number;
        readonly id: string;
        readonly theme: Types.ChallengeTheme;
        readonly legalTerms: string;
        readonly percentageCompleted: number;
        readonly permalink: string;
        readonly progressType: Types.ChallengeProgressType;
        readonly progress: number;
        readonly progressCopy: string;
        readonly status: Types.ChallengeStatus;
        readonly title: string;
      }
    | {
        readonly __typename: 'LoyaltyOptInFailed';
        readonly errorMessage: string;
      };
};

export const LoyaltyOfferOptInOnFocusDocument = gql`
  mutation loyaltyOfferOptInOnFocus($challengeId: String!) {
    challengeOptInV2(challengeId: $challengeId) {
      __typename
      ... on Challenge {
        __typename
        assetUrl
        achieved
        celebrationCopy
        descriptiveTitle
        expirationDate
        goal
        id
        theme
        legalTerms
        percentageCompleted
        permalink
        progressType
        progress
        progressCopy
        status
        title
      }
      ... on LoyaltyOptInFailed {
        __typename
        errorMessage
      }
    }
  }
`;

export function useLoyaltyOfferOptInOnFocusMutation() {
  return Urql.useMutation<
    LoyaltyOfferOptInOnFocusMutation,
    LoyaltyOfferOptInOnFocusMutationVariables
  >(LoyaltyOfferOptInOnFocusDocument);
}
