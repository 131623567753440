import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, Button, theme, useCallbackWithLimit } from '@sg/garnish';

export const BagLoyaltyErrorState = (props: BagLoyaltyErrorStateProps) => {
  const { refetch } = props;

  const { callbackWithLimit: refresh, hasReachedLimit } = useCallbackWithLimit({
    callback: refetch,
    limit: 5,
  });

  return (
    <View style={styles.container}>
      <BodyText sizeMatch={['14']}>
        <FormattedMessage {...messages.errorTitle} />
      </BodyText>

      {hasReachedLimit ? null : (
        <Button
          size="medium"
          palette="spinachMuted"
          style={styles.button}
          onPress={refresh}
        >
          <FormattedMessage {...messages.refresh} />
        </Button>
      )}
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  errorTitle: {
    defaultMessage:
      'Points and rewards are taking longer than expected to load. Please try again in a few moments.',
    description: 'Loyalty | Bag | Error Title',
  },
  refresh: {
    defaultMessage: 'Refresh',
    description: 'Loyalty | Bag | Refresh',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: theme.spacing['10'],
  },
  button: {
    minHeight: 48,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLoyaltyErrorStateProps = {
  refetch: () => void;
};
