import React, { type ComponentProps, useCallback } from 'react';
import { type LayoutChangeEvent, StyleSheet } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardProgressNodeLabel = (
  props: LoyaltyOfferCardProgressNodeLabelProps,
) => {
  const { children, alignment = 'right', style, ...restProps } = props;

  // ─── Shared Values ───────────────────────────────────────────────────

  const containerWidthSV = useSharedValue(0);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const storeContainerWidth = useCallback(
    (event: LayoutChangeEvent) => {
      // eslint-disable-next-line functional/immutable-data
      containerWidthSV.value = event.nativeEvent.layout.width;
    },
    [containerWidthSV],
  );

  // ─────────────────────────────────────────────────────────────────────

  const containerAnimatedStyles = useAnimatedStyle(() => {
    if (alignment === 'right-center') {
      return { transform: [{ translateX: containerWidthSV.value / 2 }] };
    }

    return { transform: undefined };
  });

  const containerStyles = [styles.container, containerAnimatedStyles];
  const labelStyles = [styles.label, style];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Animated.View style={containerStyles} onLayout={storeContainerWidth}>
      <BodyText style={labelStyles} sizeMatch={['14']} {...restProps}>
        {children}
      </BodyText>
    </Animated.View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing['1'],
  },
  label: {
    color: theme.colors.WHITE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProgressNodeLabelProps = {
  alignment?: 'right-center' | 'right';
} & ComponentProps<typeof BodyText>;
