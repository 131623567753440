import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  Icon,
  theme,
  TitleText,
  useCallbackWithLimit,
  useResponsive,
} from '@sg/garnish';

export const LoyaltyHomeErrorState = (props: LoyaltyHomeErrorStateProps) => {
  const { isLoading, refetch } = props;

  const { match } = useResponsive();

  const { callbackWithLimit: refresh, hasReachedLimit } = useCallbackWithLimit({
    callback: refetch,
    limit: 5,
  });

  return (
    <View style={match([styles.containerXS, styles.containerSM])}>
      <View style={match([styles.contentXS, null])}>
        <View style={styles.image}>
          <Icon name="IconSgRewardKaleFill" width={140} height={140} />
        </View>

        <View style={styles.info}>
          <TitleText style={styles.title} sizeMatch={['24']}>
            <FormattedMessage {...messages.errorTitle} />
          </TitleText>

          <View>
            <BodyText style={styles.body} sizeMatch={['16']} numberOfLines={2}>
              <FormattedMessage {...messages.errorSubtitleFirstLine} />
            </BodyText>

            <BodyText style={styles.body} sizeMatch={['16']} numberOfLines={2}>
              <FormattedMessage {...messages.errorSubtitleSecondLine} />
            </BodyText>
          </View>
        </View>
      </View>

      {hasReachedLimit && !isLoading ? null : (
        <Button
          size="large-wide"
          palette="spinachMuted"
          style={styles.button}
          isLoading={isLoading}
          onPress={refresh}
        >
          <FormattedMessage {...messages.refresh} />
        </Button>
      )}
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  errorTitle: {
    defaultMessage: 'Rewards are taking longer than expected to load.',
    description: 'Loyalty | Home | Error Title',
  },
  errorSubtitleFirstLine: {
    defaultMessage: 'Please try again in a few moments.',
    description: 'Loyalty | Home | Error Subtitle First Line',
  },
  errorSubtitleSecondLine: {
    defaultMessage: 'Thank you for your patience.',
    description: 'Loyalty | Home | Error Subtitle Second Line',
  },
  refresh: {
    defaultMessage: 'Refresh',
    description: 'Loyalty | Home | Refresh',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    flexGrow: 1,
    gap: theme.spacing['10'],
    justifyContent: 'space-between',
  },
  containerSM: {
    flexGrow: 1,
    gap: theme.spacing['10'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentXS: {
    marginVertical: 'auto',
  },
  info: {
    alignItems: 'center',
    gap: theme.spacing['4'],
  },
  image: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 220,
    height: 220,
  },
  title: {
    paddingHorizontal: theme.spacing['6'],
    textAlign: 'center',
    color: theme.colors.KALE,
  },
  body: {
    paddingHorizontal: theme.spacing['6'],
    textAlign: 'center',
  },
  button: {
    marginTop: 'auto',
    marginHorizontal: theme.spacing['6'],
    marginBottom: theme.spacing['6'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeErrorStateProps = {
  isLoading: boolean;
  refetch: () => void;
};
