import React, { useCallback, useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Pressable, StyleSheet, View, type ViewStyle } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  BodyText,
  Icon,
  theme,
  TitleText,
  usePressableState,
} from '@sg/garnish';

import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useTelemetry } from '@order/Telemetry';

import { useHasEarnedLoyaltyPoints } from '../../hooks';

export const OrderStatusLoyaltyPointsBanner = (props: PropsWithOrderId) => {
  const hasEarnedLoyaltyPoints = useHasEarnedLoyaltyPoints(props.orderId);

  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);

  const containerStyle = [
    styles.container,
    isInteracting && styles.containerInteracting,
    props.style,
  ];

  const { title, body } = useContentfulMessagesWithFallback();

  const { track } = useTelemetry();
  const { navigate } = useNavigation();
  const { formatMessage } = useIntl();

  const handlePress = useCallback(() => {
    track('order_status.sg-rewards-cta.press');
    navigate('MainTabs', { screen: 'LoyaltyTab' });
  }, [track, navigate]);

  if (!hasEarnedLoyaltyPoints) return null;

  return (
    <Pressable
      ref={ref}
      style={containerStyle}
      onPress={handlePress}
      accessibilityRole="button"
      accessibilityLabel={formatMessage(messages.a11yLabel)}
    >
      <View style={styles.info}>
        <TitleText sizeMatch={['18']} style={styles.title}>
          {title}
        </TitleText>

        <BodyText sizeMatch={['14']} style={styles.body}>
          {body}
        </BodyText>
      </View>

      <Icon name="IconSgRewardSweetCorn" width={24} height={24} />
    </Pressable>
  );
};

// ─── Helpers ────────────────────────────────────────────────────────────────

const useContentfulMessagesWithFallback = () => {
  const { formatMessage } = useIntl();

  const content = useContentfulContentTypeEntry<LoyaltyContainer>({
    contentType: 'loyalty',
  });

  const {
    orderStatusPointsBannerTitle: title = formatMessage(messages.title),
    orderStatusPointsBannerBody: body = formatMessage(messages.body),
  } = content.data?.fields ?? emptyContentfulResult;

  return useMemo(() => ({ title, body }), [title, body]);
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Your points are on the way!',
    description: 'Order Status | Loyalty Points Banner | Title',
  },
  body: {
    defaultMessage: "You'll see them in your account within 24h.",
    description: 'Order Status | Loyalty Points Banner | Body',
  },
  a11yLabel: {
    defaultMessage: 'Open loyalty home',
    description: 'Order Status | Loyalty Points Banner | Accessibility Label',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.FOREST,
    borderRadius: theme.radius.large,
    padding: theme.spacing['4'],
    marginBottom: theme.spacing['10'],
  },
  containerInteracting: {
    backgroundColor: theme.colors.KALE,
  },
  info: {
    gap: theme.spacing['2'],
  },
  title: {
    color: theme.colors.SWEET_CORN,
  },
  body: {
    color: theme.colors.WHITE,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type PropsWithOrderId = {
  orderId?: string;
  style?: ViewStyle;
};

type LoyaltyContainer = {
  orderStatusPointsBannerTitle?: string;
  orderStatusPointsBannerBody?: string;
};

// ─── Constants ──────────────────────────────────────────────────────────────

const emptyContentfulResult: LoyaltyContainer = {
  orderStatusPointsBannerTitle: undefined,
  orderStatusPointsBannerBody: undefined,
};
