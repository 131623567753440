import React, { useCallback } from 'react';
import { Platform } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { FocusScope, TextSpace, useKeyboardListener } from '@sg/garnish';
import { type DeliveryOrderDetailInput } from '@sg/graphql-schema';

import { useIsLoggedIn } from '@order/AuthMachine';
import { LoadingAnimation } from '@order/components';
import { Bag } from '@order/features/ordering';
import { type Product } from '@order/graphql';
import { useCloseBag, useNavigateToMenu } from '@order/hooks';
import { useNavigateToLastInteractedStore } from '@order/LastInteractedStore';

import { useBagContent, type useOrderState, useUpsells } from '../../hooks';
import { BagLineItems } from '../BagLineItems';
import { BagLoyalty } from '../BagLoyalty';
import { CategoryUpsells } from '../CategoryUpsells';

export const BagContent = (props: BagContentProps) => {
  const { orderState } = props;

  // ─── Flags ────────────────────────────────────────────────────────────────

  const {
    isBagEmpty,
    isBagValid,
    isFetchingInitialData,
    isSubmittingOrder,
    isShowingFutureDateWarning,
    isShowingRewardWarning,
    isLineItemQuantityChangePending,
    isPerformingBagAction,
    isShowingWarning,
    isShowingModal,
    isPostOrder,
  } = orderState;

  const isFocusable = !isShowingModal && !isShowingWarning;
  const canContinueToCheckout =
    !isPerformingBagAction &&
    !isLineItemQuantityChangePending &&
    !isShowingModal;

  // ─── Context ──────────────────────────────────────────────────────────────

  const {
    bagTracking,
    wantedTime,
    dropoffLocation = '',
    lineItemIdsBeingUpdated,
    cart,
    rewardError,
    shouldIncludeUtensils,
  } = orderState;

  // ─── Cart ─────────────────────────────────────────────────────────────────

  const {
    orderId = '',
    orderChannel = 'pickup',
    locationName = '',
    restaurantId = '',
    restaurantSlug = '',
    deliveryOrderDetail,
    dropoffLocations,
    addressId,
    lineItems,
    ledger,
  } = cart;

  // ─── Actions ──────────────────────────────────────────────────────────────

  const {
    startOrdering,
    addUpsell,
    startChangingLineItemQuantity,
    stopChangingLineItemQuantity,
    changeLineItemQuantity,
    cancelRequest,
    requestChannelOrLocationChange,
    requestDeliveryPreferencesChange,
    requestTimeChange,
    changeDropoffLocation,
    changeUtensilsUsage,
    requestCheckoutLedger,
  } = orderState;

  // Fetch ordering data on bag focus.
  useFocusEffect(startOrdering as () => void);

  // ─── Tracking ─────────────────────────────────────────────────────────────

  const { trackViewMenuPressed, trackLineItemPressed } = bagTracking;

  // ─── Navigation ───────────────────────────────────────────────────────────

  const navigation = useNavigation();
  const closeBag = useCloseBag();

  const handleViewMenu = useNavigateToLastInteractedStore();
  const navigateToMenu = useNavigateToMenu();
  const navigateToBagMenu = useCallback(() => {
    trackViewMenuPressed();
    navigateToMenu({
      orderType: orderChannel,
      restaurantSlug,
      deliveryOrderDetail: { addressId },
    });
  }, [
    orderChannel,
    restaurantSlug,
    addressId,
    navigateToMenu,
    trackViewMenuPressed,
  ]);

  // ─── Auth State ───────────────────────────────────────────────────────────

  const isLoggedIn = useIsLoggedIn();

  const handleContinue = useCallback(() => {
    if (!isLoggedIn) {
      navigation.navigate('Auth', {
        screen: 'Login',
        params: { redirect: 'bag' },
      });

      return;
    }

    requestCheckoutLedger();
  }, [isLoggedIn, navigation, requestCheckoutLedger]);

  // ─── Contentful ───────────────────────────────────────────────────────────

  const { viewMenuCta, viewMenuA11y, continueToCheckoutCta } = useBagContent();

  // ─── Channel Font Size ────────────────────────────────────────────────────

  const channelFontSize = locationName.length > 24 ? '24' : '32';

  // ─── Upsells ──────────────────────────────────────────────────────────────

  const {
    categoryUpsells,
    isLoadingUpsells,
    shouldRenderCategoryUpsells,
    hideCategoryUpsells,
  } = useUpsells({ orderId, restaurantId, orderChannel, lineItems });

  const addUpsellToBag = useCallback(
    (payload: {
      product: Partial<Product>;
      deliveryDetails?: DeliveryOrderDetailInput;
    }) => {
      const { product, deliveryDetails } = payload;

      addUpsell({
        productId: product.id ?? '',
        productName: product.name ?? '',
        deliveryDetails,
      });
    },
    [addUpsell],
  );

  // ─── Floating Ctas ────────────────────────────────────────────────────────
  // When the keyboard is open on Android, the floating cta hides the input field.

  const { isKeyboardOpen } = useKeyboardListener();
  const shouldHideFloatingCtas = Platform.OS === 'android' && isKeyboardOpen;
  const shouldShowFloatingCtas =
    !shouldHideFloatingCtas && isBagValid && !isLoadingUpsells;

  // ──────────────────────────────────────────────────────────────────────────

  if (shouldRenderCategoryUpsells) {
    return (
      <FocusScope enabled={isFocusable}>
        <CategoryUpsells
          categories={categoryUpsells}
          deliveryOrderDetail={deliveryOrderDetail}
          upsellIdsBeingAdded={lineItemIdsBeingUpdated}
          addUpsellToBag={addUpsellToBag}
          onClose={closeBag}
          onContinue={hideCategoryUpsells}
        />
      </FocusScope>
    );
  }

  return (
    <FocusScope enabled={isFocusable}>
      {/* Loading state for the bag. */}
      {isFetchingInitialData || isLoadingUpsells || isPostOrder ? (
        <LoadingAnimation />
      ) : null}

      {/* Empty state for the bag. */}
      {isBagEmpty ? <Bag.EmptyState onViewMenu={handleViewMenu} /> : null}

      {/* Loading state overlay for when actions are performed in the bag, not needed for initial fetching or placing orders. */}
      <Bag.LoadingState
        isLoading={Boolean(
          isPerformingBagAction && !isFetchingInitialData && !isSubmittingOrder,
        )}
      />

      {/* Warning that the selected date is in the future. */}
      <Bag.WarningModal visible={isShowingWarning} onClose={cancelRequest}>
        {isShowingFutureDateWarning ? (
          <Bag.FutureDateWarning onClose={cancelRequest} />
        ) : null}

        {isShowingRewardWarning ? (
          <Bag.RewardWarning message={rewardError} onClose={cancelRequest} />
        ) : null}
      </Bag.WarningModal>

      {/* Actual bag contents. */}
      {isBagValid && !isLoadingUpsells ? (
        <Bag.ScrollView
          orderChannel={orderChannel}
          locationName={locationName}
          viewMenuCta={viewMenuCta}
          viewMenuA11y={viewMenuA11y}
          isDisabled={isPerformingBagAction}
          automaticallyAdjustKeyboardInsets={!isShowingModal}
          onViewMenu={navigateToBagMenu}
          requestChannelOrLocationChange={requestChannelOrLocationChange}
          requestDeliveryPreferencesChange={requestDeliveryPreferencesChange}
          onHeaderCloseBtnPress={closeBag}
        >
          <Bag.ContentContainer>
            {/* Header with channel, location, time and dropoff location. */}
            <Bag.HeaderContainer>
              <Bag.HeaderLine sizeMatch={[channelFontSize]}>
                <Bag.ChannelHeader
                  orderChannel={orderChannel}
                  requestChannelOrLocationChange={
                    requestChannelOrLocationChange
                  }
                />

                <TextSpace />

                <Bag.TimeConnector time={wantedTime} />

                <TextSpace />

                <Bag.TimeHeader
                  time={wantedTime}
                  requestTimeChange={requestTimeChange}
                />
              </Bag.HeaderLine>

              <Bag.HeaderLine sizeMatch={[channelFontSize]}>
                {orderChannel === 'pickup' ? (
                  <Bag.LocationFromConnector />
                ) : null}

                {orderChannel === 'delivery' ? (
                  <Bag.LocationToConnector />
                ) : null}

                {orderChannel === 'outpost' ? null : <TextSpace />}

                <Bag.LocationHeader
                  orderChannel={orderChannel}
                  locationName={locationName}
                  requestChannelOrLocationChange={
                    requestChannelOrLocationChange
                  }
                  requestDeliveryPreferencesChange={
                    requestDeliveryPreferencesChange
                  }
                />
              </Bag.HeaderLine>
            </Bag.HeaderContainer>

            {orderChannel !== 'delivery' && dropoffLocations.length >= 2 ? (
              <Bag.DropoffLocationPicker
                selectedDropoffLocationId={dropoffLocation}
                firstDropoffLocation={dropoffLocations[0]}
                secondDropoffLocation={dropoffLocations[1]}
                changeDropoffLocation={changeDropoffLocation}
              />
            ) : null}

            {/* List of line items, able to edit quantity or change name. */}
            <BagLineItems
              lineItems={lineItems}
              restaurantSlug={restaurantSlug}
              addressId={addressId}
              isDelivery={orderChannel === 'delivery'}
              isDisabled={isPerformingBagAction}
              trackLineItemPressed={trackLineItemPressed}
              startChangingLineItemQuantity={startChangingLineItemQuantity}
              stopChangingLineItemQuantity={stopChangingLineItemQuantity}
              changeLineItemQuantity={changeLineItemQuantity}
            />

            {/* Rewards rail and add promo code field. */}
            <BagLoyalty orderState={orderState} />

            {orderChannel === 'delivery' ? (
              <Bag.UtensilsCta
                shouldIncludeUtensils={shouldIncludeUtensils}
                isDisabled={isPerformingBagAction}
                onChange={changeUtensilsUsage}
              />
            ) : null}

            <Bag.Footer isDisabled={isPerformingBagAction}>
              {ledger?.subtotal ? (
                <Bag.Subtotal subtotal={ledger.subtotal} />
              ) : null}

              {ledger?.discounts[0] ? (
                <Bag.Discount {...ledger.discounts[0]} />
              ) : null}
            </Bag.Footer>
          </Bag.ContentContainer>
        </Bag.ScrollView>
      ) : null}

      {shouldShowFloatingCtas ? (
        <Bag.FloatingCtas>
          <Bag.ContinueToCheckoutCta
            cta={continueToCheckoutCta}
            isDisabled={!canContinueToCheckout}
            onPress={handleContinue}
          />
        </Bag.FloatingCtas>
      ) : null}
    </FocusScope>
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type BagContentProps = {
  orderState: ReturnType<typeof useOrderState>;
};
