import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  CustomNavigationHeader,
  IllusEmpty_1,
  Image,
  theme,
  useCallbackWithLimit,
} from '@sg/garnish';

import { LoyaltyPointsHistoryLogo } from '../LoyaltyPointsHistoryLogo';

export const LoyaltyPointsHistoryErrorState = (
  props: LoyaltyPointsHistoryErrorStateProps,
) => {
  const { isLoading, refetch, onRequestClose } = props;
  const { formatMessage } = useIntl();

  const { callbackWithLimit: refresh, hasReachedLimit } = useCallbackWithLimit({
    callback: refetch,
    limit: 5,
  });

  return (
    <View style={styles.container}>
      <View>
        <CustomNavigationHeader.Container safeAreaEdges={[]}>
          <BodyText style={styles.headerText} sizeMatch={['16']}>
            {formatMessage(messages.title)}
          </BodyText>

          <CustomNavigationHeader.Button.Right
            name="IconClose"
            accessibilityLabel={formatMessage(messages.close)}
            onPress={onRequestClose}
          />
        </CustomNavigationHeader.Container>

        <LoyaltyPointsHistoryLogo style={styles.logo} />
      </View>

      <View style={styles.content}>
        <Image style={styles.image} source={IllusEmpty_1} />

        <BodyText style={styles.message} sizeMatch={['16']}>
          <FormattedMessage {...messages.error} />
        </BodyText>
      </View>

      {hasReachedLimit && !isLoading ? null : (
        <Button
          size="large-wide"
          palette="spinachMuted"
          style={styles.button}
          isLoading={isLoading}
          onPress={refresh}
        >
          <FormattedMessage {...messages.refresh} />
        </Button>
      )}
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Points history',
    description: 'Loyalty | Points History | Title',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Loyalty | Points History | Close',
  },
  error: {
    defaultMessage:
      'Aw snap. Point history is taking longer than expected to load. Please wait a few moments and refresh the page.',
    description: 'Loyalty | Points History | Error',
  },
  refresh: {
    defaultMessage: 'Refresh',
    description: 'Loyalty | Points History | Refresh',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'space-between',
    gap: theme.spacing['6'],
    paddingBottom: theme.spacing['6'],
  },
  content: {
    alignItems: 'center',
    paddingBottom: theme.spacing['10'],
  },
  logo: {
    marginHorizontal: theme.spacing['6'],
  },
  image: {
    width: 220,
    height: 220,
  },
  message: {
    maxWidth: 300,
    textAlign: 'center',
  },
  button: {
    marginHorizontal: theme.spacing['6'],
  },
  headerText: {
    textAlign: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointsHistoryErrorStateProps = {
  isLoading: boolean;
  refetch: () => void;
  onRequestClose: () => void;
};
