import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useFeatureFlag } from '@order/LaunchDarkly';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns this restaurant's delivery disclosure.
 */
export const useDeliveryDisclosure = (params: useDeliveryDisclosureParams) => {
  const { restaurantDeliveryFee = 0 } = params;

  const { formatNumber, formatMessage } = useIntl();

  // ─── Feature Flags ───────────────────────────────────────────────────

  const isDisclosureBannerEnabledForMenu = useFeatureFlag(
    'CELS-2359-delivery-fee-disclosure-banner',
  );

  // ─── Data ────────────────────────────────────────────────────────────

  const fallbackDataFields = useMemo<DeliveryDisclosuresEntryFields>(
    () => ({
      menuPageDisclosure: formatMessage(messages.menuPageDisclosure),
    }),
    [formatMessage],
  );

  const { data, fetching: isFetching } =
    useContentfulContentTypeEntry<DeliveryDisclosuresEntryFields>({
      contentType: 'deliveryTipDisclosure',
    });

  const menuPageDisclosure =
    data?.fields?.menuPageDisclosure ?? fallbackDataFields.menuPageDisclosure;

  // ─── Derived Data ────────────────────────────────────────────────────

  const restaurantDeliveryFeeInCents = restaurantDeliveryFee / 100;

  // NOTE: We use `replace` instead of `trailingZeroDisplay` and
  //      `maximumFractionDigits` since they aren't working on Android.
  const formattedDeliveryFee = formatNumber(restaurantDeliveryFeeInCents, {
    style: 'currency',
    currency: 'USD',
  }).replace('.00', '');

  const deliveryDisclosure = useMemo(() => {
    if (!isDisclosureBannerEnabledForMenu) return;

    return formatDisclosure(menuPageDisclosure, formattedDeliveryFee);
  }, [
    isDisclosureBannerEnabledForMenu,
    menuPageDisclosure,
    formattedDeliveryFee,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return { isFetching, deliveryDisclosure };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function formatDisclosure(
  disclosureMessage: string,
  restaurantDeliveryFee: string,
) {
  return disclosureMessage.replaceAll(
    '[locationDeliveryFee]',
    restaurantDeliveryFee,
  );
}

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages<keyof DeliveryDisclosuresEntryFields>({
  menuPageDisclosure: {
    description: 'Delivery disclosures | Delivery fee | Menu disclosure',
    defaultMessage:
      'Thanks for choosing delivery. [locationDeliveryFee] goes directly to our delivery partner. No service fee. Tips are separate + optional.',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type useDeliveryDisclosureParams = {
  restaurantDeliveryFee?: number;
};

type DeliveryDisclosuresEntryFields = {
  menuPageDisclosure: string;
};
