import React, { type ComponentProps, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { Loyalty } from '@order/features/loyalty';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';

import {
  useLoyaltyOfferOptInMutation,
  useLoyaltyOfferOptInWithThemeMutation,
} from '../../graphql/LoyaltyOffers.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCard = (props: LoyaltyOfferCardProps) => {
  const {
    offer,
    rounded = false,
    withBorder = false,
    layout,
    referrer,
    onNavigate,
  } = props;

  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { track } = useTelemetry();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isHurdleAndUnlimitedOffersEnabled = useFeatureFlag(
    'CELS-2951-hurdle-and-unlimited-offers-enabled',
  );

  // ─── Remote Data ─────────────────────────────────────────────────────

  const optInMutation = useLoyaltyOfferOptInMutation();
  const optInWithThemeMutation = useLoyaltyOfferOptInWithThemeMutation();

  const [{ fetching }, optIn] = isHurdleAndUnlimitedOffersEnabled
    ? optInWithThemeMutation
    : optInMutation;

  // ─── Telemetry ───────────────────────────────────────────────────────

  const trackStartButtonPress = useCallback(() => {
    if (referrer === 'offers-modal') {
      track('loyalty.offer.modal.start.cta_tapped', { offerId: offer.id });
    }

    if (referrer === 'sg-rewards') {
      track('loyalty.offer.card.order.cta_tapped', { offerId: offer.id });
    }
  }, [offer.id, referrer, track]);

  const trackOrderButtonPress = useCallback(() => {
    if (referrer === 'offers-modal') {
      track('loyalty.offer.modal.order.cta_tapped', { offerId: offer.id });
    }

    if (referrer === 'sg-rewards') {
      track('loyalty.offer.card.order.cta_tapped', { offerId: offer.id });
    }
  }, [offer.id, referrer, track]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const showErrorBanner = useCallback(() => {
    const text = formatMessage(messages.errorBannerText);
    const shouldPreventDuplicates = true;

    addNoticeBanner(
      { id: ERROR_NOTICE_BANNER_ID, text, palette: 'caution' },
      shouldPreventDuplicates,
    );
  }, [addNoticeBanner, formatMessage]);

  const optInOffer = useCallback(async () => {
    trackStartButtonPress();

    const { data, error } = await optIn({ challengeId: offer.id });

    const responseTypename = data?.challengeOptInV2?.__typename;
    const hasFailedToOptedIn = responseTypename === 'LoyaltyOptInFailed';

    if (hasFailedToOptedIn || error) {
      showErrorBanner();
    }
  }, [offer.id, optIn, showErrorBanner, trackStartButtonPress]);

  const navigateToMenu = useCallback(() => {
    trackOrderButtonPress();

    onNavigate?.();
    navigate('MainTabs', {
      screen: 'MenuTab',
      params: { screen: 'MenuWithoutSlug' },
    });
  }, [trackOrderButtonPress, onNavigate, navigate]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Loyalty.OfferCard
      offer={offer}
      isLoading={fetching}
      layout={layout}
      rounded={rounded}
      withBorder={withBorder}
      onStartButtonPress={optInOffer}
      onOrderButtonPress={navigateToMenu}
    />
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  errorBannerText: {
    defaultMessage: 'Failed to opt in to offer. Please try again later.',
    description: 'Loyalty | Offers | Opt in error banner text',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const ERROR_NOTICE_BANNER_ID = 'LOYALTY_OFFER_OPT_IN_ERROR_BANNER';

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProps = {
  referrer?: 'sg-rewards' | 'offers-modal';
  onNavigate?: () => void;
} & Pick<
  ComponentProps<typeof Loyalty.OfferCard>,
  'offer' | 'rounded' | 'withBorder' | 'layout'
>;
