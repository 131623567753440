import React, { useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Pressable, StyleSheet } from 'react-native';
import {
  BodyText,
  Icon,
  type IconName,
  theme,
  usePressableState,
} from '@sg/garnish';

import {
  useLoyaltyAvailablePoints,
  useLoyaltyPremiumStatus,
} from '@order/hooks';

export const HomeLoyaltyButton = (props: HomeLoyaltyButtonProps) => {
  const { fallbackMessage, onPress } = props;

  // ─── Interactive Style ────────────────────────────────────────────────────

  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);
  const interactingStyle = isInteracting ? styles.containerHover : null;

  // ─── Premium Icon ─────────────────────────────────────────────────────────

  const { isPremium } = useLoyaltyPremiumStatus();
  const icon: IconName = isPremium
    ? 'IconSgRewardBlackFill'
    : 'IconSgRewardSweetCornBlackOutline';

  // ─── Messaging ────────────────────────────────────────────────────────────

  const { formatMessage } = useIntl();
  const points = useLoyaltyAvailablePoints();
  const message =
    points > 0 ? formatMessage(messages.points, { points }) : fallbackMessage;

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={ref}
      style={[styles.container, interactingStyle]}
      aria-label={formatMessage(messages.a11yLabel)}
      accessibilityRole="link"
      onPress={onPress}
    >
      <Icon name={icon} width={40} height={40} />

      <BodyText style={styles.message} bold sizeMatch={['16']}>
        {message}
      </BodyText>
    </Pressable>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  points: {
    defaultMessage: '{points} points',
    description: 'Home | Loyalty Button | Points',
  },
  a11yLabel: {
    defaultMessage: 'Loyalty home page',
    description: 'Home | Loyalty Button | Accessibility Label',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.OATMEAL,
    padding: theme.spacing['1'],
    paddingRight: theme.spacing['3'],
    height: theme.spacing['12'],
    borderRadius: theme.radius.xlarge,
    gap: theme.spacing['1'],
    shadowColor: theme.colors.DARK_KALE,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    elevation: 5,
  },
  containerHover: {
    backgroundColor: theme.colors.OATMEAL_HOVER,
  },
  message: {
    marginTop: theme.spacing['1'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type HomeLoyaltyButtonProps = {
  fallbackMessage: string;
  onPress: () => void;
};
