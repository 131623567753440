/* eslint-disable functional/immutable-data */
/* eslint-disable no-empty-pattern */

import React, { memo, useCallback, useRef } from 'react';
import type { ViewProps } from 'react-native';
import { Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import type { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { datadog } from '@sg/expo-datadog-client';
import { theme, useNoticeBannersStackContext } from '@sg/garnish';

import { LoadingAnimation } from '@order/components';
import { useTrackScreen } from '@order/Telemetry';

import { NoticeBannersOverlay } from '../components/NoticeBannersOverlay';
import { usePauseKustomer } from '../integrations/kustomer';
import * as Screens from '../screens';
import {
  APP_STACK_NAVIGATOR_ID,
  navigationDocumentTitleOptions,
} from './AppNavigation.constants';
import { appNavigationLinking } from './AppNavigation.linking';
import {
  useAccountTabStackOptions,
  useAppStackOptions,
  useAuthStackOptions,
  useErrorStackOptions,
  useGiftCardsTabStackOptions,
  useHomeTabStackOptions,
  useLoyaltyTabStackOptions,
  useMainTabsOptions,
  useMenuTabStackOptions,
  useModalStackOptions,
  useReorderTabStackOptions,
  useScanTabStackOptions,
} from './AppNavigation.options';
import {
  type AccountTabScreenProps,
  type AuthStackScreenProps,
  type ErrorStackScreenProps,
  GiftCardsTabStackNavigator,
  type HomeTabScreenProps,
  type LoyaltyTabScreenProps,
  type MainTabsScreenProps,
  type MenuTabScreenProps,
  type ModalStackScreenProps,
  type ReorderTabScreenProps,
  ReorderTabStackNavigator,
  type ScanTabScreenProps,
} from './AppNavigation.props';
import {
  AccountTabStackNavigator,
  AppStackNavigator,
  AuthStackNavigator,
  ErrorStackNavigator,
  type GiftCardsTabScreenProps,
  HomeTabStackNavigator,
  LoyaltyTabStackNavigator,
  MainTabsNavigator,
  MenuTabStackNavigator,
  ModalStackNavigator,
  ScanTabStackNavigator,
} from './AppNavigation.props';
import {
  checkIfPresentedAsModalWithoutHeader,
  getAppNavigationContainerRef,
} from './utils';

export const AppNavigation = memo(() => {
  const navigationContainerRef = getAppNavigationContainerRef();
  const pauseKustomer = usePauseKustomer();
  const { trackScreen } = useTrackScreen();

  const { clear: closeActiveNoticeBanners } = useNoticeBannersStackContext();
  const { cancelReorderOnModalClose } = Screens.useDismissReorder();

  const currentRouteNameRef = useRef('');
  const currentRouteOptionsRef = useRef<NativeStackNavigationOptions>();

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * A simple utility that closes active notice banners when the modal screen
   * closes (aka blurs).
   *
   * NOTE: To prevent banners from leaking to main screens, we should
   *       close them on modal screens blur.
   */
  const closeActiveNoticeBannersOnModalClose = useCallback(
    (
      previousRouteOptions: NativeStackNavigationOptions | undefined,
      currentRouteOptions: NativeStackNavigationOptions | undefined,
    ) => {
      const isPreviousScreenModal =
        checkIfPresentedAsModalWithoutHeader(previousRouteOptions);
      const isCurrentScreenModal =
        checkIfPresentedAsModalWithoutHeader(currentRouteOptions);

      const shouldCloseActiveNoticeBanners =
        isPreviousScreenModal && !isCurrentScreenModal;

      if (!shouldCloseActiveNoticeBanners) return;

      closeActiveNoticeBanners();
    },
    [closeActiveNoticeBanners],
  );

  const handleOnReady = useCallback(() => {
    // ─── Telemetry - Tracking Views ───────────────────────────────

    const currentRouteName =
      navigationContainerRef?.current?.getCurrentRoute()?.name ?? '';
    const currentRouteOptions =
      navigationContainerRef?.current?.getCurrentOptions();

    trackScreen(currentRouteName);

    currentRouteNameRef.current = currentRouteName;
    currentRouteOptionsRef.current = currentRouteOptions;

    // ─── Datadog - Tracking Views ─────────────────────────────────

    if (Platform.OS === 'web') return; // web tracks by default
    datadog.trackViews(navigationContainerRef.current);
  }, [navigationContainerRef, trackScreen]);

  // ─── Telemetry - Tracking Views ─────────────────────────────────

  const handleStateChange = useCallback(() => {
    const previousRouteName = currentRouteNameRef.current ?? '';
    const previousRouteOptions = currentRouteOptionsRef.current;

    const currentRoute = navigationContainerRef?.current?.getCurrentRoute();
    const currentRouteOptions =
      navigationContainerRef?.current?.getCurrentOptions() as NativeStackNavigationOptions;
    const currentRouteName = currentRoute?.name ?? '';

    closeActiveNoticeBannersOnModalClose(
      previousRouteOptions,
      currentRouteOptions,
    );

    cancelReorderOnModalClose({
      currentRouteName,
      previousRouteName,
      previousRouteOptions,
      currentRouteOptions,
    });

    if (previousRouteName !== currentRouteName) {
      currentRouteNameRef.current = currentRouteName;
      currentRouteOptionsRef.current = currentRouteOptions;

      trackScreen(currentRouteName);
      pauseKustomer(currentRouteName);
    }
  }, [
    navigationContainerRef,
    pauseKustomer,
    trackScreen,
    closeActiveNoticeBannersOnModalClose,
    cancelReorderOnModalClose,
  ]);

  // ────────────────────────────────────────────────────────────────

  return (
    <NavigationContainer
      ref={navigationContainerRef}
      linking={appNavigationLinking}
      fallback={
        <LoadingAnimation
          withNavbarOffset
          backgroundColor={theme.colors.APP_BACKGROUND}
        />
      }
      onReady={handleOnReady}
      onStateChange={handleStateChange}
      documentTitle={navigationDocumentTitleOptions}
    >
      <AppScreen />
      <NoticeBannersOverlay />
    </NavigationContainer>
  );
});

// ==================================================
// APP
// ==================================================

const AppScreen = memo(({}: ViewProps) => {
  const stackOptions = useAppStackOptions();
  const Stack = AppStackNavigator;

  return (
    <Stack.Navigator
      id={APP_STACK_NAVIGATOR_ID}
      screenOptions={stackOptions.Navigator}
    >
      <Stack.Screen
        name="MainTabs"
        component={MainTabsScreen}
        options={stackOptions.MainTabs}
      />
      <Stack.Screen
        name="Modal"
        component={ModalStackScreen}
        options={stackOptions.Modal}
      />
      <Stack.Screen
        name="Auth"
        component={AuthStackScreen}
        options={stackOptions.Auth}
      />
      <Stack.Screen
        name="Error"
        component={ErrorStackScreen}
        options={stackOptions.Error}
      />
    </Stack.Navigator>
  );
});

// ==================================================
// MAIN TABS
// ==================================================

export const MainTabsScreen = memo(({}: MainTabsScreenProps) => {
  const tabsOptions = useMainTabsOptions();
  const Tabs = MainTabsNavigator;

  return (
    <Tabs.Navigator
      backBehavior={tabsOptions.BackBehavior}
      tabBar={tabsOptions.TabBar}
      screenOptions={tabsOptions.Navigator}
    >
      <Tabs.Screen
        name="HomeTab"
        component={HomeTabScreen}
        options={tabsOptions.HomeTab}
      />
      <Tabs.Screen
        name="MenuTab"
        component={MenuTabScreen}
        options={tabsOptions.MenuTab}
      />
      <Tabs.Screen
        name="LoyaltyTab"
        component={LoyaltyTabScreen}
        options={tabsOptions.LoyaltyTab}
      />
      <Tabs.Screen
        name="ScanTab"
        component={ScanTabScreen}
        options={tabsOptions.ScanTab}
      />
      <Tabs.Screen
        name="ReorderTab"
        component={ReorderTabScreen}
        options={tabsOptions.ReorderTab}
      />
      <Tabs.Screen
        name="GiftCardsTab"
        component={GiftCardsTabScreen}
        options={tabsOptions.GiftCardsTab}
      />
      <Tabs.Screen
        name="AccountTab"
        component={AccountTabScreen}
        options={tabsOptions.AccountTab}
      />
    </Tabs.Navigator>
  );
});

// ==================================================
// HOME TAB
// ==================================================

const HomeTabScreen = memo(({}: HomeTabScreenProps) => {
  const stackOptions = useHomeTabStackOptions();
  const Stack = HomeTabStackNavigator;

  return (
    <Stack.Navigator screenOptions={stackOptions.Navigator}>
      <Stack.Screen
        name="Home"
        component={Screens.HomeScreen}
        options={stackOptions.Home}
      />
    </Stack.Navigator>
  );
});

// ==================================================
// MENU TAB
// ==================================================

export const MenuTabScreen = memo(({}: MenuTabScreenProps) => {
  const stackOptions = useMenuTabStackOptions();
  const Stack = MenuTabStackNavigator;

  return (
    <Stack.Navigator screenOptions={stackOptions.Navigator}>
      <Stack.Screen
        name="Locations"
        component={Screens.LocationsScreen}
        options={stackOptions.Locations}
      />
      <Stack.Screen
        name="MenuWithoutSlug"
        component={Screens.MenuWithoutSlug}
        options={stackOptions.MenuWithoutSlug}
      />
      <Stack.Screen
        name="Menu"
        component={Screens.MenuScreen}
        options={stackOptions.Menu}
      />
      <Stack.Screen
        name="DeliveryMenu"
        component={Screens.DeliveryMenuScreen}
        options={stackOptions.DeliveryMenu}
      />
    </Stack.Navigator>
  );
});

// ==================================================
// LOYALTY TAB
// ==================================================

export const LoyaltyTabScreen = memo(({}: LoyaltyTabScreenProps) => {
  const stackOptions = useLoyaltyTabStackOptions();
  const Stack = LoyaltyTabStackNavigator;

  return (
    <Stack.Navigator screenOptions={stackOptions.Navigator}>
      {stackOptions.isLoyaltyV2Enabled ? (
        <Stack.Screen
          name="LoyaltyHome"
          component={Screens.LoyaltyHomeScreen}
          options={stackOptions.LoyaltyHome}
        />
      ) : (
        <Stack.Screen
          name="SweetpassHome"
          component={Screens.SweetpassHomeScreen}
          options={stackOptions.SweetpassHome}
        />
      )}
    </Stack.Navigator>
  );
});

// ==================================================
// SCAN TAB
// ==================================================

export const ScanTabScreen = memo(({}: ScanTabScreenProps) => {
  const stackOptions = useScanTabStackOptions();
  const Stack = ScanTabStackNavigator;

  return (
    <Stack.Navigator screenOptions={stackOptions.Navigator}>
      <Stack.Screen
        name="ScanInStore"
        component={Screens.ScanInStore}
        options={stackOptions.ScanInStore}
      />
    </Stack.Navigator>
  );
});

// ==================================================
// REORDER TAB
// ==================================================

export const ReorderTabScreen = memo(({}: ReorderTabScreenProps) => {
  const stackOptions = useReorderTabStackOptions();
  const Stack = ReorderTabStackNavigator;

  return (
    <Stack.Navigator screenOptions={stackOptions.Navigator}>
      <Stack.Screen
        name="Reorder"
        component={Screens.ReorderTab}
        options={stackOptions.Reorder}
      />
    </Stack.Navigator>
  );
});

// ─── "Gift Cards" Tab ────────────────────────────────────────────────────────

export const GiftCardsTabScreen = memo(({}: GiftCardsTabScreenProps) => {
  const stackOptions = useGiftCardsTabStackOptions();
  const Stack = GiftCardsTabStackNavigator;

  return (
    <Stack.Navigator screenOptions={stackOptions.Navigator}>
      <Stack.Screen
        name="GiftCards"
        component={Screens.GiftCardsScreen}
        options={stackOptions.GiftCards}
      />
      <Stack.Screen
        name="GiftCardCheckout"
        component={Screens.GiftCardCheckoutScreen}
        options={stackOptions.GiftCardCheckout}
      />
      <Stack.Screen
        name="GiftCardConfirmation"
        component={Screens.GiftCardConfirmationScreen}
        options={stackOptions.GiftCardConfirmation}
      />
      <Stack.Screen
        name="GiftCardRedemption"
        component={Screens.GiftCardRedemptionScreen}
        options={stackOptions.GiftCardRedemption}
      />
    </Stack.Navigator>
  );
});

// ==================================================
// ACCOUNT TAB
// ==================================================

export const AccountTabScreen = memo(({}: AccountTabScreenProps) => {
  const stackOptions = useAccountTabStackOptions();
  const Stack = AccountTabStackNavigator;

  return (
    <Stack.Navigator screenOptions={stackOptions.Navigator}>
      <Stack.Screen
        name="AccountMenu"
        component={Screens.AccountScreen}
        options={stackOptions.AccountMenu}
      />
      <Stack.Screen
        name="Profile"
        component={Screens.ProfileScreen}
        options={stackOptions.Profile}
      />
      <Stack.Screen
        name="SweetpassMembership"
        component={Screens.SweetpassMembershipScreen}
        options={stackOptions.SweetpassMembership}
      />
      <Stack.Screen
        name="PaymentAndGiftCards"
        component={Screens.PaymentAndGiftCardsScreen}
        options={stackOptions.PaymentAndGiftCards}
      />
      <Stack.Screen
        name="CreditAndPromoCodes"
        component={Screens.CreditAndPromoCodesScreen}
        options={stackOptions.CreditAndPromoCodes}
      />
      <Stack.Screen
        name="Addresses"
        component={Screens.AddressesScreen}
        options={stackOptions.Addresses}
      />
      <Stack.Screen
        name="Orders"
        component={Screens.OrdersScreen}
        options={stackOptions.Orders}
      />
      <Stack.Screen
        name="Favorites"
        component={Screens.FavoritesScreen}
        options={stackOptions.Favorites}
      />
      <Stack.Screen
        name="ReferFriend"
        component={Screens.ReferFriendScreen}
        options={stackOptions.ReferFriend}
      />
      <Stack.Screen
        name="AccountDietaryRestrictions"
        component={Screens.AccountDietaryRestrictionsScreen}
        options={stackOptions.AccountDietaryRestrictions}
      />
    </Stack.Navigator>
  );
});

// ==================================================
// MODAL STACK
// ==================================================

export const ModalStackScreen = memo((props: ModalStackScreenProps) => {
  const stackOptions = useModalStackOptions(props);
  const Stack = ModalStackNavigator;

  return (
    <Stack.Navigator screenOptions={stackOptions.Navigator}>
      <Stack.Screen
        name="ProductDetails"
        component={Screens.ProductDetailsScreen}
        options={stackOptions.ProductDetails}
      />
      <Stack.Screen
        name="EditProductDetails"
        component={Screens.EditProductDetailsScreen}
        options={stackOptions.EditProductDetails}
      />
      <Stack.Screen
        name="DeliveryProductDetails"
        component={Screens.DeliveryProductDetailsScreen}
        options={stackOptions.DeliveryProductDetails}
      />
      <Stack.Screen
        name="DeliveryEditProductDetails"
        component={Screens.DeliveryEditProductDetailsScreen}
        options={stackOptions.DeliveryEditProductDetails}
      />
      <Stack.Screen
        name="ReorderConfirmLocation"
        component={Screens.ReorderConfirmLocationScreen}
        options={stackOptions.ReorderConfirmLocation}
      />
      <Stack.Screen
        name="ReorderChangeLocation"
        component={Screens.ReorderChangeLocationScreen}
        options={stackOptions.ReorderChangeLocation}
      />
      <Stack.Screen
        name="ReorderConflictReview"
        component={Screens.ReorderConflictReviewScreen}
        options={stackOptions.ReorderConflictReview}
      />
      <Stack.Screen
        name="ReorderActiveBagWarning"
        component={Screens.ReorderActiveBagWarningScreen}
        options={stackOptions.ReorderActiveBagWarning}
      />
      <Stack.Screen
        name="Reorder"
        component={Screens.ReorderModal}
        options={stackOptions.ReorderScreen}
      />
      <Stack.Screen
        name="RateOrder"
        component={Screens.RateOrderScreen}
        options={stackOptions.RateOrder}
      />
      <Stack.Screen
        name="Bag"
        component={Screens.BagScreen}
        options={stackOptions.Bag}
      />
      <Stack.Screen
        name="OrderStatus"
        component={Screens.OrderStatusScreen}
        options={stackOptions.OrderStatus}
      />
      <Stack.Screen
        name="ShareLineItem"
        component={Screens.ShareLineItemScreen}
        options={stackOptions.ShareLineItem}
      />
      <Stack.Screen
        name="CreditAdd"
        component={Screens.CreditAddScreen}
        options={stackOptions.CreditAdd}
      />
      <Stack.Screen
        name="CreditDetails"
        component={Screens.CreditDetailsScreen}
        options={stackOptions.CreditDetails}
      />
      <Stack.Screen
        name="DietaryRestrictions"
        component={Screens.DietaryRestrictionsScreen}
        options={stackOptions.DietaryRestrictions}
      />
      <Stack.Screen
        name="AddGiftCard"
        component={Screens.AddGiftCardScreen}
        options={stackOptions.AddGiftCard}
      />
      <Stack.Screen
        name="RedeemGiftCard"
        component={Screens.AddGiftCardScreen}
        options={stackOptions.RedeemGiftCard}
      />
      <Stack.Screen
        name="PersonalData"
        component={Screens.PersonalDataScreen}
        options={stackOptions.PersonalData}
      />
      <Stack.Screen
        name="PersonalDataDetails"
        component={Screens.PersonalDataDetailsScreen}
        options={stackOptions.PersonalDataDetails}
      />
      <Stack.Screen
        name="DeliveryOrderInFlight"
        component={Screens.DeliveryOrderInFlightScreen}
        options={stackOptions.DeliveryOrderInFlight}
      />
      <Stack.Screen
        name="SweetpassBenefits"
        component={Screens.SweetpassBenefitsScreen}
        options={stackOptions.SweetpassBenefits}
      />
      <Stack.Screen
        name="SweetpassUpgrade"
        component={Screens.SweetpassUpgradeScreen}
        options={stackOptions.SweetpassUpgrade}
      />
      <Stack.Screen
        name="SweetpassCheckout"
        component={Screens.SweetpassCheckoutScreen}
        options={stackOptions.SweetpassCheckout}
      />
      <Stack.Screen
        name="SweetpassSwitchSubscription"
        component={Screens.SweetpassSwitchSubscriptionScreen}
        options={stackOptions.SweetpassSwitchSubscription}
      />
      <Stack.Screen
        name="OrderProductConfirmProduct"
        component={Screens.OrderProductConfirmProductScreen}
        options={stackOptions.OrderProductConfirmProduct}
      />
      <Stack.Screen
        name="OrderProductConfirmLocation"
        component={Screens.OrderProductConfirmLocationScreen}
        options={stackOptions.OrderProductConfirmLocation}
      />
      <Stack.Screen
        name="OrderProductSearchLocation"
        component={Screens.OrderProductSearchLocationScreen}
        options={stackOptions.OrderProductSearchLocation}
      />
      <Stack.Screen
        name="ScanAtCheckout"
        component={Screens.ScanAtCheckoutScreen}
        options={stackOptions.ScanAtCheckout}
      />
    </Stack.Navigator>
  );
});

// ==================================================
// AUTH STACK
// ==================================================

export const AuthStackScreen = memo((props: AuthStackScreenProps) => {
  const stackOptions = useAuthStackOptions(props);
  const Stack = AuthStackNavigator;

  return (
    <Stack.Navigator screenOptions={stackOptions.Navigator}>
      <Stack.Screen
        name="JoinOrSignIn"
        component={Screens.JoinOrSignInScreen}
        options={stackOptions.JoinOrSignIn}
      />
      <Stack.Screen
        name="Login"
        component={Screens.LoginScreen}
        options={stackOptions.Login}
      />
      <Stack.Screen
        name="Join"
        component={Screens.JoinScreen}
        options={stackOptions.Join}
      />
    </Stack.Navigator>
  );
});

// ==================================================
// ERROR STACK
// ==================================================

export const ErrorStackScreen = memo(({}: ErrorStackScreenProps) => {
  const stackOptions = useErrorStackOptions();
  const Stack = ErrorStackNavigator;

  return (
    <Stack.Navigator screenOptions={stackOptions.Navigator}>
      <Stack.Screen
        // @ts-expect-error TS(2322): Type '"NotFoundWithRestaurantSlug"' is not assigna... Remove this comment to see the full error message
        name="NotFoundWithRestaurantSlug"
        component={Screens.NotFoundScreen}
        options={stackOptions.NotFound}
      />
      <Stack.Screen
        name="NotFound"
        component={Screens.NotFoundScreen}
        options={stackOptions.NotFound}
      />
    </Stack.Navigator>
  );
});
