import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { HStack } from '../../../../../Stack';
import { LoyaltyOfferCardProgressNodeStep } from '../../components';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardProgressBarStep = (
  props: LoyaltyOfferCardProgressStepperProps,
) => {
  const {
    steps,
    currentStep,
    labels,
    percentageCompleted,
    accessibilityLabel,
  } = props;

  const percentage = Math.max(Math.min(100, percentageCompleted), 0);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <HStack
        itemsPerRow={steps}
        gap={theme.spacing['1']}
        accessible
        accessibilityRole="progressbar"
        accessibilityLabel={accessibilityLabel}
        accessibilityValue={{ min: 0, max: 100, now: percentage }}
      >
        {Array.from({ length: steps }).map((_item, index) => {
          const step = index + 1;
          const isActive = step <= currentStep;

          return (
            <LoyaltyOfferCardProgressNodeStep
              key={step}
              isCompleted={isActive}
              label={labels?.[index]}
              labelAlignment={step === steps ? 'right' : 'right-center'}
            />
          );
        })}
      </HStack>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProgressStepperProps = {
  steps: number;
  labels?: string[];
  currentStep: number;
  percentageCompleted: number;
  accessibilityLabel?: string;
};
