import React, { useCallback, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import {
  useFocusEffect,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import {
  BodyText,
  Icon,
  LoadingAnimation,
  ModalDialogue,
  theme,
  useResponsive,
  useToggleState,
} from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { AppFooter, SignedOutView } from '@order/components';
import { Loyalty, LOYALTY_PALETTES } from '@order/features/loyalty';
import {
  useLoyaltyContent,
  useLoyaltyInfoBenefits,
  useLoyaltyPremiumStatus,
  useNavigateToLastOrderMenu,
} from '@order/hooks';
import { useTelemetry, useTrackEffect } from '@order/Telemetry';

import { JoinSgRewardsScreen } from '../JoinSgRewardsScreen';
import {
  LoyaltyHomeChallenges,
  LoyaltyHomeFaqXs,
  LoyaltyHomeRewards,
  LoyaltyHowItWorksModal,
  LoyaltyPointHistoryModal,
} from './components';
import {
  useLoyaltyHomeScreenReferrer,
  useLoyaltyInfo,
  useTermsBeingShown,
} from './hooks';
import { useHomeProgressBenefit } from './hooks';
import { useOptInChallengeOnFocus } from './hooks/useOptInChallengeOnFocus';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyHomeScreen = () => {
  const isLoggedIn = useIsLoggedIn();

  useTrackEffect('loyalty.home.view');
  useLoyaltyHomeScreenReferrer();

  if (!isLoggedIn) return <SignedOutView />;

  return <LoyaltyHomeScreenSignedIn />;
};

const LoyaltyHomeScreenSignedIn = () => {
  const { currentBreakpoint, minWidth } = useResponsive();
  const isScreenFocused = useIsFocused();

  // ─── Auto Opt In ─────────────────────────────────────────────────────

  useOptInChallengeOnFocus();

  // ─── Premium ──────────────────────────────────────────────────────────────

  const { isPremium } = useLoyaltyPremiumStatus();
  const premiumBgColor = isPremium ? LOYALTY_PALETTES.premiumBgColor : null;
  const premiumFgColor = isPremium ? LOYALTY_PALETTES.premiumFgColor : null;

  // ─── GraphQL ──────────────────────────────────────────────────────────────

  const {
    isLoading: isLoyaltyInfoLoading,
    isStale: isLoyaltyInfoStale,
    hasFailedToLoad,
    hasNoPointsRewards,
    firstName,
    availablePoints,
    pendingPoints,
    totalPoints,
    challenges,
    rewards,
    pointsRewards,
    isOptedOut,
    refetch: refetchLoyaltyInfo,
  } = useLoyaltyInfo({ pause: !isScreenFocused });

  const hasChallenges = challenges.length > 0;
  const hasRewards = rewards.length > 0;

  // ─── Styles ───────────────────────────────────────────────────────────────

  const rewardsStyleXS = [
    premiumBgColor,
    styles.challengesRewardsPaddingXS,
    styles.paddingWithContentBottom,
  ];

  const challengesStyleXS = [
    premiumBgColor,
    styles.challengesRewardsPaddingXS,
    hasRewards ? styles.paddingWithContentTop : undefined,
  ];

  const benefitsStyle = [
    styles.loyaltyInfoBenefits,
    isPremium ? styles.premiumBenefitsBorder : undefined,
  ];

  // ─── Contentful ───────────────────────────────────────────────────────────

  const { homeHowItWorksSM, homeProgressBenefits, termsAndConditionsUrl } =
    useLoyaltyContent({
      availablePoints,
    });

  // ─── Progress Benefit ─────────────────────────────────────────────────────

  const progressBenefit = useHomeProgressBenefit({
    availablePoints,
    pendingPoints,
    homeProgressBenefits,
  });

  // ─── Loyalty Info Benefits ────────────────────────────────────────────────

  const {
    benefits: loyaltyInfoBenefits,
    isLoading: isFetchingBenefits,
    hasError: hasBenefitsError,
    refetch: refetchBenefits,
  } = useLoyaltyInfoBenefits();

  // ─── Points History Modal ─────────────────────────────────────────────────

  const {
    value: isShowingPointsHistoryModal,
    toggleOn: showPointsHistoryModalToggleOn,
    toggleOff: hidePointsHistoryModal,
  } = useToggleState();

  // ─── How it Works Modal ───────────────────────────────────────────────────

  const {
    value: isShowingHowItWorksModal,
    toggleOn: showHowItWorksModalToggleOn,
    toggleOff: hideHowItWorksModal,
  } = useToggleState();

  // ─── Modals ───────────────────────────────────────────────────────────────

  const isShowingModal =
    isShowingPointsHistoryModal || isShowingHowItWorksModal;

  // ─── Telemetry ────────────────────────────────────────────────────────────

  const { track } = useTelemetry();

  const showPointsHistoryModal = useCallback(() => {
    track('loyalty.home.points_history.open');
    showPointsHistoryModalToggleOn();
  }, [track, showPointsHistoryModalToggleOn]);

  const showHowItWorksModal = useCallback(() => {
    track('loyalty.home.how_it_works.open');
    showHowItWorksModalToggleOn();
  }, [track, showHowItWorksModalToggleOn]);

  const trackBenefitsOpened = useCallback(() => {
    track('loyalty.home.benefits.open');
  }, [track]);

  // ─── Terms Modal ──────────────────────────────────────────────────────────

  const { termsBeingShown, showTerms, hideTerms } = useTermsBeingShown();

  // ─── Navigation ───────────────────────────────────────────────────────────

  const navigation = useNavigation();
  const navigateToLastOrderMenu = useNavigateToLastOrderMenu();

  const clearModals = useCallback(() => {
    hideTerms();
    hidePointsHistoryModal();
    hideHowItWorksModal();
  }, [hideHowItWorksModal, hidePointsHistoryModal, hideTerms]);

  const startOrder = useCallback(() => {
    clearModals();
    navigateToLastOrderMenu();
  }, [clearModals, navigateToLastOrderMenu]);

  useEffect(() => {
    navigation.setOptions({ headerShown: !currentBreakpoint.isXS });
  }, [navigation, currentBreakpoint.isXS]);

  useFocusEffect(
    useCallback(() => {
      return clearModals;
    }, [clearModals]),
  );

  // ──────────────────────────────────────────────────────────────────────────

  if (isOptedOut) {
    return <JoinSgRewardsScreen />;
  }

  if (hasFailedToLoad || hasNoPointsRewards) {
    return (
      <>
        <Loyalty.Home.ErrorState
          isLoading={isLoyaltyInfoLoading || isLoyaltyInfoStale}
          refetch={refetchLoyaltyInfo}
        />
        {minWidth.isSM ? <AppFooter /> : null}
      </>
    );
  }

  if (isLoyaltyInfoLoading) return <LoadingAnimation />;

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Loyalty.Home.Container
      availablePoints={availablePoints}
      isShowingModal={isShowingModal}
      palette={isPremium ? 'dark-kale' : 'cream'}
      style={premiumBgColor}
    >
      {/* Header */}

      <Loyalty.Home.Header.Container>
        <Loyalty.Home.Header.Content>
          <Loyalty.Home.Header.FirstName
            firstName={firstName}
            style={premiumFgColor}
          />

          <Loyalty.Home.Header.AvailablePoints
            availablePoints={availablePoints}
            style={premiumFgColor}
          />
        </Loyalty.Home.Header.Content>

        {currentBreakpoint.isXS && isPremium ? (
          <Icon name="IconGoat" width={60} height={60} />
        ) : null}
      </Loyalty.Home.Header.Container>

      {/* Benefits & Progress Bar */}

      {progressBenefit && loyaltyInfoBenefits.length > 0 && minWidth.isSM ? (
        <Loyalty.Home.ProgressBenefit.SM
          progressBenefit={progressBenefit}
          style={premiumFgColor}
        />
      ) : null}

      {pointsRewards.length > 0 ? (
        <Loyalty.Home.ProgressBar
          palette={isPremium ? 'dark' : 'light'}
          availablePoints={availablePoints}
          totalPoints={totalPoints}
          pointsRewards={pointsRewards}
        />
      ) : null}

      {progressBenefit && loyaltyInfoBenefits.length > 0 && !minWidth.isSM ? (
        <Loyalty.Home.ProgressBenefit.XS
          palette={isPremium ? 'dark' : 'light'}
          progressBenefit={progressBenefit}
          benefitsCount={loyaltyInfoBenefits.length}
          onBenefitsOpened={trackBenefitsOpened}
        >
          <Loyalty.InfoBenefits
            availablePoints={availablePoints}
            loyaltyInfoBenefits={loyaltyInfoBenefits}
            isLoading={isFetchingBenefits}
            hasError={hasBenefitsError}
            style={benefitsStyle}
            refetch={refetchBenefits}
          />
        </Loyalty.Home.ProgressBenefit.XS>
      ) : null}

      {/* Point History & How It Works Buttons */}

      <Loyalty.Home.Body isPremium={isPremium}>
        {minWidth.isSM ? (
          <Loyalty.PointsHistory.CtaButton
            palette={isPremium ? 'cream' : 'secondary'}
            onPress={showPointsHistoryModal}
          />
        ) : null}

        {currentBreakpoint.isXS ? (
          <>
            <Loyalty.PointsHistory.CtaLink
              style={isPremium ? LOYALTY_PALETTES.premiumFgColor : undefined}
              onPress={showPointsHistoryModal}
            />

            <BodyText
              style={isPremium ? LOYALTY_PALETTES.premiumFgColor : undefined}
              sizeMatch={['14']}
            >
              •
            </BodyText>

            <Loyalty.HowItWorks.Cta
              style={isPremium ? LOYALTY_PALETTES.premiumFgColor : undefined}
              onPress={showHowItWorksModal}
            />
          </>
        ) : null}
      </Loyalty.Home.Body>

      {/* Challenges & Rewards */}

      {currentBreakpoint.isXS ? (
        <LoyaltyHomeRewards
          style={rewardsStyleXS}
          hasRewards={hasRewards}
          rewards={rewards}
          startOrder={startOrder}
          showTerms={showTerms}
        />
      ) : (
        <LoyaltyHomeChallenges
          style={[premiumBgColor, styles.challengesRewardsPaddingSM]}
          hasChallenges={hasChallenges}
          isPremium={isPremium}
          challenges={challenges}
          startOrder={startOrder}
        />
      )}

      {currentBreakpoint.isXS ? (
        <LoyaltyHomeChallenges
          style={challengesStyleXS}
          hasChallenges={hasChallenges}
          isPremium={isPremium}
          challenges={challenges}
          startOrder={startOrder}
        />
      ) : (
        <LoyaltyHomeRewards
          style={[premiumBgColor, styles.challengesRewardsPaddingSM]}
          hasRewards={hasRewards}
          rewards={rewards}
          startOrder={startOrder}
          showTerms={showTerms}
        />
      )}

      {/* FAQ (XS Only) */}

      {currentBreakpoint.isXS ? (
        <LoyaltyHomeFaqXs
          palette={isPremium ? 'cream' : 'kale'}
          showHowItWorksModal={showHowItWorksModal}
        />
      ) : null}

      {/* How It Works (SM+ Only) */}

      {minWidth.isSM ? (
        <Loyalty.Home.HowItWorks.Container
          palette={isPremium ? 'dark' : 'light'}
        >
          {homeHowItWorksSM.map((entry, index) => (
            <Loyalty.Home.HowItWorks.Card
              index={index + 1}
              key={entry.title}
              title={entry.title}
              description={entry.description}
              palette={isPremium ? 'dark' : 'light'}
            />
          ))}
        </Loyalty.Home.HowItWorks.Container>
      ) : null}

      {/* Loyalty Footer */}

      <Loyalty.Home.Footer
        style={premiumBgColor}
        termsAndConditionsUrl={termsAndConditionsUrl}
        isPremium={isPremium}
      />

      {/* App Footer */}

      {minWidth.isSM ? <AppFooter /> : null}

      {/* Modals */}

      <LoyaltyPointHistoryModal
        availablePoints={availablePoints}
        visible={isShowingPointsHistoryModal}
        startOrder={startOrder}
        onRequestClose={hidePointsHistoryModal}
      />

      <LoyaltyHowItWorksModal
        visible={isShowingHowItWorksModal}
        onRequestClose={hideHowItWorksModal}
      />

      <ModalDialogue
        visible={Boolean(termsBeingShown)}
        onRequestClose={hideTerms}
      >
        {termsBeingShown?.terms && termsBeingShown?.name ? (
          <Loyalty.RewardTerms
            title={termsBeingShown.name}
            terms={termsBeingShown.terms}
            expirationDate={termsBeingShown?.expirationDate}
          />
        ) : null}
      </ModalDialogue>
    </Loyalty.Home.Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  challengesRewardsPaddingXS: {
    paddingTop: theme.spacing['6'],
    paddingBottom: theme.spacing['6'],
  },
  challengesRewardsPaddingSM: {
    paddingTop: theme.spacing['10'],
    paddingBottom: theme.spacing['10'],
  },
  paddingWithContentTop: {
    paddingTop: theme.spacing['2'],
  },
  paddingWithContentBottom: {
    paddingBottom: theme.spacing['2'],
  },
  premiumBenefitsBorder: {
    borderColor: theme.colors.CREAM,
  },
  loyaltyInfoBenefits: {
    marginTop: 0,
    marginBottom: 0,
    marginHorizontal: theme.spacing['6'],
    borderTopWidth: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
});
