import { useCallback, useMemo } from 'react';
import type { AddressType } from '@sg/garnish';

import { useCartMachine } from './Cart.machine';
import { useCartQuery } from './GraphQL/Cart.generated';
import { getCartLedgerTotal, getCartLineItemsCount } from './useCart.utils';

// ────────────────────────────────────────────────────────────────────────────────

export const useCart = () => {
  const [cartResponse, getCart] = useCartQuery({
    requestPolicy: 'cache-only',
  });
  const {
    shouldDisableCartControls,
    pendingCartMutations,
    executeCartMutation,
    registerCartView,
    cartViewCounts,
  } = useCartMachine();

  const cart = cartResponse.data?.cart;
  const isFetchingCart = cartResponse.fetching || shouldDisableCartControls;
  const cartViewCount = cartViewCounts[cart?.id ?? ''] ?? 0;

  // ─────────────────────────────────────────────────────────────────

  const { id, restaurant, lineItems, ledger, deliveryOrderDetail } = cart ?? {};
  const { isOutpost } = restaurant ?? {};

  const lineItemsCount = getCartLineItemsCount(lineItems);
  const orderType = cart?.orderType?.toLowerCase();
  const cartOrderType: AddressType = (
    isOutpost ? 'outpost' : orderType ?? 'pickup'
  ) as AddressType;
  const isCartPickup = cartOrderType === 'pickup';
  const isCartOutpost = cartOrderType === 'outpost';
  const isCartDelivery = cartOrderType === 'delivery';
  const isCartEmpty = lineItemsCount === 0;
  const total = getCartLedgerTotal(ledger);

  const hasCartAddressIfNeeded =
    !isCartDelivery || Boolean(deliveryOrderDetail?.address?.id);
  const isCartValid = Boolean(!isCartEmpty && hasCartAddressIfNeeded);

  const deliveryDetails = useMemo(() => {
    const address = deliveryOrderDetail?.address ?? null;
    const cartSubtotal = ledger?.subtotal ?? 0;
    const minSubtotal = restaurant?.deliveryMinSubtotal ?? 0;
    const hasNotMetDeliveryMinimum =
      isCartDelivery && cartSubtotal < minSubtotal;
    const vendor = deliveryOrderDetail?.vendor;
    const vendorRestaurantId = deliveryOrderDetail?.vendorRestaurantId;
    const deliveryFee = deliveryOrderDetail?.deliveryFee;

    return {
      address,
      minSubtotal,
      hasNotMetDeliveryMinimum,
      vendor,
      vendorRestaurantId,
      deliveryFee,
    };
  }, [
    deliveryOrderDetail?.address,
    isCartDelivery,
    ledger?.subtotal,
    restaurant?.deliveryMinSubtotal,
    deliveryOrderDetail?.vendor,
    deliveryOrderDetail?.vendorRestaurantId,
    deliveryOrderDetail?.deliveryFee,
  ]);

  // ─── HELPERS ─────────────────────────────────────────────────────

  const fetchCart = useCallback(() => {
    getCart({ requestPolicy: 'cache-and-network' });
  }, [getCart]);

  // ─────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({
      cart,
      orderId: id,
      isFetchingCart,
      isCartEmpty,
      isCartValid,
      isCartPickup,
      isCartOutpost,
      isCartDelivery,
      cartOrderType,
      lineItemsCount,
      total,
      deliveryDetails,
      pendingCartMutations,
      fetchCart,
      executeCartMutation,
      registerCartView,
      cartViewCount,
    }),
    [
      cart,
      id,
      isFetchingCart,
      isCartEmpty,
      isCartValid,
      isCartPickup,
      isCartOutpost,
      isCartDelivery,
      cartOrderType,
      lineItemsCount,
      total,
      deliveryDetails,
      pendingCartMutations,
      fetchCart,
      executeCartMutation,
      registerCartView,
      cartViewCount,
    ],
  );
};
