import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { TitleText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardTitle = (props: LoyaltyOfferCardTitleProps) => {
  const { style, ...restProps } = props;

  return (
    <TitleText
      style={[styles.title, style]}
      sizeMatch={['24']}
      {...restProps}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  title: {
    color: theme.colors.CREAM,
    paddingVertical: 2,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardTitleProps = ComponentProps<typeof TitleText>;
