import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FloatingButtons } from '@order/components';

export const HomeReorderButton = (props: HomeReorderButtonProps) => {
  const { onPress } = props;

  return (
    <FloatingButtons.Default palette="oatmeal" size="large" onPress={onPress}>
      <FormattedMessage
        defaultMessage="Reorder"
        description="Home screen | Reorder button | Label"
      />
    </FloatingButtons.Default>
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type HomeReorderButtonProps = {
  onPress: () => void;
};
