import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type Asset, type Entry } from 'contentful';

import { SG_REWARDS_TERMS_AND_CONDITIONS } from '@order/constants';
import { useContentfulContentTypeEntry } from '@order/Contentful';

export const useEarnRedeemContent = () => {
  const { formatMessage } = useIntl();

  const content = useContentfulContentTypeEntry<LoyaltyContainer>({
    contentType: 'loyalty',
  });

  // ─── Fallback ────────────────────────────────────────────────────────

  const fallbackData = useMemo(
    () => [
      {
        title: formatMessage(fallbacks.one),
        image: {
          url: '//images.ctfassets.net/wme4s8lvzccr/15LLyVKCemxcDReNN1UVzR/2f4f64ab6eedee1b84c0f9da86740d5f/1.svg',
          description: '',
        },
      },
      {
        title: formatMessage(fallbacks.two),
        image: {
          url: '//images.ctfassets.net/wme4s8lvzccr/sbLXnGFxYSYTtLPjLTGnE/7cd1487d4f75ca6cc3ccfbaa0b5e47ff/3.svg',
          description: '',
        },
      },
      {
        title: formatMessage(fallbacks.three),
        image: {
          url: '//images.ctfassets.net/wme4s8lvzccr/sbLXnGFxYSYTtLPjLTGnE/7cd1487d4f75ca6cc3ccfbaa0b5e47ff/3.svg',
          description: '',
        },
      },
    ],
    [formatMessage],
  );

  const fields = content.data?.fields;

  // ─── Terms And Conditions ────────────────────────────────────────────

  const termsAndConditionsUrl =
    fields?.termsAndConditionsUrl ?? SG_REWARDS_TERMS_AND_CONDITIONS;

  // ─── Opted Out ───────────────────────────────────────────────────────

  const optedOutData = fields?.scanToPayOptedOut;
  const optedOutBannerImage = optedOutData?.fields?.image?.fields?.file?.url;
  const optedOutBannerA11y = optedOutData?.fields?.image?.fields?.description;

  // ─── Gift Card ───────────────────────────────────────────────────────

  const giftCardData = fields?.scanToPayNoGiftCard;
  const giftCardBannerImage = giftCardData?.fields?.image?.fields?.file?.url;
  const giftCardBannerA11y = giftCardData?.fields?.image?.fields?.description;

  // ─── How It Works ────────────────────────────────────────────────────

  const howItWorksData = fields?.scanToPayHowItWorks;
  const howItWorks = useMemo(() => {
    return (
      howItWorksData?.map((item) => ({
        ...item.fields,
        image: {
          url: item.fields.image.fields.file.url,
          description: item.fields.image.fields.description,
        },
      })) ?? fallbackData
    );
  }, [howItWorksData, fallbackData]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    howItWorks,
    optedOutBannerImage,
    optedOutBannerA11y,
    giftCardBannerImage,
    giftCardBannerA11y,
    termsAndConditionsUrl,
  };
};

// ─── Fallbacks ──────────────────────────────────────────────────────────────

const fallbacks = defineMessages({
  one: {
    defaultMessage:
      'Scan your QR code at checkout to earn 10 points for every $1 spent',
    description: 'Earn + Redeem | How it works | One',
  },
  two: {
    defaultMessage: "Tell the team member which reward you'd like to apply",
    description: 'Earn + Redeem | How it works | Two',
  },
  three: {
    defaultMessage:
      'Any points earned in-store will be added to your account shortly',
    description: 'Earn + Redeem | How it works | Three',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyContainer = {
  scanToPayHowItWorks: Entry<EarnRedeemHowItWorksItem>[];
  scanToPayOptedOut: Entry<{ image: Asset }>;
  scanToPayNoGiftCard: Entry<{ image: Asset }>;
  termsAndConditionsUrl: string;
};

type EarnRedeemHowItWorksItem = {
  id: string;
  title: string;
  image: {
    fields: {
      description: string;
      file: {
        url: string;
      };
    };
  };
};
