import React, { useCallback, useState } from 'react';
import type { LayoutChangeEvent } from 'react-native';
import { Animated, StyleSheet, View } from 'react-native';

import type {
  CollapsibleChildrenProps,
  CollapsibleDetailsProps,
} from '../Collapsible.types';

export const Details = (props: CollapsibleDetailsProps) => {
  const {
    children,
    transition,
    collapsed,
    onLayout,
    testID = 'sg-collapsible-details',
    ...restProps
  } = props as PropsType;

  const [collapsibleContentHeight, setCollapsibleContentHeight] = useState(0);

  const animatedStyles = {
    opacity: transition,
    height: transition.interpolate({
      inputRange: [0, 1],
      outputRange: [0, collapsibleContentHeight],
    }),
  };

  // ─── Helpers ──────────────────────────────────────────────────────────────

  const handleOnLayout = useCallback(
    (event: LayoutChangeEvent) => {
      onLayout?.(event);
      setCollapsibleContentHeight(event.nativeEvent.layout.height);
    },
    [onLayout],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Animated.View testID={testID} style={animatedStyles}>
      {collapsed ? null : (
        <View
          testID="sg-collapsible-details-inner"
          style={styles.collapsible}
          onLayout={handleOnLayout}
          {...restProps}
        >
          {children}
        </View>
      )}
    </Animated.View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  collapsible: {
    position: 'absolute',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type PropsType = CollapsibleDetailsProps & CollapsibleChildrenProps;
