import { useCallback } from 'react';

import {
  type BrazeInAppMessagePayload,
  useOnBrazeInAppMessageShow,
} from '@order/Braze';

import { checkIfOnboardingModal } from './helpers';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Custom hook to listen to the loyalty onboarding modal (in-app message) show event.
 *
 * Can be used as an effect (`useEffect`, `useFocusEffect`) or in response to an action.
 */
export const useOnOnboardingModalShow = (
  params: UseOnOnboardingModalShowParams,
) => {
  const { onModalShow } = params;

  const onInAppMessageShow = useCallback(
    (payload?: BrazeInAppMessagePayload) => {
      if (!checkIfOnboardingModal(payload)) return;

      onModalShow();
    },
    [onModalShow],
  );

  return useOnBrazeInAppMessageShow({ onInAppMessageShow });
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseOnOnboardingModalShowParams = {
  onModalShow: () => void;
};
