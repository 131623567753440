import React, { type ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';
import {
  HorizontalScrollRail,
  useContainerSpacing,
  useResponsive,
  VStack,
} from '@sg/garnish';

import { LoyaltyHomeChallengesRewardsContainer } from '../LoyaltyHomeChallengesRewardsContainer';

// ─────────────────────────────────────────────────────────────────────────────

const Rail = (props: RailProps) => {
  const { currentBreakpoint, match } = useResponsive();
  const containerSpacing = useContainerSpacing();
  const { formatMessage } = useIntl();

  if (currentBreakpoint.isXS) {
    return <VStack>{props.children}</VStack>;
  }

  return (
    <HorizontalScrollRail
      showNavControls={true}
      withoutHeaderBorder
      heading={formatMessage(messages.offers)}
      headerBottomSpacing={theme.spacing['6']}
      headerVariation="display"
      gap={theme.spacing['4']}
      outerOffset={
        -match([
          containerSpacing,
          containerSpacing,
          containerSpacing,
          RAIL_SPACING_LG,
        ])
      }
      itemVisiblePercentThreshold={99}
      headerPalette="light"
      titleStyle={styles.title}
    >
      {props.children}
    </HorizontalScrollRail>
  );
};

export const LoyaltyHomeChallenges = {
  Container: LoyaltyHomeChallengesRewardsContainer,
  Rail,
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  offers: {
    defaultMessage: 'Offers',
    description: 'Loyalty Home | Offers | Title',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const RAIL_SPACING_LG = theme.spacing['30'];

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  title: {
    ...TYPE_CONFIG.DISPLAY['24'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type RailProps = Pick<
  ComponentProps<typeof HorizontalScrollRail>,
  'children' | 'style'
>;
