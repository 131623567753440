import IconAccount from './Icon-Account.svg';
import IconApp from './Icon-App.svg';
import IconAppleBlack from './Icon-AppleBlack.svg';
import IconApplePay from './Icon-ApplePay.svg';
import IconApplePayPayment from './Icon-ApplePayPayment.svg';
import IconArrowDown from './Icon-ArrowDown.svg';
import IconArrowLeft from './Icon-ArrowLeft.svg';
import IconArrowOut from './Icon-ArrowOut.svg';
import IconArrowRight from './Icon-ArrowRight.svg';
import IconArrowRightThin from './Icon-ArrowRightThin.svg';
import IconArrowUp from './Icon-ArrowUp.svg';
import IconAttach from './Icon-Attach.svg';
import IconBadgeAlert from './Icon-BadgeAlert.svg';
import IconBadgeCaretDown from './Icon-BadgeCaretDown.svg';
import IconBadgeCaretLeft from './Icon-BadgeCaretLeft.svg';
import IconBadgeCaretRight from './Icon-BadgeCaretRight.svg';
import IconBadgeCaretUp from './Icon-BadgeCaretUp.svg';
import IconBadgeCheck from './Icon-BadgeCheck.svg';
import IconBadgeClose from './Icon-BadgeClose.svg';
import IconBadgeHelp from './Icon-BadgeHelp.svg';
import IconBadgeInfo from './Icon-BadgeInfo.svg';
import IconBadgeMinus from './Icon-BadgeMinus.svg';
import IconBadgePlus from './Icon-BadgePlus.svg';
import IconBadgeScan from './Icon-BadgeScan.svg';
import IconBadgeTrash from './Icon-BadgeTrash.svg';
import IconBagFill from './Icon-Bag-Fill.svg';
import IconBagStroke from './Icon-Bag-Stroke.svg';
import IconBagPlus from './Icon-BagPlus.svg';
import IconBell from './Icon-Bell.svg';
import IconBookmark from './Icon-Bookmark.svg';
import IconBowl from './Icon-Bowl.svg';
import IconBowlFill from './Icon-Bowl-Fill.svg';
import IconCalendar from './Icon-Calendar.svg';
import IconCamera from './Icon-Camera.svg';
import IconCar from './Icon-Car.svg';
import IconCaretDown from './Icon-CaretDown.svg';
import IconCaretLeft from './Icon-CaretLeft.svg';
import IconCaretRight from './Icon-CaretRight.svg';
import IconCaretUp from './Icon-CaretUp.svg';
import IconCaution from './Icon-Caution.svg';
import IconCcAmex from './Icon-CC-Amex.svg';
import IconCcDiscover from './Icon-CC-Discover.svg';
import IconCcMasterCard from './Icon-CC-Master.svg';
import IconCcVisa from './Icon-CC-Visa.svg';
import IconCheck from './Icon-Check.svg';
import IconClock from './Icon-Clock.svg';
import IconClose from './Icon-Close.svg';
import IconCopy from './Icon-Copy.svg';
import IconCourier from './Icon-Courier.svg';
import IconCredit from './Icon-Credit.svg';
import IconCreditCard from './Icon-CreditCard.svg';
import IconCustomFillLg from './Icon-Custom-Fill-Lg.svg';
import IconCustomFillSm from './Icon-Custom-Fill-Sm.svg';
import IconCustomStrokeLg from './Icon-Custom-Stroke-Lg.svg';
import IconCustomStrokeSm from './Icon-Custom-Stroke-Sm.svg';
import IconEdit from './Icon-Edit.svg';
import IconEdit2 from './Icon-Edit-2.svg';
import IconEmail from './Icon-Email.svg';
import IconFacebook from './Icon-Facebook.svg';
import IconFilter from './Icon-Filter.svg';
import IconFlappyBowl1 from './Icon-Flappy-Bowl-1.svg';
import IconFlappyBowl2 from './Icon-Flappy-Bowl-2.svg';
import IconFlappyBowl3 from './Icon-Flappy-Bowl-3.svg';
import IconFloor from './Icon-Floor.svg';
import IconGoat from './Icon-Goat.svg';
import IconGooglePay from './Icon-GooglePay.svg';
import IconGooglePayInButton from './Icon-GooglePayInButton.svg';
import IconHeartFill from './Icon-Heart-Fill.svg';
import IconHeartStroke from './Icon-Heart-Stroke.svg';
import IconHexagon from './Icon-Hexagon.svg';
import IconHome from './Icon-Home.svg';
import IconHomeAlternative from './Icon-Home-Alternative.svg';
import IconHomeFillLg from './Icon-Home-Fill-Lg.svg';
import IconHomeFillSm from './Icon-Home-Fill-Sm.svg';
import IconHomeStrokeLg from './Icon-Home-Stroke-Lg.svg';
import IconHomeStrokeSm from './Icon-Home-Stroke-Sm.svg';
import IconInsider from './Icon-Insider.svg';
import IconInstagram from './Icon-Instagram.svg';
import IconLightning from './Icon-Lightning.svg';
import IconLightningFill from './Icon-Lightning-Fill.svg';
import IconList from './Icon-List.svg';
import IconLocationPin from './Icon-Location-Pin.svg';
import IconLogout from './Icon-Logout.svg';
import IconLoyalty from './Icon-Loyalty.svg';
import IconLoyaltyCoupon from './Icon-Loyalty-Coupon.svg';
import IconMap from './Icon-Map.svg';
import IconMapPin from './Icon-MapPin.svg';
import IconMenu from './Icon-Menu.svg';
import IconMenuBowl from './Icon-Menu-Bowl.svg';
import IconMessage from './Icon-Message.svg';
import IconMessenger from './Icon-Messenger.svg';
import IconMic from './Icon-Mic.svg';
import IconMinus from './Icon-Minus.svg';
import IconMinus2 from './Icon-Minus-2.svg';
import IconNotepad from './Icon-Notepad.svg';
import IconOutpostFillLg from './Icon-Outpost-Fill-Lg.svg';
import IconOutpostFillSm from './Icon-Outpost-Fill-Sm.svg';
import IconOutpostStrokeSm from './Icon-Outpost-Stroke-Sm.svg';
import IconOutpostStrokeLg from './Icon-OutpostStroke-Lg.svg';
import IconPause from './Icon-Pause.svg';
import IconPhone from './Icon-Phone.svg';
import IconPickupFillLg from './Icon-Pickup-Fill-Lg.svg';
import IconPickupFillSm from './Icon-Pickup-Fill-Sm.svg';
import IconPickupStrokeLg from './Icon-Pickup-Stroke-Lg.svg';
import IconPickupStrokeSm from './Icon-Pickup-Stroke-Sm.svg';
import IconPinterest from './Icon-Pinterest.svg';
import IconPlay from './Icon-Play.svg';
import IconPlus from './Icon-Plus.svg';
import IconPlus2 from './Icon-Plus-2.svg';
import IconProfileFill from './Icon-Profile-Fill.svg';
import IconProfileStroke from './Icon-Profile-Stroke.svg';
import IconPromo from './Icon-Promo.svg';
import IconQr from './Icon-QR.svg';
import IconQrCodeHexagon from './Icon-QrCode-Hexagon.svg';
import IconRead from './Icon-Read.svg';
import IconRewardFill from './Icon-Reward-Fill.svg';
import IconRewardStroke from './Icon-Reward-Stroke.svg';
import IconScan from './Icon-Scan.svg';
import IconSearch from './Icon-Search.svg';
import IconSgReward from './Icon-Sg-Reward.svg';
import IconSgRewardBlackFill from './Icon-Sg-Reward-Black-Fill.svg';
import IconSgRewardBlackOutline from './Icon-Sg-Reward-Black-Outline.svg';
import IconSgRewardKaleFill from './Icon-Sg-Reward-Kale-Fill.svg';
import IconSgRewardSpinachFill from './Icon-Sg-Reward-Spinach-Fill.svg';
import IconSgRewardSweetCorn from './Icon-Sg-Reward-Sweet-Corn.svg';
import IconSgRewardSweetCornBlackOutline from './Icon-Sg-Reward-Sweet-Corn-Black-Outline.svg';
import IconSgRewardWhiteOutline from './Icon-Sg-Reward-White-Outline.svg';
import IconSgRewardWithGoat from './Icon-Sg-Reward-With-Goat.svg';
import IconSgRewardWithText from './Icon-Sg-Reward-With-Text.svg';
import IconShare from './Icon-Share.svg';
import IconShare2 from './Icon-Share-2.svg';
import IconSnapchat from './Icon-Snapchat.svg';
import IconSpotify from './Icon-Spotify.svg';
import IconStarFill from './Icon-Star-Fill.svg';
import IconStarStroke from './Icon-Star-Stroke.svg';
import IconSweetgreen from './Icon-Sweetgreen.svg';
import IconSweetgreenFull from './Icon-Sweetgreen-Full.svg';
import IconSweetpass from './Icon-Sweetpass.svg';
import IconTarget from './Icon-Target.svg';
import IconTelegram from './Icon-Telegram.svg';
import IconText from './Icon-Text.svg';
import IconTikTok from './Icon-TikTok.svg';
import IconTwitter from './Icon-Twitter.svg';
import IconUnavailable from './Icon-Unavailable.svg';
import IconWarmBowl from './Icon-WarmBowl.svg';
import IconWhatsapp from './Icon-Whatsapp.svg';
import IconWorkFillLg from './Icon-Work-Fill-Lg.svg';
import IconWorkFillSm from './Icon-Work-Fill-Sm.svg';
import IconWorkStrokeLg from './Icon-Work-Stroke-Lg.svg';
import IconWorkStrokeSm from './Icon-Work-Stroke-Sm.svg';
import IconYou from './Icon-You.svg';
import IconYouTube from './Icon-YouTube.svg';

export const StandardIcons = {
  IconArrowLeft,
  IconArrowOut,
  IconArrowRight,
  IconArrowRightThin,
  IconArrowDown,
  IconArrowUp,
  IconAttach,
  IconBadgeAlert,
  IconBadgeCaretDown,
  IconBadgeCaretLeft,
  IconBadgeCaretRight,
  IconBadgeCaretUp,
  IconBadgeCheck,
  IconBadgeClose,
  IconBadgeHelp,
  IconBadgeInfo,
  IconBadgeMinus,
  IconBadgePlus,
  IconBadgeTrash,
  IconBagFill,
  IconBagStroke,
  IconBagPlus,
  IconBell,
  IconCalendar,
  IconCamera,
  IconCar,
  IconCaretDown,
  IconCaretLeft,
  IconCaretRight,
  IconCaretUp,
  IconCaution,
  IconCheck,
  IconClock,
  IconClose,
  IconCopy,
  IconEdit,
  IconEdit2,
  IconEmail,
  IconFilter,
  IconFlappyBowl1,
  IconFlappyBowl2,
  IconFlappyBowl3,
  IconFloor,
  IconGoat,
  IconHeartFill,
  IconHeartStroke,
  IconLocationPin,
  IconLogout,
  IconMenu,
  IconMinus,
  IconMinus2,
  IconPhone,
  IconPlus,
  IconPlus2,
  IconProfileFill,
  IconProfileStroke,
  IconRewardFill,
  IconRewardStroke,
  IconSearch,
  IconShare,
  IconShare2,
  IconStarFill,
  IconStarStroke,
  IconText,
  IconUnavailable,
  IconAccount,
  IconHome,
  IconHomeAlternative,
  IconSweetpass,
  IconMenuBowl,
  IconScan,
};

export const PaymentIcons = {
  IconBadgeScan,
  IconCcAmex,
  IconCcDiscover,
  IconCcMasterCard,
  IconCcVisa,
  IconCredit,
  IconCreditCard,
  IconPromo,
  IconQr,
  IconQrCodeHexagon,
};

export const SocialIcons = {
  IconFacebook,
  IconInstagram,
  IconMessage,
  IconMessenger,
  IconPinterest,
  IconSnapchat,
  IconSpotify,
  IconTelegram,
  IconTikTok,
  IconTwitter,
  IconWhatsapp,
  IconYouTube,
};
export const MapLocationsIcons = {
  IconCustomFillLg,
  IconCustomFillSm,
  IconCustomStrokeLg,
  IconCustomStrokeSm,
  IconHomeFillLg,
  IconHomeFillSm,
  IconHomeStrokeLg,
  IconHomeStrokeSm,
  IconMapPin,
  IconOutpostFillLg,
  IconOutpostFillSm,
  IconOutpostStrokeLg,
  IconOutpostStrokeSm,
  IconPickupFillLg,
  IconPickupFillSm,
  IconPickupStrokeLg,
  IconPickupStrokeSm,
  IconWorkFillLg,
  IconWorkFillSm,
  IconWorkStrokeLg,
  IconWorkStrokeSm,
};

export const MapIcons = {
  IconCourier,
  IconList,
  IconMap,
  IconTarget,
  IconUnavailable,
  IconYou,
};

export const LoyaltyIcons = {
  IconLoyalty,
  IconLoyaltyCoupon,
  IconSgReward,
  IconSgRewardWhiteOutline,
  IconSgRewardBlackFill,
  IconSgRewardBlackOutline,
  IconSgRewardSpinachFill,
  IconSgRewardKaleFill,
  IconSgRewardSweetCorn,
  IconSgRewardSweetCornBlackOutline,
  IconSgRewardWithText,
  IconSgRewardWithGoat,
};

export const UnsortedIcons = {
  IconApp,
  IconAppleBlack,
  IconApplePay,
  IconApplePayPayment,
  IconGooglePay,
  IconGooglePayInButton,
  IconBadgeCaretLeft,
  IconBookmark,
  IconBowl,
  IconBowlFill,
  IconCaretLeft,
  IconHexagon,
  IconInsider,
  IconLightning,
  IconLightningFill,
  IconMic,
  IconNotepad,
  IconPause,
  IconPlay,
  IconRead,
  IconSweetgreen,
  IconSweetgreenFull,
  IconWarmBowl,
};
