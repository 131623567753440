/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
export type AddressFragment = {
  readonly __typename?: 'Address';
  readonly id: string;
  readonly street: string;
  readonly secondaryStreet: string | null;
  readonly name: string | null;
  readonly city: string;
  readonly state: string;
  readonly zipCode: string;
  readonly country: string;
  readonly deliveryPreference: Types.DeliveryPreferenceType;
  readonly googlePlaceId: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly notes: string | null;
};

export type CustomerCreditsFragment = {
  readonly __typename?: 'Credit';
  readonly id: string;
  readonly title: string;
  readonly description: string | null;
  readonly amount: number;
  readonly remainingAmount: number;
  readonly createdAt: string;
  readonly expiresAt: string | null;
};

export type CustomerDataFragment = {
  readonly __typename?: 'Customer';
  readonly id: string;
  readonly trackingUuid: string | null;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly phoneNumber: string | null;
  readonly email: string | null;
  readonly useCredit: boolean;
  readonly availableCredit: number;
  readonly addresses: ReadonlyArray<{
    readonly __typename?: 'Address';
    readonly id: string;
    readonly street: string;
    readonly secondaryStreet: string | null;
    readonly name: string | null;
    readonly city: string;
    readonly state: string;
    readonly zipCode: string;
    readonly country: string;
    readonly deliveryPreference: Types.DeliveryPreferenceType;
    readonly googlePlaceId: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly notes: string | null;
  }>;
  readonly credits: ReadonlyArray<{
    readonly __typename?: 'Credit';
    readonly id: string;
    readonly title: string;
    readonly description: string | null;
    readonly amount: number;
    readonly remainingAmount: number;
    readonly createdAt: string;
    readonly expiresAt: string | null;
  }>;
};

export const AddressFragmentDoc = gql`
  fragment address on Address {
    id
    street
    secondaryStreet
    name
    city
    state
    zipCode
    country
    deliveryPreference
    googlePlaceId
    latitude
    longitude
    notes
  }
`;
export const CustomerCreditsFragmentDoc = gql`
  fragment customerCredits on Credit {
    id
    title
    description
    amount
    remainingAmount
    createdAt
    expiresAt
  }
`;
export const CustomerDataFragmentDoc = gql`
  fragment CustomerData on Customer {
    id
    trackingUuid
    firstName
    lastName
    phoneNumber
    email
    addresses {
      ...address
    }
    useCredit
    availableCredit
    credits {
      ...customerCredits
    }
  }
  ${AddressFragmentDoc}
  ${CustomerCreditsFragmentDoc}
`;
