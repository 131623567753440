import { useCallback } from 'react';
import { useActor } from '@xstate/react';

import { useGlobalAppState } from '@order/GlobalAppState';
import { useCloseBag } from '@order/hooks';

import type { ReorderEvent } from '../../machines/Reorder.machine';
import { useReorderNavigation } from '../useReorderNavigation';
import { useReorderTelemetry } from '../useReorderTelemetry';
import { getValidEventKeys, logger } from '../utils';

export const useActiveBagWarning = () => {
  const { reorderMachineRef } = useGlobalAppState();
  const [, sendReorderEvent] = useActor(reorderMachineRef);
  const { existingActiveBagTelemetry } = useReorderTelemetry();
  const { navigateToActiveBagWarning } = useReorderNavigation();
  const closeBag = useCloseBag();

  return useCallback(
    (event?: ReorderEvent) => {
      logger.info('Active Bag Warning', event);

      // ─── State Machine ──────────────────────────────────────────
      sendReorderEvent({
        ...getValidEventKeys(event),
        type: 'ACTIVE_BAG_WARNING',
      });

      // ─── Telemetry ──────────────────────────────────────────────
      existingActiveBagTelemetry();

      // ─── Navigation ─────────────────────────────────────────────
      closeBag();
      setTimeout(navigateToActiveBagWarning);
    },
    [
      closeBag,
      existingActiveBagTelemetry,
      navigateToActiveBagWarning,
      sendReorderEvent,
    ],
  );
};
