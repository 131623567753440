import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

export const LoyaltyPostPurchaseSubtitle = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <BodyText sizeMatch={['14']} style={styles.subtitle}>
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  subtitle: {
    color: theme.colors.WHITE,
    marginBottom: theme.spacing['8'],
    textAlign: 'center',
  },
});
