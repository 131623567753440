import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { type SharedValue } from 'react-native-reanimated';
import { CustomNavigationHeader, theme } from '@sg/garnish';

import { Bag } from '@order/features/ordering';

import { type useOrderState } from '../../hooks';

/**
 * Header for the animated dialog.
 */
export const BagAnimatedDialogHeader = (
  props: BagAnimatedDialogHeaderProps,
) => {
  const { show, orderState, scrollOffsetSV } = props;
  const {
    isChangingTime,
    isChangingDeliveryPreferences,
    isShowingCheckoutLedger,
    isChangingPaymentMethod,
    isShowingPaymentMethodForm,
    isShowingGiftCardForm,
    isShowingLoyaltyInfo,
    cancelRequest,
    goBack,
  } = orderState;

  const { formatMessage } = useIntl();
  const style = [styles.header, !show && styles.hiddenHeader];

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <CustomNavigationHeader.Container
      palette="cream"
      safeAreaEdges={[]}
      style={style}
      scrollOffsetSV={scrollOffsetSV}
    >
      {goBack ? (
        <CustomNavigationHeader.Button.Left
          name="IconCaretLeft"
          palette="dark-kale"
          accessibilityLabel={formatMessage(messages.back)}
          onPress={goBack}
        />
      ) : null}

      <CustomNavigationHeader.Content
        pointerEvents="none"
        scrollOffsetSV={scrollOffsetSV}
      >
        <Bag.AnimatedDialogTitle
          isShowingCheckoutLedger={isShowingCheckoutLedger}
          isChangingTime={isChangingTime}
          isChangingDeliveryPreferences={isChangingDeliveryPreferences}
          isChangingPaymentMethod={isChangingPaymentMethod}
          isShowingPaymentMethodForm={isShowingPaymentMethodForm}
          isShowingGiftCardForm={isShowingGiftCardForm}
          isShowingLoyaltyInfo={isShowingLoyaltyInfo}
        />
      </CustomNavigationHeader.Content>

      {cancelRequest ? (
        <CustomNavigationHeader.Button.Right
          name="IconClose"
          palette="dark-kale"
          accessibilityLabel={formatMessage(messages.close)}
          onPress={cancelRequest}
        />
      ) : null}
    </CustomNavigationHeader.Container>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  back: {
    defaultMessage: 'Go back',
    description: 'Bag | Header | Go back',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Bag | Header | Close',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    marginBottom: -theme.spacing['18'],
  },
  hiddenHeader: {
    maxHeight: 0,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagAnimatedDialogHeaderProps = {
  orderState: ReturnType<typeof useOrderState>;
  scrollOffsetSV: SharedValue<number>;
  show?: boolean;
};
