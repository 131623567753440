import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type Challenge, type Reward, TierName } from '@sg/graphql-schema';

import { useFeatureFlag } from '@order/LaunchDarkly';

import {
  useLoyaltyInfoQuery,
  useLoyaltyInfoWithThemeQuery,
} from '../../GraphQL/LoyaltyInfo.generated';

export const useLoyaltyInfo = (props: UseLoyaltyInfoProps) => {
  const { pause } = props;

  // ─── Remote Data ─────────────────────────────────────────────────────

  const isHurdleAndUnlimitedOffersEnabled = useFeatureFlag(
    'CELS-2951-hurdle-and-unlimited-offers-enabled',
  );

  const loyaltyInfoQuery = useLoyaltyInfoQuery({
    requestPolicy: 'cache-and-network',
    pause: pause || isHurdleAndUnlimitedOffersEnabled,
  });
  const loyaltyInfoWithThemeQuery = useLoyaltyInfoWithThemeQuery({
    requestPolicy: 'cache-and-network',
    pause: pause || !isHurdleAndUnlimitedOffersEnabled,
  });

  const [response, refetch] = isHurdleAndUnlimitedOffersEnabled
    ? loyaltyInfoWithThemeQuery
    : loyaltyInfoQuery;

  // ─────────────────────────────────────────────────────────────────────

  const { formatMessage } = useIntl();
  const guest = formatMessage(messages.guest);

  const isLoading = response.fetching;
  const isStale = response.stale;
  const hasFailedToLoad = Boolean(response.error);

  return useMemo(() => {
    const customer = response.data?.currentCustomer;
    const loyaltyProfile = response.data?.loyaltyProfile;
    const customerTier = loyaltyProfile?.tier?.nameV2;
    const isOptedOut = customerTier === TierName.RewardsOptedOut;

    const challenges = response.data?.challenges ?? emptyChallenges;
    const rewards = loyaltyProfile?.rewards ?? emptyRewards;
    const pointsRewards = loyaltyProfile?.pointsRewards ?? emptyRewards;

    const hasNoPointsRewards =
      !isLoading && !isStale && pointsRewards.length === 0;

    return {
      isLoading,
      isStale,
      hasFailedToLoad,
      hasNoPointsRewards,
      firstName: customer?.firstName ?? guest,
      availablePoints: loyaltyProfile?.points?.available ?? 0,
      pendingPoints: loyaltyProfile?.points?.pending ?? 0,
      totalPoints: loyaltyProfile?.points?.total ?? 0,
      challenges,
      rewards,
      pointsRewards,
      customerTier,
      isOptedOut,
      refetch,
    };
  }, [
    guest,
    isLoading,
    isStale,
    hasFailedToLoad,
    response.data?.challenges,
    response.data?.currentCustomer,
    response.data?.loyaltyProfile,
    refetch,
  ]);
};

// ─── Constants ──────────────────────────────────────────────────────────────

const emptyChallenges: Challenge[] = [];
const emptyRewards: Reward[] = [];

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  guest: {
    defaultMessage: 'Guest',
    description: 'Loyalty | Customer Name | Fallback',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type UseLoyaltyInfoProps = {
  pause: boolean;
};
