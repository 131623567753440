/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OptInLoyaltyMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type OptInLoyaltyMutation = {
  readonly __typename?: 'Mutation';
  readonly optInLoyalty: {
    readonly __typename?: 'LoyaltyProfile';
    readonly customerId: string;
  } | null;
};

export const OptInLoyaltyDocument = gql`
  mutation OptInLoyalty {
    optInLoyalty {
      customerId
    }
  }
`;

export function useOptInLoyaltyMutation() {
  return Urql.useMutation<OptInLoyaltyMutation, OptInLoyaltyMutationVariables>(
    OptInLoyaltyDocument,
  );
}
