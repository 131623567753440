/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoyaltyPointsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LoyaltyPointsQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyProfile: {
    readonly __typename?: 'LoyaltyProfile';
    readonly customerId: string;
    readonly points: {
      readonly __typename?: 'UserPointsStatus';
      readonly available: number;
    } | null;
  } | null;
};

export const LoyaltyPointsDocument = gql`
  query LoyaltyPoints {
    loyaltyProfile {
      customerId
      points {
        available
      }
    }
  }
`;

export function useLoyaltyPointsQuery(
  options?: Omit<Urql.UseQueryArgs<LoyaltyPointsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<LoyaltyPointsQuery, LoyaltyPointsQueryVariables>({
    query: LoyaltyPointsDocument,
    ...options,
  });
}
