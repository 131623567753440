import { type BrazeInAppMessagePayload } from '../braze.types';

export const EVENT_LISTENERS = {
  IN_APP_MESSAGE_RECEIVED: new Set<
    (messagePayload?: BrazeInAppMessagePayload) => void
  >([]),
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

export function triggerInAppMessageReceivedListeners(
  messagePayload?: BrazeInAppMessagePayload,
) {
  // eslint-disable-next-line unicorn/no-array-for-each
  EVENT_LISTENERS.IN_APP_MESSAGE_RECEIVED.forEach((callback) => {
    callback(messagePayload);
  });
}

export function addInAppMessageReceivedListener(callback: () => void) {
  EVENT_LISTENERS.IN_APP_MESSAGE_RECEIVED.add(callback);

  return () => {
    EVENT_LISTENERS.IN_APP_MESSAGE_RECEIVED.delete(callback);
  };
}

export function removeInAppMessageReceivedListener(callback: () => void) {
  EVENT_LISTENERS.IN_APP_MESSAGE_RECEIVED.delete(callback);
}
