import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { Button, FallbackImage, ScanCard, theme, TitleText } from '@sg/garnish';

import { Loyalty } from '@order/features/loyalty';

import { PayIllustration } from '../../assets';
import { useEarnRedeemContent } from '../../hooks';
import { ScanInStoreTabContentContainer } from '../ScanInStoreTabContentContainer';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanAtCheckoutOptedOutContent = (props: ScanOptedOutProps) => {
  const { isOptingIn, handleOnOptIn } = props;

  const { optedOutBannerImage, optedOutBannerA11y, termsAndConditionsUrl } =
    useEarnRedeemContent();

  return (
    <ScanInStoreTabContentContainer>
      <ScanCard.Container style={styles.container} palette="darkGreen">
        <FallbackImage
          style={styles.image}
          aria-label={optedOutBannerA11y}
          baseUrl={optedOutBannerImage}
          contentfulOptions={contentfulOptions}
          defaultImage={PayIllustration}
        />

        <View style={styles.content}>
          <TitleText style={styles.title} sizeMatch={['24']}>
            <FormattedMessage {...messages.title} />
          </TitleText>

          <Button
            size="large"
            palette="sweetCorn"
            isLoading={isOptingIn}
            onPress={handleOnOptIn}
          >
            <FormattedMessage {...messages.join} />
          </Button>

          <View style={styles.termsWrapper}>
            <Loyalty.Terms
              palette="light"
              termsAndConditionsUrl={termsAndConditionsUrl}
            />
          </View>
        </View>
      </ScanCard.Container>
    </ScanInStoreTabContentContainer>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Join sg Rewards to earn when you scan at checkout!',
    description: 'Scan At Checkout | Opted Out | Header',
  },
  join: {
    defaultMessage: 'Join sg Rewards',
    description: 'Scan At Checkout | Opted Out | Join',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const bannerHeight = 206;
const contentfulOptions = {
  fm: 'webp' as const,
  h: bannerHeight,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  content: {
    gap: theme.spacing['8'],
    paddingVertical: theme.spacing['8'],
    paddingHorizontal: theme.spacing['6'],
  },
  image: {
    width: '100%',
    height: bannerHeight,
    borderTopLeftRadius: theme.radius.large,
    borderTopRightRadius: theme.radius.large,
  },
  title: {
    textAlign: 'center',
    color: theme.colors.WHITE,
  },
  termsWrapper: {
    alignSelf: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanOptedOutProps = {
  isOptingIn: boolean;
  handleOnOptIn: () => void;
};
