export const general = {
  'general.error': 'Something went wrong, try again in a few moments...',
  'general.notice': 'Notice',
  'general.modal.backdrop.a11y-label': 'Dismiss dialog',
  'general.modal.close-button.a11y-label': 'Close dialog',
  'general.close': 'Close',
  'general.close.a11y-hint': 'Closes the modal',
  'general.cancel': 'Cancel',
  'general.back': 'Back',
  'general.remove': 'Remove',
  'general.submit': 'Submit',
  'general.select': 'Select',
  'general.submit-a11y-hint': 'Submits entered text',
  'general.clear': 'Clear',
  'general.clear-a11y-hint': 'Clears entered text',
  'general.connection-issue-message':
    'Aww, snap peas! Please try again. We’re having a connection issue.',
  'general.refresh.title': 'This page is taking longer than expected to load.',
  'general.refresh.body': 'Please refresh and try again in a few moments.',
  'general.refresh.cta': 'Refresh',
  'general.menu': 'Menu',
  'general.pickup': 'Pickup',
  'general.outpost': 'Outpost',
  'general.delivery': 'Delivery',
  'general.address-type': `{addressType, select,
    pickup {Pickup}
    outpost {Outpost}
    delivery {Delivery}
    other {Pickup}
  }`,
  'general.checkout': 'Checkout',
  'general.check-out': 'Check out',
  'general.apple-pay': 'Apple Pay',
  'general.apple-pay-error':
    'Something went wrong with your Apple Pay request.',
  'general.apple-pay-disabled':
    'Verify the card in your Apple Wallet and try again.',
  'general.apple-pay-canceled':
    'This Apple Pay transaction was cancelled. Please check your Apple Wallet and try again.',
  'general.go-back': 'Go back',
  'general.continue': 'Continue',
  'general.confirm': 'Confirm',
  'general.checkout.label.open': 'Open Bag',
  'general.checkout.label.close': 'Close Bag',
  'general.calories-short': 'cal',
  'general.your-order': 'Your order',
  'general.delivery-location': 'Delivery location',
  'general.outpost-location': 'Outpost location',
  'general.pickup-location': 'Pickup location',
  'general.update': 'Update',
  'general.unavailable': 'Unavailable',
  'general.review-your-pickup-order': 'Review your pickup order',
  'general.review-your-outpost-order': 'Review your Outpost order',
  'general.review-your-delivery-order': 'Review your delivery order',
  'general.share-favorite': 'Checkout out the {productName} at sweetgreen!',
  'general.subtotal-and-tax-label': 'Subtotal & tax',
  'general.subtotal-label': 'Subtotal',
  'general.tax-label': 'Tax',
  'general.estimated-tax-label': 'Estimated tax*',
  'general.service-fee-label': 'Service Fee',
  'general.delivery-fee-label': 'Delivery Fee',
  'general.credit-label': 'Credit',
  'general.reward-header': 'Choose your reward',
  'general.service-fee-description':
    '15% of your subtotal to help us source, staff & prep your unique order.',
  'general.total-label': 'Total',
  'general.estimated-total-label': 'Estimated total',
  'general.payment': 'Payment',
  'general.payment-method': 'Payment method',
  'general.extras': 'Extras',
  'general.resend': 'Resend',
  'general.apply': 'Apply',
  'general.scrollTo': 'Scroll to {target}',
  'general.asap': 'ASAP',
  'general.asap-delivery': 'ASAP {start} - {end} MINS',
  'general.month': 'Month',
  'general.day': 'Day',
  'general.today': 'today',
  'general.available-time-today': 'Today',
  'general.available-time': '{hours}:{minutes} {clock}',
  'general.available-start-time': '{hours}:{minutes}',
  'general.weekday.monday': 'Monday',
  'general.weekday.tuesday': 'Tuesday',
  'general.weekday.wednesday': 'Wednesday',
  'general.weekday.thursday': 'Thursday',
  'general.weekday.friday': 'Friday',
  'general.weekday.saturday': 'Saturday',
  'general.weekday.sunday': 'Sunday',
  'general.month.january': 'January',
  'general.month.february': 'February',
  'general.month.march': 'March',
  'general.month.april': 'April',
  'general.month.may': 'May',
  'general.month.june': 'June',
  'general.month.july': 'July',
  'general.month.august': 'August',
  'general.month.september': 'September',
  'general.month.october': 'October',
  'general.month.november': 'November',
  'general.month.december': 'December',
  'general.you': 'You',
  'general.custom': 'Custom',
  'general.custom-product-name': 'Custom {productName}',
  'general.terms-and-conditions': 'Terms and conditions',
  'general.see': 'See',
  'general.use-by': 'Use by {date}',
  'general.ends-on-date': 'Ends on {date}',
  'general.got-it': 'Got it',
  'general.maintenance-message':
    "Apologies, we're down for maintenance right now.",
  'general.maintenance-image.a11y': 'Maintenance mode illustration',
  'general.seasonal': 'Seasonal',
  'general.link-copy-message': 'Copied link',

  // ─── Duration Unit ───────────────────────────────────────────────────

  'duration-unit.day': '{duration} day',
  'duration-unit.days': '{duration} days',
  'duration-unit.week': '{duration} week',
  'duration-unit.weeks': '{duration} weeks',
  'duration-unit.month': '{duration} month',
  'duration-unit.months': '{duration} months',
  'duration-unit.year': '{duration} year',
  'duration-unit.years': '{duration} years',

  'duration-unit-name.day': 'day',
  'duration-unit-name.days': 'days',
  'duration-unit-name.week': 'week',
  'duration-unit-name.weeks': 'weeks',
  'duration-unit-name.month': 'month',
  'duration-unit-name.months': 'months',
  'duration-unit-name.year': 'year',
  'duration-unit-name.years': 'years',
};
