import {
  interpolateColor,
  type SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { theme } from '@garnish/constants';

import { CUSTOM_NAVIGATION_HEADER_HEIGHT } from '../CustomNavigationHeader.constants';
import { type CustomNavigationHeaderPalette } from '../CustomNavigationHeader.types';

// ─────────────────────────────────────────────────────────────────────────────
/**
 * Will animate the header background color as the customer scrolls the view.
 */
export const useBackgroundAnimation = (props: AnimationProps) => {
  const { scrollOffsetSV, palette = 'default', safeAreaEdges = [] } = props;

  const { top } = useSafeAreaInsets();
  const topPadding = safeAreaEdges?.includes('top') ? top : 0;

  return useAnimatedStyle(
    () => ({
      minHeight: CUSTOM_NAVIGATION_HEADER_HEIGHT + topPadding,
      backgroundColor: interpolateColor(
        scrollOffsetSV?.value ?? 0,
        [0, CUSTOM_NAVIGATION_HEADER_HEIGHT],
        backgroundPalettes[palette],
      ),
    }),
    [scrollOffsetSV, palette, topPadding],
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const backgroundPalettes: Record<CustomNavigationHeaderPalette, string[]> = {
  default: [
    theme.colors.OPACITY.OATMEAL.ALMOST_TRANSPARENT,
    theme.colors.OATMEAL,
  ],
  oatmeal: [
    theme.colors.OPACITY.OATMEAL.ALMOST_TRANSPARENT,
    theme.colors.OATMEAL,
  ],
  cream: [theme.colors.OPACITY.CREAM.ALMOST_TRANSPARENT, theme.colors.CREAM],
  'dark-kale': [
    theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    theme.colors.DARK_KALE,
  ],
};

// ─── Types ───────────────────────────────────────────────────────────────────

type AnimationProps = {
  scrollOffsetSV?: SharedValue<number>;
  palette?: CustomNavigationHeaderPalette;
  safeAreaEdges?: readonly ('top' | 'right' | 'left')[] | null;
};
