import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, TextLinkifyTags } from '@sg/garnish';

export const LoyaltyTerms = (props: LoyaltyTermsProps) => {
  const { termsAndConditionsUrl, palette } = props;

  const { formatMessage } = useIntl();
  const links = useMemo(
    () => [
      {
        tag: 'terms',
        description: formatMessage(messages.termsTag),
        href: termsAndConditionsUrl,
        underline: true,
      },
    ],
    [termsAndConditionsUrl, formatMessage],
  );

  return (
    <BodyText style={textPalettes[palette]} sizeMatch={['12', '22']}>
      <TextLinkifyTags
        palette={textLinkPalettes[palette]}
        sizeMatch={['12', '22']}
        links={links}
      >
        {formatMessage(messages.terms, { terms: '{terms}' })}
      </TextLinkifyTags>
    </BodyText>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  termsTag: {
    defaultMessage: 'Terms',
    description: 'Loyalty Home | Footer | subtitle',
  },
  terms: {
    defaultMessage: '*See full {terms} for more details',
    description: 'Loyalty Home | Footer | subtitle',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  lightLabel: {
    color: theme.colors.CREAM,
  },
  darkLabel: {
    color: theme.colors.DARK_KALE,
  },
});

const textPalettes = {
  light: styles.lightLabel,
  dark: styles.darkLabel,
};

const textLinkPalettes = {
  light: 'sweet-corn' as const,
  dark: 'kale' as const,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyTermsProps = {
  termsAndConditionsUrl: string;
  palette: 'dark' | 'light';
};
