/* eslint-disable functional/immutable-data */

import type { Cache } from '@urql/exchange-graphcache';

import type {
  CartDataFragment,
  SubmitOrderUsingPaymentMethodMutation,
} from '@order/graphql';
import { CartDocument } from '@order/graphql';

import { LastOrderDateDocument } from '../../../../../screens/HomeScreen/hooks/useHomeIsPostOrder/LastOrderDate.query.generated';

// ────────────────────────────────────────────────────────────────────────────────

export function updateCartCache(
  cache: Cache,
  responseWithCart: MutationResponseWithCart,
) {
  if (!responseWithCart.cart) return;

  cache.updateQuery({ query: CartDocument }, (data) => {
    const shouldUpdateCache = data && responseWithCart.cart;

    if (shouldUpdateCache) data.cart = responseWithCart.cart;

    return data;
  });
}

export function invalidateCartCacheEntry(
  cache: Cache,
  response: SubmitOrderUsingPaymentMethodMutation['submitOrderUsingPaymentMethod'],
) {
  const result = response?.__typename;
  const isSubmitted = result === 'SubmitOrderUsingPaymentMethodSuccess';

  if (isSubmitted) {
    cache.updateQuery({ query: CartDocument }, (data) => {
      if (data) data.cart = null;

      return data;
    });
  }
}

export function updateOrderPostSubmission(
  cache: Cache,
  response: SubmitOrderUsingPaymentMethodMutation['submitOrderUsingPaymentMethod'],
) {
  const result = response?.__typename;
  const isSubmitted = result === 'SubmitOrderUsingPaymentMethodSuccess';

  if (isSubmitted) {
    cache.updateQuery(
      {
        query: LastOrderDateDocument,
        variables: {
          input: {
            page: 1,
            take: 1,
          },
        },
      },
      (data) => {
        const lastOrderResponse = {
          __typename: 'OrdersResponseSuccess',
          orders: {
            __typename: 'OrdersResponseSuccess',
            orders: [response.order],
          },
        };

        data = lastOrderResponse;

        return data;
      },
    );
  }
}

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type MutationResponseWithCart = Readonly<{
  cart?: Partial<CartDataFragment>;
  __typename?: string;
}>;
