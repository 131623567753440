import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

export const CheckoutLayout = (props: ViewProps) => {
  const { children } = props;

  const { match } = useResponsive();
  const style = match([styles.layoutXS, undefined, styles.layoutLG]);

  return <View style={style}>{children}</View>;
};

export const CheckoutLayoutColumn = (props: CheckoutLayoutColumnProps) => {
  const { children, shouldFitToFill = false } = props;

  const { match } = useResponsive();
  const style = match([styles.columnXS, undefined, styles.columnLG]);

  return (
    <View style={[shouldFitToFill && styles.fitToFill, style]}>{children}</View>
  );
};

export const CheckoutLayoutBlock = ({ testID, children }: ViewProps) => {
  const { match } = useResponsive();
  const layoutBlockResponsiveStyle = match([
    styles.layoutBlockXS,
    styles.layoutBlockSM,
  ]);
  const layoutBlockInnerResponsiveStyle = match([
    styles.layoutBlockInnerResponsiveXS,
    styles.layoutBlockInnerResponsiveSM,
    styles.layoutBlockInnerResponsiveMD,
  ]);
  const layoutBlockInnerStyle = [
    styles.layoutBlockInner,
    layoutBlockInnerResponsiveStyle,
  ];

  return (
    <View testID={testID} style={layoutBlockResponsiveStyle}>
      <View style={layoutBlockInnerStyle}>{children}</View>
    </View>
  );
};

// ─── Type ───────────────────────────────────────────────────────────────────

type CheckoutLayoutColumnProps = ViewProps & {
  shouldFitToFill?: boolean;
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const GUTTER_SIZE = theme.spacing['3'];

const styles = StyleSheet.create({
  layoutXS: {
    flexGrow: 1,
    rowGap: theme.spacing['10'],
  },
  layoutLG: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -GUTTER_SIZE,
    marginLeft: -GUTTER_SIZE,
  },
  fitToFill: {
    flexGrow: 1,
  },
  columnXS: {
    rowGap: theme.spacing['10'],
  },
  columnLG: {
    flexBasis: '50%',
    paddingHorizontal: GUTTER_SIZE,
  },
  layoutBlockXS: {
    flexGrow: 1,
  },
  layoutBlockSM: {
    paddingBottom: theme.spacing['6'],
  },
  layoutBlockInnerResponsiveXS: {
    flexGrow: 1,
  },
  layoutBlockInner: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  layoutBlockInnerResponsiveSM: {
    padding: theme.spacing['10'],
    borderRadius: theme.radius.large,
  },
  layoutBlockInnerResponsiveMD: {
    padding: theme.spacing['10'],
    borderRadius: theme.radius.medium,
  },
});
