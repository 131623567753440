import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { type StyleProp, StyleSheet, View, type ViewStyle } from 'react-native';
import {
  BodyText,
  Button,
  DisplayText,
  FlappyBowl,
  theme,
  TitleText,
  useResponsive,
} from '@sg/garnish';

import { LoyaltyHomeChallengesRewardsContainer } from '../LoyaltyHomeChallengesRewardsContainer';

export const LoyaltyHomeChallengesEmptyState = (props: EmptyStateProps) => {
  const { isPremium, title, body, cta, style, onPress } = props;

  const { match, minWidth } = useResponsive();

  const containerStyle = [
    match([styles.containerXS, undefined]),
    match([isPremium ? styles.premiumContainer : null, undefined]),
  ];

  const titleStyle = [
    styles.regularWeight,
    match([
      isPremium ? styles.creamLabel : styles.kaleLabel,
      isPremium ? styles.creamLabel : styles.sweetCornLabel,
    ]),
  ];

  const bodyStyle = match([
    isPremium ? styles.creamLabel : styles.kaleLabel,
    isPremium ? styles.creamLabel : styles.sweetCornLabel,
  ]);

  return (
    <LoyaltyHomeChallengesRewardsContainer style={style}>
      <View style={containerStyle}>
        {minWidth.isSM ? (
          <DisplayText style={styles.title} sizeMatch={['24']}>
            <FormattedMessage {...messages.title} />
          </DisplayText>
        ) : null}

        <FlappyBowl style={styles.flappyBowl} />

        <View style={styles.infoContainer}>
          <TitleText style={titleStyle} sizeMatch={['24', '32']}>
            {title}
          </TitleText>

          <BodyText style={bodyStyle} sizeMatch={['16', '18']}>
            {body}
          </BodyText>
        </View>

        <Button
          size="large"
          style={match([styles.ctaXS, styles.ctaSM])}
          palette={match([isPremium ? 'sweetCorn' : 'primary', 'sweetCorn'])}
          onPress={onPress}
        >
          {cta}
        </Button>
      </View>
    </LoyaltyHomeChallengesRewardsContainer>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Offers',
    description: 'Loyalty | Home | Challenges | Title',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    backgroundColor: theme.colors.SWEET_CORN,
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['6'],
    borderRadius: theme.radius.large,
  },
  premiumContainer: {
    borderWidth: 1,
    borderColor: theme.colors.CREAM,
    backgroundColor: theme.colors.FOREST,
  },
  flappyBowl: {
    alignSelf: 'center',
  },
  regularWeight: {
    fontFamily: 'GrenettePro-Regular',
  },
  title: {
    color: theme.colors.CREAM,
  },
  creamLabel: {
    color: theme.colors.CREAM,
    textAlign: 'center',
  },
  kaleLabel: {
    color: theme.colors.KALE,
    textAlign: 'center',
  },
  sweetCornLabel: {
    color: theme.colors.SWEET_CORN,
    textAlign: 'center',
  },
  infoContainer: {
    gap: theme.spacing['2'],
  },
  ctaXS: {
    marginTop: theme.spacing['6'],
    alignSelf: 'center',
  },
  ctaSM: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 340,
    marginTop: theme.spacing['10'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type EmptyStateProps = {
  isPremium: boolean;
  title: string;
  body: string;
  cta: string;
  style: StyleProp<ViewStyle>;
  onPress: () => void;
};
