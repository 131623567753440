import { useIsLoggedIn } from '@order/AuthMachine';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useOrderStatusHasEarnedLoyaltyPointsQuery } from '../../graphql/OrderStatus.generated';

/**
 * Returns a boolean value for whether the customer has earned loyalty points for this order.
 */
export const useHasEarnedLoyaltyPoints = (orderId?: string) => {
  const isLoggedIn = useIsLoggedIn();
  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');

  const variables = { id: orderId ?? '' };
  const pause = !isLoggedIn || !isLoyaltyV2Enabled || !orderId;

  const [{ data }] = useOrderStatusHasEarnedLoyaltyPointsQuery({
    pause,
    variables,
  });

  if (data?.orderStatus?.__typename === 'OrderStatus') {
    return data.orderStatus.order.hasEarnedLoyaltyPoints;
  }

  return false;
};
