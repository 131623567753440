import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import type { usePressableState } from '../../hooks';
import { webOnlyStyles } from '../../utils';

// ─── STYLES ─────────────────────────────────────────────────────────────────────

export const generalStyles = StyleSheet.create({
  disabled: { color: theme.colors.OPACITY.DARK_KALE.LIGHT },
  active: { color: theme.colors.SPINACH },
});

// ─── PALETTES ───────────────────────────────────────────────────────────────────

export const paletteStyles: Record<
  TextLinkPalette,
  ReturnType<typeof StyleSheet.create>
> = {
  primary: StyleSheet.create({
    default: { color: theme.colors.TEXT_COLOR },
    hovered: { color: theme.colors.SPINACH },
    focused: { color: theme.colors.NEUTRAL_2 },
  }),

  primaryWithCautionHover: StyleSheet.create({
    default: { color: theme.colors.TEXT_COLOR },
    hovered: { color: theme.colors.CAUTION },
    focused: { color: theme.colors.CAUTION },
  }),

  kale: StyleSheet.create({
    default: { color: theme.colors.KALE },
    hovered: { color: theme.colors.KALE_HOVER },
    focused: { color: theme.colors.KALE_HOVER },
  }),

  'sweet-corn': StyleSheet.create({
    default: { color: theme.colors.SWEET_CORN },
    hovered: { color: theme.colors.SQUASH },
    focused: { color: theme.colors.SQUASH },
  }),

  white: StyleSheet.create({
    default: { color: theme.colors.WHITE },
    hovered: { color: theme.colors.SWEET_CORN },
    focused: { color: theme.colors.SWEET_CORN },
  }),

  caution: StyleSheet.create({
    default: { color: theme.colors.CAUTION },
    hovered: { color: theme.colors.OPACITY.CAUTION.DARK },
    focused: { color: theme.colors.OPACITY.CAUTION.DARK },
  }),
};

// ─── UTILS ──────────────────────────────────────────────────────────────────────

export const getConditionalStyles = (props: GetConditionalStyleProps) => {
  const { pressableState, palette, hasOnPress, active, disabled } = props;
  const { isFocused, isHovered } = pressableState;
  const paletteStyle = paletteStyles[palette];

  // ─────────────────────────────────────────────────────────────────

  if (disabled) return generalStyles.disabled;

  if (active) return generalStyles.active;

  // ─────────────────────────────────────────────────────────────────

  if (hasOnPress && isFocused) return paletteStyle.focused;

  if (hasOnPress && isHovered) {
    return [paletteStyle.hovered, webOnlyStyles({ cursor: 'pointer' })];
  }

  return paletteStyle.default;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type GetConditionalStyleProps = Readonly<{
  pressableState: ReturnType<typeof usePressableState>;
  palette: TextLinkPalette;
  hasOnPress: boolean;
  disabled?: boolean;
  active?: boolean;
}>;

export type TextLinkPalette =
  | 'primary'
  | 'sweet-corn'
  | 'primaryWithCautionHover'
  | 'kale'
  | 'caution'
  | 'white';
