import React from 'react';
import { StyleSheet } from 'react-native';
import { Footer } from '@expo/html-elements';
import { theme } from '@sg/garnish';

import { LoyaltyTerms } from '../../../LoyaltyTerms';

export const HowItWorksFooter = (props: HowItWorksFooterProps) => {
  return (
    <Footer style={styles.footer}>
      <LoyaltyTerms
        palette="dark"
        termsAndConditionsUrl={props.termsAndConditionsUrl}
      />
    </Footer>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  footer: {
    padding: theme.spacing[4],
    alignItems: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type HowItWorksFooterProps = {
  termsAndConditionsUrl: string;
};
