import React from 'react';
import { type StyleProp, StyleSheet, View, type ViewStyle } from 'react-native';
import { useResponsive } from '@sg/garnish';

import { LoyaltyOffers } from '@order/components';
import { Loyalty } from '@order/features/loyalty';
import { useLoyaltyContent } from '@order/hooks';

import { type useLoyaltyInfo } from '../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyHomeChallenges = (props: LoyaltyHomeChallengesProps) => {
  const { style, hasChallenges, isPremium, challenges, startOrder } = props;

  const { match } = useResponsive();

  const {
    homeChallengesEmptyStateTitle,
    homeChallengesEmptyStateBody,
    homeChallengesEmptyStateCtaXS,
    homeChallengesEmptyStateCtaSM,
  } = useLoyaltyContent();

  const homeChallengesEmptyStateCta = match([
    homeChallengesEmptyStateCtaXS,
    homeChallengesEmptyStateCtaSM,
  ]);

  if (hasChallenges) {
    return (
      <Loyalty.Home.Challenges.Container style={style}>
        <Loyalty.Home.Challenges.Rail>
          {challenges.map((offer) => (
            <View
              key={offer.id}
              style={match([undefined, styles.offerCardContainerSM])}
            >
              <LoyaltyOffers.Card
                offer={offer}
                referrer="sg-rewards"
                rounded={true}
                withBorder={isPremium}
                layout="compact"
              />
            </View>
          ))}
        </Loyalty.Home.Challenges.Rail>
      </Loyalty.Home.Challenges.Container>
    );
  }

  return (
    <Loyalty.Home.ChallengesEmptyState
      isPremium={isPremium}
      title={homeChallengesEmptyStateTitle}
      body={homeChallengesEmptyStateBody}
      cta={homeChallengesEmptyStateCta}
      style={style}
      onPress={startOrder}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  offerCardContainerSM: {
    width: 343,
    flexGrow: 1,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeChallengesProps = {
  style: StyleProp<ViewStyle>;
  isPremium: boolean;
  hasChallenges: boolean;
  challenges: ReturnType<typeof useLoyaltyInfo>['challenges'];
  startOrder: () => void;
};
