/* istanbul ignore file */

import { useCallback, useMemo } from 'react';
import { useMachine } from '@xstate/react';
import type { LocationObject } from 'expo-location';
import { useClient } from 'urql';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useLastInteractedStore } from '@order/LastInteractedStore';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { getEnvVars } from '@order/utils';

import type {
  LocationSearchMachineContext,
  LocationSearchMachineEvents,
  PositionCoordinates,
} from '../../machine';
import { createLocationSearchMachine } from '../../machine';
import { useLocationSearchNavigation } from '../useLocationSearchNavigation';
import { useLocationSearchTelemetry } from '../useLocationSearchTelemetry';

// ─────────────────────────────────────────────────────────────────────────────

export const useInitLocationSearchMachine = (
  props: UseInitLocationSearchMachine,
) => {
  const {
    minimalCharactersForSearch,
    shouldUseNewLocationsLayout,
    navigateToLocation,
    navigateToDeliveryLocation,
  } = props;

  const client = useClient();
  const lastInteractedStore = useLastInteractedStore();
  const locationSearchNavigation = useLocationSearchNavigation();
  const {
    trackUserGeolocation,
    trackLocationSearchType,
    trackLocationSearchByArea,
    trackLocationSearchByString,
    trackLocationSearchByDeliveryPlaceID,
    trackFocusedLocationPinId,
    trackNavigateToLocationMenu,
    trackNavigateToDeliveryLocationMenu,
  } = useLocationSearchTelemetry();
  const isSignedIn = useIsLoggedIn();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isDeliveryPriceDifferentiationEnabled = useFeatureFlag(
    'CELS-2439-enable-delivery-price-differentiation-disclosure',
  );
  const isOutpostPriceDifferentiationEnabled = useFeatureFlag(
    'CELS-2489-enable-outpost-price-differentiation-disclosure',
  );

  // ─────────────────────────────────────────────────────────────────────

  const lastInteractedStoreAddressId = lastInteractedStore?.addressId;

  // ─── Actions ─────────────────────────────────────────────────────────

  const onUserLocationChanged = useCallback(
    (_: LocationSearchMachineContext, event: UserLocationEvent) => {
      trackUserGeolocation(event.data);
    },
    [trackUserGeolocation],
  );

  const onCurrentPositionChanged = useCallback(
    (
      _: LocationSearchMachineContext,
      event: {
        data: { currentPositionCoordinates: PositionCoordinates | undefined };
      },
    ) => {
      if (!event.data?.currentPositionCoordinates) return;

      trackUserGeolocation({ coords: event.data.currentPositionCoordinates });
    },
    [trackUserGeolocation],
  );

  const onLocationTypeChangeEvent = useCallback(
    (context: LocationSearchMachineContext) => {
      trackLocationSearchType(context);
    },
    [trackLocationSearchType],
  );

  const onNavigateToLocationEvent = useCallback(
    (context: LocationSearchMachineContext, event: NavigateToLocationEvent) => {
      trackNavigateToLocationMenu(event?.entryPoint)(context);
    },
    [trackNavigateToLocationMenu],
  );

  const onNavigateToDeliveryLocationEvent = useCallback(
    (
      context: LocationSearchMachineContext,
      event: NavigateToDeliveryLocationEvent,
    ) => {
      trackNavigateToDeliveryLocationMenu(event?.entryPoint)(context);
    },
    [trackNavigateToDeliveryLocationMenu],
  );

  const onLocationWarningConfirmEvent = useMemo(
    () => trackNavigateToLocationMenu('location-warning'),
    [trackNavigateToLocationMenu],
  );

  // ─── Guards ──────────────────────────────────────────────────────────

  const checkIfSignedIn = useCallback(() => isSignedIn, [isSignedIn]);

  const checkIfUsingLegacyFlow = useCallback(
    () => !shouldUseNewLocationsLayout,
    [shouldUseNewLocationsLayout],
  );

  const checkIfShouldShowDeliveryAddressForm = useCallback(
    (context: LocationSearchMachineContext) =>
      !context.deliveryLocation?.addressId,
    [],
  );

  // ─── Initialize Machine ──────────────────────────────────────────────

  const locationSearchMachine = useMemo(() => {
    return createLocationSearchMachine({
      client,
      lastInteractedStoreAddressId,
      minimalCharactersForSearch,
      shouldUseCurrentPosition: true,
      googlePlacesApiKey: GOOGLE_API_KEY,
      shouldUseDeliveryDisclosureFields: isDeliveryPriceDifferentiationEnabled,
      shouldUseOutpostDisclosureFields: isOutpostPriceDifferentiationEnabled,
    });
  }, [
    client,
    isDeliveryPriceDifferentiationEnabled,
    isOutpostPriceDifferentiationEnabled,
    lastInteractedStoreAddressId,
    minimalCharactersForSearch,
  ]);

  return useMachine(locationSearchMachine, {
    actions: {
      navigateToLocation:
        navigateToLocation ?? locationSearchNavigation.navigateToLocation,
      navigateToDeliveryLocation:
        navigateToDeliveryLocation ??
        locationSearchNavigation.navigateToDeliveryLocation,

      // ─── Event Listeners ─────────────────────────────────────────────────

      onUserLocationChanged,
      onLocationTypeChangeEvent,
      onNavigateToLocationEvent,
      onNavigateToDeliveryLocationEvent,
      onLocationWarningConfirmEvent,
      onSearchByAreaEvent: trackLocationSearchByArea,
      onSearchByStringEvent: trackLocationSearchByString,
      onSearchByDeliveryPlaceIdEvent: trackLocationSearchByDeliveryPlaceID,
      onFocusedPinIdChangeEvent: trackFocusedLocationPinId,
      onCurrentPositionChanged,
    },
    guards: {
      checkIfSignedIn,
      checkIfUsingLegacyFlow,
      checkIfShouldShowDeliveryAddressForm,
    },
  });
};

// ─────────────────────────────────────────────────────────────────────────────

const { GOOGLE_API_KEY } = getEnvVars();

// ─── Types ───────────────────────────────────────────────────────────────────

type UseInitLocationSearchMachine = Readonly<{
  minimalCharactersForSearch: number;
  shouldUseNewLocationsLayout: boolean;

  /*
    Optional actions that initiates the navigation process for the selected location.
    When those properties are not provided, the standard navigation is used.

    Helpful when a custom navigation method is utilized or when other actions
    need to be performed before navigation.
   */

  navigateToLocation?: (context: LocationSearchMachineContext) => void;
  navigateToDeliveryLocation?: (context: LocationSearchMachineContext) => void;
}>;

type NavigateToLocationEvent = Extract<
  LocationSearchMachineEvents,
  Readonly<{ type: 'NAVIGATE_TO_LOCATION' }>
>;

type NavigateToDeliveryLocationEvent = Extract<
  LocationSearchMachineEvents,
  Readonly<{ type: 'NAVIGATE_TO_DELIVERY_LOCATION' }>
>;

type UserLocationEvent = Readonly<{
  data: LocationObject;
}>;
