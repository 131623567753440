/* eslint-disable functional/immutable-data */

import { getEnvVars } from '@order/utils';

import { onetrustStyles } from './styles';

// ─────────────────────────────────────────────────────────────────────────────

export function loadOneTrust() {
  // NOTE: If the `HideCookiesBanner` cookie is present, we should not load the
  //       OneTrust to prevent the banner from showing up.
  const shouldPreventLoading = document.cookie.includes('HideCookiesBanner');

  if (shouldPreventLoading) return;

  const styles = createOtStyles();
  const scripts = getOneTrustScripts();

  if (styles) {
    document.head.append(styles);
  }

  // eslint-disable-next-line unicorn/no-array-for-each
  scripts.forEach((script) => {
    document.body.append(script);
  });
}

// ─────────────────────────────────────────────────────────────────────────────

function getOneTrustScripts() {
  const otAutoBlockScript = createOtAutoBlockScript();
  const otSDKStubScript = createOtSDKStubScript();
  const otOptAnonWrapper = createOtOptAnonWrapper();

  return [otAutoBlockScript, otSDKStubScript, otOptAnonWrapper].filter(
    Boolean,
  ) as HTMLScriptElement[];
}

function createOtAutoBlockScript() {
  if (!ONE_TRUST_SCRIPT_DOMAIN_KEY) return;

  return createScriptIfDoesntExist({
    id: 'ot-auto-block-script',
    src: `https://cdn.cookielaw.org/consent/${ONE_TRUST_SCRIPT_DOMAIN_KEY}/OtAutoBlock.js`,
  });
}

function createOtSDKStubScript() {
  if (!ONE_TRUST_SCRIPT_DOMAIN_KEY) return;

  const script = createScriptIfDoesntExist({
    id: 'ot-sdk-stub-script',
    src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  });

  if (!script) return;

  script.dataset.domainScript = ONE_TRUST_SCRIPT_DOMAIN_KEY;

  return script;
}

function createOtOptAnonWrapper() {
  const script = createScriptIfDoesntExist({
    id: 'ot-opt-anon-wrapper-script',
  });
  const code = 'function OptanonWrapper() { }';

  if (!script) return;

  script.append(document.createTextNode(code));

  return script;
}

function createScriptIfDoesntExist(params: CreateScriptIfDoesntExistParams) {
  const { id, src } = params;

  const isScriptAlreadyAdded = checkIfScriptExists(id);

  if (isScriptAlreadyAdded) return;

  const script = document.createElement('script');

  script.id = id;

  if (src) {
    script.src = src;
  }

  return script;
}

function createOtStyles() {
  const id = 'ot-styles';
  const isAlreadyAdded = checkIfStyleExists(id);

  if (isAlreadyAdded) return;

  const style = document.createElement('style');

  style.id = id;
  style.textContent = onetrustStyles;

  return style;
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Checks if a script with the specified ID exists in the document.
 * Helpful for avoiding duplicated actions, which can cause HMR to fail.
 */
function checkIfScriptExists(id: string) {
  const maybeScript = document.querySelector(`script[id="${id}"]`);

  return maybeScript !== null;
}

/**
 * Checks if a style element with the specified ID exists in the document.
 * Helpful for avoiding duplicated actions, which can cause HMR to fail.
 */
function checkIfStyleExists(id: string) {
  const maybeStyle = document.querySelector(`style[id="${id}"]`);

  return maybeStyle !== null;
}

// ─── Constants ───────────────────────────────────────────────────────────────

const { ONE_TRUST_SCRIPT_DOMAIN_KEY } = getEnvVars();

// ─── Types ───────────────────────────────────────────────────────────────────

type CreateScriptIfDoesntExistParams = { id: string; src?: string };
