/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoyaltyPremiumStatusQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type LoyaltyPremiumStatusQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyProfile: {
    readonly __typename?: 'LoyaltyProfile';
    readonly customerId: string;
    readonly rewardsPremiumStatus: {
      readonly __typename?: 'RewardsPremiumStatus';
      readonly expirationDate: string;
      readonly isActive: boolean;
    } | null;
  } | null;
};

export const LoyaltyPremiumStatusDocument = gql`
  query LoyaltyPremiumStatus {
    loyaltyProfile {
      customerId
      rewardsPremiumStatus {
        expirationDate
        isActive
      }
    }
  }
`;

export function useLoyaltyPremiumStatusQuery(
  options?: Omit<
    Urql.UseQueryArgs<LoyaltyPremiumStatusQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LoyaltyPremiumStatusQuery,
    LoyaltyPremiumStatusQueryVariables
  >({ query: LoyaltyPremiumStatusDocument, ...options });
}
