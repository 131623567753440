import React, { type PropsWithChildren } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { Button, Modal, theme } from '@sg/garnish';

export const ScanAtCheckoutRewardOptionsModal = (
  props: ScanAtCheckoutRewardOptionsModalProps,
) => {
  const { children, isVisible, shouldShowCta, onPressButton, onRequestClose } =
    props;

  return (
    <Modal
      size="medium"
      visible={isVisible}
      onRequestClose={onRequestClose}
      withSafeAreaView
      palette="neutral"
    >
      <Modal.CloseBtn
        palette="muted"
        onPress={onRequestClose}
        width={40}
        height={40}
      />

      {children}

      {shouldShowCta ? (
        <Modal.Footer withoutTopBorder style={styles.container}>
          <Button
            palette="primaryOutline"
            onPress={onPressButton}
            style={styles.cta}
          >
            <FormattedMessage {...messages.buttonText} />
          </Button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  buttonText: {
    defaultMessage: 'Go to SgRewards',
    description: 'Scan at checkout | Loyalty info | Button text',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  cta: {
    paddingHorizontal: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanAtCheckoutRewardOptionsModalProps = {
  children: PropsWithChildren['children'];
  isVisible: boolean;
  shouldShowCta: boolean;
  onPressButton: () => void;
  onRequestClose: () => void;
};
