import React from 'react';
import { useResponsive } from '@sg/garnish';

import { useIsLoggedIn, useIsLoggingIn } from '@order/AuthMachine';
import {
  ChallengeCompletionModal,
  LoadingAnimation,
  NotFoundView,
  SignedOutView,
} from '@order/components';
import { useDeliveryOrderInFlight } from '@order/hooks';

import { useOrderStatusData } from './hooks';
import { OrderStatusCTAs } from './OrderStatusCTAs';
import { OrderStatusInfo } from './OrderStatusInfo';
import { OrderStatusLayout } from './OrderStatusLayout';
import { OrderStatusMap } from './OrderStatusMap';

export const OrderStatusScreen = () => {
  const isLoggedIn = useIsLoggedIn();
  const isLoggingIn = useIsLoggingIn();

  // ─────────────────────────────────────────────────────────────────

  if (isLoggingIn) return <LoadingAnimation />;

  if (!isLoggedIn) return <SignedOutView />;

  return <OrderStatusScreenSignedIn />;
};

const OrderStatusScreenSignedIn = () => {
  const data = useOrderStatusData();

  useDeliveryOrderInFlight(); // results later used from cache.

  const { isInvalidOrderStatus } = data;

  if (isInvalidOrderStatus) return <NotFoundView />;

  return <OrderStatusScreenReady {...data} />;
};

const OrderStatusScreenReady = (
  props: ReturnType<typeof useOrderStatusData>,
) => {
  const {
    isRenderedOnScreen,
    isFetching,
    deliveryAddressName,
    deliveryAddressStreet,
    deliveryAddressSecondaryStreet,
    deliveryAddressCity,
    deliveryAddressState,
    deliveryAddressZipCode,
    deliveryAddressNotes,
    deliveryAddressType,
    deliveryAddressLatitude,
    deliveryAddressLongitude,
    deliveryEstimatedTime,
    deliveryPreference,
    locationName,
    locationAddress,
    locationCity,
    locationState,
    locationZipCode,
    locationLatitude,
    locationLongitude,
    dropoffLocation,
    orderId,
    orderType,
    orderStatus,
    orderTotal,
    orderWantedTime,
    orderLedger,
    orderLineItems,
    isLate,
    isPast,
    isPendingFeedback = false,
    isTrackable = false,
    orderToHealthKitLineItems,
    isOrderToHealthKitEnabled,
    vendorId,
    canCancel,
    customerName,
    customerLatitude,
    customerLongitude,
    courierName,
    courierPhone,
    courierLatitude,
    courierLongitude,
    celebration,
    restaurantId,
    shouldRenderSweetpassUpsellCta,
    onReorder,
    refetchOrderStatus,
  } = props;

  const { currentBreakpoint } = useResponsive();

  if (isFetching) return <LoadingAnimation />;

  const isOrderCancelled = orderStatus === 'failed';
  const canReorder = isOrderCancelled || isPast;
  const shouldRenderOrderStatusCTAs = canReorder && currentBreakpoint.isXS;

  return (
    <>
      <OrderStatusLayout
        orderId={orderId}
        orderType={orderType}
        orderStatus={orderStatus}
        orderWantedTime={orderWantedTime}
        orderToHealthKitLineItems={orderToHealthKitLineItems}
        isOrderToHealthKitEnabled={isOrderToHealthKitEnabled}
        deliveryAddressName={deliveryAddressName}
        deliveryAddressStreet={deliveryAddressStreet}
        deliveryAddressSecondaryStreet={deliveryAddressSecondaryStreet}
        deliveryAddressCity={deliveryAddressCity}
        deliveryAddressState={deliveryAddressState}
        deliveryAddressZipCode={deliveryAddressZipCode}
        deliveryAddressNotes={deliveryAddressNotes}
        deliveryPreference={deliveryPreference}
        locationName={locationName}
        locationAddress={locationAddress}
        locationCity={locationCity}
        locationState={locationState}
        locationZipCode={locationZipCode}
        dropoffLocation={dropoffLocation}
        ledger={orderLedger}
        lineItems={orderLineItems}
        courierName={courierName}
        courierPhone={courierPhone}
        isLate={isLate}
        shouldRenderSweetpassUpsellCta={shouldRenderSweetpassUpsellCta}
        restaurantId={restaurantId}
        canCancel={canCancel}
        refetchOrderStatus={refetchOrderStatus}
        info={
          <OrderStatusInfo
            orderId={orderId}
            vendorId={vendorId}
            orderType={orderType}
            orderStatus={orderStatus}
            wantedTime={orderWantedTime}
            estimatedDeliveryTime={deliveryEstimatedTime}
            orderTotal={orderTotal}
            locationName={locationName}
            locationAddress={locationAddress}
            isTrackable={isTrackable}
            isPendingFeedback={isPendingFeedback}
            isPast={isPast}
            onReorder={onReorder}
          />
        }
        footer={
          shouldRenderOrderStatusCTAs ? (
            <OrderStatusCTAs
              orderId={orderId}
              orderTotal={orderTotal}
              isPendingFeedback={isPendingFeedback}
              onReorder={onReorder}
            />
          ) : null
        }
      >
        <OrderStatusMap
          orderType={orderType}
          orderStatus={orderStatus}
          locationName={locationName}
          locationLatitude={locationLatitude}
          locationLongitude={locationLongitude}
          customerName={customerName}
          customerLatitude={customerLatitude}
          customerLongitude={customerLongitude}
          deliveryAddressName={deliveryAddressName}
          deliveryAddressType={deliveryAddressType}
          deliveryAddressLatitude={deliveryAddressLatitude}
          deliveryAddressLongitude={deliveryAddressLongitude}
          courierName={courierName}
          courierLatitude={courierLatitude}
          courierLongitude={courierLongitude}
        />
      </OrderStatusLayout>

      {celebration && isRenderedOnScreen ? (
        <ChallengeCompletionModal challengeCompletionDetails={celebration} />
      ) : null}
    </>
  );
};
