import { useCallback, useMemo, useState } from 'react';

/**
 * Counts how many calls the {callback} has.
 * Once it has reached {limit}, returns { hasReachedLimit: true }.
 */
export const useCallbackWithLimit = (props: UseCallbackWithLimitProps) => {
  const { limit, callback } = props;

  // ─── State ────────────────────────────────────────────────────────────────

  const [calls, setCalls] = useState(0);
  const hasReachedLimit = useMemo(() => calls === limit, [calls, limit]);

  // ─── Callback ─────────────────────────────────────────────────────────────

  const callbackWithLimit = useCallback(() => {
    if (hasReachedLimit) return;
    setCalls((currentCalls) => currentCalls + 1);
    callback();
  }, [hasReachedLimit, callback]);

  return {
    hasReachedLimit,
    callbackWithLimit,
  };
};

// ─── Types ──────────────────────────────────────────────────────────────────

type UseCallbackWithLimitProps = {
  limit: number;
  callback: () => void;
};
