import React, { useEffect, useRef } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useHover } from 'react-native-web-hooks';
import { theme } from '@garnish/constants';

import { useFluidSize, useResponsive } from '../../../../hooks';
import { webOnlyStyles } from '../../../../utils';
import { ListItem } from '../../../List';
import { DisplayText } from '../../../Text';
import { PICKER_OPTION_HEIGHT } from '../Picker.constants';
import type { PickerOptionProps } from '../Picker.types';

export const PickerOption = (props: PickerOptionProps) => {
  const {
    index,
    activeOptionIndex,
    autofocus,
    label,
    swipedDistance,
    accessibilityLabel,
    accessibilityDescribedBy,
    selectOptionByIndex,
    transition = 'none',
  } = props;

  const { match } = useResponsive();

  const optionRef = useRef<View>(null);
  const isOptionHovered = useHover(optionRef);

  const isActiveOption = activeOptionIndex === index;
  const optionLayoutStartCoordinate = index * PICKER_OPTION_HEIGHT;

  // ────────────────────────────────────────────────────────────────────

  const optionStyle = [
    styles.option,
    webOnlyStyles({
      outlineColor: theme.colors.OPACITY.DARK_KALE.LIGHT,
      cursor: 'pointer',
    }),
  ];

  const optionTextSize = [
    styles.optionText,
    { fontSize: useFluidSize()(20, 22) },
  ];

  const animatedStyle = useAnimatedStyle(() => {
    if (transition === 'none') return {};

    const normalizedSwipedDistance = Math.abs(swipedDistance.value);
    const distanceDifference = Math.abs(
      normalizedSwipedDistance - optionLayoutStartCoordinate,
    );
    const visibleOptionsStartCoordinates = [
      0,
      PICKER_OPTION_HEIGHT,
      PICKER_OPTION_HEIGHT * 2,
    ];

    const opacity = interpolate(
      distanceDifference,
      visibleOptionsStartCoordinates,
      [1, 0.5, 0.3],
    );
    const scale = interpolate(
      distanceDifference,
      visibleOptionsStartCoordinates,
      [1, 0.9, 0.85],
    );

    return { opacity, transform: [{ scale }] };
  });

  const optionPressableStyle = [
    match([
      undefined,
      [
        styles.optionSM,
        isOptionHovered ? styles.optionHover : undefined,
        isActiveOption ? styles.optionActive : undefined,
      ],
    ]),
    webOnlyStyles({
      outlineColor: theme.colors.OPACITY.DARK_KALE.LIGHT,
      outlineOffset: -1,
    }),
  ];

  const optionInnerStyle = [
    animatedStyle,

    // NOTE: Improves animation performance on web
    transition === 'scale-and-fade'
      ? webOnlyStyles({ willChange: 'opacity, transform' })
      : undefined,
  ];

  // ─── HELPERS ────────────────────────────────────────────────────────

  const handleOptionChange = () => {
    selectOptionByIndex(index);
  };

  // ─── EFFECTS ────────────────────────────────────────────────────────

  // focus selected option element
  useEffect(() => {
    if (!isActiveOption || !autofocus) return;
    optionRef.current?.focus?.();
  }, [autofocus, isActiveOption]);

  // ────────────────────────────────────────────────────────────────────

  return (
    <ListItem>
      <Pressable
        ref={optionRef}
        style={optionPressableStyle}
        focusable={isActiveOption}
        onPress={handleOptionChange}
        accessibilityRole="button"
        accessibilityLabel={`${accessibilityLabel}, ${label}`}
        accessibilityState={{ selected: isActiveOption }}
        {...{
          accessibilityDescribedBy: accessibilityLabel
            ? undefined
            : accessibilityDescribedBy,
        }}
      >
        <View style={optionStyle}>
          <Animated.View style={optionInnerStyle}>
            <DisplayText size={4} style={optionTextSize} numberOfLines={1}>
              {label}
            </DisplayText>
          </Animated.View>
        </View>
      </Pressable>
    </ListItem>
  );
};

// ─── Styles ───────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  option: {
    height: PICKER_OPTION_HEIGHT,
    justifyContent: 'center',
    paddingHorizontal: theme.spacing['2'],
  },
  optionSM: {
    borderRadius: theme.radius.large,
    marginHorizontal: theme.spacing['2'],
  },
  optionActive: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTEST,
  },
  optionHover: {
    backgroundColor: theme.colors.OPACITY.KALE.ALMOST_TRANSPARENT,
  },
  optionText: {
    textAlign: 'center',
  },
});
