import type { BrazeInAppMessagePayload } from '@order/Braze';
import { LOYALTY_ONBOARDING_MODAL } from '@order/constants';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Determines if the given in-app message corresponds to the loyalty
 * onboarding modal.
 */
export function checkIfOnboardingModal(payload?: BrazeInAppMessagePayload) {
  const { MODAL_ID, MODAL_ID_KEY } = LOYALTY_ONBOARDING_MODAL;

  return payload?.extras?.[MODAL_ID_KEY] === MODAL_ID;
}
