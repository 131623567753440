import { useIsFocused } from '@react-navigation/native';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useLoyaltyPointsQuery } from './GraphQL/LoyaltyPoints.generated';

/**
 * Returns the available points for loyalty, when the customer is logged in and the feature flag is enabled.
 */
export const useLoyaltyAvailablePoints = () => {
  const isLoggedIn = useIsLoggedIn();
  const isFocused = useIsFocused();
  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');
  const shouldQueryPoints = isLoggedIn && isLoyaltyV2Enabled && isFocused;

  const [response] = useLoyaltyPointsQuery({
    requestPolicy: 'cache-and-network',
    pause: !shouldQueryPoints,
  });

  return response?.data?.loyaltyProfile?.points?.available ?? 0;
};
