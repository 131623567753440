import {
  interpolateColor,
  type SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { CUSTOM_NAVIGATION_HEADER_HEIGHT } from '../CustomNavigationHeader.constants';
import { type CustomNavigationHeaderPalette } from '../CustomNavigationHeader.types';

// ─────────────────────────────────────────────────────────────────────────────
/**
 * Will animate the header border color as the customer scrolls the view.
 */
export const useBorderAnimation = (props: AnimationProps) => {
  const { scrollOffsetSV, palette = 'default' } = props;

  return useAnimatedStyle(
    () => ({
      borderBottomWidth: 1,
      borderBottomColor: interpolateColor(
        scrollOffsetSV?.value ?? 0,
        [0, CUSTOM_NAVIGATION_HEADER_HEIGHT],
        borderPalettes[palette],
      ),
    }),
    [scrollOffsetSV, palette],
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const borderPalettes: Record<CustomNavigationHeaderPalette, string[]> = {
  default: [theme.colors.OPACITY.DARK_KALE.TRANSPARENT, theme.colors.DARK_KALE],
  oatmeal: [theme.colors.OPACITY.DARK_KALE.TRANSPARENT, theme.colors.DARK_KALE],
  cream: [theme.colors.OPACITY.DARK_KALE.TRANSPARENT, theme.colors.DARK_KALE],
  'dark-kale': [theme.colors.OPACITY.CREAM.TRANSPARENT, theme.colors.CREAM],
};

// ─── Types ───────────────────────────────────────────────────────────────────

type AnimationProps = {
  scrollOffsetSV?: SharedValue<number>;
  palette?: CustomNavigationHeaderPalette;
};
