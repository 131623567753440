import { useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { TabItem, TabItemId, TabItems } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const useScanInStoreTabs = (shouldStartInPayTab: boolean) => {
  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const earnAndRedeemTab = useMemo<TabItem>(
    () => ({
      id: 'scan-in-store.earn-and-redeem-tab',
      panelId: 'scan-in-store.earn-and-redeem-tab-panel',
      label: formatMessage(messages.earnRedeemTitle),
    }),
    [formatMessage],
  );
  const payTab = useMemo<TabItem>(
    () => ({
      id: 'scan-in-store.pay-tab',
      panelId: 'scan-in-store.pay-tab-panel',
      label: formatMessage(messages.payTitle),
    }),
    [formatMessage],
  );

  const tabs = useMemo<TabItems>(
    () => [earnAndRedeemTab, payTab],
    [earnAndRedeemTab, payTab],
  );

  const [activeTabItemId, setActiveTabItemId] = useState<TabItemId>(
    shouldStartInPayTab ? payTab.id : earnAndRedeemTab.id,
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    activeTabItemId,
    earnAndRedeemTab,
    payTab,
    tabs,
    setActiveTabItemId,
  };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  earnRedeemTitle: {
    defaultMessage: 'Earn + Redeem',
    description: 'Earn + Redeem | Loyalty Rewards | Tab Title',
  },
  payTitle: {
    defaultMessage: 'Pay',
    description: 'Pay | Loyalty Rewards | Tab Title',
  },
});
