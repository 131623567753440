import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type ImageSourcePropType } from 'react-native';
import { type Entry } from 'contentful';

import { SG_REWARDS_TERMS_AND_CONDITIONS } from '@order/constants';
import { useContentfulContentTypeEntry } from '@order/Contentful';

import heroImageFallback from '../assets/hero-image-fallback.png';
import heroLogoFallback from '../assets/hero-logo-fallback.png';
import lockIconFallback from '../assets/lock-icon-fallback.png';
import sgRewardsIconFallback from '../assets/sgrewards-icon-fallback.png';
import trophyIconFallback from '../assets/trophy-icon-fallback.png';
import { IMAGE_IDS } from '../constants';

export const useJoinSgRewardsContent = () => {
  const { formatMessage } = useIntl();

  const content = useContentfulContentTypeEntry<LoyaltyContainer>({
    contentType: 'loyalty',
  });

  const termsAndConditionsUrl =
    content.data?.fields?.termsAndConditionsUrl ??
    SG_REWARDS_TERMS_AND_CONDITIONS;

  const fallbackData: {
    heroSection: HeroSection;
    lineItems: LineItem[];
    termsAndConditionsUrl: string;
  } = useMemo(() => {
    const heroSection = {
      heroImage: {
        id: 'Opted Out | Hero Image + Screen Title',
        title: formatMessage(fallbacks.screenTitle),
        image: {
          url: '',
          fallbackSource: heroImageFallback as ImageSourcePropType,
          description: 'Hero image',
        },
      },
      heroLogo: {
        id: 'Opted Out | Hero Logo | Sg Rewards',
        image: {
          url: '',
          fallbackSource: heroLogoFallback as ImageSourcePropType,
          description: 'Hero logo',
        },
      },
    };

    const lineItems = [
      {
        id: 'Opted Out | Line Item | Earn points',
        title: formatMessage(fallbacks.earnPoints),
        description: formatMessage(fallbacks.earnPointsDescription),
        image: {
          url: '',
          fallbackSource: sgRewardsIconFallback as ImageSourcePropType,
          description: '',
        },
      },
      {
        id: 'Opted Out | Line Item | Redeem rewards',
        title: formatMessage(fallbacks.redeemRewards),
        description: formatMessage(fallbacks.redeemRewardsDescription),
        image: {
          url: '',
          fallbackSource: trophyIconFallback as ImageSourcePropType,
          description: '',
        },
      },
      {
        id: 'Opted Out | Line Item | Unlock perks',
        title: formatMessage(fallbacks.unlockPerks),
        description: formatMessage(fallbacks.unlockPerksDescription),
        image: {
          url: '',
          fallbackSource: lockIconFallback as ImageSourcePropType,
          description: '',
        },
      },
    ];

    return { heroSection, lineItems, termsAndConditionsUrl };
  }, [formatMessage, termsAndConditionsUrl]);

  const contentfulData = content.data?.fields?.optedOut;

  const optedOutContent = useMemo(() => {
    if (contentfulData?.length) {
      const transformedData = contentfulData.map((item) => ({
        ...item.fields,
        title: item?.fields?.title,
        image: {
          url: item.fields?.image?.fields?.file?.url,
          description: item.fields?.image?.fields?.description,
        },
      }));

      const heroImage = transformedData.find((item) =>
        item.id?.includes(IMAGE_IDS.HERO_IMAGE),
      );

      const heroLogo = transformedData.find((item) =>
        item.id?.includes(IMAGE_IDS.HERO_LOGO),
      );

      const lineItems = transformedData.filter((item) =>
        item.id?.includes(IMAGE_IDS.LINE_ITEM),
      );

      return {
        heroSection: {
          heroImage,
          heroLogo,
        },
        lineItems,
        termsAndConditionsUrl,
      };
    }

    return fallbackData;
  }, [contentfulData, fallbackData, termsAndConditionsUrl]);

  return optedOutContent;
};

// ─── Fallbacks ──────────────────────────────────────────────────────────────

const fallbacks = defineMessages({
  screenTitle: {
    defaultMessage: 'The easiest way to earn free Sweetgreen.',
    description:
      'SG Rewards | Join screen | Opted Out | Hero Image + Screen Title',
  },
  earnPoints: {
    defaultMessage: 'Earn points',
    description: 'SG Rewards | Join screen | Opted Out | Earn Points',
  },
  earnPointsDescription: {
    defaultMessage:
      'Scan your QR code at checkout to earn 10 points for every $1 spent',
    description:
      'SG Rewards | Join screen | Opted Out | Earn Points description',
  },
  redeemRewards: {
    defaultMessage: 'Redeem rewards',
    description: 'SG Rewards | Join screen | Opted Out | Redeem rewards',
  },
  redeemRewardsDescription: {
    defaultMessage: 'Use points to unlock rewards and free Sweetgreen',
    description:
      'SG Rewards | Join screen | Opted Out | Redeem rewards description',
  },
  unlockPerks: {
    defaultMessage: 'Look out for offers',
    description: 'SG Rewards | Join screen | Opted Out | Look out for offers',
  },
  unlockPerksDescription: {
    defaultMessage:
      'Look out for offers to earn more points and special rewards',
    description: 'SG Rewards | Join screen | Opted Out | Look out for offers',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyContainer = {
  optedOut: Entry<ContentfulOptedOutItem>[];
  termsAndConditionsUrl: string;
};

type ContentfulOptedOutItem = {
  id: string;
  title: string;
  description: string;
  image: {
    fields: {
      description: string;
      file: {
        url: string;
      };
    };
  };
};

export type Image = {
  fallbackSource?: ImageSourcePropType;
  url?: string;
  description: string;
};

type HeroSection = {
  heroImage: {
    id: string;
    title: string;
    image: Image;
  };
  heroLogo: {
    id: string;
    image: Image;
  };
};

type LineItem = {
  id: string;
  title: string;
  description: string;
  image: Image;
};
