import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { FadeView } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { FloatingButtons } from '@order/components';
import { Home } from '@order/features/home';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';

import { type useHomeContent, useHomeNavigation } from '../../hooks';
import { HomeLoyaltyButton } from '../HomeLoyaltyButton';
import { HomeReorderButton } from '../HomeReorderButton';
import { HomeScreenBanner } from '../HomeScreenBanner';

export const HomeScreenContentXS = (props: HomeScreenContentProps) => {
  const { homeContent, bagPressed, ctaPressed } = props;

  const isLoggedIn = useIsLoggedIn();
  const navigation = useNavigation();

  const isReorderModalEnabled = useFeatureFlag(
    'CELS-2534-reorder-modal-enabled',
    { listenForChanges: true },
  );
  const { track } = useTelemetry();

  const { navigateToAccount, navigateToLoyalty } = useHomeNavigation();

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRenderReorderButton = isReorderModalEnabled && isLoggedIn;

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');

  const shouldRenderLeftFloatingButton =
    shouldRenderReorderButton || isLoyaltyV2Enabled;

  // Account button shouldn't be rendered if there is an account button on the navbar.
  const shouldRenderAccountButton = isInAppGiftCardEnabled;

  const shouldRenderScanButton = isLoggedIn && isLoyaltyV2Enabled;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onReorderPress = useCallback(() => {
    navigation.navigate('Modal', { screen: 'Reorder' });
    track('home.reorder.cta_tapped');
  }, [navigation, track]);

  const onScanPress = useCallback(() => {
    navigation.navigate('Modal', { screen: 'ScanAtCheckout' });
    track('home.scan.cta_tapped');
  }, [navigation, track]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <Home.ContainerXS>
        <Home.HeroImageXS
          imageUrl={homeContent.mainImageXS}
          imageA11yLabel={homeContent.mainImageA11yXS}
        >
          <Home.ContentWrapperXS>
            <Home.HeaderXS
              onPressAccount={
                shouldRenderAccountButton ? navigateToAccount : undefined
              }
              onPressScan={shouldRenderScanButton ? onScanPress : undefined}
            >
              <Home.EyebrowText
                text={homeContent.eyebrowText}
                fontColor={homeContent.eyebrowTextColorXS}
              />

              <Home.HeroText
                text={homeContent.heroText}
                fontColor={homeContent.heroTextColorXS}
              />

              <Home.HeaderCta
                accessibilityLabel={homeContent.ctaText}
                deepLink={homeContent.ctaDeepLink}
                onPressCta={ctaPressed}
              >
                {homeContent.ctaText}
              </Home.HeaderCta>
            </Home.HeaderXS>
          </Home.ContentWrapperXS>
        </Home.HeroImageXS>
      </Home.ContainerXS>

      <FloatingButtons.Container anchor="full">
        <Home.FloatingButtons>
          <FadeView show={shouldRenderLeftFloatingButton} keepMounted>
            {isLoyaltyV2Enabled ? (
              <HomeLoyaltyButton
                fallbackMessage={homeContent.ctaLoyalty}
                onPress={navigateToLoyalty}
              />
            ) : (
              <HomeReorderButton onPress={onReorderPress} />
            )}
          </FadeView>

          <FloatingButtons.Bag
            showMessage={!isLoyaltyV2Enabled}
            onPress={bagPressed}
          />
        </Home.FloatingButtons>

        <HomeScreenBanner />
      </FloatingButtons.Container>
    </>
  );
};

type HomeScreenContentProps = {
  homeContent: ReturnType<typeof useHomeContent>['homeContent'];
  ctaPressed: () => void;
  bagPressed: () => void;
};
