import { format } from 'date-fns';
import { FlattenedOrderStatuses } from '@sg/graphql-schema';

import { OrderType } from '@order/graphql';

import { type useHomeBannerContent } from '../../hooks';
import { type useHomeNavigation } from '../useHomeNavigation';

// ─── Home Banners ───────────────────────────────────────────────────────────

export const getLoggedOutXsBanner = (
  bannerContent: ReturnType<typeof useHomeBannerContent>,
  navigationOptions: ReturnType<typeof useHomeNavigation>,
) => {
  return {
    message: bannerContent.bannerSignUpMessage,
    cta: bannerContent.bannerSignUpCta,
    onPress: navigationOptions.navigateToSignIn,
  };
};

export const getPendingFeedbackBanner = (
  bannerContent: ReturnType<typeof useHomeBannerContent>,
  navigationOptions: ReturnType<typeof useHomeNavigation>,
) => {
  return {
    message: bannerContent.bannerRateMessage,
    cta: bannerContent.bannerRateCta,
    onPress: navigationOptions.navigateToRateOrder,
  };
};

export const getLateOrderBanner = (
  bannerContent: ReturnType<typeof useHomeBannerContent>,
  navigationOptions: ReturnType<typeof useHomeNavigation>,
) => {
  return {
    ...getSharedOrderBannerInfo(bannerContent, navigationOptions),
    message: bannerContent.bannerOrderLateMessage,
  };
};

export const getOrderNotTrackableBanner = (
  order: BannerOrder,
  bannerContent: ReturnType<typeof useHomeBannerContent>,
  navigationOptions: ReturnType<typeof useHomeNavigation>,
) => {
  const timeToInterpolate = getTimeToInterpolate(order);

  return {
    ...getSharedOrderBannerInfo(bannerContent, navigationOptions),
    message: bannerContent.bannerOrderNotTrackableMessage.replaceAll(
      '#eta',
      timeToInterpolate,
    ),
  };
};

export const getActiveOrderBanner = (
  order: BannerOrder,
  bannerContent: ReturnType<typeof useHomeBannerContent>,
  navigationOptions: ReturnType<typeof useHomeNavigation>,
) => {
  const timeToInterpolate = getTimeToInterpolate(order);
  const messageToInterpolate = getBannerMessageForStatus(
    bannerContent,
    order.orderStatus,
  );

  return {
    ...getSharedOrderBannerInfo(bannerContent, navigationOptions),
    message: messageToInterpolate.replaceAll('#eta', timeToInterpolate),
  };
};

export const getUnseenChallengesBanner = (
  bannerContent: ReturnType<typeof useHomeBannerContent>,
  navigationOptions: ReturnType<typeof useHomeNavigation>,
) => {
  return {
    message: bannerContent.bannerChallengeMessage,
    cta: bannerContent.bannerChallengeCta,
    onPress: navigationOptions.navigateToChallenges,
  };
};

export const getPromoBanner = (
  bannerPromoMessage: string,
  bannerPromoCta: string,
  bannerPromoDeepLink: string,
  navigateWithDeeplink: () => void,
) => {
  return {
    message: bannerPromoMessage,
    cta: bannerPromoCta,
    deepLink: bannerPromoDeepLink,
    onPress: navigateWithDeeplink,
  };
};

// ─── Helpers ────────────────────────────────────────────────────────────────

const getSharedOrderBannerInfo = (
  bannerContent: ReturnType<typeof useHomeBannerContent>,
  navigationOptions: ReturnType<typeof useHomeNavigation>,
) => {
  return {
    cta: bannerContent.bannerOrderCta,
    onPress: navigationOptions.navigateToOrderStatus,
  };
};

const getTimeToInterpolate = (order: BannerOrder) => {
  const { orderTime, orderType, startTime, endTime } = order;

  return orderType === OrderType.Delivery
    ? `${startTime} - ${endTime}`
    : format(orderTime, 'h:mmaaa').toLowerCase();
};

const getBannerMessageForStatus = (
  bannerContent: ReturnType<typeof useHomeBannerContent>,
  orderStatus: FlattenedOrderStatuses,
) => {
  switch (orderStatus) {
    case FlattenedOrderStatuses.Received: {
      return bannerContent.bannerOrderReceivedMessage;
    }

    case FlattenedOrderStatuses.Preparing: {
      return bannerContent.bannerOrderPreparingMessage;
    }

    case FlattenedOrderStatuses.ReadyForCourier: {
      return bannerContent.bannerOrderReadyForCourierMessage;
    }

    case FlattenedOrderStatuses.Delivering: {
      return bannerContent.bannerOrderDeliveringMessage;
    }

    case FlattenedOrderStatuses.Completed: {
      return bannerContent.bannerOrderCompletedMessage;
    }

    default: {
      return bannerContent.bannerOrderCancelledMessage;
    }
  }
};

// ─── Types ──────────────────────────────────────────────────────────────────

export type BannerOrder = {
  orderTime: Date;
  startTime: string;
  endTime: string;
  orderType: OrderType;
  orderStatus: FlattenedOrderStatuses;
};
