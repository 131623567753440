import { LoyaltyPostPurchaseBody } from './LoyaltyPostPurchaseBody';
import { LoyaltyPostPurchaseContainer } from './LoyaltyPostPurchaseContainer';
import { LoyaltyPostPurchaseCTA } from './LoyaltyPostPurchaseCTA';
import { LoyaltyPostPurchaseHero } from './LoyaltyPostPurchaseHero';
import { LoyaltyPostPurchaseLink } from './LoyaltyPostPurchaseLink';
import { LoyaltyPostPurchaseSubtitle } from './LoyaltyPostPurchaseSubtitle';
import { LoyaltyPostPurchaseTitle } from './LoyaltyPostPurchaseTitle';

export const LoyaltyPostPurchase = {
  Body: LoyaltyPostPurchaseBody,
  CTA: LoyaltyPostPurchaseCTA,
  Hero: LoyaltyPostPurchaseHero,
  Container: LoyaltyPostPurchaseContainer,
  Subtitle: LoyaltyPostPurchaseSubtitle,
  TextLink: LoyaltyPostPurchaseLink,
  Title: LoyaltyPostPurchaseTitle,
};
