/* eslint-disable @typescript-eslint/consistent-type-assertions */

import AsyncStorage from '@react-native-async-storage/async-storage';
import { assertEvent, assign, setup } from 'xstate5';

import {
  Actors,
  type Context,
  type Event,
  OFFER_IDS_STORAGE_KEY,
} from './model';

// ─── Model ───────────────────────────────────────────────────────────────────

const model = setup({
  types: {
    context: {} as Context,
    events: {} as Event,
  },
  actors: {
    getOfferIdsFromStorage: Actors.getOfferIdsFromStorage,
    setOfferIdsInStorage: Actors.setOfferIdsInStorage,
  },
  actions: {
    storeOfferIdsInStorage: assign(({ context, event }) => {
      assertEvent(event, 'hide');

      const { alreadySeenOfferIds } = context;
      const { seenOfferIds } = event;

      const offerIds = mergeOfferIds(alreadySeenOfferIds, seenOfferIds);

      return {
        alreadySeenOfferIds: offerIds,
      };
    }),
    resetSeenOfferIds: assign({
      alreadySeenOfferIds: [],
    }),
    removeSeenOfferIdsFromStorage() {
      void AsyncStorage.removeItem(OFFER_IDS_STORAGE_KEY);
    },
  },
});

// ─── Machine ─────────────────────────────────────────────────────────────────

/**
 * A state machine for managing the lifecycle of a loyalty offers modal.
 *
 * The state machine transitions through various states:
 *
 * 1. `setup`:
 *
 *    Initializes the modal by retrieving already seen offer IDs from storage.
 *    Transitions to `idle` upon successful retrieval or error.
 *
 * 2. `idle`:
 *
 *    The modal is idle and awaits an action to display.
 *    Transitions to `showing` when the `show` event is triggered.
 *
 * 3. `showing`:
 *
 *    The modal is currently being displayed to the user.
 *    On the `hide` event, it transitions to `storing-offer-ids-in-storage` and triggers the action to store offer IDs.
 *
 * 4. `storing-offer-ids-in-storage`:
 *
 *    Handles the storage of seen offer IDs to persistent storage,
 *    transitioning to `already-shown` upon success or error.
 *
 * 5. `already-shown`:
 *
 *    Indicates that the modal has been shown at least once. Awaits a `reset` event
 *    to clear seen offer IDs and return to `idle`.
 */
export const loyaltyOffersModalMachine = model.createMachine({
  initial: 'setup',
  context: {
    alreadySeenOfferIds: [],
  },
  states: {
    setup: {
      description:
        'Initializes the modal by retrieving already seen offer IDs from storage. Transitions to `idle` upon successful retrieval or error.',
      invoke: {
        src: 'getOfferIdsFromStorage',
        onDone: {
          target: 'idle',
          actions: assign({
            alreadySeenOfferIds: ({ event }) => event.output.offerIds,
          }),
        },
        onError: 'idle',
      },
    },
    idle: {
      description:
        'The modal is idle and awaits an action to display. Transitions to `showing` when the `show` event is triggered.',
      on: {
        show: 'showing',
        hide: 'already-shown', // in case the app wants to prevent showing the modal in an earlier stage because of an async event.
      },
    },
    showing: {
      description:
        'The modal is currently being displayed to the user. On the `hide` event, it transitions to `storing-offer-ids-in-storage` and triggers the action to store offer IDs.',
      on: {
        hide: {
          target: 'storing-offer-ids-in-storage',
          actions: 'storeOfferIdsInStorage',
        },
      },
    },
    'storing-offer-ids-in-storage': {
      description:
        'Handles the storage of seen offer IDs to persistent storage, transitioning to `already-shown` upon success or error.',
      invoke: {
        src: 'setOfferIdsInStorage',
        input: ({ context }) => ({ offerIds: context.alreadySeenOfferIds }),
        onDone: 'already-shown',
        onError: 'already-shown',
      },
    },
    'already-shown': {
      description:
        'Indicates that the modal has been shown at least once. Awaits a `reset` event to clear seen offer IDs and return to `idle`.',
      on: {
        reset: {
          target: 'idle',
          actions: ['resetSeenOfferIds', 'removeSeenOfferIdsFromStorage'],
        },
      },
    },
  },
});

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Merges two arrays of offer IDs into a single array containing unique offer IDs.
 */
function mergeOfferIds(existingOfferIds: string[], offerIds: string[]) {
  return [...new Set([...existingOfferIds, ...offerIds])];
}
