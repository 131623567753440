import React, { useCallback } from 'react';
import { Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

import {
  LoyaltyOffers,
  LoyaltyPostPurchaseModal,
  usePostPurchaseModalState,
} from '@order/components';
import {
  useLogLoyaltyOnboardingModalEvents,
  useOnOnboardingModalShow,
  useTrackLoyaltyOnboardingModal,
} from '@order/hooks';

import { HomeScreenContent } from './components';

export const HomeScreen = () => {
  const logLoyaltyOnboardingModalEvents = useLogLoyaltyOnboardingModalEvents();
  const trackLoyaltyOnboardingModal = useTrackLoyaltyOnboardingModal();
  const postPurchaseModalState = usePostPurchaseModalState();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const hidePostPurchaseModal = useCallback(() => {
    // NOTE: We use a short delay on iOS because of a known issue during
    //       interactions between two modals.
    const delay = Platform.select({ ios: 1000, default: 0 });

    setTimeout(postPurchaseModalState.hideModal, delay);
  }, [postPurchaseModalState.hideModal]);

  // NOTE: Post-purchase modal should be hidden when the loyalty onboarding in-app message is shown.
  const hideOffersOnOnboardingModalShow = useOnOnboardingModalShow({
    onModalShow: hidePostPurchaseModal,
  });

  // ─── Effects ─────────────────────────────────────────────────────────

  useFocusEffect(hideOffersOnOnboardingModalShow);
  useFocusEffect(trackLoyaltyOnboardingModal);
  useFocusEffect(logLoyaltyOnboardingModalEvents);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <HomeScreenContent />
      <LoyaltyOffers.Modal />
      <LoyaltyPostPurchaseModal />
    </>
  );
};
