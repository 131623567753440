import { BagLoyaltyErrorState } from './BagLoyaltyErrorState';
import { BagLoyaltyHeader } from './BagLoyaltyHeader';
import { BagLoyaltySignedOutCta } from './BagLoyaltySignedOutCta';
import { BagLoyaltySubtitle } from './BagLoyaltySubtitle';

export const LoyaltyBag = {
  LoyaltyErrorState: BagLoyaltyErrorState,
  LoyaltyHeader: BagLoyaltyHeader,
  LoyaltySignedOutCta: BagLoyaltySignedOutCta,
  LoyaltySubtitle: BagLoyaltySubtitle,
};
