import { useCallback, useMemo } from 'react';
import {
  HapticNotificationFeedbackStyle,
  triggerHapticNotificationFeedback,
} from '@sg/garnish';

import type {
  AddLineItemToCartInput,
  DeliveryOrderDetailInput,
  IngredientModificationInput,
  IngredientSubstitutionModificationInput,
  MixedDressingDetails,
  PartialProduct,
} from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';
import { getUrqlError } from '@order/utils';

import { useDeliveryOrderInFlight } from '../../useDeliveryOrderInFlight';
import { useNetworkErrorNotice } from '../../useNetworkErrorNotice';
import { useAddLineItemToCartMutation } from '../GraphQL/Cart.generated';
import { useCart } from '../useCart';
import {
  extractIngredientId,
  extractSubstitution,
  getLineItemCustomName,
} from '../useCart.utils';
import { useCartMutationErrorNotice } from '../useLineItemErrorResponse';
import { useLogCartChangeCustomEvent } from '../useLogCartChangeCustomEvent';

// ────────────────────────────────────────────────────────────────────────────────

export const useAddLineItemToCart = (props?: UseAddLineItemToCartParams) => {
  const { onSuccess, onError } = props ?? {};
  const { executeCartMutation } = useCart();
  const { t } = useLocalizationContext();
  const logCartChangeCustomEvent = useLogCartChangeCustomEvent();

  const [addLineItemResponse, addLineItem] = useAddLineItemToCartMutation();
  const { data, fetching } = addLineItemResponse;

  useCartMutationErrorNotice(data?.addLineItemToCart);
  useNetworkErrorNotice(addLineItemResponse);

  // ─── In Flight Delivery Orders ───────────────────────────────────

  const { inFlightDeliveryOrderId, navigateToDeliveryOrderInFlightScreen } =
    useDeliveryOrderInFlight({ requestPolicy: 'cache-only' });

  // ─── TELEMETRY ───────────────────────────────────────────────────

  const { track } = useTelemetry();

  const onAddToBagFailedTelemetry = useCallback(
    (userError?: string, systemError?: string) => {
      track('add_to_bag.failed', {
        userError: userError ?? t('general.error'),
        systemError,
      });
    },
    [t, track],
  );

  // ─── HELPERS ─────────────────────────────────────────────────────

  const addLineItemToCart = useCallback(
    async (inputProps: AddItemToCartInputProps) => {
      // Block adding delivery product when there is an in-flight delivery order.
      if (inputProps.deliveryDetails?.addressId && inFlightDeliveryOrderId) {
        navigateToDeliveryOrderInFlightScreen();

        return;
      }

      const {
        product,
        quantity = 1,
        modifications,
        deliveryDetails,
        customName = null,
      } = inputProps;

      const { id: productId = '', name: productName = '' } = product;

      const {
        additions = [],
        removals = [],
        substitutions = [],
        mixedDressingDetails = [],
      } = modifications ?? {};

      const deliveryOrderDetails = {
        addressId: deliveryDetails?.addressId ?? '',
        vendor: deliveryDetails?.vendor ?? '',
        vendorRestaurantId: deliveryDetails?.vendorRestaurantId ?? '',
        deliveryFee: deliveryDetails?.deliveryFee ?? 0,
        tip: deliveryDetails?.tip ?? 0,
      };

      const input: AddLineItemToCartInput = {
        productId,
        customName: getLineItemCustomName({ productName, customName }),
        quantity,
        mixedDressingDetails,
        additions: additions.map(extractIngredientId),
        removals: removals.map(extractIngredientId),
        substitutions: substitutions.map(extractSubstitution),
        ...(deliveryOrderDetails?.addressId ? { deliveryOrderDetails } : {}),
      };

      const executeMutation = async () => addLineItem({ input });
      const response = await executeCartMutation(
        executeMutation,
        'add-line-item',
      );
      const result = response.data?.addLineItemToCart;
      const possibleError = result as Readonly<{ message?: string }>;
      const isSuccessfullyAdded =
        result?.__typename === 'AddLineItemToCartSuccess';

      if (!isSuccessfullyAdded) {
        onAddToBagFailedTelemetry(
          possibleError?.message,
          getUrqlError(response.error),
        );

        onError?.({
          message: possibleError?.message,
          product,
        });

        return;
      }

      const { cart } = result;

      logCartChangeCustomEvent({ action: 'ADD', cart, productName });

      void triggerHapticNotificationFeedback(
        HapticNotificationFeedbackStyle.Success,
      );
      onSuccess?.({
        product,
        quantity,
      });
    },
    [
      inFlightDeliveryOrderId,
      executeCartMutation,
      logCartChangeCustomEvent,
      onSuccess,
      navigateToDeliveryOrderInFlightScreen,
      addLineItem,
      onAddToBagFailedTelemetry,
      onError,
    ],
  );

  // ─────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({ addLineItemToCart, fetching }),
    [addLineItemToCart, fetching],
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseAddLineItemToCartParams = Readonly<{
  onSuccess?: ({
    product,
    quantity,
  }: {
    product: PartialProduct;
    quantity: number;
  }) => void;
  onError?: ({
    product,
    message,
  }: {
    product: PartialProduct;
    message?: string;
  }) => void;
}>;

type AddItemToCartInputProps = Readonly<{
  product: PartialProduct;
  customName?: string | null;
  quantity?: number;
  deliveryDetails?: DeliveryOrderDetailInput;
  modifications?: {
    additions: readonly IngredientModificationInput[];
    removals: readonly IngredientModificationInput[];
    substitutions: readonly IngredientSubstitutionModificationInput[];
    mixedDressingDetails: readonly MixedDressingDetails[];
  };
}>;
