import React, { type ComponentProps } from 'react';

import { LoyaltyOfferCardProgressNodeBase } from '../LoyaltyOfferCardProgressNodeBase';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardProgressNodeStep = (
  props: LoyaltyOfferCardProgressNodeStepProps,
) => {
  const { isCompleted, ...restProps } = props;

  return (
    <LoyaltyOfferCardProgressNodeBase
      percentageCompleted={isCompleted ? 100 : 0}
      {...restProps}
    />
  );
};

// ─── Props ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProgressNodeStepProps = {
  isCompleted: boolean;
} & Omit<
  ComponentProps<typeof LoyaltyOfferCardProgressNodeBase>,
  'percentageCompleted'
>;
