import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOffersCarouselItem = (props: PropsWithChildren) => {
  const { match } = useResponsive();

  const containerStyles = [
    styles.container,
    match([styles.containerXS, styles.containerSM]),
  ];

  return <View style={containerStyles}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: theme.colors.KALE,
    borderRadius: theme.radius.xlarge,
    marginHorizontal: theme.spacing['4'],
  },
  containerXS: {
    marginBottom: theme.spacing['4'],
  },
  containerSM: {
    marginBottom: theme.spacing['7'],
  },
});
