/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import {
  CartLedgerFragmentDoc,
  CartLineItemFragmentDoc,
  DeliveryOrderDetailFragmentDoc,
} from '../../../hooks/useCart/GraphQL/Cart.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrderStatusQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type OrderStatusQuery = {
  readonly __typename?: 'Query';
  readonly orderStatus:
    | { readonly __typename: 'InvalidOrder'; readonly message: string }
    | {
        readonly __typename: 'OrderStatus';
        readonly gravyStatus: Types.GravyStatusType;
        readonly gravyUpdatedAt: string;
        readonly orderOccasion: string;
        readonly orderStatus: string;
        readonly flattenedOrderStatus: Types.FlattenedOrderStatuses;
        readonly courierDetails: {
          readonly __typename?: 'CourierDetails';
          readonly name: string | null;
          readonly phone: string | null;
          readonly latitude: number | null;
          readonly longitude: number | null;
        } | null;
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly description: string;
          readonly dropoffLocation: string | null;
          readonly orderType: Types.OrderType;
          readonly total: number | null;
          readonly canTrackOrderStatus: boolean;
          readonly isPendingFeedback: boolean;
          readonly wantedTime: string;
          readonly vendorId: string;
          readonly canCancel: boolean;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly city: string;
            readonly state: string;
            readonly zipCode: string;
            readonly name: string;
            readonly address: string;
            readonly slug: string;
            readonly latitude: number;
            readonly longitude: number;
            readonly isOutpost: boolean;
            readonly phone: string;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
          };
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly calories: number;
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: Types.DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: Types.DressingWeight;
            }>;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | null;
};

export type OrderStatusPollingQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type OrderStatusPollingQuery = {
  readonly __typename?: 'Query';
  readonly orderStatus:
    | { readonly __typename: 'InvalidOrder'; readonly message: string }
    | {
        readonly __typename: 'OrderStatus';
        readonly flattenedOrderStatus: Types.FlattenedOrderStatuses;
        readonly courierDetails: {
          readonly __typename?: 'CourierDetails';
          readonly name: string | null;
          readonly phone: string | null;
          readonly latitude: number | null;
          readonly longitude: number | null;
        } | null;
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly wantedTime: string;
          readonly canCancel: boolean;
        };
      }
    | null;
};

export type CanCancelOrderQueryVariables = Types.Exact<{
  input: ReadonlyArray<Types.CanCancelInput> | Types.CanCancelInput;
}>;

export type CanCancelOrderQuery = {
  readonly __typename?: 'Query';
  readonly canCancel: ReadonlyArray<
    | {
        readonly __typename: 'CanCancel';
        readonly orderId: number;
        readonly canCancel: boolean;
      }
    | { readonly __typename?: 'CanCancelAlreadySent' }
    | { readonly __typename?: 'CanCancelFailed' }
    | { readonly __typename?: 'CanCancelNotFound' }
    | { readonly __typename?: 'CanCancelUnauthorized' }
    | { readonly __typename?: 'CantCancelAlreadyCanceled' }
    | { readonly __typename?: 'CantCancelLimitExcceeded' }
    | { readonly __typename?: 'CantCancelOldOrders' }
  >;
};

export type CanCancelOrderV2QueryVariables = Types.Exact<{
  input: ReadonlyArray<Types.CanCancelInput> | Types.CanCancelInput;
}>;

export type CanCancelOrderV2Query = {
  readonly __typename?: 'Query';
  readonly canCancelV2: ReadonlyArray<
    | {
        readonly __typename: 'CanCancel';
        readonly orderId: number;
        readonly canCancel: boolean;
      }
    | { readonly __typename?: 'CanCancelAlreadySent' }
    | { readonly __typename?: 'CanCancelFailed' }
    | { readonly __typename?: 'CanCancelNotFound' }
    | { readonly __typename?: 'CanCancelUnauthorized' }
    | { readonly __typename?: 'CantCancelAlreadyCanceled' }
    | { readonly __typename?: 'CantCancelLimitExcceeded' }
    | { readonly __typename?: 'CantCancelOldOrders' }
  >;
};

export type FavoriteLineItemMutationVariables = Types.Exact<{
  input: Types.FavoriteLineItemInput;
}>;

export type FavoriteLineItemMutation = {
  readonly __typename?: 'Mutation';
  readonly favoriteLineItem: {
    readonly __typename: 'LineItem';
    readonly id: string;
    readonly favorited: boolean;
  };
};

export type OrderStatusHasEarnedLoyaltyPointsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type OrderStatusHasEarnedLoyaltyPointsQuery = {
  readonly __typename?: 'Query';
  readonly orderStatus:
    | { readonly __typename: 'InvalidOrder' }
    | {
        readonly __typename: 'OrderStatus';
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly hasEarnedLoyaltyPoints: boolean;
        };
      }
    | null;
};

export const OrderStatusDocument = gql`
  query OrderStatus($id: ID!) {
    orderStatus(orderId: $id) {
      __typename
      ... on OrderStatus {
        gravyStatus
        gravyUpdatedAt
        orderOccasion
        orderStatus
        flattenedOrderStatus
        courierDetails {
          name
          phone
          latitude
          longitude
        }
        order {
          id
          description
          dropoffLocation
          orderType
          total
          canTrackOrderStatus
          isPendingFeedback
          wantedTime
          vendorId
          canCancel
          restaurant {
            id
            city
            state
            zipCode
            name
            address
            slug
            latitude
            longitude
            isOutpost
            phone
            availableDropOffLocations {
              id
              name
            }
          }
          ledger {
            ...CartLedger
          }
          lineItems {
            calories
            ...CartLineItem
          }
          deliveryOrderDetail {
            ...DeliveryOrderDetail
          }
        }
      }
      ... on InvalidOrder {
        message
      }
    }
  }
  ${CartLedgerFragmentDoc}
  ${CartLineItemFragmentDoc}
  ${DeliveryOrderDetailFragmentDoc}
`;

export function useOrderStatusQuery(
  options: Omit<Urql.UseQueryArgs<OrderStatusQueryVariables>, 'query'>,
) {
  return Urql.useQuery<OrderStatusQuery, OrderStatusQueryVariables>({
    query: OrderStatusDocument,
    ...options,
  });
}
export const OrderStatusPollingDocument = gql`
  query OrderStatusPolling($id: ID!) {
    orderStatus(orderId: $id) {
      __typename
      ... on OrderStatus {
        flattenedOrderStatus
        courierDetails {
          name
          phone
          latitude
          longitude
        }
        order {
          id
          wantedTime
          canCancel
        }
      }
      ... on InvalidOrder {
        message
      }
    }
  }
`;

export function useOrderStatusPollingQuery(
  options: Omit<Urql.UseQueryArgs<OrderStatusPollingQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    OrderStatusPollingQuery,
    OrderStatusPollingQueryVariables
  >({ query: OrderStatusPollingDocument, ...options });
}
export const CanCancelOrderDocument = gql`
  query CanCancelOrder($input: [CanCancelInput!]!) {
    canCancel(input: $input) {
      ... on CanCancel {
        __typename
        orderId
        canCancel
      }
    }
  }
`;

export function useCanCancelOrderQuery(
  options: Omit<Urql.UseQueryArgs<CanCancelOrderQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CanCancelOrderQuery, CanCancelOrderQueryVariables>({
    query: CanCancelOrderDocument,
    ...options,
  });
}
export const CanCancelOrderV2Document = gql`
  query CanCancelOrderV2($input: [CanCancelInput!]!) {
    canCancelV2(input: $input) {
      ... on CanCancel {
        __typename
        orderId
        canCancel
      }
    }
  }
`;

export function useCanCancelOrderV2Query(
  options: Omit<Urql.UseQueryArgs<CanCancelOrderV2QueryVariables>, 'query'>,
) {
  return Urql.useQuery<CanCancelOrderV2Query, CanCancelOrderV2QueryVariables>({
    query: CanCancelOrderV2Document,
    ...options,
  });
}
export const FavoriteLineItemDocument = gql`
  mutation FavoriteLineItem($input: FavoriteLineItemInput!) {
    favoriteLineItem(input: $input) {
      __typename
      id
      favorited
    }
  }
`;

export function useFavoriteLineItemMutation() {
  return Urql.useMutation<
    FavoriteLineItemMutation,
    FavoriteLineItemMutationVariables
  >(FavoriteLineItemDocument);
}
export const OrderStatusHasEarnedLoyaltyPointsDocument = gql`
  query OrderStatusHasEarnedLoyaltyPoints($id: ID!) {
    orderStatus(orderId: $id) {
      __typename
      ... on OrderStatus {
        order {
          id
          hasEarnedLoyaltyPoints
        }
      }
    }
  }
`;

export function useOrderStatusHasEarnedLoyaltyPointsQuery(
  options: Omit<
    Urql.UseQueryArgs<OrderStatusHasEarnedLoyaltyPointsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    OrderStatusHasEarnedLoyaltyPointsQuery,
    OrderStatusHasEarnedLoyaltyPointsQueryVariables
  >({ query: OrderStatusHasEarnedLoyaltyPointsDocument, ...options });
}
