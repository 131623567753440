import React from 'react';
import {
  Image,
  ImageBackground,
  type ImageSourcePropType,
  StyleSheet,
  View,
} from 'react-native';
import { getContentfulImageUrl, useResponsive } from '@sg/garnish';

export const JoinSgRewardsHero = (props: JoinSgRewardsHeroProps) => {
  const { children, heroImage, logoImage } = props;

  const { match } = useResponsive();

  const heroImageSource = heroImage?.url
    ? { uri: getContentfulImageUrl(heroImage.url) }
    : heroImage.fallbackSource;
  const logoImageSource = logoImage?.url
    ? { uri: getContentfulImageUrl(logoImage.url) }
    : logoImage.fallbackSource;

  return (
    <View>
      <ImageBackground
        style={match([styles.heroImageXS, styles.heroImageSM])}
        source={heroImageSource}
      >
        <Image
          style={match([styles.logoXS, styles.logoSM])}
          source={logoImageSource}
        />
        {children}
      </ImageBackground>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  heroImageXS: {
    height: 320,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heroImageSM: {
    height: 640,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoXS: {
    width: 140,
    height: 40,
  },
  logoSM: {
    width: 236,
    height: 66,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinSgRewardsHeroProps = {
  children?: React.ReactNode;
  heroImage: {
    fallbackSource?: ImageSourcePropType;
    url?: string;
    description: string;
  };
  logoImage: {
    fallbackSource?: ImageSourcePropType;
    url?: string;
    description: string;
  };
};
