import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { Button, ScanCard, theme, TitleText } from '@sg/garnish';

import { ScanInStoreTabContentContainer } from '../../components/';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanInStoreEarnAndRedeem = (
  props: ScanInStoreEarnAndRedeemProps,
) => {
  const { title, loyaltyId, palette = 'darkGreen', onPress } = props;

  const leftIcon =
    palette === 'premium' ? 'IconSgRewardSweetCorn' : 'IconSgReward';

  return (
    <ScanInStoreTabContentContainer>
      <ScanCard.Container palette={palette}>
        <ScanCard.Text palette="neutral">
          <FormattedMessage {...messages.youHave} />
        </ScanCard.Text>

        <TitleText style={styles.title} sizeMatch={['32']}>
          {title}
        </TitleText>

        {loyaltyId ? <ScanCard.QrCode value={loyaltyId} /> : null}

        <Button
          size="large"
          leftIcon={leftIcon}
          palette={palette === 'premium' ? 'premium' : 'sweetCorn'}
          style={styles.optionsButton}
          onPress={onPress}
        >
          <FormattedMessage {...messages.rewardOptions} />
        </Button>
      </ScanCard.Container>
    </ScanInStoreTabContentContainer>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  youHave: {
    defaultMessage: 'You have',
    description: 'Scan at checkout card | Loyalty Rewards | Card Title Text',
  },
  rewardOptions: {
    defaultMessage: 'See all reward options',
    description:
      'Scan at checkout card | Loyalty Rewards | Reward Options Button',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  optionsButton: {
    marginTop: theme.spacing['10'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: theme.colors.SWEET_CORN,
    paddingVertical: theme.spacing['2'],
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type ScanInStoreEarnAndRedeemProps = {
  title?: string;
  loyaltyId: string;
  palette?: 'darkGreen' | 'premium';
  onPress: () => void;
};
