import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { BagIcon, theme, useResponsive } from '@sg/garnish';

import { useCart, useOpenBag } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { FloatingButton } from '../FloatingButton';

// ─────────────────────────────────────────────────────────────────────────────

export const BagFloatingButton = (props: BagFloatingButtonProps) => {
  const { showMessage = true, onPress } = props;

  // ─────────────────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();
  const { lineItemsCount, isCartEmpty } = useCart();
  const openBag = useOpenBag({ shouldUpdateCart: true });

  // ─────────────────────────────────────────────────────────────────────

  const handleOpenBag = useCallback(() => {
    onPress?.();
    openBag();
  }, [onPress, openBag]);

  const BagIconWithCount = useCallback(
    () => <BagIcon bagCount={lineItemsCount} />,
    [lineItemsCount],
  );

  // ─────────────────────────────────────────────────────────────────────

  if (isCartEmpty || !currentBreakpoint.isXS) return null;

  return (
    <FloatingButton
      testID="bag.access"
      size="large"
      onPress={handleOpenBag}
      rightElement={BagIconWithCount}
      style={showMessage ? undefined : styles.withoutMessage}
    >
      {showMessage ? t('floating-buttons.bag.title') : undefined}
    </FloatingButton>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  withoutMessage: {
    width: 48,
    height: 48,
    paddingLeft: theme.spacing['2'],
    paddingRight: theme.spacing['3'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagFloatingButtonProps = {
  showMessage?: boolean;
  onPress?: () => void;
};
