import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Main } from '@expo/html-elements';
import {
  Container,
  FadeView,
  HStack,
  theme,
  useResponsive,
  useScrollspy,
} from '@sg/garnish';

import {
  MenuCategoriesGrid,
  MenuDietaryPreferences,
  MenuFloatingButtons,
  MenuHeader,
  MenuOnlineOrderingUnavailable,
} from '@order/components/menu';
import {
  useCart,
  useCustomScreenTitle,
  useDeliveryOrderInFlight,
  useOpenBag,
} from '@order/hooks';
import { useSetLastInteractedMenuStore } from '@order/LastInteractedStore';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useDeliveryDisclosure } from '@order/shared/hooks';
import {
  telemetryLocationFromInput,
  useTelemetry,
  useTrackEventOnFocus,
} from '@order/Telemetry';

import { useReorderIfRequired } from '../../../../screens';
import { AppFooter } from '../../../AppFooter';
import { useDietaryPreferencesState } from '../../../DietaryRestrictions';
import { useMenuContentNavigation } from '../../hooks';
import {
  type MenuContentDeliveryDetailsData,
  type MenuContentOrderChannel,
  type MenuContentRestaurantData,
} from '../../MenuContent.types';
import {
  MenuContentDietaryPreferencesModal,
  useMenuContentDietaryPreferencesModal,
} from '../MenuContentDietaryPreferencesModal';
import {
  MenuContentMoreInfoModal,
  useMenuContentMoreInfoModal,
} from '../MenuContentMoreInfoModal';
import { MenuContentNavigationHeader } from '../MenuContentNavigationHeader';
import { MenuContentProductCard } from '../MenuContentProductCard';
import { MenuContentTopDownModal } from '../MenuContentTopDownModal';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuContentReady = (props: MenuContentReadyProps) => {
  const { restaurantData, deliveryDetailsData, orderChannel, locationName } =
    props;

  const { minWidth, match, currentBreakpoint } = useResponsive();
  const { setOptions } = useNavigation();
  const { formatMessage } = useIntl();
  const { lineItemsCount } = useCart();
  const { trackEvent } = useTelemetry();
  const openBag = useOpenBag();

  const { deliveryDisclosure } = useDeliveryDisclosure({
    restaurantDeliveryFee: restaurantData.deliveryFee,
  });

  // ─────────────────────────────────────────────────────────────────────

  const {
    menu,
    hours,
    slug: restaurantSlug,
    isAcceptingOrders = true,
    showDeliveryFeeDisclosure,
    flexMessage,
    notAcceptingOrdersReason,
  } = restaurantData;

  // NOTE: We need to hide empty categories
  const filteredCategories = useMemo(
    () => menu.categories.filter((category) => category.products.length > 0),
    [menu.categories],
  );

  // ─── Flags ───────────────────────────────────────────────────────────

  const isTopDownCategoriesModalEnabled = useFeatureFlag(
    'CELS-3094-menu-top-down-categories-enabled',
  );

  const isDelivery = orderChannel === 'delivery';
  const hasFlexMessage = Boolean(flexMessage);
  const hasDeliveryDisclosure = Boolean(deliveryDisclosure);

  const shouldRenderNavigationHeader = match([false, true]);
  const shouldRenderMoreInfoModal = !isDelivery;
  const shouldShowBagFloatingButton =
    currentBreakpoint.isXS && lineItemsCount > 0;

  const shouldRenderFlexMessage =
    !isDelivery && minWidth.isSM && hasFlexMessage;
  const shouldRenderDeliveryFeeDisclosure =
    isDelivery && showDeliveryFeeDisclosure && hasDeliveryDisclosure;

  const shouldRenderHeaderNotices =
    shouldRenderDeliveryFeeDisclosure || shouldRenderFlexMessage;

  const shouldRenderTopDownCategoriesModal =
    isTopDownCategoriesModalEnabled && currentBreakpoint.isXS;

  // ─── State ───────────────────────────────────────────────────────────

  const {
    hasOpenedDietaryPreferences,
    setHasOpenedDietaryPreferencesState,
    isResolvingDietaryPreferencesState,
  } = useDietaryPreferencesState();

  const {
    isDietaryPreferencesModalVisible,
    showDietaryPreferencesModal,
    hideDietaryPreferencesModal,
  } = useMenuContentDietaryPreferencesModal();

  const { isMoreInfoModalVisible, showMoreInfoModal, hideMoreInfoModal } =
    useMenuContentMoreInfoModal();

  const [isShowingMenuCategoriesModal, setIsShowingMenuCategoriesModal] =
    useState(shouldRenderTopDownCategoriesModal);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const {
    generateProductDetailsLink,
    setTargetParam,
    defaultActiveTargetId,
    changeLocation,
  } = useMenuContentNavigation({
    orderChannel,
    restaurantSlug,
    deliveryDetailsData,
  });

  const hideMenuCategoriesModal = useCallback(() => {
    setIsShowingMenuCategoriesModal(false);
  }, []);

  const showTopDownModal = useCallback(() => {
    setIsShowingMenuCategoriesModal(true);
  }, []);

  // NOTE: Our version of Xstate causes the Scrollspy feature to break on code
  //       change when hot modules replacement is enabled.
  //       This will be fixed when we update to a newer version.
  //       Until then, reload the app to fix it.
  const { helpers, activeTargetId, setActiveTargetId, sharedValues } =
    useScrollspy({
      onActiveTargetIdChange: setTargetParam,
      defaultActiveTargetId:
        defaultActiveTargetId ?? filteredCategories?.[0]?.id,
    });

  const onNavItemPress = useCallback(
    (categoryId: string) => {
      const targetCategory = filteredCategories.find(
        (category) => category.id === categoryId,
      );

      if (!targetCategory) return;

      setActiveTargetId(categoryId);

      trackEvent({
        name: 'menu.jump_nav',
        payload: { id: restaurantSlug, tabName: targetCategory.name },
      });
    },
    [filteredCategories, restaurantSlug, setActiveTargetId, trackEvent],
  );

  const onDietaryPreferencesModalClose = useCallback(() => {
    hideDietaryPreferencesModal();
    void setHasOpenedDietaryPreferencesState();
  }, [hideDietaryPreferencesModal, setHasOpenedDietaryPreferencesState]);

  // NOTE: In case of `undefined`, the default `goBack` action will be used.
  const customBackButtonHandler =
    shouldRenderTopDownCategoriesModal && !isShowingMenuCategoriesModal
      ? showTopDownModal
      : undefined;

  // ─── Effects ─────────────────────────────────────────────────────────

  useDeliveryOrderInFlight();
  useReorderIfRequired(restaurantSlug, deliveryDetailsData?.addressId);

  useSetLastInteractedMenuStore({
    restaurantSlug,
    addressId: deliveryDetailsData?.addressId,
  });

  useTrackEventOnFocus({
    name: 'menu.view',
    payload: { restaurant: telemetryLocationFromInput(restaurantData) },
  });

  /**
   * Only the smallest breakpoint uses a custom header, thus we need to
   * toggle the native header.
   */
  useLayoutEffect(() => {
    setOptions({ headerShown: shouldRenderNavigationHeader });
  }, [setOptions, shouldRenderNavigationHeader]);

  useCustomScreenTitle(
    formatMessage(messages.orderChannelAndLocationText, {
      order_channel: orderChannel,
      location: locationName,
    }),
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const dietaryPreferencesToggleContainer = match([
    styles.dietaryPreferencesToggleContainerXS,
    styles.dietaryPreferencesToggleContainerSM,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <View style={styles.container}>
        {/* ─── Navigation Header ────────────────────────────────────────── */}

        <MenuContentNavigationHeader
          orderChannel={orderChannel}
          locationName={locationName}
          onBackBtnPressOverride={customBackButtonHandler}
        />

        {/* ─── Header ───────────────────────────────────────────────────── */}

        <MenuHeader.Container
          menuGridScrollOffsetSV={sharedValues.scrollViewOffset}
        >
          {minWidth.isSM ? (
            <MenuHeader.ContentContainer>
              <MenuHeader.DetailsText>
                <MenuHeader.DetailsTextItem>
                  <MenuHeader.DetailsLocationText
                    locationName={locationName}
                    orderChannel={orderChannel}
                  />
                </MenuHeader.DetailsTextItem>

                {isDelivery && deliveryDetailsData?.completeAddress ? (
                  <MenuHeader.DetailsTextItem withSeparator>
                    {deliveryDetailsData.completeAddress}
                  </MenuHeader.DetailsTextItem>
                ) : null}

                {isDelivery && deliveryDetailsData?.deliveryEstimate ? (
                  <MenuHeader.DetailsTextItem withSeparator>
                    <FormattedMessage
                      {...messages.deliveryEtaText}
                      values={{ eta: deliveryDetailsData.deliveryEstimate }}
                    />
                  </MenuHeader.DetailsTextItem>
                ) : null}

                {isDelivery ? null : (
                  <MenuHeader.DetailsStoreHoursText
                    storeHours={hours.store}
                    permanentHours={hours.permanent}
                  />
                )}
              </MenuHeader.DetailsText>

              <MenuHeader.ChangeLocationButton onPress={changeLocation} />

              {shouldRenderMoreInfoModal ? (
                <MenuHeader.MoreInfoButton onPress={showMoreInfoModal} />
              ) : null}
            </MenuHeader.ContentContainer>
          ) : null}

          {shouldRenderHeaderNotices ? (
            <MenuHeader.AlertsContainer>
              {shouldRenderFlexMessage ? (
                <MenuHeader.Alert>{flexMessage}</MenuHeader.Alert>
              ) : null}

              {shouldRenderDeliveryFeeDisclosure ? (
                <MenuHeader.Alert>{deliveryDisclosure}</MenuHeader.Alert>
              ) : null}
            </MenuHeader.AlertsContainer>
          ) : null}

          <MenuCategoriesGrid.Nav
            testID="menu-content.categories-nav"
            register={helpers.navScrollView.register}
            deregister={helpers.navScrollView.deregister}
            trackScroll={helpers.navScrollView.trackScroll}
            storeSize={helpers.navScrollView.storeSize}
          >
            {filteredCategories.map((category) => (
              <MenuCategoriesGrid.NavItem
                key={category.id}
                category={category.name}
                register={helpers.navItem.register}
                deregister={helpers.navItem.deregister}
                targetId={category.id}
                onPress={onNavItemPress}
                isActive={activeTargetId === category.id}
              />
            ))}
          </MenuCategoriesGrid.Nav>
        </MenuHeader.Container>

        {/* ─── Categories ───────────────────────────────────────────────── */}

        <MenuCategoriesGrid.Container
          testID="menu-content.categories-container"
          register={helpers.scrollView.register}
          deregister={helpers.scrollView.deregister}
          trackScroll={helpers.scrollView.trackScroll}
          storeSize={helpers.scrollView.storeSize}
          storeContentSize={helpers.scrollView.storeContentSize}
        >
          <Main style={styles.contentContainer}>
            <Container>
              {filteredCategories.map((category, index) => {
                const isFirstCategory = index === 0;
                const isCustomCategory = category.isCustom;
                const shouldRenderDietaryPreferencesToggle =
                  isFirstCategory &&
                  !isResolvingDietaryPreferencesState &&
                  !hasOpenedDietaryPreferences;

                // ─── Custom Category ───────────────────────────────────────────

                if (isCustomCategory) {
                  const customProducts = category.products.filter(
                    (product) => product.isCustom,
                  );

                  if (customProducts.length === 0) return null;

                  const hasMultipleProducts = customProducts.length > 1;
                  const itemsPerRow = hasMultipleProducts
                    ? match([1, 1, 2])
                    : 1;

                  return (
                    <MenuCategoriesGrid.Category
                      key={category.id}
                      targetId={category.id}
                      register={helpers.target.register}
                      deregister={helpers.target.deregister}
                    >
                      <HStack
                        itemsPerRow={itemsPerRow}
                        gap={theme.spacing['4']}
                      >
                        {customProducts.map((product) => (
                          <MenuCategoriesGrid.CustomProductCard
                            key={product.id}
                            product={product}
                            to={generateProductDetailsLink(product.slug)}
                          />
                        ))}
                      </HStack>
                    </MenuCategoriesGrid.Category>
                  );
                }

                // ─── Standard Category ─────────────────────────────────────────

                return (
                  <MenuCategoriesGrid.Category
                    key={category.id}
                    targetId={category.id}
                    register={helpers.target.register}
                    deregister={helpers.target.deregister}
                  >
                    {/* NOTE: We use the first category to render extra components */}
                    {shouldRenderDietaryPreferencesToggle ? (
                      <View style={dietaryPreferencesToggleContainer}>
                        <MenuDietaryPreferences.Toggle
                          onPress={showDietaryPreferencesModal}
                        />
                      </View>
                    ) : null}

                    <MenuCategoriesGrid.CategoryHeading>
                      {category.name}
                    </MenuCategoriesGrid.CategoryHeading>

                    <MenuCategoriesGrid.ProductsStack>
                      {category.products.map((product) => {
                        const { id, slug } = product;

                        return (
                          <MenuContentProductCard
                            key={id}
                            product={product}
                            restaurantSlug={restaurantSlug}
                            deliveryDetails={deliveryDetailsData}
                            to={generateProductDetailsLink(slug)}
                            imageFetchingPriority={
                              isShowingMenuCategoriesModal ? 'low' : 'normal'
                            }
                          />
                        );
                      })}
                    </MenuCategoriesGrid.ProductsStack>
                  </MenuCategoriesGrid.Category>
                );
              })}
            </Container>
          </Main>

          {minWidth.isSM ? <AppFooter /> : null}
        </MenuCategoriesGrid.Container>
      </View>

      {/* ─── Modals ─────────────────────────────────────────────────────── */}

      <MenuContentDietaryPreferencesModal
        isVisible={isDietaryPreferencesModalVisible}
        onClose={onDietaryPreferencesModalClose}
      />

      {shouldRenderMoreInfoModal ? (
        <MenuContentMoreInfoModal
          isVisible={isMoreInfoModalVisible}
          onClose={hideMoreInfoModal}
          locationName={locationName}
          restaurant={restaurantData}
        />
      ) : null}

      {isAcceptingOrders ? null : (
        <MenuOnlineOrderingUnavailable.Container>
          <MenuOnlineOrderingUnavailable.Dialog>
            <MenuOnlineOrderingUnavailable.Message
              message={notAcceptingOrdersReason}
            />
            <MenuOnlineOrderingUnavailable.Button onPress={changeLocation} />
          </MenuOnlineOrderingUnavailable.Dialog>
        </MenuOnlineOrderingUnavailable.Container>
      )}

      {shouldRenderTopDownCategoriesModal ? (
        <MenuContentTopDownModal
          orderChannel={orderChannel}
          locationName={locationName}
          categories={filteredCategories}
          isVisible={isShowingMenuCategoriesModal}
          hideModal={hideMenuCategoriesModal}
          onCategoryPress={setActiveTargetId}
        />
      ) : null}

      {/* ─── Floating Buttons ───────────────────────────────────────────── */}

      <FadeView show={shouldShowBagFloatingButton}>
        <MenuFloatingButtons.Bag
          lineItemsCount={lineItemsCount}
          onPress={openBag}
        />
      </FadeView>
    </>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  deliveryEtaText: {
    defaultMessage: 'Delivers in {eta} minutes',
    description: 'Menu | Header | Delivery ETA text',
  },
  orderChannelAndLocationText: {
    defaultMessage: `{order_channel, select,
      pickup {Pickup from {location}}
      delivery {Delivery to {location}}
      outpost {Outpost at {location}}
      other {}
    }`,
    description: 'Menu | Navigation Header | Order channel and location text',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    paddingBottom: theme.spacing['16'],

    // required for web `<Main>` component

    display: 'flex',
    flexDirection: 'column',
  },
  dietaryPreferencesToggleContainerXS: {
    marginTop: -theme.spacing['4'],
  },
  dietaryPreferencesToggleContainerSM: {
    marginTop: -theme.spacing['8'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuContentReadyProps = {
  restaurantData: MenuContentRestaurantData;
  deliveryDetailsData?: MenuContentDeliveryDetailsData;
  orderChannel: MenuContentOrderChannel;
  locationName: string;
};
