import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardFooter = (props: LoyaltyOfferCardFooterProps) => {
  const { children, size = 'small' } = props;

  const { match } = useResponsive();

  const containerStyles = [
    styles.container,
    size === 'small'
      ? styles.containerSmall
      : match([styles.containerMedium, styles.containerMediumSM]),
  ];

  return <View style={containerStyles}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing['4'],
    minHeight: theme.spacing['14'],
    marginTop: 'auto',
    paddingHorizontal: theme.spacing['4'],
  },
  containerSmall: {
    paddingBottom: theme.spacing['4'],
  },
  containerMedium: {
    paddingBottom: theme.spacing['6'],
  },
  containerMediumSM: {
    paddingBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardFooterProps = PropsWithChildren<{
  size?: 'small' | 'medium';
}>;
