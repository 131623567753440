import { useCallback, useMemo } from 'react';
import { useInterpret, useSelector } from '@xstate/react';
import * as DateFns from 'date-fns';
import { type EventFrom } from 'xstate';

import { ignoreTimezone } from '@order/utils';

import {
  createOrderingMachine,
  type OrderingContext,
} from '../../machines/ordering-machine';
import type {
  AddPaymentMethodParams,
  AddUpsellToBagParams,
  ApplyGiftCardParams,
  ApplyPromoCodeParams,
  ApplyRewardParams,
  ChangeLineItemQuantityParams,
  ChangeUtensilsUsageParams,
  DeliveryPreferencesParams,
  OrderingCart,
  OrderingMachineServices,
  RemoveRewardParams,
  SetAvailableExpressPaymentsParams,
  SetDefaultPaymentMethodParams,
  SubmitOrderParams,
  WantedTime,
} from '../../machines/ordering-machine.types';
import { adjustWantedTime } from '../../utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A hook to provide an interface to communicate with the Ordering state machine.
 */
export const useOrdering = <LineItem, DeliveryOrderDetail>(
  orderingParams: UseOrderingParams<LineItem, DeliveryOrderDetail>,
) => {
  const {
    isLoggedIn,
    isExpressPaymentsEnabled,
    bagTracking,
    navigatePostOrder,
    fetchInitialData,
    fetchRewards,
    fetchWantedTimes,
    addUpsellToBag,
    updateLineItem,
    changeChannelOrLocation,
    applyReward,
    removeReward,
    applyPromoCode,
    addPaymentMethod,
    setDefaultPaymentMethod,
    applyGiftCard,
    submitOrder,
    onPostOrder,
  } = orderingParams;

  // ─── Tracking ────────────────────────────────────────────────────────

  const {
    trackBagView,
    trackLocationNamePressed,
    trackWantedTimePressed,
    trackWantedTimeChanged,
    trackDeliveryPreferencesPressed,
    trackDropoffLocationChanged,
    trackLineItemRemoved,
    trackLineItemQuantityChanged,
    trackLoyaltyInfoPressed,
    trackApplyRewardPressed,
    trackRemoveRewardPressed,
    trackPromoCodeAddPressed,
    trackUtensilsUsagePressed,
    trackContinuePressed,
    trackExpressCheckoutStart,
    trackChangePaymentMethodPressed,
    trackCreditPressed,
    trackPaymentMethodPressed,
    trackAddPaymentMethodPressed,
    trackAddPaymentMethodSubmitted,
    trackRedeemGiftCardPressed,
    trackRedeemGiftCardSubmitted,
    trackCheckoutStart,
  } = bagTracking;

  // ─── State Machine ───────────────────────────────────────────────────

  const orderingMachine = useMemo(
    () => createOrderingMachine<LineItem, DeliveryOrderDetail>(),
    [],
  );

  // ─── Guards ──────────────────────────────────────────────────────────

  const checkIfLoggedIn = useCallback(() => {
    return isLoggedIn;
  }, [isLoggedIn]);

  const checkIfLoggedOut = useCallback(() => {
    return !isLoggedIn;
  }, [isLoggedIn]);

  // ─── Machine Service ─────────────────────────────────────────────────

  const service = useInterpret(orderingMachine, {
    context: {
      expressPayments: {
        isExpressPaymentsEnabled,
        isStripeReady: false,
        canApplePay: false,
        canGooglePay: false,
      },
    },
    services: {
      fetchInitialData,
      fetchRewards,
      fetchWantedTimes,
      addUpsellToBag,
      updateLineItem,
      applyReward,
      removeReward,
      applyPromoCode,
      addPaymentMethod,
      applyGiftCard,
      submitOrder,
    },
    actions: {
      setDefaultPaymentMethod,
      onPostOrder,
      navigatePostOrder,
      changeChannelOrLocation(context) {
        changeChannelOrLocation(context.cart);
      },
    },
    guards: {
      checkIfLoggedIn,
      checkIfLoggedOut,
    },
  });

  // ─── Context ─────────────────────────────────────────────────────────

  const context = useSelector(service, (currentState) => currentState.context);

  // ─── Flags ───────────────────────────────────────────────────────────

  const isFetchingInitialData = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return (
      matches('ordering.idle.waiting') ||
      matches('ordering.loading.fetchingInitialData') ||
      matches('stripe.initializing')
    );
  });

  const isFetchingRewards = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('rewards.fetchingRewards');
  });

  const hasFailedToLoadRewards = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('rewards.error');
  });

  const isChangingLineItemQuantity = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.loading.changingLineItemQuantity');
  });

  const isApplyingPromoCode = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.loading.applyingPromoCode');
  });

  const isChangingTime = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.idle.changingTime');
  });

  const isChangingDeliveryPreferences = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.idle.changingDeliveryPreferences');
  });

  const isSubmittingOrder = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.loading.submittingOrder');
  });

  const isPostOrder = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.idle.postOrder');
  });

  const isAddingPaymentMethod = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.loading.addingPaymentMethod');
  });

  const isApplyingGiftCard = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.loading.applyingGiftCard');
  });

  const isLineItemQuantityChangePending = useSelector(
    service,
    (currentState) => {
      return currentState.matches('lineItems.pending');
    },
  );

  const isPerformingBagAction = useSelector(service, (currentState) => {
    const { context: ctx, matches } = currentState;
    const isRefetchingRewards =
      ctx.rewards.length > 0 && matches('rewards.fetchingRewards');

    return (
      ctx.lineItemIdsBeingUpdated.length > 0 ||
      Boolean(ctx.rewardIdBeingUpdated) ||
      isRefetchingRewards ||
      matches('ordering.loading')
    );
  });

  const isShowingFutureDateWarning = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.idle.showingFutureDateWarning');
  });

  const isShowingRewardWarning = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.idle.showingRewardWarning');
  });

  const isShowingWarning = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return (
      matches('ordering.idle.showingRewardWarning') ||
      matches('ordering.idle.showingFutureDateWarning')
    );
  });

  const isShowingCheckoutLedger = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return (
      matches('ordering.loading.submittingOrder') ||
      matches('ordering.idle.checkoutLedger')
    );
  });

  const isChangingPaymentMethod = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.idle.changingPaymentMethod');
  });

  const isShowingPaymentMethodForm = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return (
      matches('ordering.loading.addingPaymentMethod') ||
      matches('ordering.idle.paymentMethodForm')
    );
  });

  const isShowingGiftCardForm = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return (
      matches('ordering.loading.applyingGiftCard') ||
      matches('ordering.idle.giftCardForm')
    );
  });

  const isShowingLoyaltyInfo = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('ordering.idle.loyaltyInfo');
  });

  const isShowingModal = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return (
      matches('ordering.idle.changingTime') ||
      matches('ordering.idle.changingDeliveryPreferences') ||
      matches('ordering.idle.checkoutLedger') ||
      matches('ordering.idle.changingPaymentMethod') ||
      matches('ordering.loading.submittingOrder') ||
      matches('ordering.idle.paymentMethodForm') ||
      matches('ordering.loading.addingPaymentMethod') ||
      matches('ordering.idle.giftCardForm') ||
      matches('ordering.idle.loyaltyInfo') ||
      matches('ordering.loading.applyingGiftCard')
    );
  });

  const hasLineItems = (context?.cart?.lineItems?.length ?? 0) > 0;
  const isBagEmpty = !isFetchingInitialData && !hasLineItems;
  const isBagValid = !isFetchingInitialData && hasLineItems && !isPostOrder;

  // ─── Machine Helpers ─────────────────────────────────────────────────

  const startOrdering = useCallback(() => {
    service.send('START');
    trackBagView();
  }, [service, trackBagView]);

  const cancelRequest = useCallback(() => {
    // Prevents cancelling requests (closing modals) during order submission.
    if (isSubmittingOrder) return;

    service.send('CANCEL_REQUEST');
  }, [service, isSubmittingOrder]);

  const requestChannelOrLocationChange = useCallback(() => {
    service.send('REQUEST_CHANNEL_LOCATION_CHANGE');
    trackLocationNamePressed();
  }, [service, trackLocationNamePressed]);

  const requestTimeChange = useCallback(() => {
    service.send('REQUEST_TIME_CHANGE');
    trackWantedTimePressed();
  }, [service, trackWantedTimePressed]);

  const changeTime = useCallback(
    (wantedTime: WantedTime) => {
      const adjustedTime = adjustWantedTime(wantedTime);
      const validTime = adjustedTime ? ignoreTimezone(adjustedTime) : null;
      const isFutureDate = validTime ? DateFns.isTomorrow(validTime) : false;
      service.send('CHANGE_TIME', { params: { wantedTime, isFutureDate } });
      trackWantedTimeChanged();
    },
    [service, trackWantedTimeChanged],
  );

  const changeTip = useCallback(
    (tip: number) => {
      service.send('CHANGE_TIP', { params: { tip } });
    },
    [service],
  );

  const requestDeliveryPreferencesChange = useCallback(() => {
    service.send('REQUEST_DELIVERY_PREFERENCES_CHANGE');
    trackDeliveryPreferencesPressed();
  }, [service, trackDeliveryPreferencesPressed]);

  const changeDeliveryPreferences = useCallback(
    (params: DeliveryPreferencesParams['params']) => {
      service.send('CHANGE_DELIVERY_PREFERENCES', { params });
    },
    [service],
  );

  const changeDropoffLocation = useCallback(
    (dropoffLocation: string) => {
      service.send('CHANGE_DROPOFF_LOCATION', { params: { dropoffLocation } });
      trackDropoffLocationChanged();
    },
    [service, trackDropoffLocationChanged],
  );

  const addUpsell = useCallback(
    (params: AddUpsellToBagParams['params']) => {
      service.send('ADD_UPSELL_TO_BAG', { params });
    },
    [service],
  );

  const startChangingLineItemQuantity = useCallback(() => {
    service.send('START_CHANGING_LINE_ITEM_QUANTITY');
  }, [service]);

  const stopChangingLineItemQuantity = useCallback(() => {
    service.send('STOP_CHANGING_LINE_ITEM_QUANTITY');
  }, [service]);

  const changeLineItemQuantity = useCallback(
    (params: ChangeLineItemQuantityParams['params']) => {
      service.send('CHANGE_LINE_ITEM_QUANTITY', { params });
      params.quantity > 0
        ? trackLineItemQuantityChanged()
        : trackLineItemRemoved();
    },
    [service, trackLineItemQuantityChanged, trackLineItemRemoved],
  );

  const changeUtensilsUsage = useCallback(
    (params: ChangeUtensilsUsageParams['params']) => {
      service.send('CHANGE_UTENSILS_USAGE', { params });
      trackUtensilsUsagePressed();
    },
    [service, trackUtensilsUsagePressed],
  );

  const showLoyaltyInfo = useCallback(() => {
    service.send('SHOW_LOYALTY_INFO');
    trackLoyaltyInfoPressed();
  }, [service, trackLoyaltyInfoPressed]);

  const applyBagReward = useCallback(
    (rewardId: string) => {
      service.send('APPLY_REWARD', {
        params: { rewardId, orderId: context.cart.orderId },
      });
      trackApplyRewardPressed();
    },
    [context.cart.orderId, service, trackApplyRewardPressed],
  );

  const removeBagReward = useCallback(
    (rewardId: string) => {
      service.send('REMOVE_REWARD', {
        params: { rewardId, orderId: context.cart.orderId },
      });
      trackRemoveRewardPressed();
    },
    [context.cart.orderId, service, trackRemoveRewardPressed],
  );

  const refetchRewards = useCallback(() => {
    service.send('REFETCH_REWARDS');
  }, [service]);

  const applyBagPromoCode = useCallback(
    (code: string) => {
      service.send('APPLY_PROMO_CODE', { params: { code } });
      trackPromoCodeAddPressed();
    },
    [service, trackPromoCodeAddPressed],
  );

  const requestCheckoutLedger = useCallback(() => {
    service.send('REQUEST_CHECKOUT_LEDGER');
    trackContinuePressed();
  }, [service, trackContinuePressed]);

  const requestPaymentMethodChange = useCallback(() => {
    service.send('REQUEST_PAYMENT_METHOD_CHANGE');
    trackChangePaymentMethodPressed();
  }, [service, trackChangePaymentMethodPressed]);

  const changePaymentMethod = useCallback(
    (paymentMethodId: string) => {
      service.send('CHANGE_PAYMENT_METHOD', { params: { paymentMethodId } });
      trackPaymentMethodPressed();
    },
    [service, trackPaymentMethodPressed],
  );

  const changeCreditUsage = useCallback(
    (shouldUseCredit: boolean) => {
      service.send('CHANGE_CREDIT_USAGE', { params: { shouldUseCredit } });
      trackCreditPressed();
    },
    [service, trackCreditPressed],
  );

  const requestPaymentMethodForm = useCallback(() => {
    service.send('REQUEST_PAYMENT_METHOD_ADD');
    trackAddPaymentMethodPressed();
  }, [service, trackAddPaymentMethodPressed]);

  const submitPaymentMethodForm = useCallback(
    (form: Readonly<{ id: string; nickname: string; isDefault: boolean }>) => {
      service.send('ADD_PAYMENT_METHOD', { params: form });
      trackAddPaymentMethodSubmitted();
    },
    [service, trackAddPaymentMethodSubmitted],
  );

  const requestGiftCardForm = useCallback(() => {
    service.send('REQUEST_GIFT_CARD_FORM');
    trackRedeemGiftCardPressed();
  }, [service, trackRedeemGiftCardPressed]);

  const submitGiftCardForm = useCallback(
    (code: string, regCode: string) => {
      service.send('APPLY_GIFT_CARD', {
        params: {
          code,
          regCode,
        },
      });
      trackRedeemGiftCardSubmitted();
    },
    [service, trackRedeemGiftCardSubmitted],
  );

  const setAvailableExpressPayments = useCallback(
    (params: SetAvailableExpressPaymentsParams['params']) => {
      service.send('SET_AVAILABLE_EXPRESS_PAYMENTS', { params });
    },
    [service],
  );

  const requestOrderSubmission = useCallback(
    (paymentMethodId?: string) => {
      service.send('SUBMIT_ORDER', { params: { paymentMethodId } });
      trackCheckoutStart(context);

      if (paymentMethodId && context.paymentMethodId) {
        trackExpressCheckoutStart(context.paymentMethodId);
      }
    },
    [context, service, trackCheckoutStart, trackExpressCheckoutStart],
  );

  // ─── Back Behavior ───────────────────────────────────────────────────

  const canGoBack =
    isChangingPaymentMethod ||
    isShowingGiftCardForm ||
    isShowingPaymentMethodForm;

  const backBehavior = useCallback(() => {
    if (isChangingPaymentMethod) {
      service.send('REQUEST_CHECKOUT_LEDGER');
    }

    if (isShowingGiftCardForm) {
      requestPaymentMethodChange();
    }

    if (isShowingPaymentMethodForm) {
      requestPaymentMethodChange();
    }
  }, [
    isChangingPaymentMethod,
    isShowingGiftCardForm,
    isShowingPaymentMethodForm,
    service,
    requestPaymentMethodChange,
  ]);

  const goBack = canGoBack ? backBehavior : undefined;

  // ─────────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({
      ...context,
      bagTracking,
      isFetchingInitialData,
      isFetchingRewards,
      hasFailedToLoadRewards,
      isBagEmpty,
      isBagValid,
      isChangingTime,
      isChangingDeliveryPreferences,
      isChangingLineItemQuantity,
      isApplyingPromoCode,
      isShowingFutureDateWarning,
      isShowingRewardWarning,
      isSubmittingOrder,
      isPostOrder,
      isAddingPaymentMethod,
      isApplyingGiftCard,
      isLineItemQuantityChangePending,
      isPerformingBagAction,
      isShowingWarning,
      isShowingCheckoutLedger,
      isChangingPaymentMethod,
      isShowingPaymentMethodForm,
      isShowingGiftCardForm,
      isShowingLoyaltyInfo,
      isShowingModal,
      startOrdering,
      cancelRequest,
      requestChannelOrLocationChange,
      requestTimeChange,
      changeTime,
      changeTip,
      requestDeliveryPreferencesChange,
      addUpsell,
      changeDeliveryPreferences,
      changeDropoffLocation,
      startChangingLineItemQuantity,
      stopChangingLineItemQuantity,
      changeLineItemQuantity,
      changeUtensilsUsage,
      showLoyaltyInfo,
      applyBagReward,
      removeBagReward,
      refetchRewards,
      applyBagPromoCode,
      requestCheckoutLedger,
      requestPaymentMethodChange,
      requestPaymentMethodForm,
      submitPaymentMethodForm,
      requestGiftCardForm,
      submitGiftCardForm,
      changeCreditUsage,
      changePaymentMethod,
      setAvailableExpressPayments,
      requestOrderSubmission,
      goBack,
    }),
    [
      context,
      bagTracking,
      isFetchingInitialData,
      isFetchingRewards,
      hasFailedToLoadRewards,
      isBagEmpty,
      isBagValid,
      isChangingTime,
      isChangingDeliveryPreferences,
      isChangingLineItemQuantity,
      isApplyingPromoCode,
      isShowingFutureDateWarning,
      isShowingRewardWarning,
      isSubmittingOrder,
      isPostOrder,
      isAddingPaymentMethod,
      isApplyingGiftCard,
      isLineItemQuantityChangePending,
      isPerformingBagAction,
      isShowingWarning,
      isShowingCheckoutLedger,
      isChangingPaymentMethod,
      isShowingPaymentMethodForm,
      isShowingGiftCardForm,
      isShowingLoyaltyInfo,
      isShowingModal,
      startOrdering,
      cancelRequest,
      requestChannelOrLocationChange,
      requestTimeChange,
      changeTime,
      changeTip,
      requestDeliveryPreferencesChange,
      addUpsell,
      changeDeliveryPreferences,
      changeDropoffLocation,
      startChangingLineItemQuantity,
      stopChangingLineItemQuantity,
      changeLineItemQuantity,
      changeUtensilsUsage,
      showLoyaltyInfo,
      applyBagReward,
      removeBagReward,
      refetchRewards,
      applyBagPromoCode,
      requestCheckoutLedger,
      requestPaymentMethodChange,
      requestPaymentMethodForm,
      submitPaymentMethodForm,
      requestGiftCardForm,
      submitGiftCardForm,
      changeCreditUsage,
      changePaymentMethod,
      setAvailableExpressPayments,
      requestOrderSubmission,
      goBack,
    ],
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseOrderingParams<LineItem, DeliveryOrderDetail> = {
  isLoggedIn: boolean;
  isExpressPaymentsEnabled: boolean;

  bagTracking: {
    trackBagView: () => void;
    trackLocationNamePressed: () => void;
    trackWantedTimePressed: () => void;
    trackWantedTimeChanged: () => void;
    trackDeliveryPreferencesPressed: () => void;
    trackDropoffLocationChanged: () => void;
    trackViewMenuPressed: () => void;
    trackLineItemPressed: () => void;
    trackLineItemRemoved: () => void;
    trackLineItemQuantityChanged: () => void;
    trackLoyaltyInfoPressed: () => void;
    trackLoyaltySignedOutPressed: () => void;
    trackApplyRewardPressed: () => void;
    trackRemoveRewardPressed: () => void;
    trackPromoCodeAddPressed: () => void;
    trackUtensilsUsagePressed: () => void;
    trackContinuePressed: () => void;
    trackExpressCheckoutStart: (paymentMethodId: string) => void;
    trackChangePaymentMethodPressed: () => void;
    trackCreditPressed: () => void;
    trackPaymentMethodPressed: () => void;
    trackAddPaymentMethodPressed: () => void;
    trackAddPaymentMethodSubmitted: () => void;
    trackRedeemGiftCardPressed: () => void;
    trackRedeemGiftCardSubmitted: () => void;
    trackCheckoutStart: (
      context: OrderingContext<LineItem, DeliveryOrderDetail>,
    ) => void;
  };
  onPostOrder: (
    context: OrderingContext<LineItem, DeliveryOrderDetail>,
    event: Extract<
      EventFrom<
        ReturnType<typeof createOrderingMachine<LineItem, DeliveryOrderDetail>>
      >,
      { data: { order: { id: string } } }
    >,
  ) => void;
  navigatePostOrder: () => void;
  changeChannelOrLocation: (
    params: OrderingCart<LineItem, DeliveryOrderDetail>,
  ) => void;

  fetchInitialData: () => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['fetchInitialData']['data']
  >;
  fetchRewards: () => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['fetchRewards']['data']
  >;
  fetchWantedTimes: () => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['fetchWantedTimes']['data']
  >;
  addUpsellToBag: (
    context: OrderingContext<LineItem, DeliveryOrderDetail>,
    params: AddUpsellToBagParams,
  ) => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['addUpsellToBag']['data']
  >;
  updateLineItem: (
    context: OrderingContext<LineItem, DeliveryOrderDetail>,
    params: ChangeLineItemQuantityParams,
  ) => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['updateLineItem']['data']
  >;
  applyReward: (
    context: OrderingContext<LineItem, DeliveryOrderDetail>,
    params: ApplyRewardParams,
  ) => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['applyReward']['data']
  >;
  removeReward: (
    context: OrderingContext<LineItem, DeliveryOrderDetail>,
    params: RemoveRewardParams,
  ) => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['removeReward']['data']
  >;
  applyPromoCode: (
    context: OrderingContext<LineItem, DeliveryOrderDetail>,
    params: ApplyPromoCodeParams,
  ) => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['applyPromoCode']['data']
  >;
  addPaymentMethod: (
    context: OrderingContext<LineItem, DeliveryOrderDetail>,
    params: AddPaymentMethodParams,
  ) => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['addPaymentMethod']['data']
  >;
  setDefaultPaymentMethod: (
    context: OrderingContext<LineItem, DeliveryOrderDetail>,
    params: SetDefaultPaymentMethodParams,
  ) => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['setDefaultPaymentMethod']['data']
  >;
  applyGiftCard: (
    context: OrderingContext<LineItem, DeliveryOrderDetail>,
    params: ApplyGiftCardParams,
  ) => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['applyGiftCard']['data']
  >;
  submitOrder: (
    context: OrderingContext<LineItem, DeliveryOrderDetail>,
    params: SubmitOrderParams,
  ) => Promise<
    OrderingMachineServices<
      LineItem,
      DeliveryOrderDetail
    >['submitOrder']['data']
  >;
};
