import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';

import { useResponsive } from '../../../../hooks';
import { Image } from '../../../Image';
import { DefaultImage, DefaultImageLarge } from './assets';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardImage = (props: LoyaltyOfferCardImageProps) => {
  const { source, size = 'small', ...restProps } = props;
  const fallbackSource = size === 'small' ? DefaultImage : DefaultImageLarge;

  const { match } = useResponsive();

  const imageStyles = [
    size === 'small'
      ? styles.imageSmall
      : match([styles.imageSmall, styles.imageLarge]),
  ];

  return (
    <Image
      source={source ?? fallbackSource}
      style={imageStyles}
      pointerEvents="none"
      {...restProps}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imageSmall: {
    height: 190,
  },
  imageLarge: {
    height: 334,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardImageProps = {
  size?: 'small' | 'large';
  source: string | undefined;
} & ComponentProps<typeof Image>;
