import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardContentContainer = (
  props: LoyaltyOfferCardContentContainerProps,
) => {
  const { size = 'small', children } = props;

  const { match } = useResponsive();

  const containerStyles = [
    styles.container,
    size === 'small'
      ? styles.containerSmall
      : match([styles.containerSmall, styles.containerLargeSM]),
  ];

  return <View style={containerStyles}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    rowGap: theme.spacing['2'],
    alignItems: 'flex-start',
    paddingTop: theme.spacing['4'],
    paddingHorizontal: theme.spacing['4'],
  },
  containerSmall: {
    paddingBottom: theme.spacing['6'],
  },
  containerLargeSM: {
    paddingBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardContentContainerProps = PropsWithChildren<{
  size?: 'small' | 'large';
}>;
