import React, { type PropsWithChildren } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Platform, StyleSheet, View, type ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import {
  getIosModalPresentationConfig,
  IconLink,
  Modal,
  theme,
  useResponsive,
} from '@sg/garnish';

export const LoyaltyPostPurchaseContainer = (
  props: LoyaltyPostPurchaseContainerProps,
) => {
  const {
    visible,
    children,
    style,
    isPremium,
    onRequestClose,
    onCloseButtonPress,
  } = props;

  const { formatMessage } = useIntl();
  const { match } = useResponsive();

  // Adding the top safe area inset to the absolute positioned close button.
  const { top: topInset } = useSafeAreaInsets();
  const topPosition = useStyle(
    () => ({
      top: Platform.select({
        android: theme.spacing['4'] + topInset,
        default: theme.spacing['4'],
      }),
    }),
    [topInset],
  );

  return (
    <Modal
      size={match(['full', 'medium'])}
      fitHeight
      visible={visible}
      onRequestClose={onRequestClose}
      accessibilityLabel={formatMessage(messages.modalA11yLabel)}
      palette={isPremium ? 'dark-kale' : 'forest'}
      {...getIosModalPresentationConfig()}
    >
      <View style={style}>
        <IconLink
          name="IconClose"
          palette="light-semi-transparent"
          width={40}
          height={40}
          style={[styles.closeButton, topPosition]}
          accessibilityLabel={formatMessage(messages.closeA11yLabel)}
          onPress={onCloseButtonPress}
        />

        {children}
      </View>
    </Modal>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  modalA11yLabel: {
    defaultMessage: 'Loyalty offers modal',
    description: 'Loyalty Post Purchase | Modal | a11y label',
  },
  closeA11yLabel: {
    defaultMessage: 'Close modal',
    description: 'Loyalty Post Purchase | Modal | Close button a11y label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    top: theme.spacing['4'],
    right: theme.spacing['4'],
    zIndex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyPostPurchaseContainerProps = PropsWithChildren<{
  visible: boolean;
  style?: ViewStyle;
  isPremium?: boolean;
  onCloseButtonPress: () => void;
  onRequestClose: () => void;
}>;
