import React from 'react';
import type { ViewProps } from 'react-native';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import type { PaletteProps } from '../../Modal.types';

export const ModalContent = (props: ModalContentProps) => {
  const {
    children,
    palette,
    shouldFitToFill,
    withSafeAreaView = true,
    style,
    ...restProps
  } = props;
  const { currentBreakpoint } = useResponsive();

  const responsiveStyles = currentBreakpoint.isXS
    ? styles.contentMobile
    : styles.contentTabletUp;
  const sizeBasedStyles = shouldFitToFill
    ? styles.contentFitToFill
    : responsiveStyles;

  const paletteSpecificStyles = palette ? modalPaletteStyles[palette] : {};

  const Wrapper = withSafeAreaView ? SafeAreaView : View;

  return (
    <Wrapper
      style={[styles.content, sizeBasedStyles, paletteSpecificStyles, style]}
      {...restProps}
    >
      {children}
    </Wrapper>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type ModalContentProps = ViewProps &
  PaletteProps &
  Readonly<{
    /**
     * Determines whether modal content should flex grow.
     */
    shouldFitToFill: boolean;

    /**
     * Determines whether modal content should be wrapped in the `SafeArea` container.
     */
    withSafeAreaView?: boolean;
  }>;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  content: {
    backgroundColor: theme.colors.APP_BACKGROUND,
    maxHeight: '100%',
    overflow: 'hidden',
  },
  contentMobile: {
    ...theme.elevations['2'],
    borderTopRightRadius: theme.radius.large,
    borderTopLeftRadius: theme.radius.large,
  },
  contentTabletUp: {
    ...theme.elevations['3'],
    borderRadius: theme.radius.large,
  },
  contentFitToFill: {
    flexGrow: 1,
  },
  paletteSecondary: {
    backgroundColor: theme.colors.SPINACH,
  },
  paletteLime: {
    backgroundColor: theme.colors.LIME,
  },
  paletteForest: {
    backgroundColor: theme.colors.FOREST,
  },
  paletteDarkKale: {
    backgroundColor: theme.colors.DARK_KALE,
  },
});

const modalPaletteStyles = {
  primary: undefined,
  neutral: undefined,
  secondary: styles.paletteSecondary,
  lime: styles.paletteLime,
  forest: styles.paletteForest,
  'dark-kale': styles.paletteDarkKale,
};
