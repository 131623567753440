import React, { createContext, useCallback, useContext, useMemo } from 'react';
import type { ViewProps } from 'react-native';

import type { BrazeUser } from './client';
import { client } from './client';

// ────────────────────────────────────────────────────────────────────────────────

const {
  init,
  destroy,
  changeUser,
  logCustomEvent: logCustomBrazeEvent,
  logPurchase: logBrazePurchase,
  addInAppMessageReceivedListener: addInAppMessageReceivedEventListener,
  removeInAppMessageReceivedListener: removeInAppMessageReceivedEventListener,
} = client;

const BrazeContext = createContext<BrazeContextType | undefined>(undefined);

/**
 Braze provider component.
 *
 * @example
 * const { syncCustomer } = useBraze();
 * syncCustomer(customer)
 *
 * @see {@link https://www.braze.com/}
 */
export const BrazeProvider = (props: Pick<ViewProps, 'children'>) => {
  //
  // ─── HELPERS ────────────────────────────────────────────────────────

  const syncCustomer = useCallback((customer: BrazeUser) => {
    if (!customer.id) return;

    changeUser?.(customer);
  }, []);

  const logCustomEvent = useCallback<BrazeContextType['logCustomEvent']>(
    (eventName, eventPayload) => {
      logCustomBrazeEvent?.(eventName, eventPayload);
    },
    [],
  );

  /**
   * Record in-app purchases so that you can track your revenue over time and across revenue sources,
   * as well as segment your users by their lifetime value (NOTE: from Braze docs).
   *
   * @see {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/react_native/analytics/#logging-purchases Logging purchases | React Native}
   * @see {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/logging_purchases/ Logging purchases | Web}
   */
  const logPurchase = useCallback<BrazeContextType['logPurchase']>(
    (...params) => {
      logBrazePurchase?.(...params);
    },
    [],
  );

  /**
   * A hook that returns a function for adding a listener to in-app message
   * reception events.
   *
   * The listener is activated whenever an in-app message is received.
   *
   * It returns a method that can be used to delete the listener as needed.
   *
   * @example
   *
   * useFocusEffect(
   *   useCallback(() => {
   *     const remove = addInAppMessageReceivedListener(doSomething);
   *
   *     return () => {
   *       remove?.();
   *     };
   *   }, [addInAppMessageReceivedListener, doSomething]),
   * );
   */
  const addInAppMessageReceivedListener = useCallback<
    BrazeContextType['addInAppMessageReceivedListener']
  >((callback) => {
    return addInAppMessageReceivedEventListener?.(callback);
  }, []);

  /**
   * Removes registered callback from the event listeners list.
   */
  const removeInAppMessageReceivedListener = useCallback<
    BrazeContextType['removeInAppMessageReceivedListener']
  >((callback) => {
    return removeInAppMessageReceivedEventListener?.(callback);
  }, []);

  // ────────────────────────────────────────────────────────────────────

  const value = useMemo(
    () => ({
      init,
      destroy,
      syncCustomer,
      logCustomEvent,
      logPurchase,
      addInAppMessageReceivedListener,
      removeInAppMessageReceivedListener,
    }),
    [
      addInAppMessageReceivedListener,
      logCustomEvent,
      logPurchase,
      removeInAppMessageReceivedListener,
      syncCustomer,
    ],
  );

  return (
    <BrazeContext.Provider value={value}>
      {props.children}
    </BrazeContext.Provider>
  );
};

/**
 * Braze client hook.
 *
 * @example
 * const { syncCustomer } = useBraze();
 * syncCustomer('USER_ID')
 *
 * @see {@link https://www.braze.com/}
 */
export const useBraze = () => {
  const context = useContext(BrazeContext);

  if (context === undefined) {
    throw new Error('useBraze must be used within a <BrazeProvider>');
  }

  return context;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type BrazeContextType = Readonly<{
  init: (() => void) | undefined;
  destroy: (() => void) | undefined;
  syncCustomer: (user: BrazeUser) => void;
  logCustomEvent: <EventPayload extends Record<string, unknown>>(
    eventName: string,
    eventPayload?: EventPayload,
  ) => void;
  logPurchase: (
    productId: string,
    price: number,
    currencyCode?: string,
    quantity?: number,
    purchaseProperties?: Record<string, string | number | boolean | Date>,
  ) => void;
  addInAppMessageReceivedListener: (
    callback: () => void,
  ) => (() => void) | undefined;
  removeInAppMessageReceivedListener: (callback: () => void) => void;
}>;
