import { DeliveryPreferenceType, OrderType } from '../../../graphql/types';
import type { CartDataFragment as Cart } from '../GraphQL/Cart.generated';

export const cartMock: Cart = {
  id: '1234',
  orderType: OrderType.Pickup,
  availableWantedTimes: [
    // @ts-expect-error TS(2741): Property 'deliveryOffset' is missing in type '{ ti... Remove this comment to see the full error message
    {
      time: '2021-10-10T15:15:00',
    },
  ],
  ledger: {
    tax: 199,
    subtotal: 1990,
    feesTotal: 120,
    discountsTotal: 0,
    creditsTotal: 0,
    discounts: [],
    tip: 0,
    credits: [
      {
        name: 'Credit',
        amount: 10,
        description: 'Some credit',
      },
    ],
    fees: [
      {
        name: 'Pickup fee',
        amount: 60,
        description: 'This is a fee helping us to improve pickup experience.',
      },
      {
        name: 'Dev Fee',
        amount: 10,
        description: 'This is a fee that goes directly to engineers.',
      },
      {
        name: 'SF Health Mandate Surcharge',
        amount: 60,
        description:
          'To comply with San Francisco Health Care Security Ordinance, a 3% surcharge will be added to your bill.',
      },
    ],
  },
  lineItems: [
    {
      id: '1119746',
      quantity: 1,
      perItemCost: 995,
      name: 'berry + burrata',
      favorited: false,
      addedIngredients: [],
      removedIngredients: [],
      product: {
        id: 'berry + burrata',
        name: 'berry + burrata',
        asset: {
          url: 'https://res.cloudinary.com/sweetgreen/image/upload/v1622741444/gravy/staging/Gravy::MasterProduct/2021_02_25_peach_burrata_bowl_three_quarter_digital_evergreen_2004_V1_kpeqrqt_o5f8au.png',
        },
        ingredients: [
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'cucumber',
            name: 'cucumber',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'shredded kale',
            name: 'shredded kale',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'tomato',
            name: 'tomato',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'basil',
            name: 'basil',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'strawberries',
            name: 'strawberries',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'toasted almonds',
            name: 'toasted almonds',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'mint',
            name: 'mint',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'blueberries',
            name: 'blueberries',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'burrata',
            name: 'burrata',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'balsamic vinaigrette',
            name: 'balsamic vinaigrette',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'spring mix',
            name: 'spring mix',
          },
        ],

        slug: '1119746-berry-burrata',
      },
    },
    {
      id: '1119747',
      quantity: 2,
      perItemCost: 1295,
      name: 'Hot Honey Chicken Plate',
      favorited: false,
      addedIngredients: [],
      removedIngredients: [],
      product: {
        id: 'Hot Honey Chicken Plate',
        name: 'Hot Honey Chicken Plate',
        asset: {
          url: 'https://res.cloudinary.com/sweetgreen/image/upload/v1622668639/gravy/staging/Gravy::MasterProduct/hkz9trpv7fktyesfjt5c_xd6sq6.png',
        },
        ingredients: [
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'toasted almonds',
            name: 'toasted almonds',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'warm quinoa',
            name: 'warm quinoa',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'blackened chicken',
            name: 'blackened chicken',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'blackened chicken',
            name: 'blackened chicken',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'kale-cabbage slaw',
            name: 'kale-cabbage slaw',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'hot honey sauce',
            name: 'hot honey sauce',
          },
          // @ts-expect-error TS(2741): Property 'kind' is missing in type '{ id: string; ... Remove this comment to see the full error message
          {
            id: 'hot roasted sweet potatoes',
            name: 'hot roasted sweet potatoes',
          },
        ],
        slug: '1119747-hot-honey-chicken-plate',
      },
    },
  ],
  // @ts-expect-error TS(2741): Property 'id' is missing in type '{ address: { id:... Remove this comment to see the full error message
  deliveryOrderDetail: {
    address: {
      id: '1369',
      street: '16 Broadway',
      secondaryStreet: null,
      city: 'New York',
      state: 'NY',
      country: 'United States',
      zipCode: '10004',
      deliveryPreference: DeliveryPreferenceType.Curbside,
      googlePlaceId: 'ChIJjXfp4hRM2WRa2Yv27E',
      latitude: 40.704,
      longitude: -74.017,
      name: 'home',
      notes: 'Testing 234',
    },
    estimatedDeliveryTime: '',
    deliveryFee: 399,
    vendor: 'Bringg',
    vendorRestaurantId: '123',
    tip: 100,
    orderId: '1234',
  },
  // @ts-expect-error TS(2739): Type '{ id: string; slug: string; name: string; ci... Remove this comment to see the full error message
  restaurant: {
    id: '33',
    slug: 'sweetgreen-brink-demo',
    name: 'Sweetgreen Brink Demo',
    city: 'New York',
    state: 'NY',
    address: '1 Clarke Place',
    zipCode: '10004',
    asset: {
      url: 'https://i.pinimg.com/originals/a0/9d/4a/a09d4a81ff163f36aaf61fa6072ccc0c.png',
    },
    deliveryMinSubtotal: 399,
  },
};

export const cartFromCartQuery = { ...cartMock, orderType: OrderType.Pickup };
