export type BrazeClient = Readonly<{
  init: (() => void) | undefined;
  destroy: (() => void) | undefined;
  changeUser: ((user: BrazeUser) => void) | undefined;
  logCustomEvent:
    | ((eventName: string, eventPayload?: Record<string, unknown>) => void)
    | undefined;

  /**
   * Record in-app purchases so that you can track your revenue over time and across revenue sources,
   * as well as segment your users by their lifetime value (NOTE: from Braze docs).
   *
   * @see {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/react_native/analytics/#logging-purchases Logging purchases | React Native}
   * @see {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/logging_purchases/ Logging purchases | Web}
   */
  logPurchase:
    | ((
        productId: string,
        price: number,
        currencyCode?: string,
        quantity?: number,
        purchaseProperties?: Record<string, string | number | boolean | Date>,
      ) => void)
    | undefined;
  addInAppMessageReceivedListener:
    | ((callback: () => void) => () => void)
    | undefined;
  removeInAppMessageReceivedListener:
    | ((callback: () => void) => void)
    | undefined;
}>;

export type BrazeUser = Readonly<{
  id?: string | null;
  trackingUuid?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}>;

export type BrazeInAppMessagePayload = {
  extras?: Record<string, unknown>;
};
