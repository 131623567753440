import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { CustomNavigationHeader } from '@sg/garnish';

import { type LocationSearchMachineContext } from '../../machine';
import { LocationSearchTypePicker } from '../LocationSearchTypePicker';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearchHeader = (props: LocationSearchHeaderProps) => {
  const {
    locationSearchType,
    isDisabled,
    shouldApplySafeAreaInsets = true,
    onLocationSearchTypeChange,
  } = props;

  const { formatMessage } = useIntl();
  const { canGoBack, goBack, navigate } = useNavigation();

  const canGoBackToPreviousScreen = canGoBack();

  // ─────────────────────────────────────────────────────────────────────

  const withSafeAreaInsets =
    (shouldApplySafeAreaInsets && Platform.OS === 'ios') ||
    Platform.OS === 'android';

  // ─────────────────────────────────────────────────────────────────────

  const handleGoBack = useCallback(() => {
    if (canGoBackToPreviousScreen) {
      goBack();

      return;
    }

    navigate('MainTabs', {
      screen: 'HomeTab',
      params: { screen: 'Home' },
    });
  }, [canGoBackToPreviousScreen, goBack, navigate]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <CustomNavigationHeader.Container
      transition="gradient"
      safeAreaEdges={withSafeAreaInsets ? ['top'] : []}
    >
      {canGoBackToPreviousScreen ? (
        <CustomNavigationHeader.Button.Left
          name="IconArrowLeft"
          onPress={handleGoBack}
          accessibilityLabel={formatMessage(messages.goBackButtonA11yLabel)}
        />
      ) : null}

      <LocationSearchTypePicker
        locationSearchType={locationSearchType}
        onLocationSearchTypeChange={onLocationSearchTypeChange}
        isDisabled={isDisabled}
      />

      <CustomNavigationHeader.Button.Right
        name="IconClose"
        onPress={handleGoBack}
        accessibilityLabel={formatMessage(messages.closeButtonA11yLabel)}
      />
    </CustomNavigationHeader.Container>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  goBackButtonA11yLabel: {
    defaultMessage: 'Go back',
    description:
      'Location Search | Navigation Header | Back button | a11y label',
  },
  closeButtonA11yLabel: {
    defaultMessage: 'Close',
    description:
      'Location Search | Navigation Header | Close button | a11y label',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchHeaderProps = {
  locationSearchType: LocationSearchMachineContext['locationSearchType'];
  onLocationSearchTypeChange: (searchType: string) => void;
  isDisabled: boolean;
  shouldApplySafeAreaInsets?: boolean;
};
