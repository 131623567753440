import React, { useCallback } from 'react';
import { useResponsive } from '@sg/garnish';

import { LoadingAnimation } from '@order/components';
import { useTelemetry } from '@order/Telemetry';

import { useHomeContent, useHomeLastOrderStatus } from '../../hooks';
import { HomeScreenContentSM } from './HomeScreenContentSM';
import { HomeScreenContentXS } from './HomeScreenContentXS';

export const HomeScreenContent = () => {
  const { currentBreakpoint } = useResponsive();

  // ─── Telemetry ───────────────────────────────────────────────────────

  const { track } = useTelemetry();

  const handleCtaPress = useCallback(() => {
    track('home.hero.cta_tapped');
  }, [track]);

  const handleBagPress = useCallback(() => {
    track('home.bag.cta_tapped');
  }, [track]);

  // ─── Data ────────────────────────────────────────────────────────────
  // Used from 'cache-only' in internal components.

  useHomeLastOrderStatus('cache-and-network');

  // ─── Contentful ──────────────────────────────────────────────────────

  const { hasPlacedOrder, homeContent, isFetchingHomeContent } =
    useHomeContent();

  if (isFetchingHomeContent) {
    return <LoadingAnimation />;
  }

  // ─── Rendering ───────────────────────────────────────────────────────

  if (currentBreakpoint.isXS) {
    return (
      <HomeScreenContentXS
        homeContent={homeContent}
        bagPressed={handleBagPress}
        ctaPressed={handleCtaPress}
      />
    );
  }

  return (
    <HomeScreenContentSM
      homeContent={homeContent}
      hasPlacedOrder={hasPlacedOrder}
      ctaPressed={handleCtaPress}
    />
  );
};
