import React, { type PropsWithChildren, useCallback, useMemo } from 'react';
import { StyleSheet, View, type ViewProps, type ViewStyle } from 'react-native';
import { type LayoutChangeEvent } from 'react-native/Libraries/Types/CoreEventTypes';
import Animated, {
  type SharedValue,
  useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import {
  CUSTOM_NAVIGATION_HEADER_BUTTON_SIZE,
  CUSTOM_NAVIGATION_HEADER_CONTENT_HORIZONTAL_PADDING,
  CUSTOM_NAVIGATION_HEADER_HEIGHT,
  CUSTOM_NAVIGATION_HEADER_HORIZONTAL_PADDING,
} from '../../CustomNavigationHeader.constants';
import {
  type CustomNavigationHeaderContainerTransition,
  type CustomNavigationHeaderPalette,
} from '../../CustomNavigationHeader.types';
import { useBackgroundAnimation, useBorderAnimation } from '../../hooks';
import { CustomNavigationHeaderGradient } from '../CustomNavigationHeaderGradient';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomNavigationHeaderContainer = (
  props: CustomNavigationHeaderContainerProps,
) => {
  const {
    children,
    style,
    safeAreaEdges = ['top'],
    palette = 'default',
    buttonSize = CUSTOM_NAVIGATION_HEADER_BUTTON_SIZE,
    scrollOffsetSV,
    transition = 'background-and-border',
    gradientColors,
    testID,
  } = props;

  const safeAreaInsets = useSafeAreaInsets();

  // ─── Animation Helpers ───────────────────────────────────────────────

  const containerHeightSV = useSharedValue(0);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackLayoutChanges = useCallback(
    (event: LayoutChangeEvent) => {
      // eslint-disable-next-line functional/immutable-data
      containerHeightSV.value = event.nativeEvent.layout.height;
    },
    [containerHeightSV],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const backgroundStyle = backgroundPalettes[palette];
  const outerContainerDynamicStyles = useMemo<ViewStyle | undefined>(() => {
    if (!safeAreaEdges || safeAreaEdges.length === 0) return;

    const edges = new Set(safeAreaEdges);
    const { top, right, left } = safeAreaInsets;

    return {
      paddingTop: edges.has('top') ? Math.min(top, SAFE_AREA_MAX) : 0,
      paddingRight: edges.has('right') ? Math.min(right, SAFE_AREA_MAX) : 0,
      paddingLeft: edges.has('left') ? Math.min(left, SAFE_AREA_MAX) : 0,
    };
  }, [safeAreaEdges, safeAreaInsets]);

  const outerContainerStyles = [
    styles.outerContainer,
    outerContainerDynamicStyles,
  ];

  const innerContainerStyles = [
    styles.innerContainer,
    useStyle(
      () => ({
        paddingHorizontal:
          buttonSize +
          CUSTOM_NAVIGATION_HEADER_HORIZONTAL_PADDING +
          CUSTOM_NAVIGATION_HEADER_CONTENT_HORIZONTAL_PADDING,
      }),
      [buttonSize],
    ),
  ];

  // ─── Animated Styles ─────────────────────────────────────────────────

  const backgroundAnimation = useBackgroundAnimation({
    scrollOffsetSV,
    palette,
    safeAreaEdges,
  });

  const borderAnimation = useBorderAnimation({
    scrollOffsetSV,
    palette,
  });

  const animatedBackgroundStyle =
    transition === 'background-and-border'
      ? backgroundAnimation
      : backgroundStyle;

  const animatedBorderStyle =
    transition === 'background-and-border' ? borderAnimation : null;

  // ─────────────────────────────────────────────────────────────────────

  const containerStyle = [
    outerContainerStyles,
    animatedBackgroundStyle,
    animatedBorderStyle,
    style,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Animated.View
      testID={testID}
      pointerEvents="box-none"
      onLayout={trackLayoutChanges}
      style={containerStyle}
    >
      <View pointerEvents="box-none" style={innerContainerStyles}>
        {children}
      </View>

      {transition === 'gradient' ? (
        <CustomNavigationHeaderGradient
          containerHeightSV={containerHeightSV}
          scrollOffsetSV={scrollOffsetSV}
          gradientColors={gradientColors}
        />
      ) : null}
    </Animated.View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const SAFE_AREA_MAX = theme.spacing['12'];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    zIndex: 1,
  },
  innerContainer: {
    flexDirection: 'row',
    minHeight: CUSTOM_NAVIGATION_HEADER_HEIGHT,
    gap: theme.spacing['2'],
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: theme.spacing['2'],
    paddingHorizontal:
      CUSTOM_NAVIGATION_HEADER_BUTTON_SIZE +
      CUSTOM_NAVIGATION_HEADER_HORIZONTAL_PADDING +
      CUSTOM_NAVIGATION_HEADER_CONTENT_HORIZONTAL_PADDING,
  },
});

const backgroundPalettes: Record<CustomNavigationHeaderPalette, ViewStyle> = {
  default: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  oatmeal: {
    backgroundColor: theme.colors.OATMEAL,
  },
  cream: {
    backgroundColor: theme.colors.CREAM,
  },
  'dark-kale': {
    backgroundColor: theme.colors.DARK_KALE,
  },
};

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomNavigationHeaderContainerProps = PropsWithChildren<{
  safeAreaEdges?: readonly ('top' | 'right' | 'left')[] | null;
  testID?: string;

  /**
   * Optional override for custom buttons that are bigger than icon links.
   */
  buttonSize?: number;

  /**
   * Optional transition type, to be used as the content scrolls.
   * If {null} is passed it will have no scrolling indicator.
   */
  transition?: CustomNavigationHeaderContainerTransition | null;

  /**
   * An optional shared value of the associated scroll view offset, which can be
   * used for animation.
   */
  scrollOffsetSV?: SharedValue<number>;
  gradientColors?: string[];
  style?: ViewProps['style'];
  palette?: CustomNavigationHeaderPalette;
}>;
