import React, { type ComponentProps } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, type TextStyle, View } from 'react-native';
import { Button, DisplayText, theme, VStack } from '@sg/garnish';

import { Loyalty } from '@order/features/loyalty';
import { useLoyaltyContent } from '@order/hooks';

export const LoyaltyHomeFaqXs = (props: LoyaltyHomeFaqXsProps) => {
  const { palette, showHowItWorksModal } = props;

  const { faqLoyaltyHome } = useLoyaltyContent({ availablePoints: undefined });

  if (faqLoyaltyHome.length === 0) return null;

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <DisplayText style={palettes[palette]} sizeMatch={['32']}>
          <FormattedMessage {...messages.title} />
        </DisplayText>

        <Button
          size="small"
          palette={buttonPalettes[palette]}
          onPress={showHowItWorksModal}
        >
          <FormattedMessage {...messages.howItWorks} />
        </Button>
      </View>

      <VStack hasDivider gap={0}>
        {faqLoyaltyHome.map((entry) => (
          <Loyalty.FAQEntry key={entry.title} entry={entry} palette={palette} />
        ))}
      </VStack>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'FAQ',
    description: 'Loyalty | Home | FAQ | Title',
  },
  howItWorks: {
    defaultMessage: 'How it works',
    description: 'Loyalty | Home | FAQ | How it works button',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    padding: theme.spacing['6'],
  },
  kaleText: {
    color: theme.colors.KALE,
  },
  creamText: {
    color: theme.colors.CREAM,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

// ─── Palettes ───────────────────────────────────────────────────────────────

const palettes: Record<LoyaltyHomeFaqXsProps['palette'], TextStyle> = {
  kale: styles.kaleText,
  cream: styles.creamText,
};

const buttonPalettes: Record<
  LoyaltyHomeFaqXsProps['palette'],
  ComponentProps<typeof Button>['palette']
> = {
  kale: 'secondary',
  cream: 'cream',
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeFaqXsProps = {
  palette: 'kale' | 'cream';
  showHowItWorksModal: () => void;
};
