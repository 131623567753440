import React, { type ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { Image } from '@sg/garnish';

import { IllusSgRewardsKale } from '../../../assets';

export const LoyaltyPointsHistoryLogo = (
  props: Pick<ComponentProps<typeof Image>, 'style'>,
) => {
  const { formatMessage } = useIntl();

  return (
    <Image
      contentFit="contain"
      style={[styles.logo, props.style]}
      source={IllusSgRewardsKale}
      aria-label={formatMessage(messages.sgRewardsLogo)}
    />
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  sgRewardsLogo: {
    defaultMessage: 'SG Rewards logo',
    description: 'Loyalty | Point History Header | Rewards',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  logo: {
    width: 80,
    height: 24,
  },
});
