import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import { Container, useResponsive } from '@sg/garnish';

export const JoinSgRewardsBodyContainer = (props: PropsWithChildren) => {
  const { match } = useResponsive();

  return (
    <Container
      style={match([styles.containerXS, styles.containerSM])}
      wrapperStyle={styles.wrapper}
    >
      {props.children}
    </Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  containerXS: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: theme.spacing['4'],
    paddingHorizontal: theme.spacing['6'],
  },
  containerSM: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: theme.spacing['16'],
    paddingHorizontal: theme.spacing['30'],
  },
});
