import React from 'react';
import type { Control } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, Text, View } from 'react-native';
import {
  BodyText,
  Form,
  TagAlert,
  TextLink,
  TextSpace,
  theme,
} from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';

import type { JoinFormData } from '../../utils';

export const TermsAndConditions = (props: TermsAndConditionsProps) => {
  const { control, errorMessage } = props;

  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');

  const { formatMessage } = useIntl();
  const accessibilityLabel = formatMessage(messages.accessibilityLabel);

  // ────────────────────────────────────────────────────────────────────

  return (
    <View>
      <View style={styles.controlsWrapper}>
        <View style={styles.checkboxWrapper}>
          <Form.Checkbox
            testID="check-terms"
            name="terms"
            control={control}
            rules={{ required: true }}
            accessibilityLabel={accessibilityLabel}
          />
        </View>

        <Text style={styles.textWrapper}>
          <BodyText sizeMatch={['14']}>
            <FormattedMessage {...messages.prefix} />
            <TextSpace />
          </BodyText>

          <TextLink
            sizeMatch={['14']}
            href="https://www.sweetgreen.com/terms/"
            testID="link-terms-of-use"
          >
            <FormattedMessage {...messages.termsOfUse} />
          </TextLink>

          <BodyText sizeMatch={['14']}>
            <TextSpace />
            <FormattedMessage {...messages.and} />
            <TextSpace />
          </BodyText>

          <TextLink
            sizeMatch={['14']}
            href="https://www.sweetgreen.com/privacy-policy/"
            testID="link-privacy-policy"
          >
            <FormattedMessage {...messages.privacyPolicy} />
          </TextLink>

          <BodyText sizeMatch={['14']}>
            {isLoyaltyV2Enabled ? (
              <FormattedMessage {...messages.loyaltyEnrollment} />
            ) : (
              <FormattedMessage {...messages.dot} />
            )}
          </BodyText>

          {isLoyaltyV2Enabled ? (
            <>
              <TextSpace />

              <TextLink
                sizeMatch={['14']}
                href="http://www.sweetgreen.com/sg-rewards-program-agreement"
                testID="link-loyalty-terms"
              >
                <FormattedMessage {...messages.terms} />
              </TextLink>

              <BodyText sizeMatch={['14']}>
                <FormattedMessage {...messages.dot} />
              </BodyText>
            </>
          ) : null}
        </Text>
      </View>

      {errorMessage ? (
        <View style={styles.errorMessageWrapper}>
          <TagAlert palette="caution">{errorMessage}</TagAlert>
        </View>
      ) : null}
    </View>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Terms and conditions',
    description: 'Join | Terms and Conditions | Accessibility Label',
  },
  termsOfUse: {
    defaultMessage: 'terms of use',
    description: 'Join | Terms and Conditions | Terms of Use',
  },
  privacyPolicy: {
    defaultMessage: 'privacy policy',
    description: 'Join | Terms and Conditions | Privacy Policy',
  },
  prefix: {
    defaultMessage: "I agree to sweetgreen's",
    description: 'Join | Terms and Conditions | Prefix',
  },
  and: {
    defaultMessage: 'and',
    description: 'Join | Terms and Conditions | And',
  },
  loyaltyEnrollment: {
    defaultMessage: ', and to my enrollment in sg rewards as provided in the',
    description: 'Join | Terms and Conditions | Loyalty Enrollment',
  },
  terms: {
    defaultMessage: 'terms',
    description: 'Join | Terms and Conditions | Terms',
  },
  dot: {
    defaultMessage: '.',
    description: 'Join | Terms and Conditions | Dot',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type TermsAndConditionsProps = Readonly<{
  control: Control<JoinFormData>;
  errorMessage?: string;
}>;

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  controlsWrapper: {
    flexDirection: 'row',
  },
  checkboxWrapper: {
    paddingRight: theme.spacing['4'],
  },
  textWrapper: {
    flexShrink: 1,
  },
  errorMessageWrapper: {
    paddingTop: theme.spacing['4'],
  },
});
