import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { type StyleProp, StyleSheet, View, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';
import {
  BodyText,
  Button,
  type CloudinaryTransformConfig,
  FallbackImage,
  IllusEmpty_2,
  LoadingAnimation,
  useCallbackWithLimit,
} from '@sg/garnish';

/**
 * Info regarding loyalty rewards availability.
 */
export const LoyaltyInfoBenefits = (props: LoyaltyInfoBenefitsProps) => {
  const {
    palette = 'cream',
    loyaltyInfoBenefits,
    availablePoints,
    style,
    isLoading,
    hasError,
    refetch,
  } = props;

  const paletteStyle = palette ? paletteStyles[palette] : {};

  const { callbackWithLimit: refresh, hasReachedLimit } = useCallbackWithLimit({
    callback: refetch,
    limit: 5,
  });

  if (hasError || (!isLoading && loyaltyInfoBenefits.length === 0)) {
    return (
      <View style={styles.errorContainer}>
        <BodyText sizeMatch={['22']}>
          <FormattedMessage {...messages.errorState} />
        </BodyText>

        {hasReachedLimit && !isLoading ? null : (
          <Button
            size="large-wide"
            palette="spinachMuted"
            isLoading={isLoading}
            onPress={refresh}
          >
            <FormattedMessage {...messages.refresh} />
          </Button>
        )}
      </View>
    );
  }

  return (
    <View style={[styles.container, paletteStyle, style]}>
      {isLoading && loyaltyInfoBenefits.length === 0 ? (
        <LoadingAnimation />
      ) : null}

      {loyaltyInfoBenefits.map((loyaltyInfoBenefit) => (
        <LoyaltyInfoBenefitsRow
          key={loyaltyInfoBenefit.title}
          availablePoints={availablePoints}
          loyaltyInfoBenefit={loyaltyInfoBenefit}
        />
      ))}
    </View>
  );
};

const LoyaltyInfoBenefitsRow = (props: LoyaltyInfoBenefitsRowProps) => {
  const {
    availablePoints,
    loyaltyInfoBenefit: { title, points, imageUrl },
  } = props;

  const hasEnoughPoints = availablePoints >= points;

  const wrapperStyle = [
    styles.pointsWrapper,
    hasEnoughPoints ? null : styles.pointsWrapperUnusable,
  ];

  const pointsStyle = [
    styles.points,
    hasEnoughPoints ? null : styles.lockedPoints,
  ];

  const imageStyle = [
    styles.image,
    hasEnoughPoints ? null : styles.lockedImage,
  ];

  const titleStyle = [
    styles.title,
    hasEnoughPoints ? null : styles.lockedTitle,
  ];

  const { formatMessage } = useIntl();

  return (
    <View style={styles.reward}>
      <View style={wrapperStyle}>
        <BodyText bold sizeMatch={['12']} style={pointsStyle}>
          {points}
        </BodyText>
      </View>

      <FallbackImage
        contentFit="contain"
        style={imageStyle}
        defaultImage={IllusEmpty_2}
        baseUrl={imageUrl}
        cloudinaryConfig={cloudinaryConfig}
        aria-label={formatMessage(messages.accessibilityLabel)}
      />

      <BodyText style={titleStyle} sizeMatch={['16']}>
        {title}
      </BodyText>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Loyalty reward',
    description: 'Loyalty Info | Accessibility Label',
  },
  errorState: {
    defaultMessage:
      'Points are taking longer than expected to load. Please try again in a few moments.',
    description: 'Loyalty Info | Error State',
  },
  refresh: {
    defaultMessage: 'Refresh',
    description: 'Loyalty Info | Refresh',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const IMAGE_SIZE = 60;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.CREAM,
    borderColor: theme.colors.KALE,
    borderRadius: theme.radius.medium,
    borderWidth: 1,
    margin: theme.spacing['4'],
    marginTop: theme.spacing['18'],
    minHeight: theme.spacing['24'],
  },
  errorContainer: {
    alignItems: 'center',
    margin: theme.spacing['4'],
    paddingBottom: theme.spacing['6'],
    gap: theme.spacing['10'],
  },
  reward: {
    flexDirection: 'row',
    gap: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
    alignItems: 'center',
  },
  pointsWrapper: {
    height: theme.spacing['6'],
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.spacing['2'],
    borderRadius: theme.radius.xxxlarge,
    backgroundColor: theme.colors.BASIL,
  },
  pointsWrapperUnusable: {
    backgroundColor: theme.colors.CREAM,
    borderColor: theme.colors.BASIL,
    borderWidth: 1,
  },
  points: {
    marginTop: 2,
    textAlign: 'center',
    minWidth: 50,
    color: theme.colors.CREAM,
  },
  lockedPoints: {
    color: theme.colors.BASIL,
  },
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
  lockedImage: {
    opacity: 0.8,
  },
  title: {
    flex: 1,
  },
  lockedTitle: {
    color: theme.colors.CHARCOAL,
  },
  cream: {
    backgroundColor: theme.colors.CREAM,
  },
  oatmeal: {
    backgroundColor: theme.colors.OATMEAL,
  },
});

const cloudinaryConfig: CloudinaryTransformConfig = [
  { crop: 'crop', height: 0.6, width: 0.6 },
  { width: IMAGE_SIZE },
];

const paletteStyles = {
  cream: styles.cream,
  oatmeal: styles.oatmeal,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyInfoBenefitsProps = {
  availablePoints: number;
  loyaltyInfoBenefits: LoyaltyInfoBenefit[];
  isLoading: boolean;
  hasError: boolean;
  palette?: 'cream' | 'oatmeal';
  style?: StyleProp<ViewStyle>;
  refetch: () => void;
};

type LoyaltyInfoBenefitsRowProps = {
  availablePoints: number;
  loyaltyInfoBenefit: LoyaltyInfoBenefit;
};

type LoyaltyInfoBenefit = {
  title: string;
  points: number;
  imageUrl?: string;
};
