import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { type StyleProp, StyleSheet, type ViewStyle } from 'react-native';
import { BodyText, DisplayText, theme, useResponsive } from '@sg/garnish';

import { LoyaltyHomeChallengesRewardsContainer } from '../LoyaltyHomeChallengesRewardsContainer';

export const LoyaltyHomeRewardsEmptyState = (props: EmptyStateProps) => {
  const { match, minWidth } = useResponsive();

  if (!minWidth.isSM) return null;

  return (
    <LoyaltyHomeChallengesRewardsContainer style={props.style}>
      <DisplayText style={styles.creamLabel} sizeMatch={['24']}>
        <FormattedMessage {...messages.rewards} />
      </DisplayText>

      <BodyText
        style={match([styles.centerLabel, styles.creamLabel])}
        sizeMatch={['18']}
      >
        <FormattedMessage {...messages.emptyState} />
      </BodyText>
    </LoyaltyHomeChallengesRewardsContainer>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  rewards: {
    defaultMessage: 'Rewards',
    description: 'Loyalty | Home | Rewards | Rewards',
  },
  emptyState: {
    defaultMessage:
      'No bonus rewards available at the moment. Place an order to earn more points.',
    description: 'Loyalty | Home | Rewards | Empty State (SM+)',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  centerLabel: {
    textAlign: 'center',
  },
  creamLabel: {
    color: theme.colors.CREAM,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type EmptyStateProps = {
  style: StyleProp<ViewStyle>;
};
