import { useCartQuery } from '@order/graphql';

// Extracts the restaurant ids (slug/address) from the bag restaurant.
export const useBagIds = () => {
  const [response] = useCartQuery({
    requestPolicy: 'cache-only',
  });

  const cart = response.data?.cart;
  const restaurantSlug = cart?.restaurant?.slug;
  const bagAddressId = cart?.deliveryOrderDetail?.address?.id;
  const isCartEmpty = (cart?.lineItems?.length ?? 0) === 0;

  if (isCartEmpty) {
    return {
      bagRestaurantSlug: undefined,
      bagAddressId: undefined,
    };
  }

  return {
    bagAddressId,
    bagRestaurantSlug: bagAddressId ? undefined : restaurantSlug,
  };
};
