import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import { useNavigateToLastOrderMenu } from '@order/hooks';
import { useTelemetry } from '@order/Telemetry';

import { useNavigateToMainTabs } from '../../../../navigation';

export const useHomeNavigation = (orderId: string | null = null) => {
  const { track } = useTelemetry();
  const navigation = useNavigation();

  // ─── Navigation ──────────────────────────────────────────────────────

  const navigateToLastOrderMenu = useNavigateToLastOrderMenu();
  const navigateToMainTabs = useNavigateToMainTabs(navigation);

  const navigateToLastMenu = useCallback(() => {
    track('home.hero.cta_tapped');
    navigateToLastOrderMenu();
  }, [navigateToLastOrderMenu, track]);

  const navigateToAccount = useCallback(() => {
    track('home.account.cta_tapped');
    void navigateToMainTabs('AccountTab', { screen: 'AccountMenu' });
  }, [navigateToMainTabs, track]);

  const navigateToLoyalty = useCallback(() => {
    track('home.loyalty.cta_tapped');
    void navigateToMainTabs('LoyaltyTab', { screen: 'LoyaltyHome' });
  }, [navigateToMainTabs, track]);

  const navigateToSignIn = useCallback(() => {
    track('home.banner.cta_tapped', { destination: 'SignIn' });
    navigation.navigate('Auth', {
      screen: 'JoinOrSignIn',
      params: { redirect: 'home' },
    });
  }, [navigation, track]);

  const navigateToOrderStatus = useCallback(() => {
    if (!orderId) return;

    track('home.banner.cta_tapped', { destination: 'OrderStatus' });
    navigation.navigate('Modal', {
      screen: 'OrderStatus',
      params: { orderId },
    });
  }, [navigation, orderId, track]);

  const navigateToRateOrder = useCallback(() => {
    if (!orderId) return;

    track('home.banner.cta_tapped', { destination: 'RateOrder' });
    navigation.navigate('Modal', {
      screen: 'RateOrder',
      params: { orderId },
    });
  }, [navigation, orderId, track]);

  const navigateToChallenges = useCallback(() => {
    track('home.banner.cta_tapped', { destination: 'Challenges' });
    navigation.navigate('MainTabs', {
      screen: 'LoyaltyTab',
      params: { screen: 'SweetpassHome' },
    });
  }, [navigation, track]);

  const navigateToGiftCards = useCallback(() => {
    track('home.banner.cta_tapped', { destination: 'GiftCards' });
    navigation.navigate('MainTabs', {
      screen: 'GiftCardsTab',
      params: { screen: 'GiftCards' },
    });
  }, [navigation, track]);

  return {
    navigateToLastMenu,
    navigateToAccount,
    navigateToLoyalty,
    navigateToSignIn,
    navigateToOrderStatus,
    navigateToRateOrder,
    navigateToChallenges,
    navigateToGiftCards,
  };
};
