import React, { type PropsWithChildren } from 'react';
import { type StyleProp, StyleSheet, View, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

export const LoyaltyPostPurchaseBody = (
  props: LoyaltyPostPurchaseBodyProps,
) => {
  const { children, style } = props;

  return <View style={[styles.bodyContainer, style]}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  bodyContainer: {
    paddingBottom: theme.spacing['10'],
    gap: theme.spacing['6'],
    alignItems: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyPostPurchaseBodyProps = PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
}>;
