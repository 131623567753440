import { useCallback, useEffect } from 'react';
import {
  type RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { LOYALTY_HOME_SCREEN } from '@order/constants';
import { useTrackLoyaltyOnboardingModalNavigation } from '@order/hooks';

import { type LoyaltyTabStackParamList } from '../../../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A custom hook that manages the referrer parameter for the Loyalty Home screen.
 */
export const useLoyaltyHomeScreenReferrer = () => {
  const { params = {} } = useRoute<RouteProp<LoyaltyTabStackParamList, 'LoyaltyHome'>>(); // prettier-ignore
  const { setParams } = useNavigation<NativeStackNavigationProp<LoyaltyTabStackParamList, 'LoyaltyHome'>>(); // prettier-ignore

  const isNavigatedFromOnboarding =
    params.referrer === LOYALTY_HOME_SCREEN.REFERRERS.ONBOARDING;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const removeReferrerParam = useCallback(() => {
    setParams({ referrer: undefined });
  }, [setParams]);

  const trackLoyaltyOnboardingModalNavigation =
    useTrackLoyaltyOnboardingModalNavigation({
      hasNavigated: isNavigatedFromOnboarding,
      onTrack: removeReferrerParam,
    });

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(trackLoyaltyOnboardingModalNavigation);
};
