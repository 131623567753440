import React, { type ComponentProps, useMemo } from 'react';
import { StyleSheet, View, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

import { LoyaltyOfferCardProgressNodeLabel } from '../LoyaltyOfferCardProgressNodeLabel';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardProgressNodeBase = (
  props: LoyaltyOfferCardProgressNodeBaseProps,
) => {
  const {
    percentageCompleted,
    accessibilityLabel,
    label,
    labelAlignment,
    ...restProps
  } = props;

  const percentage = Math.max(Math.min(100, percentageCompleted), 0);

  // ─── Styles ──────────────────────────────────────────────────────────

  const progressBarStyle = useMemo<ViewStyle>(
    () => ({ width: `${percentage}%` }),
    [percentage],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View>
      <View
        style={styles.container}
        accessible
        accessibilityRole="progressbar"
        accessibilityLabel={accessibilityLabel}
        accessibilityValue={{ min: 0, max: 100, now: percentage }}
        {...restProps}
      >
        <View style={[styles.progressBar, progressBarStyle]} />
      </View>

      {label ? (
        <LoyaltyOfferCardProgressNodeLabel alignment={labelAlignment}>
          {label}
        </LoyaltyOfferCardProgressNodeLabel>
      ) : null}
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const PROGRESS_NODE_HEIGHT = 8;
const PROGRESS_NODE_BORDER_RADIUS = 100;
const PROGRESS_NODE_BG_IDLE = 'rgba(255, 255, 255, 0.3)';
const PROGRESS_NODE_BG_ACTIVE = theme.colors.SWEET_CORN;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: PROGRESS_NODE_HEIGHT,
    borderRadius: PROGRESS_NODE_BORDER_RADIUS,
    backgroundColor: PROGRESS_NODE_BG_IDLE,
  },
  progressBar: {
    height: PROGRESS_NODE_HEIGHT,
    borderRadius: PROGRESS_NODE_BORDER_RADIUS,
    backgroundColor: PROGRESS_NODE_BG_ACTIVE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProgressNodeBaseProps = {
  percentageCompleted: number;
  label?: string;
  labelAlignment?: ComponentProps<typeof LoyaltyOfferCardProgressNodeLabel>['alignment']; // prettier-ignore
} & ComponentProps<typeof View>;
