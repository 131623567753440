import { useCallback } from 'react';
import { useSelector } from '@xstate5/react';

import { useGlobalAppState } from '@order/GlobalAppState';

export const usePostPurchaseModalState = () => {
  const { postPurchaseModalMachineRef } = useGlobalAppState();
  const orderId = useSelector(
    postPurchaseModalMachineRef,
    (snapshot) => snapshot.context.orderId,
  );

  const isVisible = useSelector(postPurchaseModalMachineRef, (snapshot) =>
    snapshot.matches('showing'),
  );

  const hideModal = useCallback(() => {
    postPurchaseModalMachineRef.send({ type: 'hide' });
  }, [postPurchaseModalMachineRef]);

  return {
    hideModal,
    isVisible,
    orderId,
  };
};
