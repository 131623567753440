import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { VStack } from '@sg/garnish';

import { ScanAtCheckout } from '@order/features/loyalty';

import {
  useKustomerAvailability,
  useKustomerContactUsHandler,
} from '../../../../integrations/kustomer';
import { useEarnRedeemContent } from '../../hooks';
import {
  textPaletteStyles,
  tintColorStyles,
} from '../../ScanAtCheckout.palettes';
import { ScanInStoreEarnAndRedeem } from '../../tabs';
import { ScanInStoreCta } from '../ScanInStoreCta';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanAtCheckoutOptedInContent = (
  props: ScanAtCheckoutOptedInContentProps,
) => {
  const { loyaltyId, availablePoints, isPremium, handleSeeRewardOptionsPress } =
    props;

  const { formatMessage } = useIntl();
  const title = formatMessage(messages.points, {
    rewards_points: availablePoints,
  });

  const { handleContactUs, handlingContactUs } = useKustomerContactUsHandler(
    useKustomerAvailability(),
    'account',
  );

  const { howItWorks } = useEarnRedeemContent();
  const palette = isPremium ? 'premium' : 'primary';
  const textStyles = textPaletteStyles[palette];

  return (
    <>
      {loyaltyId ? (
        <ScanInStoreEarnAndRedeem
          title={title}
          loyaltyId={loyaltyId}
          palette={isPremium ? 'premium' : 'darkGreen'}
          onPress={handleSeeRewardOptionsPress}
        />
      ) : null}

      <ScanAtCheckout.HowItWorks.Container>
        <ScanAtCheckout.HowItWorks.Title style={textStyles}>
          <FormattedMessage {...messages.howItWorks} />
        </ScanAtCheckout.HowItWorks.Title>

        <VStack hasDivider gap={4}>
          {howItWorks.map((item) => (
            <ScanAtCheckout.HowItWorks.Item
              key={item.title}
              image={item.image}
              description={item.title}
              textStyle={textStyles}
              tintColor={tintColorStyles[palette].tintColor}
            />
          ))}
        </VStack>
      </ScanAtCheckout.HowItWorks.Container>

      <ScanAtCheckout.Footer>
        <ScanAtCheckout.FooterText>
          <FormattedMessage {...messages.haveQuestions} />
        </ScanAtCheckout.FooterText>

        <ScanInStoreCta
          palette="white"
          isLoading={handlingContactUs}
          onPress={handleContactUs}
        >
          {formatMessage(messages.contactSupport)}
        </ScanInStoreCta>
      </ScanAtCheckout.Footer>
    </>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  header: {
    defaultMessage: 'Scan at checkout',
    description: 'Earn + Redeem | Loyalty Rewards | Header',
  },
  contactSupport: {
    defaultMessage: 'Contact support',
    description: 'Earn + Redeem | Loyalty Rewards | Footer CTA',
  },
  haveQuestions: {
    defaultMessage: 'Have questions?',
    description: 'Earn + Redeem | Loyalty Rewards | Footer Text',
  },
  points: {
    defaultMessage: '{rewards_points} points',
    description: 'Earn + Redeem | Loyalty Rewards | Points',
  },
  howItWorks: {
    defaultMessage: 'How it works',
    description: 'Earn + Redeem | Loyalty Rewards | How it works Title',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Earn + Redeem | Loyalty Rewards | Close',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanAtCheckoutOptedInContentProps = {
  loyaltyId: string | null;
  availablePoints: number;
  isPremium: boolean;
  handleSeeRewardOptionsPress: () => void;
};
