import {
  LoyaltyOffersCarousel,
  LoyaltyOffersCarouselItem,
  LoyaltyOffersModal,
} from './components';

export const LoyaltyOffers = {
  Modal: LoyaltyOffersModal,
  Carousel: LoyaltyOffersCarousel,
  CarouselItem: LoyaltyOffersCarouselItem,
};
