import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import { LinearGradient } from 'expo-linear-gradient';

// ─── Components ──────────────────────────────────────────────────────────────

const ShadowGradient = (props: ShadowGradientProps) => {
  const { position } = props;

  const isTopPosition = position === 'top';

  const containerStyles = [
    styles.shadowGradient,
    isTopPosition ? styles.shadowGradientTop : styles.shadowGradientBottom,
  ];

  return (
    <LinearGradient
      pointerEvents="none"
      colors={isTopPosition ? COLORS_TOP : COLORS_BOTTOM}
      locations={COLOR_LOCATIONS}
      style={containerStyles}
    />
  );
};

const ShadowGradientTop = () => <ShadowGradient position="top" />;
const ShadowGradientBottom = () => <ShadowGradient position="bottom" />;

// ─────────────────────────────────────────────────────────────────────────────

export const PickerGradientShadow = {
  Top: ShadowGradientTop,
  Bottom: ShadowGradientBottom,
};

// ─── Constants ───────────────────────────────────────────────────────────────

export const SHADOW_GRADIENT_HEIGHT = 40;

const COLOR_LOCATIONS = [0, 1];
const COLORS_TOP = [theme.colors.APP_BACKGROUND, theme.colors.OPACITY.OATMEAL.TRANSPARENT]; // prettier-ignore
const COLORS_BOTTOM = [theme.colors.OPACITY.OATMEAL.TRANSPARENT, theme.colors.APP_BACKGROUND]; // prettier-ignore

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  shadowGradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 10,
    height: SHADOW_GRADIENT_HEIGHT,
  },
  shadowGradientTop: {
    top: 0,
  },
  shadowGradientBottom: {
    bottom: 0,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ShadowGradientProps = {
  position: 'top' | 'bottom';
};
