import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, theme, useResponsive } from '@sg/garnish';

export const JoinSgRewardsCta = (props: JoinSgRewardsCtaProps) => {
  const { text, isLoading = false, onPress } = props;
  const { match } = useResponsive();

  return (
    <Button
      style={match([styles.buttonXS, styles.buttonSM])}
      size="large-wide"
      palette="sweetCorn"
      onPress={onPress}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  buttonXS: {
    marginTop: theme.spacing['6'],
    marginBottom: theme.spacing['10'],
    marginHorizontal: theme.spacing['6'],
  },
  buttonSM: {
    marginVertical: theme.spacing['10'],
    marginHorizontal: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinSgRewardsCtaProps = {
  text: string;
  isLoading?: boolean;
  onPress: () => void;
};
