import { useCallback } from 'react';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useBraze } from '@order/Braze';
import { LOYALTY_ONBOARDING_MODAL } from '@order/constants';
import { useLoyaltyPremiumStatus } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A hook that logs a Braze custom event for loyalty onboarding purposes based
 * on the user's loyalty membership status.
 *
 * Can be used as an effect (`useEffect`, `useFocusEffect`) or in response to an action.
 */
export const useLogLoyaltyOnboardingModalEvents = () => {
  const { logCustomEvent } = useBraze();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');
  const isLoyaltyOnboardingModalEnabled = useFeatureFlag(
    'CELS-2952-loyalty-onboarding-modal-enabled',
  );
  const isLoggedIn = useIsLoggedIn();

  // ─── Remote Data ─────────────────────────────────────────────────────

  const { isFetchingPremiumStatus, isPremium } = useLoyaltyPremiumStatus();

  // ─────────────────────────────────────────────────────────────────────

  return useCallback(() => {
    const canLogEvents =
      isLoggedIn &&
      isLoyaltyOnboardingModalEnabled &&
      isLoyaltyV2Enabled &&
      !isFetchingPremiumStatus;

    if (!canLogEvents) return;

    const event = isPremium ? SHOW_GOAT_MODAL : SHOW_MODAL;

    logCustomEvent(event);
  }, [
    isFetchingPremiumStatus,
    isLoggedIn,
    isLoyaltyOnboardingModalEnabled,
    isLoyaltyV2Enabled,
    isPremium,
    logCustomEvent,
  ]);
};

// ─────────────────────────────────────────────────────────────────────────────

const { SHOW_MODAL, SHOW_GOAT_MODAL } = LOYALTY_ONBOARDING_MODAL.CUSTOM_EVENTS;
