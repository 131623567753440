import { HapticFeedbackStyle, triggerHapticFeedback } from '../../../utils';
import { PICKER_OPTION_HEIGHT } from './Picker.constants';
import type { PickerOptionLayoutCoordinates } from './Picker.types';

export const findClosestOptionIndex = (
  swipedDistance: number,
  optionsLayoutCoordinates: readonly PickerOptionLayoutCoordinates[],
  shouldRoundToNextOption?: boolean,
) => {
  'worklet';

  const lastIndex = optionsLayoutCoordinates.length - 1;
  const lastOption = optionsLayoutCoordinates[lastIndex];

  // ────────────────────────────────────────────────────────────────────

  if (swipedDistance <= 0) return 0;

  if (swipedDistance >= lastOption.end) return lastIndex;

  // ────────────────────────────────────────────────────────────────────

  const closestOptionIndex = optionsLayoutCoordinates.findIndex(
    (coordinates) =>
      swipedDistance >= coordinates.start && swipedDistance <= coordinates.end,
  );

  if (closestOptionIndex === -1) return 0;

  // if the user scrolls beyond the center coordinate of the closest option,
  // return the next available option start coordinate
  const isSwipedMoreThanOptionCenterCoordinate =
    swipedDistance > optionsLayoutCoordinates[closestOptionIndex].center;

  if (shouldRoundToNextOption && isSwipedMoreThanOptionCenterCoordinate) {
    const isNextOptionAvailable = Boolean(
      optionsLayoutCoordinates[closestOptionIndex + 1],
    );

    return isNextOptionAvailable ? closestOptionIndex + 1 : closestOptionIndex;
  }

  return closestOptionIndex;
};

export const getOptionLayoutCoordinates = (_: unknown, index: number) => {
  const start = index * PICKER_OPTION_HEIGHT;
  const end = start + PICKER_OPTION_HEIGHT;
  const center = start + PICKER_OPTION_HEIGHT / 2;

  return { start, center, end };
};

/**
 * Returns calculated distance based on velocity and option height to add momentum scrolling effect.
 */
export const calculateMomentumScrollDistance = (velocity: number) => {
  'worklet';

  const normalizedVelocity = Math.abs(velocity);

  if (normalizedVelocity <= 100) return 0;

  return (velocity / 100) * PICKER_OPTION_HEIGHT;
};

export const triggerHaptic = () => {
  void triggerHapticFeedback(HapticFeedbackStyle.Medium);
};
