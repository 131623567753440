/* istanbul ignore file */

import { useCallback } from 'react';
import type { Region } from '@sg/garnish';
import { useDebounceFn } from '@sg/garnish';

import { type CompleteAddressForm } from '../../components';
import { getLocationOrderChannel } from '../../helpers';
import type {
  Location,
  LocationNavigationEntryPoint,
  LocationSearchMachineContext,
} from '../../machine';
import { useInitLocationSearchMachine } from '../useInitLocationSearchMachine';
import { useNewLocationsLayout } from '../useNewLocationsLayout';

// ─────────────────────────────────────────────────────────────────────────────

export const useLocationSearchMachine = (
  props: UseLocationSearchMachineProps,
) => {
  const {
    minimalCharactersForSearch,
    navigateToLocation,
    navigateToDeliveryLocation,
  } = props;

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldUseNewLocationsLayout = useNewLocationsLayout();

  // ─────────────────────────────────────────────────────────────────────

  const [state, send, service] = useInitLocationSearchMachine({
    minimalCharactersForSearch,
    shouldUseNewLocationsLayout,
    navigateToLocation,
    navigateToDeliveryLocation,
  });

  const { matches, context } = state;

  // ─── Derived Data ────────────────────────────────────────────────────

  const hasActiveLocation = context.activeLocation !== undefined;

  const isLoadingPredictions = state.matches(
    'search.loading.searchDeliveryAddresses',
  );
  const isLoadingLocations = shouldUseNewLocationsLayout
    ? matches('search.loading') && !isLoadingPredictions
    : (matches('search.loading') ||
        matches('search.setup.setupNearbyLocationsLegacy')) &&
      !isLoadingPredictions;
  const isAddingAddress = state.matches('search.loading.addNewDeliveryAddress');

  const shouldShowLocationWarning =
    (matches('search.warning.pickupLocation') ||
      matches('search.warning.outpostLocation')) &&
    hasActiveLocation;

  const isLoadingRecentAndNearbyLocations =
    matches('search.setup.setupRecentAndNearbyLocations') ||
    matches('search.setup.setupNearbyLocations');

  const shouldRenderRecentAndNearbyLocations =
    !matches('searchType.delivery') &&
    (matches('search.recentAndNearbyLocations') ||
      isLoadingRecentAndNearbyLocations);

  const shouldRenderDeliveryAddressForm = matches(
    'search.showingDeliveryAddressForm',
  );

  // This indicates that the user used the location search field to search
  // for locations.
  const isUsingSearchField = matches('search.loading.searchByString');

  // ─── Helpers ─────────────────────────────────────────────────────────

  const setLocationSearchType = useCallback(
    (searchType: string) => {
      if (searchType === 'pickup') send('SET_LOCATION_SEARCH_TYPE_PICKUP');

      if (searchType === 'delivery') send('SET_LOCATION_SEARCH_TYPE_DELIVERY');

      if (searchType === 'outpost') send('SET_LOCATION_SEARCH_TYPE_OUTPOST');
    },
    [send],
  );

  const searchBasedOnCurrentPosition = useCallback(() => {
    send('SEARCH_BY_CURRENT_POSITION');
  }, [send]);

  const searchLocationsByArea = useCallback(() => {
    send('SEARCH_BY_AREA');
  }, [send]);

  const searchLocationsByString = useCallback(
    (searchString: string) => {
      send('SEARCH_BY_STRING', { searchString });
    },
    [send],
  );

  const searchDeliveryAddresses = useDebounceFn(
    useCallback(
      (searchString: string) => {
        send('SET_DELIVERY_SEARCH_STRING', { searchString });
      },
      [send],
    ),
    200,
  );

  const setLocationSearchArea = useCallback(
    (region: Region) => {
      const { topLeft, bottomRight } = region;

      send('SET_SEARCH_AREA', { searchArea: { topLeft, bottomRight } });
    },
    [send],
  );

  const setDeliveryPredictionPlaceId = useCallback(
    (placeId: string) => {
      send('SET_DELIVERY_PREDICTION_PLACE_ID', { placeId });
    },
    [send],
  );

  const setFocusedLocation = useCallback(
    (id?: string) => {
      send('SET_FOCUSED_PIN_ID', { id });
      send('SET_FOCUSED_CARD_ID', { id });
    },
    [send],
  );

  const setFocusedLocationPin = useCallback(
    (id: string | undefined) => {
      send('SET_FOCUSED_PIN_ID', { id });
    },
    [send],
  );

  const confirmLocationWarning = useCallback(() => {
    send('LOCATION_WARNING_CONFIRM');
  }, [send]);

  const cancelLocationWarning = useCallback(() => {
    send('LOCATION_WARNING_CANCEL');
  }, [send]);

  const navigateToSelectedLocation = useCallback(
    (
      { restaurantSlug }: LocationRestaurantData,
      options?: LocationNavigationOptions,
    ) => {
      send('NAVIGATE_TO_LOCATION', {
        restaurantSlug,
        entryPoint: options?.entryPoint,
      });
    },
    [send],
  );

  const cancelDeliveryAddressForm = useCallback(() => {
    send('CANCEL_DELIVERY_ADDRESS_FORM');
  }, [send]);

  const submitDeliveryAddressForm = useCallback(
    (address: CompleteAddressForm) => {
      send('SUBMIT_DELIVERY_ADDRESS_FORM', {
        address: {
          ...context.deliveryLocation,

          // new parameters that were submitted from the form.
          secondaryStreet: address.secondaryStreet,
          name: address.name,
          notes: address.notes,
          deliveryPreference: address.deliveryPreference,
        },
      });
    },
    [context.deliveryLocation, send],
  );

  const navigateToSelectedDeliveryLocation = useCallback(
    (
      _location: LocationRestaurantData,
      options?: LocationNavigationOptions,
    ) => {
      send('NAVIGATE_TO_DELIVERY_LOCATION', {
        entryPoint: options?.entryPoint,
      });
    },
    [send],
  );

  const navigateToRecentOrNearbyLocation = useCallback(
    (params: NavigateToLocationParams) => {
      const { location, options } = params;

      const locationOrderChannel = getLocationOrderChannel(location);

      if (locationOrderChannel === 'pickup') {
        send('NAVIGATE_TO_RECENT_PICKUP_LOCATION', {
          restaurantSlug: location.restaurantSlug,
          entryPoint: options?.entryPoint,
        });

        return;
      }

      if (locationOrderChannel === 'outpost') {
        send('NAVIGATE_TO_RECENT_OUTPOST_LOCATION', {
          restaurantSlug: location.restaurantSlug,
          entryPoint: options?.entryPoint,
        });

        return;
      }

      send('NAVIGATE_TO_RECENT_DELIVERY_LOCATION', {
        addressId: location.addressId,
        entryPoint: options?.entryPoint,
      });
    },
    [send],
  );

  const showRecentAndNearbyLocations = useCallback(() => {
    if (!shouldUseNewLocationsLayout) return;

    send('SHOW_RECENT_AND_NEARBY_LOCATIONS');
  }, [shouldUseNewLocationsLayout, send]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    state,
    service,
    flags: {
      isLoadingPredictions,
      isLoadingLocations,
      isAddingAddress,
      isLoadingRecentAndNearbyLocations,
      isUsingSearchField,
      shouldShowLocationWarning,
      shouldRenderRecentAndNearbyLocations,
      shouldRenderDeliveryAddressForm,
    },
    helpers: {
      setLocationSearchType,
      searchBasedOnCurrentPosition,
      searchLocationsByArea,
      searchLocationsByString,
      searchDeliveryAddresses,
      setLocationSearchArea,
      setDeliveryPredictionPlaceId,
      setFocusedLocation,
      setFocusedLocationPin,
      confirmLocationWarning,
      cancelLocationWarning,
      navigateToSelectedLocation,
      cancelDeliveryAddressForm,
      submitDeliveryAddressForm,
      navigateToSelectedDeliveryLocation,
      navigateToRecentOrNearbyLocation,
      showRecentAndNearbyLocations,
    },
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseLocationSearchMachineProps = Readonly<{
  minimalCharactersForSearch: number;

  /*
    Optional actions that initiates the navigation process for the selected location.
    When those properties are not provided, the standard navigation is used.

    Helpful when a custom navigation method is utilized or when other actions
    need to be performed before navigation.
   */

  navigateToLocation?: (context: LocationSearchMachineContext) => void;
  navigateToDeliveryLocation?: (context: LocationSearchMachineContext) => void;
}>;

type LocationRestaurantData = Readonly<{ restaurantSlug?: string }>;

type NavigateToLocationParams = {
  location: Location;
  options?: LocationNavigationOptions;
};

type LocationNavigationOptions = {
  entryPoint?: LocationNavigationEntryPoint;
};
