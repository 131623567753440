import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardProgressBarContainer = (
  props: LoyaltyOfferCardProgressBarContainerProps,
) => {
  const { children, size = 'small' } = props;

  const { match } = useResponsive();

  const containerStyles =
    size === 'large'
      ? match([styles.containerSmall, styles.containerLargeSM])
      : styles.containerSmall;

  return <View style={containerStyles}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerSmall: {
    width: '100%',
    marginTop: theme.spacing['4'],
  },
  containerLargeSM: {
    width: '100%',
    marginTop: theme.spacing['8'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProgressBarContainerProps = PropsWithChildren<{
  size?: 'small' | 'large';
}>;
