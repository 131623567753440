import React, { type PropsWithChildren } from 'react';
import { TextLink } from '@sg/garnish';

export const LoyaltyPostPurchaseLink = (
  props: LoyaltyPostPurchaseLinkProps,
) => {
  const { children, onPress } = props;

  return (
    <TextLink sizeMatch={['18']} onPress={onPress} palette="white">
      {children}
    </TextLink>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyPostPurchaseLinkProps = PropsWithChildren<{
  onPress?: () => void;
}>;
