import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, type TextProps, View } from 'react-native';
import {
  BodyText,
  type CloudinaryTransformConfig,
  FallbackImage,
  IllusEmpty_2,
  theme,
  useResponsive,
} from '@sg/garnish';

export const LoyaltyHomeProgressBarReward = (reward: PointsReward) => {
  const { name, assetUrl, points, textStyle, hasReachedMilestone } = reward;

  const { minWidth, match } = useResponsive();
  const { formatMessage } = useIntl();

  const shouldRenderName = minWidth.isSM && Boolean(name);
  const shouldRenderImage = minWidth.isSM && points > 0;

  const unreachedStyle = hasReachedMilestone ? null : styles.unreached;

  const milestoneStyle = match([
    styles.milestoneLabelXS,
    styles.milestoneLabelSM,
  ]);

  return (
    <View style={styles.container}>
      <BodyText style={[milestoneStyle, textStyle]} sizeMatch={['12', '18']}>
        {points}
      </BodyText>

      {shouldRenderImage ? (
        <FallbackImage
          contentFit="contain"
          style={[styles.image, unreachedStyle]}
          defaultImage={IllusEmpty_2}
          baseUrl={assetUrl}
          cloudinaryConfig={cloudinaryConfig}
          aria-label={formatMessage(messages.accessibilityLabel, { points })}
        />
      ) : null}

      {shouldRenderName ? (
        <BodyText
          style={[styles.rewardLabel, textStyle, unreachedStyle]}
          numberOfLines={3}
          sizeMatch={['18', '18', '22']}
        >
          {name}
        </BodyText>
      ) : null}
    </View>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const WIDTH = 100;
const HEIGHT = 80;

const cloudinaryConfig: CloudinaryTransformConfig = [
  { crop: 'crop', height: 0.6, width: 0.6 },
  { width: WIDTH },
];

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Reward milestone, {points} points',
    description: 'Loyalty | Reward | Reward A11y',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    width: '100%',
  },
  unreached: {
    opacity: 0.6,
  },
  milestoneLabelXS: {
    textAlign: 'center',
  },
  milestoneLabelSM: {
    textAlign: 'center',
    marginBottom: theme.spacing['4'],
  },
  rewardLabel: {
    textAlign: 'center',
  },
  image: {
    width: WIDTH,
    maxWidth: '100%',
    height: HEIGHT,
    marginBottom: theme.spacing['2'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type PointsReward = {
  points: number;
  name?: string;
  assetUrl?: string | null;
  textStyle?: TextProps['style'];
  hasReachedMilestone: boolean;
};
