import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  type ImageSourcePropType,
  type ImageStyle,
  StyleSheet,
} from 'react-native';
import { Image } from '@sg/garnish';

export const LoyaltyPostPurchaseHero = (
  props: LoyaltyPostPurchaseHeroProps,
) => {
  const { image, style } = props;

  const { formatMessage } = useIntl();

  const heroImageSource = image?.url
    ? { uri: image.url }
    : image.fallbackSource;

  return (
    <Image
      style={[styles.image, style]}
      contentFit="cover"
      aria-label={formatMessage(messages.imageA11yLabel)}
      source={heroImageSource}
    />
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  imageA11yLabel: {
    defaultMessage: 'Post Purchase modal image',
    description: 'Loyalty Post Purchase | Modal Hero | a11y label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  image: {
    width: '100%',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyPostPurchaseHeroProps = {
  image: {
    fallbackSource?: ImageSourcePropType;
    url?: string;
    description: string;
  };
  style?: ImageStyle;
};
