import type { ReactChild, ReactNode, ReactText } from 'react';
import type {
  DimensionValue,
  PressableProps,
  PressableStateCallbackType as UIEventState,
  TextStyle,
  ViewStyle,
} from 'react-native';

import type { IconName } from '../Icon';

export type ButtonProps = GarnishProps &
  PressableProps &
  ButtonChildren &
  IconProp &
  Readonly<{
    width?: DimensionValue;
    style?: ViewStyle;
    rightElement?: (uiEventState: UIEventState) => ReactNode;
  }>;

export type GarnishStyleProps = Readonly<{
  hasDisabledStyle?: boolean;
}>;

export type GarnishProps = Readonly<{
  size?: SizeTypes;
  palette?: PaletteTypes;
  isLoading?: boolean;
}> &
  GarnishStyleProps;

export type ButtonChildren = Readonly<{
  children?: ReactChild | ReactText;
}>;

export type BoxProps = Readonly<{
  children: ReactNode;
  uiEventState: UIEventState;
  style?: ViewStyle;
}> &
  Required<GarnishProps>;

export type ButtonIconProps = Readonly<{
  uiEventState: UIEventState;
}> &
  Required<GarnishProps> &
  IconProp;

export type TextParentProps = Readonly<{
  uiEventState: UIEventState;
}> &
  Required<GarnishProps> &
  ButtonChildren;

export type LoadingDotsProps = Readonly<{
  uiEventState: UIEventState;
}> &
  Required<GarnishProps>;

type IconProp = Readonly<{
  centerIcon?: IconName;
  leftIcon?: IconName;
  rightIcon?: IconName;
}>;

export type SizeTypes = keyof typeof ButtonSize;
export type PaletteTypes = keyof typeof ButtonPalette;

export const ButtonSize = {
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
  'large-wide': 'large-wide',
};

export const ButtonPalette = {
  primary: 'primary',
  primaryLime: 'primaryLime',
  primaryOutline: 'primaryOutline',
  secondary: 'secondary',
  sweetCorn: 'sweetCorn',
  caution: 'caution',
  lime: 'lime',
  limeOutline: 'limeOutline',
  limeOutlineFull: 'limeOutlineFull',
  limeOnGreen: 'limeOnGreen',
  kale: 'kale',
  kaleLightest: 'kaleLightest',
  oatmeal: 'oatmeal',
  quinoa: 'quinoa',
  light: 'light',
  cream: 'cream',
  outline: 'outline',
  white: 'white',
  black: 'black',
  muted: 'muted',
  premium: 'premium',
  spinach: 'spinach',
  spinachMuted: 'spinachMuted',
};

export const ValidButtonPalettes = new Set<PaletteTypes>(
  Object.keys(ButtonPalette) as Array<PaletteTypes>,
);

export type ButtonStyleBlock = Readonly<{
  box: ViewStyle;
  text: TextStyle;
}>;
