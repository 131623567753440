import { useMemo } from 'react';
import { type RequestPolicy } from 'urql';
import { TierName } from '@sg/graphql-schema';

import { useFeatureFlag } from '@order/LaunchDarkly';

import { useLoyaltyInfoBenefitsQuery } from './GraphQL/LoyaltyInfoBenefits.generated';

/**
 * Hooks up the loyalty profile points and rewards to the <Loyalty.InfoBenefits /> component.
 */
export const useLoyaltyInfoBenefits = (props?: UseLoyaltyInfoBenefits) => {
  const { requestPolicy = 'cache-and-network', pause = false } = props ?? {};
  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');

  const [{ data, error, fetching, stale }, refetch] =
    useLoyaltyInfoBenefitsQuery({
      requestPolicy,
      pause: pause || !isLoyaltyV2Enabled,
    });

  return useMemo(() => {
    const loyaltyProfile = data?.loyaltyProfile;

    const rewards = loyaltyProfile?.pointsRewards ?? [];

    const customerTier = loyaltyProfile?.tier?.nameV2;
    const isOptedOut = customerTier === TierName.RewardsOptedOut;

    return {
      isLoading: fetching || stale,
      availablePoints: loyaltyProfile?.points?.available ?? 0,
      benefits: rewards.map(toBenefit),
      hasError: Boolean(error),
      isOptedOut,
      refetch,
    };
  }, [data?.loyaltyProfile, fetching, stale, error, refetch]);
};

// ─── Helpers ───────────────────────────────────────────────────────────────

const toBenefit = (pointsReward: PointsReward) => {
  return {
    points: pointsReward.points,
    title: pointsReward.name,
    imageUrl: pointsReward.assetUrl ?? undefined,
  };
};

// ─── Types ──────────────────────────────────────────────────────────────────

type UseLoyaltyInfoBenefits = {
  requestPolicy?: RequestPolicy;
  pause?: boolean;
};

type PointsReward = Readonly<{
  id: string;
  name: string;
  points: number;
  assetUrl: string | null;
}>;
