import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, Button, FallbackImage, theme } from '@sg/garnish';

import { PayEmptyStateIllustration } from '../../../../assets';
import { useEarnRedeemContent } from '../../../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanInStorePayEmptyState = (
  props: ScanInStorePayEmptyStateProps,
) => {
  const { onPressCta } = props;
  const { giftCardBannerImage, giftCardBannerA11y } = useEarnRedeemContent();

  return (
    <View style={styles.container}>
      <FallbackImage
        baseUrl={giftCardBannerImage}
        source={PayEmptyStateIllustration}
        style={styles.illustration}
        contentfulOptions={contentfulOptions}
        aria-label={giftCardBannerA11y}
      />

      <BodyText sizeMatch={['18']} style={styles.body}>
        <FormattedMessage {...messages.body} />
      </BodyText>

      <Button
        size="large"
        palette="secondary"
        style={styles.cta}
        onPress={onPressCta}
      >
        <FormattedMessage {...messages.cta} />
      </Button>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const bannerHeight = 206;
const contentfulOptions = {
  fm: 'webp' as const,
  h: bannerHeight,
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  body: {
    defaultMessage:
      'Have a gift card? Add it to your account and use it in-store or for digital orders.',
    description: 'Scan In Store | Empty State | Body',
  },
  cta: {
    defaultMessage: 'Add gift card',
    description: 'Scan In Store | Empty State | CTA',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.QUINOA,
  },
  body: {
    marginVertical: theme.spacing['8'],
    marginHorizontal: theme.spacing['6'],
    textAlign: 'center',
  },
  cta: {
    marginBottom: theme.spacing['8'],
  },
  illustration: {
    width: '100%',
    height: bannerHeight,
    borderTopLeftRadius: theme.radius.large,
    borderTopRightRadius: theme.radius.large,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanInStorePayEmptyStateProps = Readonly<{
  onPressCta: () => void;
}>;
