import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Linking, Text } from 'react-native';
import {
  getCloudinaryUrl,
  getFormattedExpirationDate,
  LoyaltyOfferCard as OfferCard,
  useResponsive,
} from '@sg/garnish';
import { type Challenge, ChallengeProgressType } from '@sg/graphql-schema';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCard = (props: LoyaltyOfferCardProps) => {
  const {
    offer,
    isLoading,
    layout = 'standard',
    rounded,
    withBorder = false,
    onStartButtonPress,
    onOrderButtonPress,
  } = props;

  const { match } = useResponsive();
  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const expirationDate = getFormattedExpirationDate(
    offer.expirationDate,
    formatMessage(messages.endsPrefix),
  );
  const formattedImageUrl = offer.assetUrl
    ? match([
        getFormattedAssetUrl(offer.assetUrl).XS,
        getFormattedAssetUrl(offer.assetUrl).SM,
      ])
    : undefined;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const openTerms = useCallback(() => {
    void Linking.openURL(TERMS_LINK);
  }, []);

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldDisplayTag = checkIfNotStartedOffer(offer) && layout === 'standard'; // prettier-ignore
  const shouldDisplayProgressTag = checkIfInProgressOffer(offer) && layout === 'standard'; // prettier-ignore
  const shouldDisplayCompletedLabel = checkIfShouldCompletedLabel(offer);
  const shouldDisplayHurdleCompletedLabel = checkIfShouldDisplayHurdleCompletedLabel(offer); // prettier-ignore
  const shouldDisplayLabel = checkIfStartedOffer(offer) && layout === 'standard'; // prettier-ignore
  const shouldDisplayStartButton = checkIfNotStartedOffer(offer);
  const shouldDisplayOrderButton = checkIfShouldDisplayOrderButton(offer);

  const shouldDisplayStepProgressBar = checkIfShouldDisplayStepProgressBar(offer); // prettier-ignore
  const shouldDisplaySpendProgressBar = checkIfShouldDisplaySpendProgressBar(offer); // prettier-ignore
  const shouldDisplayHurdleSpendProgressBar = checkIfShouldDisplayHurdleSpendProgressBar(offer); // prettier-ignore
  const shouldShowProgressBar = shouldDisplaySpendProgressBar || shouldDisplayStepProgressBar || shouldDisplayHurdleSpendProgressBar; // prettier-ignore

  // ─────────────────────────────────────────────────────────────────────

  return (
    <OfferCard.Container rounded={rounded} withBorder={withBorder}>
      <OfferCard.Image
        source={formattedImageUrl}
        size={layout === 'compact' ? 'small' : 'large'}
      />

      {shouldDisplayCompletedLabel ? (
        <OfferCard.FloatingLabel>
          <FormattedMessage {...messages.labelCompleted} />
        </OfferCard.FloatingLabel>
      ) : null}

      {shouldDisplayHurdleCompletedLabel && getAchievedHurdleOutcome(offer) ? (
        <OfferCard.FloatingLabel>
          <FormattedMessage
            {...messages.pointsEarned}
            values={{ points: getAchievedHurdleOutcome(offer) }}
          />
        </OfferCard.FloatingLabel>
      ) : null}

      {checkIfShouldDisplayUnlimitedCompletedLabel(offer) ? (
        <OfferCard.FloatingLabel>
          <FormattedMessage
            {...messages.completedTimes}
            values={{ count: offer.achieved }}
          />
        </OfferCard.FloatingLabel>
      ) : null}

      <OfferCard.ContentContainer
        size={layout === 'compact' ? 'small' : 'large'}
      >
        {shouldDisplayTag ? (
          <OfferCard.Tag>
            <FormattedMessage {...messages.tagNew} />
          </OfferCard.Tag>
        ) : null}

        {shouldDisplayProgressTag ? (
          <OfferCard.Tag palette="forest">{offer.progressCopy}</OfferCard.Tag>
        ) : null}

        {shouldDisplayLabel ? <LoyaltyOfferCardLabel offer={offer} /> : null}

        {/* ──────────────────────────────────────────────────────────────── */}

        <OfferCard.Title sizeMatch={layout === 'standard' ? ['36'] : ['24']}>
          {offer.title}
        </OfferCard.Title>

        {/* ─── Description and Terms ────────────────────────────────────── */}

        <OfferCard.Text>
          {offer.descriptiveTitle}

          {expirationDate ? (
            <>
              <Text> </Text>
              <OfferCard.Text>{expirationDate}</OfferCard.Text>
              <Text>.</Text>
            </>
          ) : null}

          <Text> </Text>

          {layout === 'standard' ? (
            <OfferCard.PressableText onPress={openTerms}>
              <FormattedMessage {...messages.details} />
              <Text>.</Text>
            </OfferCard.PressableText>
          ) : null}
        </OfferCard.Text>

        {/* ─── Progress Bars ────────────────────────────────────────────── */}

        {shouldShowProgressBar ? (
          <OfferCard.ProgressBar.Container
            size={layout === 'compact' ? 'small' : 'large'}
          >
            {shouldDisplaySpendProgressBar ? (
              <OfferCard.ProgressBar.Spend
                percentageCompleted={offer.percentageCompleted}
                accessibilityLabel={formatMessage(
                  messages.progressBarA11yLabel,
                  { progress: offer.percentageCompleted },
                )}
              />
            ) : null}

            {shouldDisplayStepProgressBar ? (
              <OfferCard.ProgressBar.Step
                currentStep={offer.progress}
                steps={offer.goal}
                percentageCompleted={offer.percentageCompleted}
                accessibilityLabel={formatMessage(
                  messages.progressBarA11yLabel,
                  { progress: offer.percentageCompleted },
                )}
                labels={getSteppedHurdleLabels(
                  offer?.multipleBehaviorDetails,
                  offer.goal,
                )}
              />
            ) : null}

            {shouldDisplayHurdleSpendProgressBar ? (
              <OfferCard.ProgressBar.HurdleSpend
                hurdles={offer.multipleBehaviorDetails?.hurdleBehaviors ?? []}
                percentageCompleted={offer.percentageCompleted}
                accessibilityLabel={formatMessage(
                  messages.progressBarA11yLabel,
                  { progress: offer.percentageCompleted },
                )}
              />
            ) : null}
          </OfferCard.ProgressBar.Container>
        ) : null}
      </OfferCard.ContentContainer>

      {/* ─── Footer ─────────────────────────────────────────────────────── */}

      {layout === 'compact' ? (
        <OfferCard.Footer size="small">
          <OfferCard.PressableText onPress={openTerms}>
            <FormattedMessage {...messages.details} />
          </OfferCard.PressableText>

          {shouldDisplayStartButton ? (
            <OfferCard.Button
              size="medium"
              onPress={onStartButtonPress}
              isLoading={isLoading}
            >
              <FormattedMessage {...messages.startButtonLabel} />
            </OfferCard.Button>
          ) : null}

          {shouldDisplayOrderButton ? (
            <OfferCard.Button
              size="xsmall"
              onPress={onOrderButtonPress}
              palette="white"
            >
              {offer.progressCopy}
            </OfferCard.Button>
          ) : null}
        </OfferCard.Footer>
      ) : null}

      {layout === 'standard' &&
      (shouldDisplayStartButton || shouldDisplayOrderButton) ? (
        <OfferCard.Footer size="medium">
          {shouldDisplayStartButton ? (
            <OfferCard.Button
              fluid
              size={match(['large', 'large-wide'])}
              onPress={onStartButtonPress}
              isLoading={isLoading}
            >
              <FormattedMessage {...messages.startButtonLabel} />
            </OfferCard.Button>
          ) : null}

          {shouldDisplayOrderButton ? (
            <OfferCard.Button
              fluid
              size={match(['large', 'large-wide'])}
              onPress={onOrderButtonPress}
              palette="white"
            >
              <FormattedMessage {...messages.orderNowButtonLabel} />
            </OfferCard.Button>
          ) : null}
        </OfferCard.Footer>
      ) : null}
    </OfferCard.Container>
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const LoyaltyOfferCardLabel = (props: LoyaltyOfferCardLabelProps) => {
  const { offer } = props;

  if (offer.status === 'STARTED') {
    return (
      <OfferCard.Label>
        <FormattedMessage {...messages.labelStarted} />
      </OfferCard.Label>
    );
  }

  if (offer.status === 'IN_PROGRESS') {
    return (
      <OfferCard.Label>
        <FormattedMessage {...messages.labelInProgress} />
      </OfferCard.Label>
    );
  }

  return null;
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function checkIfHurdleOffer(offer: Offer) {
  return offer.themeV2 === 'HURDLE';
}

function checkIfUnlimitedOffer(offer: Offer) {
  return offer.themeV2 === 'UNLIMITED';
}

function checkIfNotStartedOffer(offer: Offer) {
  return offer.status === 'NOT_STARTED';
}

function checkIfStartedOffer(offer: Offer) {
  return offer.status === 'STARTED';
}

function checkIfInProgressOffer(offer: Offer) {
  return offer.status === 'IN_PROGRESS';
}

function checkIfCompletedOffer(offer: Offer) {
  return offer.status === 'COMPLETED';
}

function checkIfStepOffer(offer: Offer) {
  return offer.progressType === ChallengeProgressType.Step;
}

function checkIfSpendOffer(offer: Offer) {
  return offer.progressType === ChallengeProgressType.Spend;
}

function checkIfHasHurdles(offer: Offer) {
  if (!checkIfHurdleOffer(offer)) return false;

  const { multipleBehaviorDetails } = offer;
  const maybeMultipleBehaviorDetails = multipleBehaviorDetails?.hurdleBehaviors ?? []; // prettier-ignore

  return maybeMultipleBehaviorDetails.length > 0;
}

function checkIfShouldDisplayProgressBar(offer: Offer) {
  return checkIfStartedOffer(offer) || checkIfInProgressOffer(offer);
}

function checkIfShouldDisplayStepProgressBar(offer: Offer) {
  return (
    !checkIfUnlimitedOffer(offer) &&
    (checkIfShouldDisplayProgressBar(offer) ||
      (checkIfHurdleOffer(offer) && checkIfCompletedOffer(offer))) &&
    checkIfStepOffer(offer)
  );
}

function checkIfShouldDisplaySpendProgressBar(offer: Offer) {
  return (
    !checkIfHurdleOffer(offer) &&
    checkIfShouldDisplayProgressBar(offer) &&
    checkIfSpendOffer(offer)
  );
}

function checkIfShouldDisplayHurdleSpendProgressBar(offer: Offer) {
  return (
    checkIfHasHurdles(offer) &&
    (checkIfShouldDisplayProgressBar(offer) || checkIfCompletedOffer(offer)) &&
    checkIfSpendOffer(offer)
  );
}

function checkIfShouldDisplayOrderButton(offer: Offer) {
  return (
    !(checkIfUnlimitedOffer(offer) && checkIfStepOffer(offer)) &&
    (checkIfStartedOffer(offer) || checkIfInProgressOffer(offer))
  );
}

function checkIfShouldCompletedLabel(offer: Offer) {
  return !checkIfUnlimitedOffer(offer) && checkIfCompletedOffer(offer);
}

function checkIfShouldDisplayHurdleCompletedLabel(offer: Offer) {
  return (
    checkIfHasHurdles(offer) &&
    checkIfInProgressOffer(offer) &&
    Boolean(getAchievedHurdleOutcome(offer))
  );
}

function checkIfShouldDisplayUnlimitedCompletedLabel(offer: Offer) {
  return (
    checkIfUnlimitedOffer(offer) &&
    (checkIfInProgressOffer(offer) || checkIfCompletedOffer(offer)) &&
    offer.achieved > 0
  );
}

//  ─────────────────────────────────────────────────────────────────────────────

function getSteppedHurdleLabels(
  multipleBehaviorDetails: Challenge['multipleBehaviorDetails'] | undefined,
  offerGoal: number,
) {
  const maybeHurdleBehaviors = multipleBehaviorDetails?.hurdleBehaviors ?? [];

  if (maybeHurdleBehaviors.length === 0) return [];

  const outcomes = maybeHurdleBehaviors.reduce<Record<number, string>>(
    (outcomesList, hurdleBehavior) => {
      const { outcome, goal } = hurdleBehavior;
      const isInvalid = Number.isNaN(outcome);

      if (isInvalid) return outcomesList;

      return { ...outcomesList, [goal]: `${outcome}` };
    },
    {},
  );

  return Array.from({ length: offerGoal }, (_, index) => outcomes[index + 1]);
}

function getAchievedHurdleOutcome(offer: Offer) {
  const hurdles = offer.multipleBehaviorDetails?.hurdleBehaviors ?? [];
  const achievedHurdle = [...hurdles].reverse().find((hurdle) => hurdle.achieved >= 1); //  prettier-ignore

  return achievedHurdle?.outcome;
}

function getFormattedAssetUrl(assetUrl: string) {
  return {
    XS: getCloudinaryUrl(assetUrl, { height: IMAGE_SIZE.XS }),
    SM: getCloudinaryUrl(assetUrl, { height: IMAGE_SIZE.SM }),
  };
}

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  tagNew: {
    defaultMessage: 'New!',
    description: 'Loyalty | Offer Card | Tag | New',
  },
  details: {
    defaultMessage: 'Details',
    description: 'Loyalty | Offer Card | Details',
  },
  labelStarted: {
    defaultMessage: 'Started',
    description: 'Loyalty | Offer Card | Label | Started',
  },
  labelInProgress: {
    defaultMessage: 'In progress!',
    description: 'Loyalty | Offer Card | Label | In progress',
  },
  labelCompleted: {
    defaultMessage: 'Completed',
    description: 'Loyalty | Offer Card | Label | Completed',
  },
  endsPrefix: {
    defaultMessage: 'Ends',
    description: 'Loyalty | Offer Card | Ends prefix',
  },
  startButtonLabel: {
    defaultMessage: 'Start',
    description: 'Loyalty | Offer Card | Button | Start',
  },
  orderNowButtonLabel: {
    defaultMessage: 'Order now',
    description: 'Loyalty | Offer Card | Button | Order now',
  },
  pointsEarned: {
    defaultMessage: '{points} points earned!',
    description: 'Loyalty | Offer Card | Button | Points earned',
  },
  completedTimes: {
    defaultMessage: 'You completed {count}x',
    description: 'Loyalty | Offer Card | Button |  Completed times',
  },
  progressBarA11yLabel: {
    defaultMessage: `Offer completion progress is {progress}%`,
    description: 'Loyalty | Offer Card | Progress bar | a11y label',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const TERMS_LINK = 'https://www.sweetgreen.com/terms';
const IMAGE_SIZE = {
  XS: 190,
  SM: 330,
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProps = {
  offer: Offer;
  isLoading: boolean;
  rounded?: boolean;
  withBorder?: boolean;
  layout?: 'compact' | 'standard';
  onStartButtonPress: () => void;
  onOrderButtonPress: () => void;
};

type LoyaltyOfferCardLabelProps = {
  offer: Offer;
};

type Offer = Omit<
  Challenge,
  'theme' | 'themeV2' | 'multipleBehaviorDetails'
> & {
  themeV2?: Challenge['themeV2'];
  multipleBehaviorDetails?: Challenge['multipleBehaviorDetails'];
};
