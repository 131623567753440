import React from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { Container, theme, useResponsive } from '@sg/garnish';

/**
 * Container to be used for both challenges and rewards rails and empty states.
 * The goal is to have a single implementation shared amongst the four usages.
 * So as to avoid discrepancies in margins between rail and empty states.
 */
export const LoyaltyHomeChallengesRewardsContainer = (props: ViewProps) => {
  const { match, minWidth } = useResponsive();

  const containerStyle = [
    match([styles.containerXS, styles.containerSM]),
    minWidth.isLG && styles.containerStaticSpacingLG,
    props.style,
  ];

  if (minWidth.isLG) {
    return <View style={[containerStyle, props.style]}>{props.children}</View>;
  }

  return (
    <Container style={[containerStyle, props.style]}>
      {props.children}
    </Container>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const RAIL_SPACING_LG = theme.spacing['30'];

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    backgroundColor: theme.colors.FOREST,
    paddingVertical: theme.spacing['10'],
  },
  containerSM: {
    backgroundColor: theme.colors.FOREST,
    paddingVertical: theme.spacing['10'],
    gap: theme.spacing['6'],
  },
  containerStaticSpacingLG: {
    paddingHorizontal: RAIL_SPACING_LG,
  },
});
