import { useMemo } from 'react';
import { TierName } from '@sg/graphql-schema';

import { useUserRewardsPointsQuery } from '../../graphql/UserRewardsPoints.generated';

export const useUserRewardsPoints = () => {
  const [{ data, fetching }, refetch] = useUserRewardsPointsQuery({
    requestPolicy: 'cache-and-network',
  });

  return useMemo(() => {
    const loyaltyProfile = data?.loyaltyProfile;
    const loyaltyId = loyaltyProfile?.loyaltyId ?? null;
    const availablePoints = loyaltyProfile?.points?.available ?? 0;

    const customerTier = loyaltyProfile?.tier?.nameV2;
    const isOptedOut = customerTier === TierName.RewardsOptedOut;

    return {
      fetching,
      loyaltyId,
      availablePoints,
      isOptedOut,
      refetch,
    };
  }, [data?.loyaltyProfile, fetching, refetch]);
};
