import React, { type PropsWithChildren } from 'react';
import { type StyleProp, StyleSheet, type TextStyle } from 'react-native';
import { theme, TitleText } from '@sg/garnish';

export const LoyaltyPostPurchaseTitle = (
  props: LoyaltyPostPurchaseTitleProps,
) => {
  const { children, style } = props;

  return (
    <TitleText sizeMatch={['36']} style={[styles.title, style]}>
      {children}
    </TitleText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  title: {
    color: theme.colors.SWEET_CORN,
    marginTop: theme.spacing['20'],
    textAlign: 'center',
    fontFamily: 'GrenettePro-Regular',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyPostPurchaseTitleProps = PropsWithChildren<{
  style?: StyleProp<TextStyle>;
}>;
