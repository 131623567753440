/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { assign, setup } from 'xstate5';

import { type Context, type Event } from './model';

// ─── Model ───────────────────────────────────────────────────────────────────

const model = setup({
  types: {
    context: {} as Context,
    events: {} as Event,
  },
});

// ─── Machine ─────────────────────────────────────────────────────────────────

/**
 * A state machine for managing post-purchase modal.
 */
export const postPurchaseModalMachine = model.createMachine({
  context: {
    orderId: undefined,
  },
  initial: 'hidden',
  states: {
    hidden: {
      on: {
        show: {
          target: 'showing',
          actions: assign((args) => ({
            orderId: args.event.orderId,
          })),
        },
      },
    },
    showing: {
      on: {
        hide: {
          target: 'hidden',
          actions: assign({
            orderId: undefined,
          }),
        },
      },
    },
  },
});
