import { defineMessages } from 'react-intl';

export const fallbacks = defineMessages({
  bagHeaderLoggedOut: {
    defaultMessage: 'Complete your order + earn',
    description: 'Loyalty Content | Title Logged Out',
  },
  bagHeaderNewUser: {
    defaultMessage: 'You’re about to get rewarded',
    description: 'Loyalty Content | Title New User',
  },
  bagHeaderRecurringUser: {
    defaultMessage: 'You have {points} points',
    description: 'Loyalty Content | Title Recurring User',
  },
  bagHeaderSubtitle: {
    defaultMessage: 'Apply one available reward to this order',
    description: 'Loyalty Content | Subtitle',
  },
  bagHeaderLoggedOutImageMessage: {
    defaultMessage:
      'Get a free side of Ripple Fries when you create an account',
    description: 'Loyalty Content | Image Text',
  },
  earnPointsTitle: {
    defaultMessage: 'Earn points',
    description: 'Loyalty Content | Earn points | Title',
  },
  earnPointsDescription: {
    defaultMessage:
      'Scan your QR code at checkout to earn 10 points for every $1 spent',
    description: 'Loyalty Content | Earn points | Description',
  },
  redeemRewardsTitle: {
    defaultMessage: 'Redeem rewards',
    description: 'Loyalty Content | Redeem rewards | Title',
  },
  redeemRewardsDescription: {
    defaultMessage: 'Use points to unlock rewards and free Sweetgreen',
    description: 'Loyalty Content | Redeem rewards | Description',
  },
  lookOutForOffersTitle: {
    defaultMessage: 'Look out for offers',
    description: 'Loyalty Content | Look out for offers | Title',
  },
  lookOutForOffersDescription: {
    defaultMessage:
      'Look out for offers to earn more points and special rewards',
    description: 'Loyalty Content | Look out for offers | Description',
  },
  homeChallengesEmptyStateTitle: {
    defaultMessage: 'Free delicious deals ahead',
    description: 'Loyalty Content | Home | Challenges | Empty State | Title',
  },
  homeChallengesEmptyStateBody: {
    defaultMessage: 'Earn 10 points for every $1',
    description: 'Loyalty Content | Home | Challenges | Empty State | Body',
  },
  homeChallengesEmptyStateCtaXS: {
    defaultMessage: 'Start order',
    description: 'Loyalty Content | Home | Challenges | Empty State | CTA XS',
  },
  homeChallengesEmptyStateCtaSM: {
    defaultMessage: 'Start an order',
    description: 'Loyalty Content | Home | Challenges | Empty State | CTA SM',
  },
});
