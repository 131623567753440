import React from 'react';
import { type ViewProps } from 'react-native';
import Animated, { type SharedValue } from 'react-native-reanimated';

import { type CustomNavigationHeaderContentTransition } from '../../CustomNavigationHeader.types';
import { useOpacityAnimation } from '../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomNavigationHeaderContent = (
  props: CustomNavigationHeaderContentProps,
) => {
  const {
    children,
    scrollOffsetSV,
    transition = 'opacity',
    style,
    ...viewProps
  } = props;

  // ─── Animated Styles ─────────────────────────────────────────────────

  const opacityAnimation = useOpacityAnimation({
    scrollOffsetSV,
  });

  const animatedContentStyle =
    transition === 'opacity' ? opacityAnimation : undefined;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Animated.View style={[animatedContentStyle, style]} {...viewProps}>
      {children}
    </Animated.View>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomNavigationHeaderContentProps = {
  /**
   * Optional transition type, to be used as the content scrolls.
   * If {null} is passed it will have no scrolling indicator.
   */
  transition?: CustomNavigationHeaderContentTransition | null;

  /**
   * An optional shared value of the associated scroll view offset, which can be
   * used for animation.
   */
  scrollOffsetSV?: SharedValue<number>;
} & ViewProps;
