import React, { useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  type LayoutChangeEvent,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native';
import Animated from 'react-native-reanimated';
import { theme } from '@garnish/constants';
import { Container, useResponsive } from '@sg/garnish';

import { LoyaltyHomeProgressBarMilestone } from './components';
import { useLoyaltyHomeProgressBarAnimation } from './hooks';

export const LoyaltyHomeProgressBar = (props: LoyaltyHomeProgressBarProps) => {
  const { availablePoints, totalPoints, pointsRewards, palette } = props;
  const { formatMessage } = useIntl();

  const { match } = useResponsive();

  const containerSpacing = match([
    theme.spacing['6'],
    theme.spacing['10'],
    theme.spacing['30'],
  ]);

  const barSize = match([styles.barSizeXS, styles.barSizeSM]);

  const progressBarColorBase = palettesBase[palette];
  const progressBarColorPending = palettesPending[palette];
  const progressBarColorAvailable = palettesAvailable[palette];

  const [progressBarWidth, setProgressBarWidth] = useState(0);

  const progress = useMemo(() => {
    const lastRewardPoints = pointsRewards.at(-1)?.points ?? 0;
    return (availablePoints / lastRewardPoints) * 100;
  }, [availablePoints, pointsRewards]);

  const { availableBarStyle, pendingBarStyle } =
    useLoyaltyHomeProgressBarAnimation({
      progressBarWidth,
      availablePoints,
      totalPoints,
      pointsRewards,
    });

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setProgressBarWidth(event.nativeEvent.layout.width);
  }, []);

  return (
    <Container
      customSpacing={containerSpacing}
      accessibilityRole="progressbar"
      accessibilityLabel={formatMessage(messages.progressBar, { progress })}
      accessibilityValue={{ min: 0, max: 100, now: progress }}
    >
      <View>
        <Animated.View
          style={[
            styles.pendingBar,
            barSize,
            progressBarColorPending,
            pendingBarStyle,
          ]}
        />

        <Animated.View
          style={[
            styles.availableBar,
            barSize,
            progressBarColorAvailable,
            availableBarStyle,
          ]}
        />

        <View
          style={[styles.progressBar, barSize, progressBarColorBase]}
          onLayout={onLayout}
        />

        <View style={styles.milestonesContainer}>
          <LoyaltyHomeProgressBarMilestone
            palette={palette}
            availablePoints={availablePoints}
          />

          {pointsRewards.map((pointsReward) => (
            <LoyaltyHomeProgressBarMilestone
              key={pointsReward.id}
              palette={palette}
              availablePoints={availablePoints}
              pointsReward={pointsReward}
            />
          ))}
        </View>
      </View>
    </Container>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  progressBar: {
    defaultMessage: `Loyalty reward progress bar: {progress}%}`,
    description: 'Loyalty Home | Progress Bar | A11y',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  barSizeXS: {
    minHeight: 16,
    borderRadius: 16,
  },
  barSizeSM: {
    minHeight: 40,
    borderRadius: 40,
  },
  progressBar: {
    zIndex: 1,
    flex: 1,
    flexDirection: 'row',
  },
  pendingBar: {
    zIndex: 2,
    position: 'absolute',
  },
  availableBar: {
    zIndex: 3,
    position: 'absolute',
  },
  milestonesContainer: {
    zIndex: 4,
    flexDirection: 'row',
    gap: theme.spacing['3'],
  },
});

// ─── Palettes ───────────────────────────────────────────────────────────────

const palettesBase: Record<LoyaltyHomeProgressBarProps['palette'], ViewStyle> =
  {
    dark: {
      backgroundColor: theme.colors.BASIL,
      borderColor: theme.colors.CREAM,
      borderWidth: 1,
    },
    light: {
      backgroundColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
    },
  };

const palettesPending: Record<
  LoyaltyHomeProgressBarProps['palette'],
  ViewStyle
> = {
  dark: {
    backgroundColor: theme.colors.CREAM,
    borderColor: theme.colors.CREAM,
    borderWidth: 1,
  },
  light: {
    backgroundColor: theme.colors.AVOCADO,
  },
};

const palettesAvailable: Record<
  LoyaltyHomeProgressBarProps['palette'],
  ViewStyle
> = {
  dark: {
    backgroundColor: theme.colors.AVOCADO,
    borderColor: theme.colors.CREAM,
    borderWidth: 1,
  },
  light: {
    backgroundColor: theme.colors.DARK_BASIL,
  },
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeProgressBarProps = {
  availablePoints: number;
  totalPoints: number;
  pointsRewards: readonly PointsReward[];
  palette: 'dark' | 'light';
};

type PointsReward = {
  id: string;
  name?: string;
  points?: number | null;
  assetUrl?: string | null;
};
