import { useCallback, useState } from 'react';
import { type Reward } from '@sg/graphql-schema';

/**
 * Returns which term is being shown.
 */
export const useTermsBeingShown = () => {
  const [termsBeingShown, setTermsBeingShown] = useState<Reward | null>(null);

  const showTerms = useCallback((reward: Reward) => {
    return () => {
      setTermsBeingShown(reward);
    };
  }, []);

  const hideTerms = useCallback(() => {
    setTermsBeingShown(null);
  }, []);

  return {
    termsBeingShown,
    showTerms,
    hideTerms,
  };
};
