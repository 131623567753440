import { useCallback } from 'react';

import { useTelemetry } from '@order/Telemetry';

import { useLoyaltyPremiumStatus } from '../../useLoyaltyPremiumStatus';
import { useOnOnboardingModalShow } from '../useOnOnboardingModalShow';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Custom hook to track the display of the loyalty onboarding modal using telemetry.
 *
 * Can be used as an effect (`useEffect`, `useFocusEffect`) or in response to an action.
 */
export const useTrackLoyaltyOnboardingModal = () => {
  const { track } = useTelemetry();
  const { isPremium } = useLoyaltyPremiumStatus();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackLoyaltyOnboardingModalShow = useCallback(() => {
    track('sgrewards.onboarding_view', { isGOAT: isPremium });
  }, [isPremium, track]);

  // ─────────────────────────────────────────────────────────────────────

  return useOnOnboardingModalShow({
    onModalShow: trackLoyaltyOnboardingModalShow,
  });
};
