import { defineMessages } from 'react-intl';

export const reorderHomeScreenMessages = defineMessages({
  screenTitle: {
    defaultMessage: 'Reorder',
    description: 'Reorder Home Screen | Title',
  },
  screenCustomDocumentTitle: {
    defaultMessage: 'Reorder | sweetgreen',
    description: 'Reorder Screen | Custom Document Title',
  },
});
