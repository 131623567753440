import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { format } from 'date-fns';
import {
  BodyText,
  DisplayText,
  TextLinkify,
  theme,
  useResponsive,
} from '@sg/garnish';

/**
 * Terms for the loyalty reward.
 */
export const LoyaltyRewardTerms = (props: LoyaltyRewardTermsProps) => {
  const { title, terms, expirationDate } = props;

  const { match } = useResponsive();

  const expiration = expirationDate
    ? format(new Date(expirationDate), 'MMM d')
    : null;

  return (
    <View style={match([styles.containerXS, styles.containerSM])}>
      <DisplayText sizeMatch={['24']}>{title}</DisplayText>

      {expiration ? (
        <BodyText sizeMatch={['12']} style={styles.expirationLabel}>
          <FormattedMessage
            {...messages.expirationDate}
            values={{ expiration }}
          />
        </BodyText>
      ) : null}

      <BodyText sizeMatch={['16']}>
        <TextLinkify>{terms}</TextLinkify>
      </BodyText>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  expirationDate: {
    defaultMessage: 'Use by {expiration}',
    description: 'Loyalty | Reward | Terms | Expiration date',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['10'],
    gap: theme.spacing['2'],
  },
  containerSM: {
    paddingHorizontal: theme.spacing['6'],
    paddingBottom: theme.spacing['10'],
    gap: theme.spacing['2'],
  },
  expirationLabel: {
    color: theme.colors.CHARCOAL,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyRewardTermsProps = {
  title: string;
  terms: string;
  expirationDate?: string | null;
};
